import React, { FC, useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  IconButton,
  Grid,
  TextField,
} from '@mui/material';
import ItemList from '../../newLayer/ItemListNewLayer';
import SearchIcon from '@mui/icons-material/Search';
import {
  getAssetChildrenPage,
  getAssetById,
} from '../../../../assets/services';
import { useTranslation } from 'react-i18next';
import { Asset } from '../../../../models/asset';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import SnackbarObject from '../../../../lib/components/Snackbar/SnackbarObject';

export interface AssetBrowseProps {
	asset: Asset;
	setMode: (value: any) => void;
	setAsset: (asset: Asset) => void;
}

const AssetBrowse: FC<AssetBrowseProps> = ({ setMode, asset, setAsset }) => {
	const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
	const [snackBarMsg, setSnackBarMsg] = React.useState<{
		msg: string;
		severity: string;
	}>({ msg: '', severity: 'success' });
	const { t } = useTranslation();
	const [search, setSearch] = useState<boolean>(false);
	const pageSize = 4;
	let page = 1;

	const handleActiveSearch = () => {
		setSearch(!search);
	};
	const handleParent = async () => {
		try{
		if (asset.parentId != null) {
			const parent = await getAssetById(asset.parentId);
			setAsset(parent);
		}
		} catch (error) {
			setSnackBarMsg({ msg: t("Sorry, an unexpected error has ocurred"), severity: "error" });
			setOpenSnackbar(true);
		}
	};

	const handleOpenChild = (child: Asset) => {
		setAsset(child);
	};
	const handleGetChildren = async () => {
		try {
		if (asset.id != null) {
			const params = {
				pageSize,
				page,
			};
			const children = await getAssetChildrenPage(asset.id, params);
			setAsset({ ...asset, children: children });
		}
		} catch (error) {
			setSnackBarMsg({ msg: t("Sorry, an unexpected error has ocurred"), severity: "error" });
			setOpenSnackbar(true);
		}
	};

	// pagination
	const handleNext = () => {
		if (asset.children != null) {
		const nPages = Math.ceil(asset.children?.count / pageSize);
		if (page < nPages) {
			page = page + 1;
			handleGetChildren();
		}
		}
	};
	const handlePrev = () => {
		if (page > 1) {
		page = page - 1;
		handleGetChildren();
		}
	};

	useEffect(() => {
		handleGetChildren();
		setSearch(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [asset.id]);
	//console.log(asset);

	return (
		<>
		<SnackbarObject
			type="alert"
			openSnackbar={openSnackbar}
			setOpenSnackbar={setOpenSnackbar}
			msg={snackBarMsg.msg}
			severity={snackBarMsg.severity}
		/>
		<Grid
			container
			spacing={2}
			sx={{ marginLeft: '2px' }}
			alignItems="center"
		>
			{!search && (
			<Grid item xs={asset.parentId != null ? 0 : 1}>
				{asset.parentId != null ? (
				<IconButton onClick={handleParent}>
					<SubdirectoryArrowLeftIcon sx={{transform: "rotate(90deg)"}}/>
				</IconButton>
				) : (
				<Divider
					sx={{
					marginTop: '8px',
					borderStyle: 'dashed',
					borderWidth: '0.1rem',
					}}
				/>
				)}
			</Grid>
			)}

			<Grid item xs={search ? 9 : asset.parentId != null ? 7 : 8}>
			{search ? (
				<TextField
				id="q"
				type="search"
				label={t('Search asset')}
				//value={}
				onChange={() => console.log('asd')}
				variant="outlined"
				autoComplete="off"
				size="small"
				/>
			) : (
				<Typography>{asset.title}</Typography>
			)}
			</Grid>
			<Grid item sx={{ height: '100%'}}>
			<IconButton
				onClick={handleActiveSearch}
				sx={{marginLeft: '10px'}}
				color="secondary"
				aria-label={t('Search')}
				size="small"
			>
				<SearchIcon />
			</IconButton>
			</Grid>
		</Grid>

		<ItemList
			data={asset.children}
			next={handleNext}
			prev={handlePrev}
			isAsset={true}
			addChild={true}
			setMode={setMode}
			showEye={true}
			handleClick={handleOpenChild}
		/>
		</>
	);
};
export default AssetBrowse;
