import React, {useEffect, KeyboardEvent} from 'react';
import {
    Theme,
    Toolbar,
    Typography,
    Container,
    Grid,
    Hidden,
    TextField,
    IconButton
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import SelectOrgList from './SelectOrgList';
import {useAsyncCallback} from "react-async-hook";
import ErrorMsg from "../../layout/ErrorMsg";
import LoadingMsg from "../../layout/LoadingMsg";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            display: 'flex',
        },
        title: {
            flexGrow: 1,
        },
        container: {
            padding: 0,
        },
        row: {},
        textSearch: {
            width: "90%",
        },
        iconButton: {
            marginTop: "3px",
            marginLeft: "-55px",
        }
    }),
);

interface InputState {
    groupId: string,
    page: number,
    pageSize: number,
    q: string,
};

export interface Org {
    id: string,
    title: string,
};

export interface SearchResponse {
    page: Org[],
   count: number,
}


let wbInput: InputState | null = null; // para guardar el historico de datos

async function fetchSearch(values: InputState): Promise<any> {
    /*
    const params = {
        groupId: values.groupId,
        page: values.page,
        pageSize: values.pageSize,
        q: values.q,
    }
    */
    // const qs = querystring.stringify(params);
    /*
    return await akoFetchCHANGE(BACKEND_URL + '/v1/groups?' + qs, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    */
}

// get group id 
async function fetchGroupId(): Promise<any> {
    /*
    return await akoFetchCHANGE(BACKEND_URL + '/v1/groups',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    */
}

function SelectOrgPage(props: any) {
    console.debug("SelectOrgPage start...");
    const classes = useStyles();
    const { t } = useTranslation();
    
    // state: input values
    const [q, setQ]= React.useState<string>("");
    const [input, setInput] = React.useState<InputState>({
        groupId: "",
        page: 1,
        pageSize: 4,
        q: "",
    });

    // pagination
    function handleNext(){
        //console.log(input, search.result);
        const nPages = Math.ceil(search.result!.count / input.pageSize);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });
        }
    }

    function handlePrev(){
        //console.log(input, search.result);
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
        }
    }
   
    useEffect(() => {
        if (input.groupId) {
            search.execute(input);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    useEffect(() => {
        // recover from wayback
        if (wbInput) {
            setInput(wbInput);
        // or init groupId
        } else {
            fetchGroupId()
            .then(groups => {
                setInput({...input, groupId: groups.page[0].id});
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        wbInput = {...input, [prop]: event.target.value};
        setQ(event.target.value);
    };

    // handle search event
    const search = useAsyncCallback(fetchSearch);

    function handleSearch(event: any) {
        setInput({...input, q: q});
    }

    function handleEnter(event: KeyboardEvent<HTMLDivElement>) {        
        if (event.key === "Enter") {
            setInput({...input, q: q});
        }
    }

    // return children
    return (
        <Container className={classes.container} maxWidth={false}>
            {search.loading && (<LoadingMsg/>)}
            {search.error && (<ErrorMsg error={search.error}/>)}
            <Toolbar disableGutters={true}>
                <Typography component="div" variant="h4" color="inherit" noWrap className={classes.title}>
                    <Trans>Change current Organization</Trans>
                </Typography>
                <Hidden mdUp>
                    <span></span>
                </Hidden>
            </Toolbar>

            <Grid container spacing={0}>
                <Grid item xs={12} md={8}>
                    <Container>
                        <TextField
                            className={classes.textSearch}
                            id="q"
                            type="text"
                            label={t("Search")}
                            value={q}
                            onChange={handleChange("q")}
                            onKeyPress={handleEnter}
                            variant="outlined"
                        />
                        <IconButton className={classes.iconButton} onClick={handleSearch} color="secondary"
                                    aria-label={t("search organization")} component="span">
                            <SearchIcon/>
                        </IconButton>
                    </Container>
                    {search.result && <SelectOrgList data={search.result} next={handleNext} prev={handlePrev}/> }
                </Grid>
            </Grid>
        </Container>
    );
}

export default SelectOrgPage;
