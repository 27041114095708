import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    IconButton,
    Divider,
    Fade,
    Typography,
    List,
    ListItemText,
    ListItemSecondaryAction,
    ListItem,
} from '@mui/material';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation, Trans } from 'react-i18next';
import AddLayerExitentDataset from './AddLayerExistentDataset';
import AddLayerEmpty from './AddLayerEmpty';
import AddLayerExistentAsset from './AddLayerExistentAsset';
import AddNewAssetLayer from './AddNewAssetLayer';
import AddAnnotationLayer from './AddAnnotationLayer';

const useStyles = makeStyles((theme: Theme) => ({
    separator: {
        paddingRight: theme.spacing(3),
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: theme.palette.secondary.light,
        ...theme.mixins.toolbar,
    },
    settingsContent: {
        margin: theme.spacing(1),
    },
    wrapNormal: {
        whiteSpace: 'normal',
    },
}));

interface NewLayerProps {
    map: any;
    layer: any;
    onCloseSettings: any;
    onChangeLayer: Function;
}

function NewLayer(props: NewLayerProps) {
    const classes = useStyles();
    const hasAnnotation = props.map.layers.find((l: any) => l.lytype === 'annotation');
    const { t } = useTranslation();
    const [mode, setMode] = useState<
        'Choose' | 'Empty' | 'Existent' | 'AssetDataset' | 'NewAsset' | 'Annotation'
    >('Choose');

    if (props.layer == null || props.layer.lytype !== 'new') {
        return <span />;
    }

    function handleClose() {
        setMode('Choose');
        props.onCloseSettings();
    }

    const handleExistent = () => {
        setMode('Existent');
    };

    const handleAnnotation = () => {
        if(!hasAnnotation) setMode('Annotation');
    };

    const handleEmpty = () => {
        setMode('Empty');
    };

    return (
        <>
        {mode === 'NewAsset' ? (
            <AddNewAssetLayer
            layer={props.layer}
            setMode={setMode}
            onChangeLayer={props.onChangeLayer}
            onCloseSettings={props.onCloseSettings}
            />
        ) : 
        (
            <React.Fragment>
                <div className={classes.toolbarIcon}>
                    <div className={classes.separator}>
                        <Typography>
                            <Trans>New Layer</Trans>
                        </Typography>
                        <Typography variant="caption">
                            <Trans>Select Layer Type</Trans>
                        </Typography>
                    </div>
                    <div style={{ width: '100%' }}></div>
                    <IconButton edge="start" color="primary" aria-label={t('close settings')} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <Divider />

                {mode === 'Choose' && (
                    <Fade in={true}>
                        <div className={classes.settingsContent}>
                            <List dense={true}>
                                <ListItem button>
                                    <ListItemText onClick={handleExistent} 
                                        primary={t("Existent Dataset")}
                                        secondary={t("Add a layer with an existent dataset")}/>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={handleExistent} edge="end">
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText onClick={handleEmpty} primary={t("Background")}
                                        secondary={t("Add a layer of background (osm, google, sentinel...)")}
                                        className={classes.wrapNormal}/>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={handleEmpty} edge="end">
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem button disabled={Boolean(hasAnnotation)}>
                                    <ListItemText onClick={handleAnnotation} primary={t("Annotation")}
                                        secondary={t("Add a layer of Annotation to the map")}
                                        className={classes.wrapNormal} />
                                    <ListItemSecondaryAction>
                                        <IconButton disabled={Boolean(hasAnnotation)} onClick={handleAnnotation} edge="end">
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>

                        </div>
                    </Fade>
                )}
                {mode === 'Existent' && (
                    <AddLayerExitentDataset {...props} setMode={setMode} />
                )}
                {mode === 'Empty' && <AddLayerEmpty {...props} setMode={setMode} />}
                {mode === 'Annotation' && <AddAnnotationLayer {...props} setMode={setMode} />}
                {mode === 'AssetDataset' && (
                    <AddLayerExistentAsset {...props} setMode={setMode} />
                )}
            </React.Fragment>
        )}
        </>
    );
}

export default NewLayer;
