import React, { useEffect } from "react";
import { Theme, FormControlLabel, Switch, Typography, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import { Trans } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(2)
        },
        marginTop: {
            marginTop: theme.spacing(1),
        },
        settingsTitle: {
            marginLeft: theme.spacing(1),
        },

    }),
);

interface MyProps {
    map: any;
    layer: any;
    onClose: Function;
    onChangeLayer: Function;
    onDrawerClose: Function;
    changeSubtitle: Function;
}

function ShapeSettings(props: MyProps) {
    const classes = useStyles();

    const [values, setValues] = React.useState({
        edit: props.layer.settings?.shape?.edit || false,
    });
    
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const name = event.target.name;
        setValues({ ...values, [name]: event.target.checked });

        props.layer.settings.shape = props.layer.settings.shape || {};
        if (name === "edit") {
            props.layer.settings.shape.edit = event.target.checked;
        }

        props.onChangeLayer(props.layer, "shape::" + name, event.target.checked);
        if (event.target.checked){
            props.onDrawerClose();
        }
        
    };

    // inicializacion
    useEffect(() => {
        setValues(props.layer.settings.shape);
        return () => {
            // close edit 
            props.layer.settings.shape.edit = false;
            setValues({...values, edit: false});
            props.onChangeLayer(props.layer, "shape::edit", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <SettingsDetail {...props} allowNote={false} disableNoteEdit={true}></SettingsDetail>
            <Divider></Divider>
            <Grid container spacing={1}>
                <Grid item lg={12} xs={12} md={12} sx={{marginTop: '8px',  marginLeft: '8px',}}>
                    <Typography className={clsx(classes.settingsTitle, classes.marginTop)} variant="subtitle2"><Trans>Figures</Trans></Typography>
                </Grid>
                <Grid item lg={11} xs={12} md={12} sx={{marginBottom: '8px', marginLeft: '24px',}}>
                    <FormControlLabel 
                        label={<Typography variant="body2" color="textSecondary"><Trans>Edit</Trans></Typography>}
                        control={
                            <Switch
                                size="small"
                                checked={values.edit}
                                onChange={handleChange}
                                disabled={props.layer.settings.basic.noteEdit}
                                name="edit"
                                inputProps={{ 'aria-label': 'edit notes' }}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ShapeSettings;
