import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        },
    },
    palette: {
        primary: {
            main: '#292e42',
        },
        secondary: {
            main: '#00897b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f5f5f6',

        },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                  // Some CSS
                  marginRight: "8px",
                },
              },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: "1280px",
                }
            }
        }
    }
    //overrides: {
    //    MuiSlider: {
    //        markLabel: {
    //           fontSize: 11
    //       }
    //    }
    //},
});

/*
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#292e42',
        },
        secondary: {
            main: '#00897b',
        },
    },
    overrides: {
    }
});
*/


/*
const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

const theme = React.useMemo(
    () =>
        createMuiTheme({
            palette: {
                type: prefersDarkMode ? 'dark' : 'light',
            },
        }),
    [prefersDarkMode],
);
*/

export default theme;