import React, { useState } from 'react';
import {
  Theme,
  List,
  Box,
  Button,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import VisibiltyIcon from '@mui/icons-material/Visibility';
import VisibiltyOffIcon from '@mui/icons-material/VisibilityOff';
import { Trans } from 'react-i18next';
import { handleLongText } from '../../../lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  inline: {
    display: 'inline',
  },
}));

function GenericListItem(props: any) {
  const [eyeActive, setEyeActive] = useState<boolean>(true);
  const classes = useStyles();
  const type = props.data.dstype
    ? props.data.dstype
    : props.data.asttype
    ? props.data.asttype
    : null;

  return (
    <ListItem
      dense
      selected={props.itemSelected?.id === props.data.id}
      alignItems="flex-start"
      button
      onClick={() => props.handleClick(props.data)}
    >
      <ListItemAvatar>
        <React.Fragment>
          <Avatar
            alt={type ? type.toUpperCase() : null}
            src="/static/images/avatar/2.jpg"
          />
        </React.Fragment>
      </ListItemAvatar>
      <ListItemText
        primary={handleLongText(30, props?.data?.title)}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="caption"
              className={classes.inline}
              color="textPrimary"
            >
              {type ? type.toUpperCase() : 'ASSET'}
            </Typography>
            {props.data.detail && props.data.detail !== 'None'
              ? ` ${props.data.detail}`
              : ''}
          </React.Fragment>
        }
      />
      {props.showEye && (
        <ListItemSecondaryAction onClick={() => setEyeActive(!eyeActive)}>
          {eyeActive ? (
            <VisibiltyIcon fontSize="small" color="secondary" />
          ) : (
            <VisibiltyOffIcon fontSize="small" color="secondary" />
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function ItemList(props: any) {
  const classes = useStyles();

  const handleChangeMode = () => {
    if (props?.addChild) {
      props.setMode('NewAssetChild');
    } else {
      props.setMode('NewAsset');
    }
  };

  return (
    <React.Fragment>
      <List className={classes.root}>
        {props.data?.page?.map((item: any) => {
          return (
            <GenericListItem
              key={item.id}
              data={item}
              handleClick={props.handleClick}
              itemSelected={props.itemSelected}
              showEye={props?.showEye}
            />
          );
        })}
      </List>

      <Box sx={{marginLeft:`${props.showEye ? "40px" : "0px"}`}}>
        {props?.data?.page?.length > 0 && (
          <>
            <Button onClick={props.prev} startIcon={<ChevronLeft />}>
              <Trans>Previous</Trans>
            </Button>
            <Button onClick={props.next} endIcon={<ChevronRight />}>
              <Trans>Next</Trans>
            </Button>
          </>
        )}
        {props?.isAsset && (
          <Fab
            size="small"
            color="secondary"
            aria-label="add"
            sx={{
              marginLeft: `${props?.data?.page?.length > 0 ? '15%' : '75%'}`,
            }}
          >
            <AddIcon onClick={handleChangeMode} />
          </Fab>
        )}
      </Box>
    </React.Fragment>
  );
}

export default ItemList;
