import React, { useEffect } from 'react';
import { Container } from "@mui/material";

/*
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    ({
    }),
);
*/

function PreLoginLayout(props: any) {
    //const classes = useStyles();

    useEffect(() => {
        document.body.style.overflow = "hidden";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth={false} sx={{maxWidth: "unset"}} disableGutters>
            {props.children}
        </Container>
    )
}

export default PreLoginLayout;
