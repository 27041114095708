import React, { useEffect, KeyboardEvent } from 'react';
import {
    Theme,
    Toolbar,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    TextField,
    IconButton,
    InputAdornment,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import AddIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import GroupList from './GroupList';
import { useAsyncCallback } from "react-async-hook";
import ErrorMsg from "../layout/ErrorMsg";
import LoadingMsg from "../layout/LoadingMsg";
import { Link as RouterLink } from "@reach/router";
import { Trans, useTranslation } from "react-i18next";
import { getGroupsPage } from './services';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingRight: 0,
            paddingLeft: 0
        },
        title: {
            flexGrow: 0,
            paddingRight: theme.spacing(2),
        },
        textSearch: {
            width: "90%",
        },
        iconButton: {
            marginTop: "3px",
            marginLeft: "-55px",
        }
    }),
);

interface InputState {
    q: string,
    page: number,
    pageSize: number,
    order: string,
}


function GroupsPage(props: any) {
    console.debug("GroupsPage start...");
    const classes = useStyles();
    const { t } = useTranslation();

    const [q, setQ] = React.useState<string>("");
    const [input, setInput] = React.useState<InputState>({
        page: 1,
        pageSize: 10,
        order: "recent",
        q: "",
    });

    const searchGroupsPage = (values: InputState) => {
        const params = {
            page: values.page,
            pageSize: values.pageSize,
            order: values.order,
        }

        return getGroupsPage(params);
    };

    // pagination
    const handleNext = () => {
        const nPages = Math.ceil(search.result!.count / input.pageSize);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });
        }
    }

    const handlePrev = () => {
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
        }
    }

    const handleSearch = (event: any) => {
        setInput({ ...input, q: q });
    }

    // handle search event
    const search = useAsyncCallback(searchGroupsPage);

    useEffect(() => {
        search.execute(input);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // wbInput = {...input, [prop]: event.target.value};
        setQ(event.target.value);
    };

    const handleEnter = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            setInput({ ...input, q: q });
        }
    }


    // return children
    return (
        <Container maxWidth={false}>
            {search.loading && (<LoadingMsg />)}
            {search.error && (<ErrorMsg error={search.error} />)}
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>Groups</Trans>
                </Typography>
                <Box>
                   <Button component={RouterLink} to="/groups/new" variant="outlined" color="secondary" startIcon={<AddIcon/>}><Trans>Create Group</Trans></Button>
                </Box>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/groups/new" color="secondary" startIcon={<AddIcon />}><Trans>Create Group</Trans></Button>
                        </Box>
                        {/* <Box>
                            <Button color="secondary" startIcon={<HelpIcon />}><Trans>Help</Trans></Button>
                        </Box> */}
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>

                    <TextField
                        className={classes.textSearch}
                        id="q"
                        type="text"
                        label={t("Search")}
                        value={q}
                        onChange={handleChange("q")}
                        onKeyPress={handleEnter}
                        variant="outlined"
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={classes.iconButton}
                                        onClick={handleSearch}
                                        color="secondary"
                                        aria-label={t("Search")}
                                        component="span"
                                        size="large">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    {search.result && <GroupList data={search.result} next={handleNext} prev={handlePrev} />}
                </Grid>
            </Grid>
        </Container>
    );
}

export default GroupsPage;
