import React, { useEffect } from "react";
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "./SettingsBasic";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
        },
        settingsRow: {
            margin: theme.spacing(1),
        },
        settingsTitle: {
            margin: theme.spacing(1),
            marginLeft: 0,
        }
    }),
);

interface MyProps {
    map: any;
    layer: any;
    onClose: Function;
    onChangeLayer: Function;
}

function SettingsGoogle(props: MyProps) {
    console.debug("SettingsGoogle: start...");
    const classes = useStyles();

    // inicializacion
    useEffect(() => {
    }, []);

    return (
        <div className={classes.container}>
            <SettingsDetail {...props} allowNote={false}></SettingsDetail>
        </div>
    )
}

export default SettingsGoogle;
