import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from '../../settings/SettingsBasic';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { getDatasetFile } from '../../../services';
import useLoading from '../../../../lib/hooks/useLoading';
import { LoadingRight } from '../../../../lib/components/LoadingProgress/LoadingProgress';
import { ColorPicker } from '../../../../lib/components/ColorPicker/ColorPicker';

const useStyles = makeStyles((theme: Theme) => ({
  settingsRow: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  settingsTitle: {
    marginLeft: theme.spacing(1),
  },
  inputWidth: {
    width: 240,
  },
  space: {
    marginBottom: 40,
  },
}));

export function defaultSettings() {
  return {
    interleave: 1,
    range: [0, 0],
  };
}

interface PointInputs {
  lat: string;
  lng: string;
  radiusCol: string;
  label: string;
  linepoints: boolean;
  radius: string;
  mode: string;
  color: string;
}

interface MyProps {
  map: any;
  layer: any;
  mustClose: any;
  onChangeLayer: Function;
  changeSubtitle: Function;
}

function PointSettings(props: MyProps) {
  const classes = useStyles();
  const [loading, setLoading] = useLoading(false);
  const { t } = useTranslation();

  const [colsCsv, setColsCsv] = React.useState<string[]>([]);
  const [inputs, setInputs] = React.useState<PointInputs>({
    lat: '',
    lng: '',
    radiusCol: '',
    label: '',
    linepoints: false,
    radius: '1',
    mode: 'marker',
    color: '#ffeb3b',
  });
  const [editColor, setEditColor] = React.useState<boolean>(false);

  // inicializacion
  useEffect(() => {
    // console.log("PointSettings:", props);
    loadPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPoints = async () => {
    setLoading(true);
    const dataset = props.layer.dataset;
    const filename = 'index.csv';
    if (dataset?.id && dataset.dstype === 'csv') {
      try {
        const data = await getDatasetFile(dataset.id, dataset.path, filename);

        // parse csv TODO add real parser
        const rows = data.split('\n');
        // console.log("rows", rows);
        const cols = rows[0].split(',').map((title: string) => {
          return title.trim();
        });
        const colsOptions = [''].concat(cols);
        // console.log("cols", colsOptions);
        setColsCsv(colsOptions);

        // console.log("settings", props.layer.settings.point);
        setInputs({
          lat: props.layer.settings.point.colLat || '',
          lng: props.layer.settings.point.colLng || '',
          radiusCol: props.layer.settings.point.colRadius || '',
          label: props.layer.settings.point.colLabel || '',
          linepoints: props.layer.settings.point.linepoints || false,
          radius: props.layer.settings.point.radiusValue || 1,
          mode: props.layer.settings.point.mode || 'marker',
          color: props.layer.settings.point.color || '#ffeb3b',
        });
        setLoading(false);
      } catch (error) {
        console.log('Error', error);
      }
    }
  };

  const handleChangeLat = (event: SelectChangeEvent<string>) => {
    setInputs({ ...inputs, lat: event.target.value });
    props.layer.settings.point.colLat = event.target.value;
    props.onChangeLayer(props.layer);
  };
  const handleChangeLong = (event: SelectChangeEvent<string>) => {
    setInputs({ ...inputs, lng: event.target.value });
    props.layer.settings.point.colLng = event.target.value;
    props.onChangeLayer(props.layer);
  };
  const handleChangeRadiusCol = (event: SelectChangeEvent<string>) => {
    setInputs({ ...inputs, radiusCol: event.target.value });
    props.layer.settings.point.colRadius = event.target.value;
    props.onChangeLayer(props.layer);
  };
  const handleChangeLabel = (event: SelectChangeEvent<string>) => {
    setInputs({ ...inputs, label: event.target.value });
    props.layer.settings.point.colLabel = event.target.value;
    props.onChangeLayer(props.layer);
  };
  const handleChangeMode = (event: SelectChangeEvent<string>) => {
    setInputs({ ...inputs, mode: event.target.value });
    props.layer.settings.point.mode = event.target.value;
    props.onChangeLayer(props.layer);
  };
  const handleChangeLinepoints = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputs({ ...inputs, linepoints: event.target.checked });
    props.layer.settings.point.linepoints = event.target.checked;
    props.onChangeLayer(props.layer);
  };
  const handleChangeRadiusValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputs({ ...inputs, radius: event.target.value });
    props.layer.settings.point.radiusValue = event.target.value;
    props.onChangeLayer(props.layer);
  };

  const handleOpenEditColor = () => {
    setEditColor(true);
  };

  const handleChangeColor = (color: string) => {
    setInputs({ ...inputs, color: color });
    props.layer.settings.point.color = color;
    setEditColor(false);
    props.onChangeLayer(props.layer);
  };

  return (
    <React.Fragment>
      <SettingsDetail {...props} allowNote={true}></SettingsDetail>
      <Divider></Divider>
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12} md={12}>
          <Typography
            sx={{ marginTop: '8px', marginLeft: '8px' }}
            variant="subtitle2"
          >
            <Trans>Columns</Trans>
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{ marginBottom: '8px', marginLeft: '24px' }}
        >
          <FormControl
            variant="outlined"
            size="small"
            className={classes.inputWidth}
          >
            <InputLabel id="lat">
              <Trans>Latitude</Trans>
            </InputLabel>
            <Select
              id="latS"
              value={inputs.lat}
              onChange={handleChangeLat}
              label="Latitude"
            >
              {colsCsv.map((opt, i) => (
                <MenuItem key={i} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {loading && <LoadingRight></LoadingRight>}
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{ marginBottom: '8px', marginLeft: '24px' }}
        >
          <FormControl
            variant="outlined"
            size="small"
            className={classes.inputWidth}
          >
            <InputLabel id="long">
              <Trans>Longitude</Trans>
            </InputLabel>
            <Select
              id="longS"
              value={inputs.lng}
              onChange={handleChangeLong}
              label="Longitude"
            >
              {colsCsv.map((opt, i) => (
                <MenuItem key={i} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{ marginBottom: '8px', marginLeft: '24px' }}
        >
          <FormControl
            variant="outlined"
            size="small"
            className={classes.inputWidth}
          >
            <InputLabel id="label">
              <Trans>Label</Trans>
            </InputLabel>
            <Select
              id="labelS"
              value={inputs.label}
              onChange={handleChangeLabel}
              label="Label"
            >
              {colsCsv.map((opt, i) => (
                <MenuItem key={i} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{ marginBottom: '8px', marginLeft: '24px' }}
        >
          <FormControl
            variant="outlined"
            size="small"
            className={classes.inputWidth}
          >
            <InputLabel id="radius-col">
              <Trans>Radius</Trans>
            </InputLabel>
            <Select
              id="radius-colS"
              value={inputs.radiusCol}
              onChange={handleChangeRadiusCol}
              label="Radius"
            >
              {colsCsv.map((opt, i) => (
                <MenuItem key={i} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sx={{ marginLeft: '8px' }}>
          <Typography
            className={clsx(classes.settingsTitle, classes.marginTop)}
            variant="subtitle2"
          >
            <Trans>Styles</Trans>
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{ marginBottom: '8px', marginLeft: '24px' }}
        >
          <FormControl
            variant="outlined"
            size="small"
            className={classes.inputWidth}
          >
            <InputLabel id="label">
              <Trans>Mode</Trans>
            </InputLabel>
            <Select
              id="labelS"
              value={inputs.mode}
              onChange={handleChangeMode}
              label="Mode"
            >
              <MenuItem value="marker">
                <Trans>Marker</Trans>
              </MenuItem>
              <MenuItem value="rectangle">
                <Trans>Rectangle</Trans>
              </MenuItem>
              <MenuItem value="circle">
                <Trans>Circle</Trans>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {(inputs.mode === 'rectangle' || inputs.mode === 'circle') && (
          <React.Fragment>
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sx={{ marginBottom: '8px', marginLeft: '24px' }}
            >
              <TextField
                size="small"
                id="Radius"
                type="number"
                label={t('Radius')}
                value={inputs.radius}
                variant="outlined"
                className={classes.inputWidth}
                onChange={handleChangeRadiusValue}
              />
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              md={12}
              sx={{ marginBottom: '8px', marginLeft: '24px' }}
            >
              <InputLabel id="label">Color</InputLabel>
            </Grid>
            {!editColor && (
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                sx={{ marginBottom: '8px', marginLeft: '24px' }}
              >
                <ColorPicker
                  mode="view"
                  color={inputs.color}
                  onOpenEdit={handleOpenEditColor}
                />
              </Grid>
            )}
            {editColor && (
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                sx={{ marginBottom: '8px', marginLeft: '24px' }}
              >
                <ColorPicker mode="picker" onSelectColor={handleChangeColor} />
              </Grid>
            )}
          </React.Fragment>
        )}

        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          sx={{ marginBottom: '8px', marginLeft: '24px' }}
        >
          <FormControlLabel
            label={
              <Typography variant="body2" color="textSecondary">
                <Trans>Line points</Trans>
              </Typography>
            }
            control={
              <Switch
                size="small"
                checked={inputs.linepoints}
                onChange={handleChangeLinepoints}
                name="active"
                inputProps={{ 'aria-label': 'active line points' }}
              />
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: 40 }}></Box>
    </React.Fragment>
  );
}

export default PointSettings;
