import React, { useEffect } from "react";
import { Theme, Typography, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import Slider from '@mui/material/Slider';
import { Trans } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            margin: theme.spacing(1),
        },
        marginTop: {
            marginTop: theme.spacing(1),
        },
        settingsTitle: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        settingsSubTitle: {
            marginLeft: theme.spacing(1),
            fontSize: 13,
        },
        slider: {
            width: '60%',
            marginLeft: theme.spacing(2),
            marginTop: "-10px"
        },
        root: {
            width: 300,
        }

    }),
);

interface MyProps {
    map: any;
    layer: any;
    onClose: Function;
    onChangeLayer: Function;
    changeSubtitle: Function;
}

function ImageSettings(props: MyProps) {
    console.debug("SettingsShape: start...");
    const classes = useStyles();

    const [values, setValues] = React.useState({
        thresholdArray: [0, 20],
        maxscoreArray: [0, 100],
    });

    const handleChangeThresholdArray = (event: any, newValue: number | number[]) => {
        setValues({ ...values, thresholdArray: newValue as number[]});
        props.layer.settings.image.thresholdArray=newValue;
        const cloned = Object.assign({}, props.layer.settings.image);
        props.layer.settings.image=cloned
        props.onChangeLayer(props.layer, "image::thresholdArray", event.target.checked);
    };

    function thresholdArrayText(value: number) {
        return `${value} detecciones`;
    }

    const handleChangeMaxscoreArray = (event: any, newValue: number | number[]) => {
        setValues({ ...values, maxscoreArray: newValue as number[]});
        props.layer.settings.image.maxscoreArray=newValue;
        const cloned = Object.assign({}, props.layer.settings.image);
        props.layer.settings.image=cloned
        props.onChangeLayer(props.layer, "image::maxscoreArray", event.target.checked);
    };

    function maxscoreArrayText(value: number) {
        return `${value} %`;
    }

    // inicializacion
    useEffect(() => {
        setValues(props.layer.settings.image);
        return () => {
            // close edit
            //props.layer.settings.shape.edit = false;
            //setValues({...values, edit: false});
            //props.onChangeLayer(props.layer, "shape::edit", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const marks = [
        {
          value: 0,
          label: '0',
        },
        {
          value: 10,
          label: '10',
        },
        {
          value: 20,
          label: '20',
        },
      ];

      const marksScore = [
        {
          value: 0,
          label: '',
        },
        {
        value: 25,
        label: '25%',
        },
        {
          value: 50,
          label: '50%',
        },
        {
            value: 75,
            label: '75%',
        },
        {
          value: 100,
          label: '100%',
        },
      ];

    return (
        <React.Fragment>
            <SettingsDetail {...props} allowNote={false} disableNoteEdit={false}/>
            <Divider></Divider>
            <Grid container spacing={2}>
                <Grid item lg={12} xs={12} md={12} sx={{marginTop: '8px',marginLeft: '8px',}}>
                    <Typography className={clsx(classes.settingsTitle, classes.marginTop)} variant="subtitle2"><Trans>Figures</Trans></Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '16px',}}>
                    <Typography id="range-slider" className={clsx(classes.settingsSubTitle, classes.marginTop)}>
                        <Trans>Notes Number</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.thresholdArray}
                        onChange={handleChangeThresholdArray}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={thresholdArrayText}
                        name="thresholdArray"
                        min={0}
                        max={20}
                        marks={marks}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '16px',}}>
                    <Typography id="range-slider" className={clsx(classes.settingsSubTitle, classes.marginTop)}>
                        <Trans>Confidence</Trans> [%]
                    </Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.maxscoreArray}
                        onChange={handleChangeMaxscoreArray}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={maxscoreArrayText}
                        name="maxscoreArray"
                        min={0}
                        max={100}
                        marks={marksScore}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ImageSettings;
