import React, { useEffect } from 'react';
import {
    Theme,
    Button,
    Container,
    TextField,
    Grid,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Trans, useTranslation } from "react-i18next";
import { MapAkopica } from "../../../models/map"
import { getGroupsPage } from '../../services';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingLeft: 0,
            margin: theme.spacing(1),
        },
        comboLytype: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: "80%",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            //width: '50ch', 
            width: "80%",
        },
        rowActions: {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: theme.spacing(2),
        },
    }),
);

export interface SearchResponse {
    page: MapAkopica[],
    count: number,
}

interface InputError {
    title?: string,
    lytype?: string,
}

function AddAnnotationLayer(props: any) {
    //console.debug("DatasetsPageNewLayer start...");
    const classes = useStyles();
    const { t } = useTranslation();
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [inputError, setInputError] = React.useState<InputError>({})

    const handleChangeText = (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.trim() === '') {
            setInputError({...inputError, title: t("Please provide a Title")});
        } else {
            setInputError({...inputError, title:undefined});
        }
        setInput({ ...input, [prop]: value });
    };

    const [input, setInput] = React.useState<any>({
        groupId: "",
        page: 1,
        pageSize: 3,
        q: "",
        title: "Annotation",
        detail: "",
        lytype: "annotation",
    });

    const searchGroupId = () => {
        getGroupsPage()
            .then(async groups => {
                //console.log("response", groups);
                setInput({...input, groupId: groups.page[0].id});
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
            }
        );
    };

    useEffect(() => {
        searchGroupId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[input]);
    

    const validateData = () => {
        if(input.title.trim() === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const handleSave = () => {
        props.layer.lytype = input.lytype;
        props.layer.title = input.title.trim();
        props.layer.detail = input.detail;

        props.onChangeLayer(props.layer);
        props.setMode("Choose");
        props.onCloseSettings();
    }

    return (
        <Container maxWidth="md" className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Trans>Layer Info</Trans>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        className={classes.textField}
                        label={t("Title")}
                        id="title"
                        value={input.title}
                        onChange={handleChangeText("title")}
                        variant="outlined"
                        helperText={inputError.title}
                        error={inputError.title !== undefined}
                        required
                    />
                </Grid>
                <Grid item xs={12} className={classes.rowActions}>
                    <Button disabled={disabled} color="secondary" variant="contained" onClick={handleSave}>Agregar Layer</Button>
                </Grid>
            </Grid>

        </Container>
    );
}

export default AddAnnotationLayer;
