import React from 'react';
import { Link as RouterLink } from "@reach/router"
import makeStyles from '@mui/styles/makeStyles';
import { Theme, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import { Org } from './SelectOrgPage';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: Org,
}

function SelectOrgItem(props: MyProps) {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" button component={RouterLink} to="/maps/bb43b358-1549-41f7-8412-f9a66ec4b58d">
            <ListItemAvatar>
                <Avatar alt="Dataset" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary={props.data.title}
                secondary={
                    <React.Fragment>
                    <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                    >
                        Ali Connors
                    </Typography>
                    {" — I'll be in your neighborhood doing errands this…"}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
  }
  
export default SelectOrgItem;
