import { BACKEND_API_URL} from "../lib/config";
import { httpGet } from "../AppContext";
import { MapAkopica } from "../models/map";
import { Site } from "../models/group";

const URL_PUBLIC = "/v1/public";

export async function getPublicSite(siteName: string): Promise<Site> {
    return httpGet<any>(BACKEND_API_URL + URL_PUBLIC + `/site/` + siteName);
}

export async function getPublicMapsBySite(siteName: string, params: any): Promise<any> {
    return httpGet<any>(BACKEND_API_URL + URL_PUBLIC + `/site/` + siteName + "/maps", params);
}


export async function getPublicMapById(id: string): Promise<MapAkopica> {
    return httpGet<MapAkopica>(BACKEND_API_URL + URL_PUBLIC + `/map/${id}`);
}