import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            position: "absolute",
            left: "92%",
            top: "5%",
        },
    }),
);

function LoadingMsg(props: any) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Trans>Loading</Trans> ...
        </div>
    );
}

export default LoadingMsg;
