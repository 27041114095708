import { Grid, Typography, FormControl,
    MenuItem,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment,
    IconButton, } from '@mui/material';
import React, { useState } from 'react';
import ClearIcon from "@mui/icons-material/Clear";
import { Trans, useTranslation } from 'react-i18next';
import { Dataset } from '../models/dataset';
import { updateDataset } from './services';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';

interface FeatureConfigProps {
  originDataset: Dataset,
  canEdit?: boolean,
  layer?: any,
  onChangeLayer?: (layer: any, changed?: string, value?: any) => void,
}

interface ColumnSetting {
  name: string;
  columnOrder: number;
  title: string;
  type: string;
}

interface ColumnRegister {
    index: number;
    title: string;
}

interface ColumnNames {
    columnTitle: string;
    columnSubtitle: string;
}

interface OriginalData {
    columns: ColumnRegister[];
    columnNames?: ColumnNames;
}

export default function DatasetFeatureConfig({originDataset, canEdit, layer, onChangeLayer}: FeatureConfigProps) {
    const [dataset, setDataset] = useState(originDataset);
    const [originalData, setOriginalData]= useState({
        columns: [],
    } as OriginalData);
    const { t } = useTranslation();
    const [title, setTitle] = useState(dataset?.settings?.columnTitle);
    const [subtitle, setSubtitle] = useState(dataset?.settings?.columnSubtitle);
    const [open, setOpen] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});

    const handleSaveOriginData = () => {
        const settg = dataset.settings;
        if (!originalData.columnNames) setOriginalData({
            ...originalData, 
            columnNames: {
                columnTitle: settg.columnTitle, 
                columnSubtitle: settg.columnSubtitle
            }
        });
    };

    const handleOnChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSaveOriginData();
        setDisabled(false);
        const element = event.target;
        const value = element.value;

        if (element.name === "title") {
            if (value === subtitle) {
                setSubtitle(title);
                dataset.settings.columnSubtitle = title;
            }
            setTitle(value);
            dataset.settings.columnTitle = value;
        }

        if (element.name === "subtitle") {
            if (value === title) {
                setTitle(subtitle);
                dataset.settings.columnTitle = subtitle;
            }
            setSubtitle(value);
            dataset.settings.columnSubtitle = value;
        }
        setDataset({...dataset});
    };

    const handleOnClearSelect = (_event: any, name: string) => {
        handleSaveOriginData();
        setDisabled(false);

        if (name === "title") {
            setTitle('');
            delete dataset.settings.columnTitle;
        }

        if (name === "subtitle") {
            setSubtitle('');
            delete dataset.settings.columnSubtitle;
        }

        setDataset({...dataset});
    };

    const handleChangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(false);
        const value = event.target.value;
        const index = parseInt(event.target.id);
        const exist = originalData.columns.find(o => o?.index === index);
        const title = dataset.settings.columns[index].title;

        if (!exist) {
            originalData.columns.push({index: index, title: title})
            setOriginalData({...originalData});
        }
        dataset.settings.columns[index].title = value;
        setDataset({...dataset});
    };

    const handleOpenDialog = () => setOpen(true);

    const handleCloseDialog = () => setOpen(false);

    const handleOpenCancel = () => setOpenCancel(true);

    const handleCloseCancel = () => setOpenCancel(false);

    const handleCancel = () => {
        originalData.columns.forEach(oData => dataset.settings.columns[oData.index].title = oData.title);
        const cols = originalData.columnNames;
        if (cols) {
            dataset.settings.columnTitle = cols.columnTitle;
            dataset.settings.columnSubtitle = cols.columnSubtitle;
            setTitle(cols.columnTitle);
            setSubtitle(cols.columnSubtitle);
        }
        setDataset({...dataset});
        setOpenCancel(false);
        setDisabled(true);
    };

    const handleSave = async () => {
        try {
            setOpen(false);
            const id = dataset.id || "";
            const params = {
                title: dataset.title,
                settings: dataset.settings, 
            }
            const res = await updateDataset(id, params, true);
            if (res) {
                setSnackBarMsg({msg:t("Saved Successfully"), severity:"success"});
                setOpenSnackbar(true);
                setDisabled(true);
                
                if(layer && onChangeLayer) {
                    layer.dataset = dataset;
                    onChangeLayer({...dataset});
                }
            }
        } catch (e: any) {
            if (e.message) {
                setSnackBarMsg({msg:t(e.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
                <Grid item xs={12} md={12}>
                    <Typography component="h1" variant="h6" color="secondary" noWrap sx={{marginBottom: '8px'}}>
                        <Trans>Settings</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Typography
                        sx={{ marginBottom: '8px'}}
                        variant="subtitle2"
                    >
                        <Trans>Select Title and Subtitle columns</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={2} columnSpacing={2} justifyContent="center">
                        <Grid
                            item
                            lg={6}
                            xs={6}
                            md={6}
                        >
                            <FormControl
                                variant="outlined"
                                size="small"
                                sx={{width: '100%'}}
                            >
                                <TextField
                                    select
                                    id="title"
                                    name="title"
                                    value={title ?? ''}
                                    onChange={handleOnChangeSelect}
                                    label={t("Title")}
                                    disabled={!canEdit}
                                    InputProps={{
                                        endAdornment: ( 
                                            <InputAdornment position="end">
                                                {title && 
                                                    <IconButton
                                                        onClick={(e) => handleOnClearSelect(e, 'title')}
                                                        color="primary"
                                                        size="large"
                                                        disabled={!canEdit}>
                                                            <ClearIcon/>
                                                    </IconButton>}
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    {dataset?.settings?.columns?.map((opt: ColumnSetting, i: number) => (
                                        <MenuItem key={i} value={opt.name}>
                                            {opt.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            xs={6}
                            md={6}
                        >
                            <FormControl
                                variant="outlined"
                                size="small"
                                sx={{width: '100%'}}
                            >
                                <TextField
                                    select
                                    id="subtitle"
                                    name="subtitle"
                                    value={subtitle ?? ''}
                                    onChange={handleOnChangeSelect}
                                    label={t("Subtitle")}
                                    disabled={!canEdit}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {subtitle && 
                                                    <IconButton
                                                        onClick={(e) => handleOnClearSelect(e, 'subtitle')}
                                                        color="primary"
                                                        size="large"
                                                        disabled={!canEdit}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                }
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    {dataset?.settings?.columns?.map((opt: ColumnSetting, i: number) => (
                                        <MenuItem key={i} value={opt.name}>
                                            {opt.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Typography
                        sx={{ marginTop: '8px'}}
                        variant="subtitle1"
                    >
                        <Trans>Columns</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Typography
                        sx={{ marginBottom: '8px'}}
                        variant="subtitle2"
                    >
                        <Trans>Adjust title for every column</Trans>.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        { dataset?.settings?.columns?.map((opt: ColumnSetting, i: number) => (
                            <Grid item xs={12} key={i}>
                                <TextField 
                                    id={`${i}`}
                                    value={opt.title} 
                                    fullWidth 
                                    size="small" 
                                    onChange={handleChangeColumn}
                                    disabled={!canEdit}
                                />
                            </Grid>
                        ))}
                    </Grid>    
                </Grid>
                <Grid item xs={12} md={12} sx={{width: "100%", textAlign: "right"}}>
                    <Button onClick={handleOpenCancel} disabled={disabled || !canEdit} variant="outlined" color="secondary"><Trans>Cancel</Trans></Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={handleOpenDialog}  disabled={!canEdit} variant="contained" color="secondary"><Trans>Save</Trans></Button>
                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{t("Update Dataset Settings")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Trans>Are you sure you want to edit the settings of this dataset?</Trans>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                <Trans>No</Trans>
                            </Button>
                            <Button onClick={handleSave} color="primary" autoFocus>
                                <Trans>Yes</Trans>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openCancel}
                        onClose={handleCloseCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{t("Discard Changes")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Trans>Are you sure you want to discard changes?</Trans>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCancel} color="primary">
                                <Trans>No</Trans>
                            </Button>
                            <Button onClick={handleCancel} color="primary" autoFocus>
                                <Trans>Yes</Trans>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </>
    )
}
