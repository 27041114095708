import React, { useEffect, useCallback } from 'react';
import { 
    Theme,
    Button,
    Container,
    TextField,
    Grid,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ItemList from './ItemListNewLayer';
import {useAsyncCallback} from "react-async-hook";
import { useTranslation } from "react-i18next";
import { LoadingProgress } from '../../../lib/components/LoadingProgress/LoadingProgress';
import { debounce } from "lodash";
import { getDatasetsPage } from '../../services';

const useStyles = makeStyles((theme: Theme) =>
  ({
        container: {
            paddingLeft: 0,
            margin: theme.spacing(1),
        },
        textSearch: {
            marginLeft: theme.spacing(1),
            width: "80%",
        },
        rowActions: {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: theme.spacing(2),
        },
    }),
);

interface InputState {
    groupId: string,
    page: number,
    pageSize: number,
    q: string,
};


function AddLayerExitentDataset(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [datasetSelected, setDatasetSelected]= React.useState<any|null>(null)
    const handleClick = (data:any) => {
      setDatasetSelected(data);
    }

    // state: input values
    const [q, setQ]= React.useState<string>("");
    const [input, setInput] = React.useState<any>({
        groupId: "",
        page: 1,
        pageSize: 4,                                                        
        q: "",
    });


    const searchDatasetsPage = (values: InputState) => {
        const params = {
            groupId: props.map.group.id,
            page: values.page,
            pageSize: values.pageSize,
            q: values.q,
        }

        return getDatasetsPage(params);
    };

    // pagination
    const handleNext = () => {
        const nPages = Math.ceil(search.result!.count/input.pageSize);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });          
        }
    }
    const handlePrev = () => {
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
        }
    }
   
    useEffect(() => {
        handlerChangeQ(input);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlerChangeQ = useCallback(debounce((input) => search.execute(input), 200), []);

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => { 
        setInput({...input, [prop]: event.target.value});
        setQ(event.target.value);
        setDatasetSelected(null);
    };

    // handle search event
    const search = useAsyncCallback(searchDatasetsPage);

    const handleSave = () => {
        if (setDatasetSelected != null) {
            props.layer.lytype = getLytypeFromDataset(datasetSelected);
            props.layer.title = datasetSelected.title;
            props.layer.detail = input.detail;
            props.layer.datasetId = datasetSelected.id;
            props.onChangeLayer(props.layer);
            props.setMode("Choose");
            props.onCloseSettings();
        } 
        
    }
    const getLytypeFromDataset = (dataset: any) => {
        switch (dataset.dstype) {
            case "geojson":
                return "shape";
            case "csv":
                return "point";
            default:
                return dataset.dstype;
        }
    };

    return (
        <Container maxWidth="md" className={classes.container}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        className={classes.textSearch}
                        id="q"
                        type="search"
                        label={t("Search dataset")}
                        value={q}
                        onChange={handleChange("q")}
                        variant="outlined"
                        autoComplete='off'
                        size="small"
                    />
                        
                    {search.result &&
                        <ItemList data={search.result} next={handleNext} prev={handlePrev} handleClick={handleClick} itemSelected={datasetSelected}/> 
                    }
                    {search.loading && (<LoadingProgress/>)}
                    
                </Grid>
                {datasetSelected != null &&
                    <Grid item xs={12} className={classes.rowActions}>
                        <Button color="secondary" variant="contained" onClick={handleSave}>{t("Add Layer")}</Button>
                    </Grid>
                }
            </Grid>

        </Container>
    );
  }
  
export default AddLayerExitentDataset;
