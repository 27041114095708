import { httpGet } from '../AppContext';
import { BACKEND_API_URL } from '../lib/config';


// const URL_PUBLIC_FEATURES = "/v1/public/feature";

const URL_LAYERS = "/v1/layers";
const URL_PUBLIC_LAYERS = "/v1/public/layers";



export async function getFeaturesGeoJson(duid: string, pub?: boolean): Promise<any> {
    if (pub) {
        return httpGet<any>(BACKEND_API_URL + URL_PUBLIC_LAYERS + "/" + duid + "/features/geoJson");
    } else {
        return httpGet<any>(BACKEND_API_URL + URL_LAYERS + "/" + duid + "/features/geoJson");
    }
    
}

export async function getFeatureData(duid: string, id: number, pub?: boolean): Promise<any> {
    if (pub) {
        return httpGet<any>(BACKEND_API_URL + URL_PUBLIC_LAYERS + "/" + duid + "/features/" + id + "/data");
    } else {
        return httpGet<any>(BACKEND_API_URL + URL_LAYERS + "/" + duid + "/features/" + id + "/data");
    }
    
}

export async function getFeaturesByDataset(duid: string, params: any): Promise<any> {
    return httpGet<any>(BACKEND_API_URL + URL_LAYERS + "/" + duid + "/features/page" , params);
}