import { User, UserInfo, UserSign } from "../models/auth";
import { BACKEND_URL } from "../lib/config";
import { httpDelete, httpPost } from "../AppContext";

const URL_AUTH = "/v1/auth";

export async function signupWithEmail(userSign: UserSign): Promise<User> {
    return httpPost<User>(BACKEND_URL + URL_AUTH + "/signup-with-email", userSign, {sendAuthToken: true});
}

export async function loginWithEmail(email: string, recaptchaToken: string | null): Promise<User> {
    return httpPost<User>(BACKEND_URL + URL_AUTH + "/login-with-email", { email: email, recaptchaToken: recaptchaToken }, {sendAuthToken: false} );
}

export async function accessWithEmailToken(token: string, recaptchaToken: string | null): Promise<UserInfo> {
    return httpPost<UserInfo>(BACKEND_URL + URL_AUTH + "/access-et", {token: token, recaptchaToken: recaptchaToken}, {sendAuthToken: false});
}

export async function accessWithGuestToken(token: string, recaptchaToken: string | null): Promise<UserInfo> {
    return httpPost<UserInfo>(BACKEND_URL + URL_AUTH + "/access-gt", {token: token, recaptchaToken: recaptchaToken}, {sendAuthToken: false});
}

export async function logout(): Promise<any> {
    return httpDelete<any>(BACKEND_URL + URL_AUTH + "/logout");
}
