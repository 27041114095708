import React from 'react';
import { Button, ButtonGroup, Grid } from '@mui/material';

const colors = {
  colorRed: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#aa2e25',
    },
  },
  colorPink: {
    backgroundColor: '#e91e63',
    '&:hover': {
      backgroundColor: '#a31545',
    },
  },
  colorPurple: {
    backgroundColor: '#9c27b0',
    '&:hover': {
      backgroundColor: '#6d1b7b',
    },
  },
  colorDeepPurple: {
    backgroundColor: '#673ab7',
    '&:hover': {
      backgroundColor: '#482880',
    },
  },
  colorIndigo: {
    backgroundColor: '#3f51b5',
    '&:hover': {
      backgroundColor: '#2c387e',
    },
  },
  colorBlue: {
    backgroundColor: '#3388ff',
    '&:hover': {
      backgroundColor: '#1769aa',
    },
  },
  colorLightBlue: {
    backgroundColor: '#03a9f4',
    '&:hover': {
      backgroundColor: '#0276aa',
    },
  },
  colorCyan: {
    backgroundColor: '#00bcd4',
    '&:hover': {
      backgroundColor: '#008394',
    },
  },
  colorTeal: {
    backgroundColor: '#009688',
    '&:hover': {
      backgroundColor: '#00695f',
    },
  },
  colorGreen: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#357a38',
    },
  },
  colorLightGreen: {
    backgroundColor: '#8bc34a',
    '&:hover': {
      backgroundColor: '#618833',
    },
  },
  colorLime: {
    backgroundColor: '#cddc39',
    '&:hover': {
      backgroundColor: '#8f9a27',
    },
  },
  colorYellow: {
    backgroundColor: '#ffeb3b',
    '&:hover': {
      backgroundColor: '#b2a429',
    },
  },
  colorAmber: {
    backgroundColor: '#ffc107',
    '&:hover': {
      backgroundColor: '#b28704',
    },
  },
  colorOrange: {
    backgroundColor: '#ff9800',
    '&:hover': {
      backgroundColor: '#b26a00',
    },
  },
  colorDeepOrange: {
    backgroundColor: '#ff5722',
    '&:hover': {
      backgroundColor: '#b23c17',
    },
  },
};




function ColorPicker(props: any) {

  const getColorCss = (color: string) => {
    switch (color) {
      case '#f44336':
        return colors.colorRed;
      case '#e91e63':
        return colors.colorPink;
      case '#9c27b0':
        return colors.colorPurple;
      case '#673ab7':
        return colors.colorDeepPurple;
      case '#3f51b5':
        return colors.colorIndigo;
      case '#3388ff':
        return colors.colorBlue;
      case '#03a9f4':
        return colors.colorLightBlue;
      case '#00bcd4':
        return colors.colorCyan;
      case '#009688':
        return colors.colorTeal;
      case '#4caf50':
        return colors.colorGreen;
      case '#8bc34a':
        return colors.colorLightGreen;
      case '#cddc39':
        return colors.colorLime;
      case '#ffeb3b':
        return colors.colorYellow;
      case '#ffc107':
        return colors.colorAmber;
      case '#ff9800':
        return colors.colorOrange;
      case '#ff5722':
        return colors.colorDeepOrange;

      default:
        return colors.colorRed;
    }
  };

  const getColorButton = (color: string) => (
    <Button
      onClick={() => props.onSelectColor(color)}
      sx={{ minHeight: 50, minWidth: 50, ...getColorCss(color) }}
    ></Button>
  );

  return (
    <React.Fragment>
      {props.mode === 'picker' && (
        <Grid container spacing={3}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <ButtonGroup size="large" color="primary">
              {getColorButton('#f44336')}
              {getColorButton('#e91e63')}
              {getColorButton('#9c27b0')}
              {getColorButton('#673ab7')}
            </ButtonGroup>
            <ButtonGroup size="large" color="primary">
              {getColorButton('#3f51b5')}
              {getColorButton('#3388ff')}
              {getColorButton('#03a9f4')}
              {getColorButton('#00bcd4')}
            </ButtonGroup>
            <ButtonGroup size="large" color="primary">
              {getColorButton('#009688')}
              {getColorButton('#4caf50')}
              {getColorButton('#8bc34a')}
              {getColorButton('#cddc39')}
            </ButtonGroup>
            <ButtonGroup size="large" color="primary">
              {getColorButton('#ffeb3b')}
              {getColorButton('#ffc107')}
              {getColorButton('#ff9800')}
              {getColorButton('#ff5722')}
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
      {props.mode === 'view' && (
        <Button
          onClick={() => props.onOpenEdit()}
          sx={{ minHeight: 50, minWidth: 50, ...getColorCss(props.color)}}
        ></Button>
      )}
    </React.Fragment>
  );
}

export { ColorPicker };
