import React from 'react';
import { Link as RouterLink } from "@reach/router"
import { Theme, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Asset } from '../models/asset';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
		},
		inline: {
			display: "inline",
		},
	}),
);

interface MyProps {
  data: Asset,
}

function AssetItem(props: MyProps) {
    const classes = useStyles();

    return (
		<ListItem alignItems="flex-start" button component={RouterLink} to={"/assets/" + props.data.id} >
			<ListItemAvatar>
				<Avatar alt="Dataset" src="/static/images/avatar/1.jpg" />
			</ListItemAvatar>
			<ListItemText
				primary={props.data.title}
				secondary={
					<Typography variant="body2" className={classes.inline} color="textPrimary">
						{props.data.title + " " + props.data.name}
					</Typography>
				}
			/>
		</ListItem>
    );
  }
  
export default AssetItem;
