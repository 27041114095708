import React, { useEffect, KeyboardEvent } from 'react';
import {
  Theme,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Hidden,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssetList from './AssetList';
import { useAsyncCallback } from 'react-async-hook';
import ErrorMsg from '../layout/ErrorMsg';
import LoadingMsg from '../layout/LoadingMsg';
import { Link as RouterLink } from '@reach/router';
import { useTranslation, Trans } from 'react-i18next';
import { Asset } from '../models/asset';
import { getAssetsPage } from './services';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
	},
	title: {
		flexGrow: 1,
	},
	container: {
		padding: 0,
	},
	row: {},
	textSearch: {
		width: '90%',
	},
	iconButton: {
		marginTop: '3px',
		marginLeft: '-55px',
	},
}));

interface InputState {
	page: number;
	pageSize: number;
	order: string;
	q: string;
}

export interface SearchResponse {
	page: Asset[];
	count: number;
}

let wbInput: InputState | null = null; // para guardar el historico de datos

function AssetsMenu(props: any) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={classes.root}>
		<IconButton
			aria-label={t('more')}
			aria-controls="recent-menu"
			aria-haspopup="true"
			onClick={handleClick}
		>
			<MoreVertIcon />
		</IconButton>
		<Menu
			id="assets-menu"
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={handleClose}
			PaperProps={{
			style: {
				maxHeight: 48 * 4.5,
				width: '32ch',
			},
			}}
		>
			<MenuItem>
				<Trans>Option</Trans>
			</MenuItem>
			<MenuItem>
				<Trans>Option</Trans>
			</MenuItem>
			<MenuItem>
				<Trans>Option</Trans>
			</MenuItem>
		</Menu>
		</div>
	);
}

function AssetsPage(props: any) {
	const classes = useStyles();
	const { t } = useTranslation();

	// state: input values
	const [q, setQ] = React.useState<string>('');
	const [input, setInput] = React.useState<InputState>({
		page: 1,
		pageSize: 10,
		order: 'RECENT',
		q: '',
	});

	// pagination
	function handleNext() {
		const nPages = Math.ceil(search.result!.count / input.pageSize);
		if (input.page < nPages) {
		setInput({ ...input, page: input.page + 1 });
		}
	}

	function handlePrev() {
		if (input.page > 1) {
		setInput({ ...input, page: input.page - 1 });
		}
	}

	useEffect(() => {
		search.execute(input);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input]);

	useEffect(() => {
		// recover from wayback
		if (wbInput) {
			setInput(wbInput);
		// or init groupId
		} else {
			getAssetsPage(input).then((assets) => {
				console.log(assets);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// handle input changes
	const handleChange =
		(prop: keyof InputState) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			wbInput = { ...input, [prop]: event.target.value };
			setQ(event.target.value);
		};

	// handle search event
	const search = useAsyncCallback(getAssetsPage);

	function handleSearch(event: any) {
		setInput({ ...input, q: q });
	}

	function handleEnter(event: KeyboardEvent<HTMLDivElement>) {
		if (event.key === 'Enter') {
			setInput({ ...input, q: q });
		}
	}
	// return children
	return (
		<Container className={classes.container} maxWidth={false}>
			{search.loading && <LoadingMsg />}
			{search.error && <ErrorMsg error={search.error} />}
			<Toolbar disableGutters={true}>
				<Typography component="div" variant="h5" color="inherit" noWrap	className={classes.title}>
				<Trans>Assets</Trans>
				</Typography>
				<Hidden mdUp>
					<AssetsMenu />
				</Hidden>
			</Toolbar>
			<Grid container spacing={0} direction="row-reverse">
				<Hidden smDown>
					<Grid item md={4}>
						<Typography component="h1" variant="h6" color="inherit" noWrap>
							<Trans>Tools</Trans>
						</Typography>
						<Box>
							<Button
								component={RouterLink}
								to="/assets/new"
								color="secondary"
								startIcon={<AddIcon />}
							>
								<Trans>Create Asset</Trans>
							</Button>
						</Box>
						{/* <Box>
							<Button color="secondary" startIcon={<HelpIcon />}>
								<Trans>Help</Trans>
							</Button>
						</Box> */}
					</Grid>
				</Hidden>
				<Grid item xs={12} md={8}>
					<Container>
						<TextField
							className={classes.textSearch}
							id="q"
							type="text"
							label={t('Search')}
							value={q}
							onChange={handleChange('q')}
							onKeyPress={handleEnter}
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
										className={classes.iconButton}
										onClick={handleSearch}
										color="secondary"
										aria-label={t('Search')}
										component="span"
										>
										<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Container>
				{search.result && (
					<AssetList
						data={search.result}
						next={handleNext}
						prev={handlePrev}
					/>
				)}
				</Grid>
			</Grid>
		</Container>
	);
}

export default AssetsPage;
