import { httpDelete, httpGet, httpPost, httpPut } from "../AppContext";
import { BACKEND_URL, BACKEND_API_URL } from "../lib/config";
import { Page } from "../models/common";
import { Dataset } from "../models/dataset";
import { Group } from "../models/group";
import { MapAkopica, MapShareUrl } from "../models/map";

const URL_MAPS = "/v1/maps";
const URL_GROUPS = "/v1/groups";
const URL_DATASETS = "/v1/datasets";
const URL_ASSETS = "/v1/assets";
const URL_LAYERS = "/v1/layers";


export async function getMapById(id: string): Promise<MapAkopica> {
    return httpGet<MapAkopica>(BACKEND_URL + URL_MAPS + `/${id}`);
}

export async function getShareMapUrl(id: string): Promise<MapShareUrl> {
    return httpGet<MapShareUrl>(BACKEND_URL + URL_MAPS + `/share/${id}`);
}

export async function updateMap(id: string, data: any): Promise<any> {
    return httpPut<any>(BACKEND_API_URL + URL_MAPS + `/${id}`, data);
}

export async function addLayerToMap(mapId: string, layer: any): Promise<any> {
    return httpPost<any>(BACKEND_API_URL + URL_MAPS + `/${mapId}/layers`, layer);
}

export async function updateLayerInMap(mapId: string, layerId: string, layer: any): Promise<any> {
    return httpPut<any>(BACKEND_URL + URL_MAPS + `/${mapId}/layers/${layerId}`, layer);
}

export async function removeLayerFromMap(mapId: string, layerId: string): Promise<any> {
    return httpDelete<any>(BACKEND_API_URL + URL_MAPS + `/${mapId}/layers/${layerId}`);
}

export async function getMapsPage(params: any): Promise<Page<MapAkopica>> {
    return httpGet<Page<MapAkopica>>(BACKEND_URL + URL_MAPS, params);
}

export async function getGroupsPage(): Promise<Page<Group>> {
    return httpGet<Page<Group>>(BACKEND_URL + URL_GROUPS);
}


export async function removeMap(mapId: string) : Promise<any> {
    return httpDelete<any>(BACKEND_API_URL + URL_MAPS+ `/${mapId}`);
}

export async function createMap(map: MapAkopica): Promise<MapAkopica> {
    return httpPost<MapAkopica>(BACKEND_URL + URL_MAPS, map);
}
/*
export async function getDatasetGeojson(datasetId: string, datasetPath: string): Promise<any> {
    // return httpGet<any>(BACKEND_URL + URL_DATASETS + `/${datasetId}/files/${datasetPath}/index.geojson`);
    return fetch(BACKEND_URL + URL_DATASETS + `/${datasetId}/files/${datasetPath}/index.geojson`);
}
*/

export async function getDatasetsPage(params: any): Promise<Page<Dataset>> {
    return httpGet<Page<Dataset>>(BACKEND_URL + URL_DATASETS, params);
}

export async function getAssetById(id: string): Promise<any> {
    return httpGet<any>(BACKEND_URL + URL_ASSETS + `/${id}`);
}
export async function getAssetLayer(id: string): Promise<any> {
    return httpGet<any>(BACKEND_URL + URL_ASSETS + `/${id}/layer`);
}
export async function getDatasetFile(datasetId: string, datasetPath: string, filename: string, contentType?: string): Promise<any> {
    return httpGet<any>(BACKEND_URL + URL_DATASETS + `/${datasetId}/files/${datasetPath}/${filename}`, undefined, {useAuthTokenQuery: true, contentTypeAccepted: contentType});
}
export async function getUploadDatasetFileUrl(datasetId: string, datasetPath: string, filename: string): Promise<any> {
    return httpGet<any>(BACKEND_URL + URL_DATASETS + `/${datasetId}/upload/${datasetPath}/${filename}`);
}
export async function uploadDatasetFile(url: string, file: any): Promise<any> {
    return httpPut<any>(url, file, {sendAuthToken: false, useJsonStringfyOnBody: false});
}
export async function createAnnotation(layerId: string, params: any): Promise<any> {
    return httpPost<any>(BACKEND_API_URL + URL_LAYERS + `/${layerId}/annotation`, params);
}

export async function updateAnnotation(layerId: string, annotationId: string, params: any): Promise<any> {
    return httpPut<any>(BACKEND_API_URL + URL_LAYERS + `/${layerId}/annotation/${annotationId}`, params);
}

export async function deleteAnnotation(layerId: string, annotationId: string): Promise<any> {
    return httpDelete<any>(BACKEND_API_URL + URL_LAYERS + `/${layerId}/annotation/${annotationId}`);
}

export async function getAnnotationPage(layerId: string, params: any): Promise<any> {
    return httpGet<any>(BACKEND_API_URL + URL_LAYERS + `/${layerId}/annotation/page`, params);
}

export async function getAnnotationGeojson(layerId: string): Promise<any> {
    return httpGet<any>(BACKEND_API_URL + URL_LAYERS + `/${layerId}/annotation/geoJson`);
}