import React, { useState, useEffect } from "react";
import { Divider, FormControl, Grid, Slider, InputLabel, MenuItem, Select, TextField, Theme, Typography, SelectChangeEvent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import clsx from "clsx";
import moment from "moment";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Trans, useTranslation } from "react-i18next";


const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(3)
        },
        marginTop: {
            marginTop: theme.spacing(5),
        },
        settingsTitle: {
            marginLeft: theme.spacing(1),
        },
        inputWidth: {
            marginTop: theme.spacing(2),
            width: 240,
        },
        space: {
            marginBottom: 40
        },
        settingsSubTitle: {
            marginLeft: theme.spacing(1),
            fontSize: 10,
        },
    }),
);


interface SentinelInputs {
    preset: string;
    time: string;
    maxcc: number | Array<number>;
    interval: number;
}

interface MyProps {
    map: any;
    layer: any;
    onChangeLayer: Function;
    changeSubtitle: Function;
}

function SentinelSettings(props: MyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
   
    const [inputs, setInputs] = React.useState<SentinelInputs>({
        preset: props.layer.settings.sentinel.preset,
        time: props.layer.settings.sentinel.time,
        maxcc: props.layer.settings.sentinel.maxcc,
        interval: props.layer.settings.sentinel.interval,
    });

    const [dateRef, setDateRef] = useState<Date>(props.layer.settings.sentinel.date);
    const maxDate = new Date();

    const marks = [{
        value: 0,
        label: '',
      },
      {
      value: 25,
      label: '25%',
      },
      {
        value: 50,
        label: '50%',
      },
      {
          value: 75,
          label: '75%',
      },
      {
        value: 100,
        label: '100%',
      }
    ];

    const intervals = [{
        value: 5,
        label: '',
      },
      {
      value: 7,
      label: '7 Days',
      },
      {
        value: 14,
        label: '14 Days',
      },
      {
          value: 21,
          label: '21 Days',
      },
      {
        value: 30,
        label: '30 Days',
      }
    ];

    const setTimeSentinel = () => {
        const dateTo = moment(dateRef).format("YYYY-MM-DD");
        let dateFrom:Date|string = new Date(dateRef); 

        dateFrom?.setDate?.(dateFrom.getDate() - inputs.interval);
        dateFrom = moment(dateFrom).format("YYYY-MM-DD");

        const newTime = `${dateFrom}/${dateTo}`;
        setInputs({...inputs, time: newTime});

        props.layer.settings.sentinel.time = newTime;
        props.onChangeLayer(props.layer);
    };

    useEffect(() => {
        setTimeSentinel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs.interval])

    useEffect(() => {
        props.changeSubtitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRef, inputs.preset]);

    const handleChangeDate = (date: any, keyboardInputValue?: string | undefined) => {
        setDateRef(date);
        props.layer.settings.sentinel.date = date;
        props.onChangeLayer(props.layer);
        setTimeSentinel();
    };

    const handleChangePreset = (event: SelectChangeEvent<string>) => {
        setInputs({...inputs, preset: event.target.value});
        props.layer.settings.sentinel.preset = event.target.value;
        props.onChangeLayer(props.layer);
    };

    const maxccText = (value: number) => {
        return `${value} %`;
    }

    const handleChangeMaxcc = (event: Event, newValue: number | number[]) => {
        if (inputs.maxcc !== newValue) {
            setInputs({...inputs, maxcc: newValue as number[]});
            props.layer.settings.sentinel.maxcc = newValue;
            props.onChangeLayer(props.layer);
        }
    };

    const intervalDaysText = (value: number) => {
        return `${value} Days`;
    };

    const handleChangeIntervalDays = (event: Event, newValue: number | number[]) => {
        if (inputs.interval !== newValue) {
            setInputs({...inputs, interval: newValue as number});
            props.layer.settings.sentinel.interval = newValue;
            props.onChangeLayer(props.layer);
        }
    };

    return (
        <React.Fragment>
            <SettingsDetail {...props} allowNote={true}/>
            <Divider/>
            <Grid container spacing={1}>
                <Grid item lg={12} xs={12} md={12} sx={{marginTop: '8px',  marginLeft: '8px',}}>
                    <Typography className={clsx(classes.settingsTitle, classes.marginTop)} variant="subtitle2"><Trans>Sentinel Settings</Trans></Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginBottom: '8px', marginLeft: '24px',}}>
                    <FormControl variant="outlined" size="small" className={classes.inputWidth}>
                        <InputLabel id="label"><Trans>Preset</Trans></InputLabel>
                        <Select id="labelS" value={inputs.preset} onChange={handleChangePreset} label="Mode" sx={{fontSize: 14,}}>
                            <MenuItem value="AGRICULTURE">Agriculture</MenuItem>
                            <MenuItem value="BATHYMETRIC">Bathymetric</MenuItem>
                            <MenuItem value="FALSE-COLOR">False Color (vegetation)</MenuItem>
                            <MenuItem value="FALSE-COLOR-URBAN">False Color (urban)</MenuItem>
                            <MenuItem value="GEOLOGY">Geology</MenuItem>
                            <MenuItem value="MOISTURE-INDEX">Moisture Index</MenuItem>
                            <MenuItem value="NATURAL-COLOR">Natural Color (true color)</MenuItem>
                            <MenuItem value="NDVI">NDVI</MenuItem>    
                            <MenuItem value="SWIR">SWIR</MenuItem>
                            <MenuItem value="TRUE-COLOR-S2L2A">True Color S2L2A</MenuItem>    
                        </Select>
                    </FormControl>           
                </Grid>
                <Grid item  lg={12} xs={12} md={12} sx={{marginBottom: '8px', marginLeft: '24px',}}>
                    <MobileDatePicker
                        label={t("Date")}
                        value={dateRef ?? ""}
                        onChange={handleChangeDate}
                        maxDate={maxDate}
                        renderInput={(params) => <TextField size="small" className={classes.inputWidth} {...params} error={false} variant="outlined" />}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '16px',}}>
                    <Typography id="range-slider" sx={{fontSize:14,}}>
                        <Trans>Interval Days</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px', fontSize: '10px'}}
                        value={inputs.interval}
                        defaultValue={7}
                        valueLabelDisplay="auto"
                        onChange={handleChangeIntervalDays}
                        getAriaValueText={intervalDaysText}
                        min={5}
                        max={30}
                        marks={intervals}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '16px',}}>
                    <Typography id="range-slider" sx={{fontSize:14,}}>
                        <Trans>Cloud Coverage</Trans> [%]
                    </Typography>
                </Grid>
                <Grid item lg={12} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px', fontSize: '10px'}}
                        value={inputs.maxcc}
                        defaultValue={20}
                        valueLabelDisplay="auto"
                        onChange={handleChangeMaxcc}
                        getAriaValueText={maxccText}
                        min={0}
                        max={100}
                        marks={marks}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SentinelSettings;
