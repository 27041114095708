import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete, Box, Grid, IconButton, List, ListItem, ListItemText, styled, TextField, Theme, Typography } from "@mui/material";
import { IntaLayer, GeoIntaProject, geoIntaProjects } from "./GeoIntaProjectsData";
import VisibiltyIcon from "@mui/icons-material/Visibility";
import VisibiltyOffIcon from "@mui/icons-material/VisibilityOff";


const SettingTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(1),
    marginLeft: 0,
}));

const useStyles = makeStyles((theme: Theme) => ({
    combo: {
        marginBottom: theme.spacing(1),
        width: '90%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%',
    },
}));

interface MyProps {
    layer: any;
    onChangeLayer: Function;
}

function GeoIntaProjects(props: MyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const projects: GeoIntaProject[] = geoIntaProjects;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleChangeProject = (event: any, value: GeoIntaProject) => {
        props.layer.settings.proyect = value;
        props.onChangeLayer(props.layer);
    };

    const handleVisibilityToggle = (item: any) => {
        item.visible = !item.visible;
        props.onChangeLayer(props.layer);
    };

    const drewListSublayer = (sublayers: IntaLayer[]) => {
        return (
            <React.Fragment>
                { 
                    sublayers.map((item: IntaLayer, i: number) => {
                        return (
                            <List key={i} sx={{paddingTop: 0, paddingBottom: 0}}>
                                {
                                    item.type === "group" &&
                                    <React.Fragment>
                                        <SettingTitle variant="subtitle2">
                                            {item.title}
                                        </SettingTitle>
                                        <Box sx={{marginLeft: "20px"}}>
                                            {drewListSublayer(item.list || [])}
                                        </Box>
                                    </React.Fragment>
                                }
                                {
                                    item.type === "layer" &&
                                    <React.Fragment>
                                        {drewItemSublayer(item)}
                                    </React.Fragment>
                                }
                            </List>
                        );
                    })
                }
            </React.Fragment>
        );
    };

    const drewItemSublayer = (item: any) => {
        return (
            <ListItem 
                secondaryAction={
                    <IconButton edge="end" onClick={e => handleVisibilityToggle(item)}>
                        { item.visible
                            ? <VisibiltyIcon fontSize="small" color="secondary"/>
                            : <VisibiltyOffIcon fontSize="small" color="secondary"/>
                        }
                    </IconButton>
                }
                sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0}}
            >
                <ListItemText primary={item.title} sx={{maxWidth: "220px", whiteSpace: "normal"}}/>
            </ListItem>
        );
    };


    return (
        <Box sx={{ marginLeft: "15px" }}>
            <Grid container spacing={1}>
                <Grid item lg={12} xs={12} md={12} >
                    <SettingTitle variant="subtitle2">
                        <Trans>Choose a project</Trans>
                    </SettingTitle>
                </Grid>
                <Grid item lg={12} xs={12} md={12} >
                    <Autocomplete
                        className={classes.combo}
                        size="small"
                        id="combo-box-asttype"
                        options={projects}
                        value={props.layer.settings.proyect || {key: "", title: ""}}
                        onChange={handleChangeProject}
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) =>
                            option.key === value.key
                        }
                        renderInput={(params) => (
                            <TextField {...params} label={t('Projects')} variant="outlined" />
                        )}
                    />
                </Grid>

                {props.layer.settings.proyect != null &&
                    <Grid item lg={12} xs={12} md={12} >
                        { drewListSublayer(props.layer.settings.proyect.sublayers) }
                    </Grid>
                }

                <Grid item lg={12} xs={12} md={12} >
                </Grid>
                
            </Grid>
        </Box>
    );

}


export default GeoIntaProjects;