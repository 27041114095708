import React, { useState, useEffect, useCallback } from "react";
import useLoading from "../../../../lib/hooks/useLoading";
import { getFeaturesByDataset } from "../../../../features/services";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PlaceIcon from '@mui/icons-material/Place';
import { LoadingRight } from "../../../../lib/components/LoadingProgress/LoadingProgress";
import { Page } from "../../../../models/common";
import Button from "@mui/material/Button";
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Trans, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Theme } from "@mui/material";
import { debounce } from "lodash";


const useStyles = makeStyles((theme: Theme) =>
  ({
        textSearch: {
            marginTop: "10px",
            marginLeft: theme.spacing(1),
            width: "80%",
        },
    }),
);


interface FeatureBrowserProps {
    layer: any;
    mapCurr: any;
}

function FeatureBrowser(props: FeatureBrowserProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [featurePage, setFeaturePage] = useState<Page<any>|null>(null);
    const [loading, setLoading] = useLoading(false);
    const PAGE_SIZE = 6;
    const [page, setPage] = useState<number>(1);
    const [q, setQ]= React.useState<string>("");
    const [filters, setFilters] = React.useState<any>({page: 1, pageSize: 6});

    const loadFeaturePage = (filters: any) => {
        setLoading(true);
        getFeaturesByDataset(props.layer.id, filters)
            .then(async page => {
                setFeaturePage(page);
            })
            .catch(error => {
                setFeaturePage(null);
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    // pagination
    const handleNext = () => {
        const nPages = Math.ceil((featurePage?.count || 0) / PAGE_SIZE);
        if (page < nPages) {
            const newPage = page + 1;
            setPage(newPage);
            let f = filters;
            f.page = newPage;
            loadFeaturePage(f);
        }
    };
    const handlePrev = () => {
        if (page > 1) {
            const newPage = page - 1;
            setPage(newPage);
            let f = filters;
            f.page = newPage;
            loadFeaturePage(f);
        }
    };

    const moveToPoint = (i: number) => {
        const point = featurePage?.page[i];
        props.mapCurr?.setView([point['_lat'], point['_long']], 14);
    }

    const searchQ = (f:any) => {
        loadFeaturePage(f);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlerChangeQ = useCallback(debounce((filter)=>searchQ(filter), 300), []);

    const changeQ = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQ(event.target.value);
        const val = event.target.value.trim();

        if (val.length > 1) {
            const f = filters;
            f.page = 1;
            f.q = event.target.value.trim();
            setFilters(f);
            handlerChangeQ(f);
        }

        if (val === "") {
            const f = filters;
            f.page = 1;
            delete f['q'];
            setFilters(f);
            handlerChangeQ(f);
        }
    }


    useEffect(() => {
        //console.log(props);
        loadFeaturePage(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box sx={{textAlign: "center"}}>
            <Grid container spacing={1}>
                <Grid item lg={12} md={12} xs={12}>
                    <TextField id="q" type="search" size="small" autoComplete='off' variant="outlined"
                        className={classes.textSearch}
                        label={t("Search")}
                        value={q}
                        onChange={changeQ}
                    />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    { 
                        featurePage?.page.map((feature, i) => {
                            return (
                                <ListItem onClick={()=> moveToPoint(i)} disablePadding key={i}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PlaceIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                        feature[props.layer.dataset.settings.columnTitle] != null 
                                                        ? feature[props.layer.dataset.settings.columnTitle] 
                                                        : `[${feature['_long'].toFixed(5)}, ${feature['_lat'].toFixed(5)}]` 
                                                    } 
                                            secondary={
                                                        feature[props.layer.dataset.settings.columnSubtitle] != null 
                                                        ? feature[props.layer.dataset.settings.columnSubtitle] 
                                                        : ""
                                                    }
                                            sx={{
                                                '& .MuiListItemText-primary': {textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"},
                                                '& .MuiListItemText-secondary': {textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    }
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <Button onClick={handlePrev} startIcon={<ChevronLeft />}>
                        <Trans>Previous</Trans>
                    </Button>
                    <Button onClick={handleNext} endIcon={<ChevronRight />}>
                        <Trans>Next</Trans>
                    </Button>
                    {loading && <LoadingRight></LoadingRight>}
                </Grid>
            </Grid>
            
        </Box>
    );

}


export default FeatureBrowser;