import React, { useEffect } from 'react';
import {
    Theme,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    Toolbar,
    TextField,
    FormControl,
    FormHelperText,
    Select,
    InputLabel,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { Link as RouterLink } from "@reach/router";
import { LoadingRight } from '../lib/components/LoadingProgress/LoadingProgress';
import { Group } from "../models/group";
import { getGroups, createDataset } from './services';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';
import useLoading from '../lib/hooks/useLoading';
import FolderIcon from '@mui/icons-material/Folder';
import { useNavigate } from "@reach/router";
import { useTranslation, Trans } from "react-i18next";
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from "moment";
import { getAppUser } from '../AppContext';

const useStyles:any = makeStyles((theme: Theme) =>
({
    toolbar: {
        paddingRight: 0,
        paddingLeft: 0
    },
    title: {
        flexGrow: 1,
    },
    rowActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
}),
);

export interface InputState {
    id?: string,
    subject?: string,
    refdate: string,
    title: string,
    detail?: string,
    groupId?: string,
    assetId?: string,
    dstype?: string,
    name: string,
}

export interface UploadFileOutput {
    path: string
    s3_file_key?: string

}
interface InputError {
    groupId?: string,
    title?: string,
    name?: string,
    subject?: string,
    refdate?: string,
    dstype?: string,
}

function DatasetNewPage(props: any) {

    const classes = useStyles();
    const { t, i18n} = useTranslation();
    const language = i18n.language;

    // state: input values
    const [input, setInput] = React.useState<InputState>({
        subject: "",
        refdate: new Date().toISOString().substr(0, 10),
        title: "",
        detail: "",
        groupId: "",
        assetId: "",
        dstype: "",
        name: "",
    });
    const [refDate, setRefDate] = React.useState<string>(new Date().toISOString());
    const [inputError, setInputError] = React.useState<InputError>({})
    const [groups, setGroups] = React.useState<any>();
    const [loading, setLoading] = useLoading(false);
    const [dstypeInfo, setDstypeInfo] = React.useState<string>('');
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{ msg: string, severity: string }>({ msg: "", severity: "success" });
    const navigate = useNavigate();

    useEffect(() => {
        validateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    useEffect(() => {
        handleDstypeInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input.dstype, language]);

    useEffect(() => {
        getGroups().then((group) => {
            setGroups(group);
            if (group?.page?.length === 1) setInput({ ...input, groupId: group?.page[0]?.id })
        }).catch((error) => {
            console.log('Error:', error);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        if (prop === "title") {
            if (value.trim() === "") {
                setInputError({ ...inputError, title: t("Please provide a Title") });
            } else {
                setInputError({ ...inputError, title: undefined });
            }
        }

        if (prop === "name") {
            if (value.trim() === "") {
                setInputError({ ...inputError, name: t("Please provide a Name") });
            } else {
                setInputError({ ...inputError, name: undefined });
            }
        }

        setInput({ ...input, [prop]: value });
    };

    const handleChangeSelect = (event:SelectChangeEvent<string>) => {
        const prop = event.target.name;

        if (prop === "dstype") {
            if (event?.target?.value === "") setInputError({ ...inputError, dstype: t("Please choose a Dataset Type") });
        } else if (prop === "groupId") {

            if (event?.target?.value === "") {
                setInputError({ ...inputError, groupId: t("Please choose a Group") });
                return;
            } else {
                setInputError({ ...inputError, groupId: undefined });
            }

        }
        setInput({ ...input, [prop]: event.target.value});
    };

    const handleChangeRefDate = (date: any, keyboardInputValue?: string | undefined) => {
        setRefDate(date);
        input.refdate = moment(date).format("YYYY-MM-DD");

        setInput({ ...input});
    };

    
    const validateData = () => {
        if (input.groupId === "" || input.dstype === "" || input.title.trim() === "" || input.name?.trim() === "" || input.refdate?.trim() === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const handleDstypeInfo = () => {
        if (input.dstype === "tile") setDstypeInfo(t('Dataset Tile Description'));

        if (input.dstype === "geojson") setDstypeInfo(t('Dataset Geojson Description'));

        if (input.dstype === "csv") setDstypeInfo(t('Dataset Csv Description'));

        if (input.dstype === "image") setDstypeInfo(t('Dataset Image Description'));

        if (input.dstype === "feature") setDstypeInfo(t('Dataset Feature Description'));

    }

    const handleCreate = async (event: any) => {
        try {
            setLoading(true);
            if (inputError.title || inputError.name || inputError.groupId) {
                return
            }

            setInputError({ groupId: undefined, title: undefined, name: undefined });

            const newDataset = await createDataset({...input, title: input.title.trim(), name: input.name.trim()});
            if (newDataset) navigate('/datasets/' + newDataset.id, { state: { new: true } });
        } catch (error:any) {            
            if(error.error.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else {
                setSnackBarMsg({ msg: t("Sorry, an unexpected error has ocurred"), severity: "error" });
            }
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    }

    const isOldVersion = () =>{
        const user = getAppUser();
        if (user != null && user.siteversion != null && user.siteversion < 2) {
            return true;
        }
        return false;
    };
    
    const getAllDatasetType = () => {
        const widgets: JSX.Element[] = [];

        widgets.push(<MenuItem value={"image"} key="image"><Trans>Images</Trans></MenuItem>);
        widgets.push(<MenuItem value={"csv"} key="csv"><Trans>Csv</Trans></MenuItem>);
        widgets.push(<MenuItem value={"geojson"} key="geojson"><Trans>GeoJson</Trans></MenuItem>);
        widgets.push(<MenuItem value={"tile"} key="tile"><Trans>Tiles</Trans></MenuItem>);
        widgets.push(<MenuItem value={"feature"} key="feature"><Trans>Feature</Trans></MenuItem>);
        
        return widgets;
    };

    const getNewDatasetType = () => {
        const widgets: JSX.Element[] = [];

        widgets.push(<MenuItem value={"feature"} key="feature"><Trans>Feature</Trans></MenuItem>);
        
        return widgets;
    };

    return (
        <Container maxWidth={false}>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity} />
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    {input.title !== "" ? input.title : t("New Dataset")}
                </Typography>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/datasets" color="secondary" startIcon={<FolderIcon />}><Trans>Search Dataset</Trans></Button>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <FormControl variant="outlined" required fullWidth>
                                <InputLabel htmlFor="group-label">
                                    <Trans>Group</Trans>
                                </InputLabel>
                                <Select
                                    labelId="groupId-label"
                                    id="groupId"
                                    name="groupId"
                                    onChange={handleChangeSelect}
                                    value={input.groupId}
                                    error={inputError.groupId !== undefined}
                                    label={("Group")}>

                                    {
                                        groups?.page.map((group: Group) => {
                                            return <MenuItem value={group.id} key={group.id}>{group.title}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText
                                    error={inputError.groupId !== undefined}
                                >
                                    {inputError.groupId ? inputError.groupId : null}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                id="title"
                                label={t("Title")}
                                value={input.title}
                                onChange={handleChange("title")}
                                variant="outlined"
                                placeholder={t("Title of the dataset")}
                                helperText={inputError.title ?? t("Title Helper New Dataset")}
                                error={inputError.title !== undefined}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                id="name"
                                label={t("Name")}
                                value={input.name}
                                onChange={handleChange("name")}
                                variant="outlined"
                                placeholder={t("Name of the dataset")}
                                helperText={inputError.name ?? t("Name Helper New Dataset")}
                                error={inputError.name !== undefined}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <MobileDatePicker
                                label={t("Reference Date")}
                                value={refDate ?? ""}
                                onChange={handleChangeRefDate}
                                renderInput={(params) => <TextField {...params} 
                                                                    fullWidth 
                                                                    variant="outlined"
                                                                    helperText={inputError.subject}
                                                                    error={inputError.subject !== undefined}
                                                                    required
                                                            />}
                            />
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <FormControl variant="outlined" required fullWidth>
                                <InputLabel htmlFor="dstype-label"><Trans>Dataset Type</Trans></InputLabel>
                                <Select
                                    labelId="dstype-label"
                                    id="dstype"
                                    name="dstype"
                                    onChange={handleChangeSelect}
                                    value={input.dstype}
                                    error={inputError.dstype !== undefined}
                                    label={t("Dataset Type")}
                                >   
                                    { isOldVersion() ?
                                        getAllDatasetType()
                                        :
                                        getNewDatasetType()
                                    } 
                                </Select>
                                <FormHelperText
                                    error={inputError.dstype !== undefined}
                                    
                                >
                                    {inputError.dstype ? inputError.dstype : null}
                                    {dstypeInfo ? dstypeInfo : null}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                id="detail"
                                multiline
                                rows={4}
                                label={t("Detail")}
                                value={input.detail}
                                onChange={handleChange("detail")}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={10} className={classes.rowActions}>
                            <Button disabled={disabled || loading} onClick={handleCreate} variant="contained" color="secondary"><Trans>Create</Trans></Button>
                            {loading && <LoadingRight />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DatasetNewPage;
