import React, { useEffect, useState } from 'react'
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import 'iv-viewer/dist/iv-viewer.css';
import L, { LeafletMouseEvent } from 'leaflet';
import { MapContainer, FeatureGroup, ImageOverlay, useMapEvents} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import Spinner from './Spinner';
import { useTranslation, Trans } from "react-i18next";
import { BACKEND_URL } from '../../lib/config';
import { getDatasetFile, getUploadDatasetFileUrl, uploadDatasetFile } from '../services';
import { authToken } from '../../AppContext';
import { RotateLeft, RotateRight } from '@mui/icons-material';

const useStyles = makeStyles((theme) =>
    ({
        dialog: {
        },
        map: {
        },
        comments: {
            marginLeft: "40px",
        },
        row: {
            marginBottom: "16px",
        },
        spinner: {
            marginTop: "16px",
        },
        inline: {
            margin: "8px",
            display: "inline-block",
        },
        rotate: {
            marginLeft: "40px",
            wrap: 'nowrap',
            marginBottom: "10px",
        },
        text: {
            marginRight: "40px",
            color: "primary",
        },
    }),
);

interface ImageMapDialogProps {
    layer: any,
    dataset: any,
    open: boolean,
    imageName: any,
    onClose: any, // TODO what type???
    featureLayer: any,
    imageType: any,
    data: any,
    onChangeData: any,
}

export default function ImageMapDialog(props:ImageMapDialogProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [cargando, setCargando] = useState(true);
    const [cargandoMap, setCargandoMap] = useState(true);

    const cargandoS3 = false;
    const [comment, setComment] = useState("");
    const [grados, setGrados] = useState([]);
    const [geoJson, setGeoJson] = useState("");
    const [selectShape, setSelectShape] = useState({ open: false, layer: undefined });
    const [featureComment, setFeatureComment] = useState("");
    const [previuosLayer, setPreviuosLayer] = useState(null);

    const [editableFG, setEditableFG] = useState<L.FeatureGroup>();

    const [bounds, setBounds] = useState(L.latLngBounds(L.latLng(0, 0), L.latLng(512, 640)));
    const imageOverlayRef: any = React.createRef();
    const [imageAngle, setImageAngle] = useState<any>(0);

    const rotateLeft = t('Rotate Left');
    const rotateRight = t('Rotate Right');

    var rotateAngle = imageAngle;

    const handleRotate = (direction: string) => {

        if(direction === 'right') rotateAngle+=90;
        
        if(direction === 'left') rotateAngle-=90;

        const {leafletElement} = imageOverlayRef.current
        rotateImage(leafletElement.getElement(), rotateAngle);

    };
    const handleRotateCenter = (image: any) => {
        image.style.webkitTransformOrigin = '50% 50%';
        image.style.mozTransformOrigin = '50% 50%';
        image.style.oTransformOrigin = '50% 50%';
        image.style.transformOrigin = '50% 50%';
    };

    const rotateImage = (image: any, angle: any) => {
        handleRotateCenter(image);
        image.style.transform = `rotate(${angle}deg)`;
        setImageAngle(angle);
      }

    const onCreatePath = (e:any) => {
        uploadGeoJson();

    };

    const onEditPath = (e:any) => {
        //console.log('Edit path layer.toGeoJSON: ', e.layer.toGeoJSON())
    };

    function onDeletePath(e:any) {
        //console.log('Delete path layer.toGeoJSON: ', e.layer.toGeoJSON())
    }

    const loadData = async () => {
    
        const detailsData = async () => {
            if (props.dataset && props.dataset.id) {
                try {
                    const data = await getDatasetFile(props.dataset.id, props.dataset.path, `${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.info`, "application/json");
                    setImageAngle(data.angle);
                    setComment(data.comment);
                } catch (error) {
                    console.log("No se encuentra el comentario");
                    console.log(error);
                    setComment("");
                    setImageAngle("");

                }
                try {
                    const data = await getDatasetFile(props.dataset.id, props.dataset.path, `${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.geojson`, "application/json");
                    setGeoJson(data);
                } catch (error) {
                    console.log("No se encuentra el comentario");
                    console.log(error);
                }

                try {
                    const data = await getDatasetFile(props.dataset.id, props.dataset.path, `${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.pixdata`, "application/json");
                    setGrados(data.data);
                } catch (error) {
                    console.log("No se encuentra pixdata");
                    console.log(error);
                    setGrados([]);
                }
            }
        }

        return await detailsData();
    }

    useEffect(() => {
        if (props.imageName.substring(0, 3)==='DSC') {
            setBounds(L.latLngBounds(L.latLng(0, 0), L.latLng(600, 800)));
        }
        
        (async function () {
            await loadData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (imageOverlayRef.current !== null) handleLeafletLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageAngle]);

    useEffect(() => {
        loadDraw(geoJson);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoJson]);

    useEffect(() => {
        if (!cargandoMap && !cargandoS3) {
            setCargando(false);
        }
    }, [cargandoMap, cargandoS3]);

    const uploadGeoJson = () => {
        if (editableFG) {

            const fileData = JSON.stringify(editableFG.toGeoJSON());
            
            const file = new Blob([fileData], { type: "text/plain" });
            
            // Retrieve a URL from our server.
            retrieveNewURLGeoJson(file, (file:any, url:any) => {
                // Upload the file to the server.
                uploadFile(file, url);
            }); 
        }
    };

    const retrieveNewURLGeoJson = (file:any, cb:any) => {
        // let urlbe = `${BACKEND_URL}/v1/datasets/${props.dataset.id}/upload/${props.dataset.path}/${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.geojson`;
        getUploadDatasetFileUrl(props.dataset.id, props.dataset.path, `${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.geojson`)
        .then((url) => {
            cb(file, url);
        }).catch((e) => {
            console.error(e);
        });
    };

    const upload = () => {
        let jsonData = { "comment": comment, "angle": imageAngle };
        const fileData = JSON.stringify(jsonData);
        const file = new Blob([fileData], { type: "text/plain" });

        // Retrieve a URL from our server.
        retrieveNewURL(file, (file:any, url:any) => {
            // Upload the file to the server.
            uploadFile(file, url);
        });
    };

    const retrieveNewURL = (file:any, cb:any) => {
        //let urlbe = `${BACKEND_URL}/v1/datasets/${props.dataset.id}/upload/${props.dataset.path}/${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.info`;
        getUploadDatasetFileUrl(props.dataset.id, props.dataset.path, `${props.imageName.substring(0, props.imageName.lastIndexOf("."))}.info`)
        .then((url) => {
            cb(file, url);
        }).catch((e) => {
            console.error(e);
        });
    };

    const uploadFile = (file:any, url:any) => {
        uploadDatasetFile(url, file)
        .then(() => {
            // If multiple files are uploaded, append upload status on the next line.
            //document.querySelector('#status').innerHTML += `<br>Uploaded ${file.name}.`;
            console.log("subido");
        }).catch((e) => {
            console.error(e);
        });
    };

    const saveFeatures = () => {
        const layer:any = selectShape.layer;
        if (layer) {
            layer.feature.properties.featureComment = featureComment;
            layer.feature.properties.angle = imageAngle;
        }
        uploadGeoJson();

    };

    const handleSave = () => {
        saveFeatures();
        upload();
        props.onClose();
    }

    const [gradosc, setGradosc] = useState('')

    const onMouseMove = (e:LeafletMouseEvent) => {
        if (grados && grados.length > 0) {
            try {
                let gradospix = grados[512 - Math.round(e.latlng.lat)][Math.round(e.latlng.lng)] + ' °C'
                setGradosc(gradospix)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleChange = (event:any) => {
        setFeatureComment(event.target.value);
    };

    const onClickFeature = (event:any) => {
        const mystyle = {
        default: {
            "color": "#0000ff",
            "weight": 3,
            "opacity": 0.5
        },
        click: {
            "color": "#66C600",
            "weight": 5,
            "opacity": 0.9
        }}
        //TODO en el caso de los Point habria que cambiar el icono, no el color
        if (event.layer.feature && event.layer.feature.geometry
            && event.layer.feature.geometry.type && event.layer.feature.geometry.type !== "Point") {
            event.layer.setStyle(mystyle.click);
        }
        //event.layer.selected = true;
        //}

        const pl: any = previuosLayer;
        if (pl) {
            if (pl.feature && pl.feature.geometry
                && pl.feature.geometry.type && pl.feature.geometry.type !== "Point") {
                pl.setStyle(mystyle.default);
            }
        }

        setPreviuosLayer(event.layer);

        if (featureComment || imageAngle) {
            saveFeatures();
        }
        if (event.layer.feature.properties.featureComment) {
            setFeatureComment(event.layer.feature.properties.featureComment);
        } else {
            setFeatureComment("");
        }
        if (event.layer.feature.properties.angle) {
            setImageAngle(event.layer.feature.properties.angle);
        } else {
            setImageAngle(0);
        }
        setSelectShape({ open: true, layer: event.layer });
    }

    const loadDraw = (geoJson:any) => {
        if (editableFG) {
            let leafletFG = editableFG;
            leafletFG?.clearLayers();
            
            new L.GeoJSON(geoJson).eachLayer(layer => leafletFG?.addLayer(layer));
        }
    }

    const onLayerAdd = (event:any) => {
        const layer = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
        feature.properties["Foo"] = "Bar";
    }

    const handleLeafletLoad = (event?:any) => {
        try{
            const { leafletElement } = imageOverlayRef.current;
            const image = leafletElement.getElement()
            handleRotateCenter(image);
    
            image.style.transform = `rotate(${imageAngle}deg)`;
                    
            if (!cargandoS3) {
                setCargando(false);
            }
            setCargandoMap(false);
        } catch (e) {
            console.log(e);
        } finally {
            setCargandoMap(false);
        }
    }
    const ImageMapEvents = () => {
        
        useMapEvents({
            move: (e) => {
                onMouseMove(e as LeafletMouseEvent);             
            },
        });

        return null
    }

    return (
        <div>
            <Dialog className={classes.dialog} maxWidth="lg"
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title"><Trans>Layer</Trans>:: {props.layer.title} - {props.imageName}</DialogTitle>

                <DialogContent>
                        <Grid container>
                            <Grid item xs={8}>

                                <MapContainer 
                                    center={[256, 320]}
                                    zoom={0}
                                    maxZoom={4}
                                    minZoom={0}
                                    crs={L.CRS.Simple}
                                    style={{
                                        height: '512px',
                                        width: '640px',
                                        margin: '0 auto',
                                    }}
                                >
                                    <ImageMapEvents />
                                    {/*    height: '40vh',
                                        width: '25vw', */}
                                    {/* {geoJson && */}
                                    <FeatureGroup
                                        ref={(featureGroupRef:any) => {
                                            setEditableFG(featureGroupRef);
                                        }}
                                        eventHandlers={{
                                            click: onClickFeature,
                                            layeradd: onLayerAdd,
                                        }}
                                    >

                                        {true &&
                                            <EditControl position="topright"
                                                onCreated={onCreatePath}
                                                onEdited={onEditPath}
                                                onDeleted={onDeletePath}
                                                draw={{
                                                circle: false,
                                                circlemarker: true,
                                                polyline: false
                                                }}
                                            />
                                        }
                                    </FeatureGroup>

                                    <ImageOverlay
                                        eventHandlers={{
                                            load: handleLeafletLoad,
                                        }}
                                        ref={imageOverlayRef}
                                        bounds={bounds}
                                        url={`${BACKEND_URL}/v1/datasets/${props.dataset.id}/files/${props.dataset.path}/${props.imageName}?jwt=${authToken()}`}
                                    />
                                </MapContainer>
                            </Grid>

                            <Grid>
                                <Grid item xs={8} sx={{marginLeft: "10px"}}>
                                    <Grid container direction="row" alignItems="center" className={classes.rotate}>
                                        <Grid item>
                                            <Typography className={classes.text}>
                                                <Trans>Rotate Image</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={rotateLeft}>
                                                <RotateLeft onClick={() => {handleRotate('left')}} fontSize="large"/>
                                            </Tooltip>
                                            <Tooltip title={rotateRight} >
                                                <RotateRight onClick={() => {handleRotate('right')}} fontSize="large"/>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{marginLeft: "10px"}}>
                                    <div className={classes.comments}>
                        
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        value={comment}
                                        id="name"
                                        label={t("Image Comment")}
                                        type="text"
                                        onChange={e => setComment(e.target.value)}
                                        fullWidth
                                    />

                                    {gradosc && typeof(gradosc) !== 'undefined' &&
                                        <p><Trans>Temperature</Trans>: {gradosc}</p>
                                    }

                                    <div>
                                        {selectShape.open &&
                                        <div>
                                            <p><Trans>Detection Data</Trans>: </p>

                                            <TextField
                                            autoFocus
                                            margin="dense"

                                            id="name"
                                            label={t("Detection Comment")}
                                            name="prop1"
                                            value={featureComment}
                                            onChange={handleChange}
                                            type="text"
                                            /* onChange={e => setComment(e.target.value)} */
                                            fullWidth
                                            />
                                        </div>
                                        }
                                    </div>

                                    {cargando &&
                                        <div className={classes.spinner}>
                                            <Spinner />
                                        </div>
                                    }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                </DialogContent>


                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        <Trans>Close</Trans>
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
