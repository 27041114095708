import React, { useEffect } from "react";
import { Theme, FormControlLabel, Switch, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        container: {
        },
        settingsRow: {
            margin: theme.spacing(1),
        },
        settingsTitle: {
            margin: theme.spacing(1),
            marginLeft: 0,
        }

    }),
);

interface MyProps {
    map: any;
    layer: any;
    onClose: Function;
    onChangeLayer: Function;
    changeSubtitle: Function;
}

function NoteSettings(props: MyProps) {
    console.debug("SettingsNote: start...");
    const classes = useStyles();

    const [values, setValues] = React.useState({
        edit: props.layer.settings?.note?.edit || false,
    });
    
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        //console.log("HANDLE CHANGE");
        const name = event.target.name;
        setValues({ ...values, [name]: event.target.checked });

        props.layer.settings.note = props.layer.settings.note || {};
        if (name === "edit") {
            props.layer.settings.note.edit = event.target.checked;
        }

        props.onChangeLayer(props.layer, "note::" + name, event.target.checked);
    };

    // inicializacion
    useEffect(() => {
        setValues(props.layer.settings.note);
        return () => {
            // close edit
            props.layer.settings.note.edit = false;
            setValues({...values, edit: false});
            //props.onChangeLayer(props.layer, "note::edit", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <SettingsDetail {...props} allowNote={false} disableNoteEdit={true}></SettingsDetail>
            <Divider></Divider>
            <Typography className={classes.settingsTitle} variant="subtitle2"><Trans>Notes</Trans></Typography>
            <div className={classes.settingsRow}>
                <FormControlLabel 
                    label={<Typography variant="body2" color="textSecondary"><Trans>Edit</Trans></Typography>}
                    control={<Switch
                        size="small"
                        checked={values.edit}
                        onChange={handleChange}
                        disabled={props.layer.settings.basic.noteEdit}
                        name="edit"
                        inputProps={{ 'aria-label': 'edit notes' }}
                        />}
                />
            </div>
        </div>
    )
}

export default NoteSettings;
