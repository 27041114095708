import React from 'react';
import { Theme, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, ListItemSecondaryAction } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Trans } from "react-i18next";
import { User } from '../models/auth';
import { getAppUser } from '../AppContext';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: User,
    canEdit: boolean,
    handleSelect: Function,
    handleRemove: Function,
}

function MemberListItem(props: MyProps) {
    const currentUser = getAppUser();
    const classes = useStyles();
    const handleRemove = () => {
        props.handleSelect(props.data);
        props.handleRemove()
    }
    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar alt="Group" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary={`${props.data.firstname} ${props.data.lastname} `}
                secondary={
                    <React.Fragment>
                        <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                            Email: {props.data.email}
                        </Typography>
                        {""}
                    </React.Fragment>
                }
            />
            {props.canEdit && currentUser?.email !== props.data.email && <ListItemSecondaryAction onClick={handleRemove} sx={{cursor: 'pointer'}}>
                <PersonRemoveIcon color="secondary"/>
            </ListItemSecondaryAction>}
        </ListItem>
    );
}

export default MemberListItem;
