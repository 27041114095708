import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Theme, MenuItem, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Layer } from "leaflet";
import { PointSchema } from "./PointSchema";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        dialog: {
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        inline: {
            margin: theme.spacing(1),
            display: "inline-block",
        },
        fieldBox: {
            width: 245,
            marginBottom: theme.spacing(1.5),
            [theme.breakpoints.up(400)]: {
                width: "100%",
            },
        },
        fieldSize: {
            width: "50%"
        }
    }),
);

interface MyProps {
    layer: any,
    open: boolean,
    featureLayer?: Layer,
    onClose: any, // TODO what type???
    onSave: Function, 
}

function PointDialog(props: MyProps) {
    const classes = useStyles();
    const [open] = useState(props.open);
    const defval: {[key: string]: any} = {};
    const [point, setPoint] = React.useState(defval);
    const [csvValues, setCsvValues] = React.useState<any>({});
  
    /*
    function handleSave(event: MouseEvent) {
        const layer: any = props.featureLayer;
        console.log("layer: ", layer);
        layer.feature.properties.point = point;
        props.onSave(event);
        props.onClose(event);
    }
    */

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setPoint({ ...point, [name]: event.target.value });
    
        // props.onChangeLayer(props.layer, "basic::" + name, event.target.checked);
        //console.log("Values: ", point);
    };

    useEffect(() => {
        const layer: any = props.featureLayer;
        setPoint(layer.feature?.properties?.point || {});
        //console.log("Point Dialog: ", [props, open]);
        //console.log("Point: ", layer.csvValues);
        setCsvValues(layer.csvValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    const widgets: JSX.Element[] = [];

    const loadSchema = () => {
        const fields = (props.layer.dataset?.settings?.schema as PointSchema)?.fields || [];
        for (let field of fields) {
            const widget = field.widget || "default";
            const fieldOptions =  field.options || [];
            const width = field.width || "48ch";
            const variant = widgets.length === 0 ? "filled" : "outlined";
            let comp;

            if (field.type === "string" && widget === "default") {
                const cls = classes.row;
                comp = 
                    <div key={field.name} className={cls}>
                        <TextField
                            label={field.label}
                            id={field.name}
                            name={field.name}
                            variant={variant}
                            size="small"
                            value={point[field.name] || ""}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if (field.type === "string" && widget === "multiline") {
                const cls = classes.row;
                comp =
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            size="small"
                            multiline
                            rows={field.rows || 6}
                            value={point[field.name] || ""}
                            variant={variant}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if (field.type === "float" && widget === "default") {
                const cls = classes.row;
                comp =
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            size="small"
                            type="number"
                            value={point[field.name] || ""}
                            variant={variant}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if (field.type === "int" && widget === "default") {
            const cls = classes.row;
                comp = 
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            label={field.label}
                            size="small"
                            type="number"
                            value={point[field.name] || ""}
                            variant={variant}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if ((field.type === "string" || field.type === "int" ) && widget === "select") {
                const cls = classes.row;
                comp = 
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            size="small"
                            select
                            value={point[field.name] || ""}
                            onChange={handleChange}
                            helperText=""
                            variant={variant}
                            style={{
                                width: width,
                            }}
                        >
                        {fieldOptions.map((option) => (
                            <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                            </MenuItem>
                        ))}
                        </TextField>
                    </div>
            } else {
                comp = <div><Trans>Unknown</Trans></div>
            }

            widgets.push(comp);
        }
    }

    const loadCsvFields = () => {
        // console.log("LOADING CUSTOM FIELDS", shape);
        let comp;

        for (const key in csvValues) {
            // console.log("keylist", customFieldList[i]);
            comp =  <Box key={key} className={classes.fieldBox}>
                        <TextField variant="outlined" size="small" className={classes.fieldSize} value={key}/>
                        <TextField variant="outlined" size="small" className={classes.fieldSize} value={csvValues[key]}/>
                    </Box>
            widgets.push(comp);
        }

        // widgets.push(comp);
        
    };

    if (props.layer.dataset?.settings?.schema != null) {
        loadSchema();
    } else {
        loadCsvFields();
    }


    return (
        <Dialog className={classes.dialog} open={open} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
            <DialogTitle>{props.layer.title}</DialogTitle>
            <DialogContent>
                { widgets.map((w: any) => { return w })}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    <Trans>Close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PointDialog;
