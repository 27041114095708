import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FullLayout from './layout/FullLayout';
import HomePage from './home/HomePage';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Router } from '@reach/router';
import MapsPage from './maps/MapsPage';
import DatasetsPage from './datasets/DatasetsPage';
import MapPage from './maps/MapPage';
import SelectOrgPage from './orgs/select/SelectOrgPage';
import PreLoginLayout from './layout/PreLoginLayout';
import { LoginPage, LoginEmailNotFoundPage, LoginEmailSentPage } from './auth/LoginPage';
import { getAppUser, setAppUser, useAppContext, useAuth } from './AppContext';
import DatasetNewPage from './datasets/DatasetNewPage';
import ManagePage from './manage/ManagePage';
import MapsNewPage from './maps/MapsNewPage';
import DatasetPage from './datasets/DatasetPage';
import AssetsPage from './assets/AssetsPage';
import AssetPage from './assets/AssetPage';
import GroupsPage from './groups/GroupsPage';
import GroupsNewPage from './groups/GroupsNewPage';
import GroupPage from  './groups/GroupPage';
import AssetNewPage from './assets/AssetNewPage';
import { SignupPage, SignupEmailSentPage }from './auth/SignupPage';
import { AccessWithEmailTokenPage } from './auth/AccessWithEmailTokenPage';
import { AccessWithGuestTokenPage } from './auth/AccessWithGuestTokenPage';
import UserProfilePage from './groups/UserProfilePage';
import { getLoggedUser } from './groups/services';
import { LoadingProgress } from './lib/components/LoadingProgress/LoadingProgress';
import akopPackage from '../package.json';
import { MapDashboardPage } from './public/MapDashboardPage';
import PublicMapPage from './public/PublicMapPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    ({
        layout: {
            padding: 0,
        }
    }),
);

const localeMap: any = {
    en: enLocale,
    es: esLocale,
};

function Copyright() {
    const appContext = useAppContext();
    if (!appContext.layout.showCopyright) {
        return (<span></span>);
    }

    return (
        <>
            <Typography variant="body2" color="textSecondary" align="center">
                {"Akopica v" + akopPackage.version + " - "}
                {'Copyright © '}
                <Link color="inherit" href="https://tecso.coop">
                    Coop. Tecso Ltda.
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}            
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                Icons made by <Link color="inherit" href="https://www.freepik.com" title="Freepik">Freepik</Link> from <Link color="inherit" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link>
            </Typography>
        </>
    );
}

export default function App() {
    const classes = useStyles();
    const logged = useAuth();
    const [userLoaded, setUserLoaded] = React.useState<boolean>(false);
    const { i18n } = useTranslation();
    const language = i18n.language;

    // On refresh page, we look for the user info
    useEffect(() => {
        console.log(`Akopica gui version: ${akopPackage.version}`);
        if (getAppUser() == null && logged) {
            getLoggedUser().then(user => {
                setAppUser(user);
                setUserLoaded(true);
            }).catch(error => {
            console.error('Error:', error);
            })
        } else {
            setUserLoaded(true);
        }
    }, [logged]);

    const full =
        <FullLayout className={classes.layout}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[language]}>
                <Router>
                    <HomePage default path="/home"/>
                    <SignupPage path="/auth/signup"/>
                    <SignupEmailSentPage path="/auth/signup/sent"/>
                    
                    <UserProfilePage path="/user/profile"/>

                    <AssetsPage path="/assets"/>
                    <AssetPage path="/assets/:assetId"/>
                    <AssetNewPage path="/assets/new"/>

                    <MapsPage path="/maps"/>
                    <MapPage path="/maps/:mapId"/>
                    <MapPage path="/maps/:mapId/view"/>
                    <MapsNewPage path="/maps/new"/>
                    
                    <SelectOrgPage path="/orgs/select"/>
                    
                    <ManagePage path="/manage"/>
                    
                    <DatasetsPage path="/datasets"/>
                    <DatasetNewPage path="/datasets/new"/>
                    <DatasetPage path="/datasets/:datasetId"/>

                    <GroupsPage path="/groups"/>
                    <GroupsNewPage path="/groups/new"/>
                    <GroupPage path="/groups/:groupId"/>
                </Router>
                <Box my={4}>
                    <Copyright />
                </Box>
            </LocalizationProvider>
        </FullLayout>

    const login =
        <PreLoginLayout>
            <Router>
                <LoginPage default path="/auth/signin"/>
                <LoginEmailNotFoundPage path="/auth/signin/notfound"/>
                <LoginEmailSentPage path="/auth/signin/sent"/>

                {/*<SignupPage path="/auth/signup"/>*/}
                {/*<SignupEmailSentPage path="/auth/signup/sent"/>*/}

                <AccessWithEmailTokenPage path="/auth/access-et/:emailToken"/>
                <AccessWithGuestTokenPage path="/auth/access-gt/:mapToken"/>

                <MapDashboardPage path="/public/maps/:siteName" />
                <PublicMapPage path="/public/map/:mapId" />
            </Router>
        </PreLoginLayout>

    const loading =
        <LoadingProgress></LoadingProgress>

    if (!logged || (window.location.href.indexOf("public/") > 1)) {
        return login;
    } else {
        if (userLoaded) {
            return full;
        }
        return loading;
    }
}
