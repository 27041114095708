import React, { useState, useEffect } from 'react';
import {
    Theme,
    Toolbar,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    TextField,
    IconButton, Menu, MenuItem, Tabs, Tab,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Link as RouterLink, navigate} from "@reach/router";
import { useTranslation, Trans } from "react-i18next";
import { RouteComponentProps } from '@reach/router';
import SearchIcon from "@mui/icons-material/Search";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { Group } from '../models/group';
import { deleteAsset, getAssetById, updateAsset } from './services';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        title: {
            flexGrow: 1,
        },
        container: {
            padding: 0,
        },
        row:{
            marginBottom: theme.spacing(2),
        },
        rowActions:{
            marginBottom: theme.spacing(2),
            width: "100%",
            textAlign: "right",
        },
        textField: {
            width: "100%",
        },
        iconButton: {
            marginTop: "3px",
            marginLeft: "-55px",
        },
        tabs: {
            backgroundColor: "#aed1cd", // theme.palette.grey.A100,
        }    
    }),
);

interface AssetPageProps extends RouteComponentProps {
    assetId?: string;
}


interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any,
}

interface Owner{
    id: string,
    username: string,
    email: string,
    firstname: string,
    lastname: string,
}

export interface Asset {
    id: string,
    name: string,
    title: string,
    detail: string,
    asttype: string,
    // location: {}, CONSULTAR!
    settings: {},
    shape: {},
    created: string,
    modified: string,
    group: Group,
    owner: Owner,
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
        {value === index && (        
            <Box>
                <br></br>
                {children}
            </Box>
        )}
        </div>
    );
}

function tabProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
}

function AssetMenu(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div className={classes.root}>
        <IconButton
          aria-label={t("more")}
          aria-controls="recent-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id="assets-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={ handleClose }
            PaperProps={{
                style: {
                    maxHeight: 48 * 4.5,
                    width: '32ch',
                },
            }}
        >
        <MenuItem><Trans>Option</Trans></MenuItem>
        <MenuItem><Trans>Option</Trans></MenuItem>
        <MenuItem><Trans>Option</Trans></MenuItem>
      </Menu>
      </div>
    );
}




function AssetPage(props: AssetPageProps) {
    console.debug("AssetPage start...");
    const classes = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState(0);
    const [open, setOpen] = React.useState(false);

    const [asset, setAsset] = React.useState<Asset>({
        asttype : "",
        created : "",
        detail : "",
        group : {
            id: "",
            name: "",
            title: ""},
        id: "",
        modified: "",
        name: "",
        owner: {
            id:"",
            username:"",
            email: "",
            firstname: "",
            lastname: ""},
        settings: {},
        shape: {},
        title: "",
    });

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        loadAsset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //load asset info
    const loadAsset = async () => {
        const data = await getAssetById(props.assetId || "");   
        setAsset(data);
    };

    // handle input changes
    const handleChange = (prop: keyof Asset) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setAsset({...asset, [prop]: value });
    };
    
    // save asset updates
    const handleSave = () => {
        const params = {
            title: asset.title,
            name: asset.name,
            detail: asset.detail,
            settings: asset.settings,
        }
        updateAsset(asset.id, params)
        .then(response => navigate('/assets/')); 
    };

    // delete asset
    const handleDelete = () => {
        handleCloseAlert();
        deleteAsset(asset.id)
        .then(response => navigate('/assets/'));   
    };

    // Delete confirmaton 
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseAlert = () => {
        setOpen(false);
    };

    const handleAddMember = () => {
        alert("ADD MEMBER")
    };

    // return children
    return (
        <Container className={classes.container} maxWidth={false}>
            <Toolbar disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>Asset</Trans>: {asset.title}
                </Typography>
                <Hidden mdUp>
                    <AssetMenu/>
                </Hidden>
            </Toolbar>
            <Grid container spacing={0} direction="row-reverse">
                <Hidden smDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/home" color="secondary" startIcon={<AddIcon/>}><Trans>Home</Trans></Button>
                        </Box>
                        <Box>
                            <Button component={RouterLink} to="/assets" color="secondary" startIcon={<SearchIcon />}><Trans>Search Asset</Trans></Button>
                            
                        </Box>
                        {tab === 1 &&
                            <Box>
                                <Button onClick={handleAddMember} color="secondary" startIcon={<LibraryAddIcon />}><Trans>Add Member</Trans></Button>
                            </Box>
                        }
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Container>
                        <Tabs
                            className={classes.tabs}
                            indicatorColor="secondary"
                            value={tab}
                            onChange={handleChangeTab}
                            variant="fullWidth"
                            >

                            <Tab label={t("Main")} {...tabProps(0)}></Tab>
                            <Tab label={t("Members")} {...tabProps(1)}></Tab>
                        
                        </Tabs>


                        {/* Main Panel */}
                        <TabPanel value={tab} index={0}>
                            <div className={classes.row}>
                                <Typography variant="h6" color="secondary" noWrap className={classes.title}>
                                    <Trans>Main Information</Trans>
                                </Typography>
                            </div>

                            <div className={classes.row}>
                                <TextField
                                    className={classes.textField}
                                    id="title"
                                    type="text"
                                    label={t("Title")}
                                    value={asset.title}
                                    onChange={handleChange("title")}
                                    variant="standard"
                                />
                            </div>

                            <div className={classes.row}>
                                <TextField
                                    className={classes.textField}
                                    id="name"
                                    type="text"
                                    label={t("Name")}
                                    value={asset.name}
                                    onChange={handleChange("name")}
                                    variant="standard"
                                />
                            </div>

                            <div className={classes.row}>
                                <TextField
                                    className={classes.textField}   
                                    id="detail"
                                    type="text"
                                    label={t("Detail")}
                                    value={asset.detail}
                                    onChange={handleChange("detail")}
                                    variant="outlined"
                                />
                            </div>

                            <div className={classes.row}>
                                <TextField
                                    className={classes.textField}   
                                    id="settings"
                                    type="text"
                                    label={t("Settings")}
                                    value={asset.settings}
                                    onChange={handleChange("settings")}
                                    variant="outlined"
                                />
                            </div>
                
                            <div className={classes.rowActions}>
                            <Button onClick={handleClickOpen} variant="outlined" color="secondary"><Trans>Delete</Trans></Button>
                                <Dialog
                                    open={open}
                                    onClose={handleCloseAlert}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{t("Delete Asset")}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Trans>Are you sure you want to permanently delete this asset?</Trans>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseAlert} color="primary">
                                            <Trans>No</Trans>
                                        </Button>
                                        <Button onClick={handleDelete} color="primary" autoFocus>
                                            <Trans>Yes</Trans>
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <Button onClick={handleSave} variant="contained" color="secondary"><Trans>Save</Trans></Button>
                            </div>
                        </TabPanel>

                        {/* Members Panel */}
                        <TabPanel value={tab} index={1}>
                            <div className={classes.row}>
                                <Typography component="h1" variant="h6" color="secondary" noWrap>
                                    <Trans>Manage Members</Trans>
                                </Typography>
                            </div>
                        </TabPanel>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AssetPage;
