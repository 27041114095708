import React from 'react';
import { Link as RouterLink } from "@reach/router"
import { Theme, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Dataset } from '../models/dataset';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
        dstype: {
            display: "inline",
            fontSize: "70%",
            fontWeight: "bold",
        },
    }),
);

interface MyProps {
    data: Dataset,
}

function DatasetListItem(props: MyProps) {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" button component={RouterLink} to={"/datasets/" + props.data.id}>
            <ListItemAvatar>
                <React.Fragment >
                    <Avatar alt={props.data.dstype.toUpperCase()} src="/static/images/avatar/2.jpg"/>
                    <Typography component="span" variant="caption" color="textSecondary" className={classes.inline}>
                        {props.data.dstype.toUpperCase()}
                    </Typography>            
                </React.Fragment>
            </ListItemAvatar>
            <ListItemText
                primary = {props.data.title}
                secondary = {
                    <React.Fragment>
                        { props.data.owner.firstname + " " + props.data.owner.lastname } - {props.data.group.title}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}

export default DatasetListItem;
