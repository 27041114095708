import React from "react";
import NoteLayer from "./layer/note/NoteLayer";
import TileLayer from "./layer/tile/TileLayer";
import ShapeLayer from "./layer/shape/ShapeLayer";
import PointLayer from "./layer/point/PointLayer";
import AssetLayer from "./layer/asset/AssetLayer";
import FeatureLayer from "./layer/feature/FeatureLayer";
import ImageLayer from "./layer/image/ImageLayer";
import SentinelLayer from "./layer/sentinel/SentinelLayer";
import GeoIntaLayer from "./layer/geoInta/GeoIntaLayer";
import LayerSideBySide from "./layer/compare/SideBySideLayer";
import { TileLayer as LeafletTileLayer} from "react-leaflet";
import { BACKEND_URL } from "../../lib/config";
import { authToken } from "../../AppContext";
import AnnotationLayer from "./layer/annotation/AnnotationLayer";



const TYPE_COMPONENTS: any = {
    'annotation': AnnotationLayer,
    'asset': AssetLayer,
    'compare': LayerSideBySide,
    'google': LeafletTileLayer,
    'image': ImageLayer,
    'note': NoteLayer,
    'osm' : LeafletTileLayer,
    'point': PointLayer,
    'feature': FeatureLayer,
    'sentinel': SentinelLayer,
    'geoInta': GeoIntaLayer,
    'shape': ShapeLayer,
    'tile': TileLayer,
    //'image': GeoJSONWithLayer,
}


const AkopicaLayer = (props: any) => {
    const { drawControl, setDrawControl, ...rest } = props;
    const lytype: string = props.lytype;
    const SpecificType = TYPE_COMPONENTS[lytype];
    React.useEffect(() => {
        if(lytype === 'annotation' && !props.layer.settings.basic.active) {
            props.drawControl?.remove();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.layer.settings.basic.active
    ])

    if (props.layer.settings.basic.active) {
        if (props.lytype === 'google' || props.lytype === 'osm') {
            const url = props.lytype === 'google' 
                            ? 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                            : 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            return <SpecificType {...rest}
                        url={url}
                        attribution={props.lytype === 'google' ? '&copy; <a href="https://www.google.com/maps/">Google Maps</a>' : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
                        subdomains={props.lytype === 'google' ? ['mt0', 'mt1', 'mt2', 'mt3']: ['a','b','c']}
                        tms=''/>
        }

        if (props.lytype === 'tile') {
            return <SpecificType
                        {...rest}
                        url={`${BACKEND_URL}/v1/datasets/${props.dataset.id}/files/${props.dataset.path}/{z}/{x}/{y}.png?jwt=${authToken()}`}
                        tms='true' />
        }
        if (props.lytype === 'annotation') return <SpecificType drawControl={drawControl} setDrawControl={setDrawControl} {...rest}/>
        return <SpecificType {...rest} />
    }
    return null
}

export default AkopicaLayer;
