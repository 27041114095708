import React, {useEffect, KeyboardEvent} from 'react';
import {
    Theme,
    Toolbar,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    TextField,
    IconButton,
    InputAdornment,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Chip,
    SelectChangeEvent,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddCircle";
import TuneIcon from "@mui/icons-material/Tune";
import DatasetList from './DatasetList';
import { Dataset } from '../models/dataset';
import {useAsyncCallback} from "react-async-hook";
import ErrorMsg from "../layout/ErrorMsg";
import LoadingMsg from "../layout/LoadingMsg";
import {Link as RouterLink, RouteComponentProps, useNavigate} from "@reach/router";
import { useTranslation, Trans } from "react-i18next";
import { getDatasetPage, getGroups } from './services';
import { Group } from '../models/group';
import { Page } from '../models/common';
import useLoading from '../lib/hooks/useLoading';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from "moment";
import { DialogFilter } from '../orgs/dialog/DialogFilter';
import { getGroupById } from '../groups/services';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';

const useStyles = makeStyles((theme: Theme) =>
    ({
        toolbar: {
            paddingRight: 0,
            paddingLeft: 0
        },
        title: {
            flexGrow: 0,
            paddingRight: theme.spacing(2),
        },
        textSearch: {
            width: "85%",
        },
        filterBox: {
            marginTop: theme.spacing(1),
        },
        filterField: {
            marginTop: theme.spacing(1),
        },
    }),
);

interface InputState {
    page: number,
    pageSize: number,
    q: string,
    groupId: string,
    group?: Group,
    refdateFrom?: string,
    refdateTo?: string,
};

export interface SearchResponse {
   page: Dataset[],
   count: number,
}

interface DatasetsPageProps extends RouteComponentProps {
    location?: any;
}

let wbInput: InputState | null = null; // para guardar el historico de datos

function DatasetsPage(props: DatasetsPageProps) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [groups, setGroups] = React.useState<Page<Group>>();
    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const [loading, setLoading] = useLoading(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});
    
    // state: input values
    const [q, setQ]= React.useState<string>("");
    const [input, setInput] = React.useState<InputState>({
        page: 1,
        pageSize: 10,
        q: "",
        groupId: props.location?.state?.group?.id ?? "",
        group: props.location?.state?.group,
        refdateFrom: "",
        refdateTo: "",
    });

    const [filterGroup, setFilterGroup] = React.useState<Group>();
    const [filterGroupId, setFilterGroupId] = React.useState<string>("");
    const [filterRefDateFrom, setFilterRefDateFrom] = React.useState<string>("");
    const [filterRefDateTo, setFilterRefDateTo] = React.useState<string>("");
    const [disabledFilter, setDisabledFilter] = React.useState<boolean>(true);

    // pagination
    const handleNext = () => {
        const nPages = Math.ceil(search.result!.count / input.pageSize);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });
        }
    }

    const handlePrev = () => {
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
        }
    }
    
    useEffect(() => {
        search.execute(input);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    useEffect(() => {
        // load group if necesary
        if (input.groupId !== "" && !input.group) {
            getGroup(input.groupId);
        }
        
        // recover from wayback
        if (wbInput) {
            setInput(wbInput);
        // or init groupId
        }
        getGroups().then((group) => {
            setGroups(group);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filterGroup?.id|| filterRefDateFrom || filterRefDateTo) {
            setDisabledFilter(false);
        } else {
            setDisabledFilter(true)
        }
    }, [filterGroup?.id,filterRefDateFrom, filterRefDateTo]);

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        wbInput = {...input, [prop]: event.target.value};
        setQ(event.target.value);
    };

    const getGroup = (id: string) => {
        setLoading(true);
        getGroupById(id)
            .then(async gr => {
                setInput({...input, group: gr });
            })
            .catch(error => {
                console.error('Error:', error);
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                setOpenSnackbar(true);    
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    // handle search event
    const search = useAsyncCallback(getDatasetPage);

    const handleSearch = (event: any) => {
        setInput({...input, q: q});
    }

    const handleEnter = (event: KeyboardEvent<HTMLDivElement>) => {        
        if (event.key === "Enter") {
            setInput({...input, q: q});
        }
    }

    const handleDeleteChip = (prop: keyof InputState) => {
        // re draw url bar
        //navigate("/datasets", {replace: true});

        // remove group filter
        if (prop === 'group') {
            setInput({ ...input, groupId: "", group: undefined});
            setFilterGroup({});
            setFilterGroupId("");
        }

        if (prop === 'refdateFrom') {
            setInput({ ...input, refdateFrom: ""});
            setFilterRefDateFrom("");
        }

        if (prop === 'refdateTo') {
            setInput({ ...input, refdateTo: ""});
            setFilterRefDateTo("");
        }
    };

    const handleClickGroup = () => {
        navigate("/groups/" + input.group?.id);
    };

    const handleOpenFilters = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeSelectFilters = (event: SelectChangeEvent<string>) => {
        const prop = event.target.name;

        if (prop === "group") {
            const selected = groups?.page.filter((g:Group) => (g.id===event.target.value))[0];
            setFilterGroupId(event.target.value);
            setFilterGroup(selected);
        }

    }

    const handleChangeRefDateFrom = (date: any, keyboardInputValue?: string | undefined) => {
        setFilterRefDateFrom(date);
    };

    const handleChangeRefDateTo = (date: any, keyboardInputValue?: string | undefined) => {
        setFilterRefDateTo(date);
    };
    

    const handleSubmitFilters = () => {

        if (filterGroup) {
            input.group =  {'id': filterGroup.id, 'title': filterGroup.title};
            input.groupId = filterGroupId;
            setInput({...input});
        }

        if (filterRefDateFrom) {
            input.refdateFrom = moment(filterRefDateFrom).format('YYYY-MM-DD');
            setInput({...input});
        }

        if (filterRefDateTo) {
            input.refdateTo = moment(filterRefDateTo).format('YYYY-MM-DD');
            setInput({...input});
        }

        handleClose();

    };

    const handleFormatDateFrom = () => {
        let dateFrom = language === "en" ? 
                                moment(filterRefDateFrom).format('MM-DD-YYYY') 
                                : moment(filterRefDateFrom).format('DD-MM-YYYY');
        return dateFrom;
    };

    const handleFormatDateTo = () => {
        let dateTo = language === "en" ? moment(filterRefDateTo).format('MM-DD-YYYY') : moment(filterRefDateTo).format('DD-MM-YYYY');
        return dateTo;
    };


    // return children
    return (
        <Container maxWidth={false}>
            {search.loading && (<LoadingMsg/>)}
            {search.error && (<ErrorMsg error={search.error}/>)}
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>Datasets</Trans>
                </Typography>
                <Box>
                   <Button component={RouterLink} to="/datasets/new" variant="outlined" color="secondary" startIcon={<AddIcon/>}><Trans>Create Dataset</Trans></Button>
                </Box>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                        <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/datasets/new" color="secondary" startIcon={<AddIcon/>}><Trans>Create Dataset</Trans></Button>
                        </Box>
                        {/* <Box>
                            <Button color="secondary" startIcon={<HelpIcon/>}><Trans>Help</Trans></Button>
                        </Box> */}
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <TextField
                        className={classes.textSearch}
                        id="q"
                        type="text"
                        label={t("Search")}
                        value={q}
                        onChange={handleChange("q")}
                        onKeyPress={handleEnter}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleSearch}
                                        color="secondary"
                                        aria-label={t("Search")}
                                        size="large">
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                          }}
                    />
                    <IconButton
                        onClick={handleOpenFilters}
                        color="secondary"
                        aria-label={t("filter")}
                        component="span"
                        size="large">
                        <TuneIcon />
                    </IconButton>

                    <Box className={classes.filterBox}>
                        {input?.group?.id &&
                            <Chip label={t("group_filter") + input.group.title} onDelete={()=>handleDeleteChip("group")} onClick={handleClickGroup} color="secondary" />
                        }
                        {input.refdateFrom &&
                            <Chip label={t("date_from_filter") + ": " + handleFormatDateFrom()}  onDelete={()=>handleDeleteChip("refdateFrom")} color="secondary" />
                        }
                        {input.refdateTo &&
                            <Chip label={t("date_to_filter") + ": " + handleFormatDateTo()}  onDelete={()=>handleDeleteChip("refdateTo")} color="secondary" />
                        }
                    </Box>

                    {search.result && <DatasetList data={search.result} next={handleNext} prev={handlePrev}/> }
                </Grid>
                <Grid item xs={12} md={10}>
                    <DialogFilter open={open} onClose={handleClose} disabled={disabledFilter} onSubmit={handleSubmitFilters}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={10}>
                                <FormControl variant="outlined" required fullWidth >
                                    <InputLabel htmlFor="group-label" className={classes.filterField}>
                                        <Trans>Group</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="groupId-label"
                                        id="groupId"
                                        className={classes.filterField}
                                        name="group"
                                        onChange={handleChangeSelectFilters}
                                        value={filterGroup?.id ?? ""}
                                        label={("Group")}>

                                        {
                                            groups?.page.map((group: Group) => {
                                                return <MenuItem value={group.id} key={group.id}>{group.title}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Grid container direction="row"spacing={2} alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <MobileDatePicker
                                            label={t("date_from_filter")}
                                            value={filterRefDateFrom ?? ""}
                                            onChange={handleChangeRefDateFrom}
                                            renderInput={(params) => <TextField {...params} error={false} variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MobileDatePicker
                                            label={t("date_to_filter")}
                                            value={filterRefDateTo ?? ""}
                                            onChange={handleChangeRefDateTo}
                                            renderInput={(params) => <TextField {...params} error={false} fullWidth variant="outlined"/>}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogFilter>
                </Grid>
            </Grid>
            {loading && <LoadingProgress/>}
        </Container>
    );
}

export default DatasetsPage;
