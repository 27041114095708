import React, { ReactEventHandler, createRef, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { LayerEvent, geoJSON, TooltipOptions, PathOptions } from "leaflet";
import L from "leaflet";
import ImageMapDialog from "../../ImageMapDialog";
import { getDatasetFile, getUploadDatasetFileUrl, uploadDatasetFile } from "../../../services";

/*
interface MyProps {
    layer: any,
    allowNote: boolean,
    onChangeMap: Function,
    onChangeLayer: Function
}
*/

export const tooltipOptions: TooltipOptions = {
    permanent: false,
    direction: "center",
    opacity: 0.7,
};

const shapeStyle: PathOptions = {
    color: "#faa",
    dashArray: [8],
}

function ImageLayer(props: any) {
    const layer = props.layer;
    const settings = props.layer.settings || {};
    const featureGroupRef = createRef<L.FeatureGroup>();
    // const [selectShape, setSelectShape] = useState({ open: false, layer: undefined });
    const [selectShape, setSelectShape] = React.useState({ 
        open: false, 
        layer: undefined,
        imageType: undefined,
        imageName: undefined,
        dataset: undefined
    });

    const filename = "index.geojson";

    function onCreatePath(event: ReactEventHandler) {
        saveShapes();
        //console.log("create path");
    }

    function saveShapes() {
        const data = featureGroupRef.current?.toGeoJSON();
        // console.log("FEATUREGROUOREF", featureGroupRef.current?.leafletElement);
        if (!data) {
            // XXX handle error
            return;
        }
        const fileData = JSON.stringify(data);
        const file = new Blob([fileData], {type: "text/plain"});
        getNewUrl(file, upload);
    }

    async function loadShapes() {
        const dataset = layer.dataset;
        if (dataset?.id) {
            try {
                const data = await getDatasetFile(dataset.id, dataset.path, filename, "application/json");
                const featureGroup = featureGroupRef.current;
                featureGroup?.clearLayers();
                try {
                    geoJSON(data, {
                        style: shapeStyle,
                        pointToLayer: myPointToLayer,
                    }).eachLayer(layer => { 
                        // bind tooltop with note title if exists
                        const l: any = layer;
                        // const ttLabel = l.feature?.properties?.title;
                        let ttLabel = "";
                        for (const key in l.feature?.properties) {
                            ttLabel += "<b>" + key + "</b>: " + l.feature?.properties[key] + "</br>";
                        }

                        if (ttLabel) {
                            layer.bindTooltip(ttLabel, tooltipOptions);
                        }

                        // add note to group
                        featureGroup?.addLayer(layer);
                    });
                } catch(error) {
                        console.error("Error: loading geojson dataset", [dataset, data, error]);
                }
            } catch (error) {
                console.error("Error: loading dataset", [dataset, error]);
            }
        }
    }

    function upload(file: any, url: string) {
        uploadDatasetFile(url, file)
        .then(() => {
            //console.log("Upload ok");
            // props.onChangeMap();
            loadShapes();
        }).catch((e) => {
            console.error("Upload Error: ", e);
        });
    }

    function getNewUrl(file: any, cb: Function) {
        getUploadDatasetFileUrl(layer.dataset.id, layer.dataset.path, filename)
        .then((url) => {
            //console.log("url: ", url);
            cb(file, url);
        }).catch((e) => {
            console.error("getNewUrl Error: ", e);
        });
    }

    function onEditPath(event: ReactEventHandler) {
        saveShapes();
        //console.log("edit path");
    }

    function onDeletePath(event: ReactEventHandler) {
        saveShapes();
        //console.log("delete path");
    }


    const onClickFeature = (e: any) => {
        setSelectShape({
            open: true, 
            layer:  e.sourceTarget.feature, 
            imageType: undefined, 
            imageName: e.sourceTarget.feature.properties.file,
            dataset: layer.dataset
        });
    }

    const onCloseShape = (event: MouseEvent) => {
        setSelectShape({open: false, layer: undefined, imageType: undefined, imageName: undefined, dataset: undefined});
    }

    const onChangeData = (data: any) => {
        //const pl: any = previuosLayer;
        //if (pl && pl.feature) {
        //    pl.setStyle({
        //        fillColor: getColor(data)
        //    });
        //}
    }
    
    const getColor = (d:any) => {
        return d > 4
            ? "#ff1100"
            : d > 2
            ? "#ff5500"
            : d > 0
            ? "#ff9900"
            : "#007800";
    };

    const myPointToLayer = (geoJsonPoint:any, latlng:any) => {
        let fillColor = getColor(geoJsonPoint.properties.detects);
            
        let geojsonMarkerOptions: L.CircleMarkerOptions = {
            stroke: false,
            radius: 6,
            fillColor: fillColor,
            color: "#000",
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8,            
        };
        
        return L.circleMarker(latlng, geojsonMarkerOptions); //L.marker(latlng);
    }

    const handleSetMapView = () => {
        try {
            const featureGroup = featureGroupRef.current;
            const coords = featureGroup?.getBounds().getCenter();
            props.mapRef.setView([coords?.lat, coords?.lng], 14);
        } finally {
            props.setIntoLayer("");
        }
    };

    function onLayerAdd(event: LayerEvent) {
        const layer: any = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
    }

    useEffect(() => {
        loadShapes().then(() => {
            if(props.intoLayer === layer.id) {
                handleSetMapView();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.debug("Start AkopicaShapeLayer: props:", props);

    return (
        <React.Fragment>
            <FeatureGroup
                ref={featureGroupRef}
                eventHandlers={{
                    click: onClickFeature,
                    layeradd: onLayerAdd,
                }}
            >

                {settings.image.edit &&
                <EditControl position="topright"
                    onCreated={onCreatePath}
                    onEdited={onEditPath}
                    onDeleted={onDeletePath}
                    draw={{
                        rectangle: false,
                        circle: false,
                        circlemarker: false,
                    }}
                />
                }
            </FeatureGroup>

            {selectShape.open &&            
                <ImageMapDialog 
                    {...props} 
                    open={true} 
                    layer={props.layer} 
                    featureLayer={selectShape.layer}
                    imageType={selectShape.imageType}
                    imageName={selectShape.imageName}
                    dataset={selectShape.dataset}
                    onClose={onCloseShape}
                    onChangeData={onChangeData}>
                </ImageMapDialog>
            }
        </React.Fragment>
    );
}

ImageLayer.defaultProps = {
    popupContent: '',
}

export default ImageLayer;
