import React, { useEffect } from "react";
import { Theme, Switch, FormControlLabel, Typography, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        settingsTitle: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
        }
    }),
);

interface MyProps {
    map: any;
    layer: any;
    onChangeLayer: Function;
    allowNote: boolean;
    disableNoteEdit?: boolean;
    handleDisabledBasic?: () => void;
}

export interface BasicSettings {
    active: boolean,
    noteEdit: boolean,
    noteView: boolean,
}

function SettingsDetail(props: MyProps) {
    const classes = useStyles();

    // If not loaded, use settings by default
    const basicSettings =  props.layer.settings.basic;
    const [values, setValues] = React.useState(basicSettings);
    const [allowNote, setAllowNote] = React.useState(props.allowNote);
    
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (props.handleDisabledBasic) props.handleDisabledBasic();
        const name = event.target.name;
        setValues({ ...values, [name]: event.target.checked });
        
        props.layer.settings.basic = props.layer.settings.basic || {};

        if (name === "active") {
            basicSettings.active = event.target.checked;
        } else if (name === "noteEdit") {
            basicSettings.noteEdit = event.target.checked;
        } else if(name === "noteView") {
            basicSettings.noteView = event.target.checked;
        }

        props.onChangeLayer(props.layer, "basic::" + name, event.target.checked);
    };

    // inicializacion
    useEffect(() => {
        setValues(basicSettings);
        return () => {
            // close edit
            basicSettings.noteEdit = false;
            setValues({...values, noteEdit: false});
            //props.onChangeLayer(props.layer, "basic::noteEdit", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basicSettings]);

    useEffect(() => {
        setAllowNote(props.allowNote);
    }, [props.allowNote]);

    return (
        <Grid container spacing={1}>
            <Grid item lg={12} xs={12} md={12}>
                <Typography sx={{marginTop: '8px', marginLeft: '8px',}} variant="subtitle2"><Trans>Basic</Trans></Typography>
            </Grid>
            <Grid item lg={12} xs={12} md={12} sx={{marginBottom: '16px', marginLeft: '24px',}}>
                <FormControlLabel
                    label={<Typography variant="body2" color="textSecondary"><Trans>Active</Trans></Typography>}
                    control={<Switch
                        size="small"
                        checked={values.active}
                        onChange={handleChange}
                        name="active"
                        inputProps={{ 'aria-label': 'active layer' }}
                    />}
                />
            </Grid>

            { allowNote && false && // REMOVED WITH FALSE, CHECK IF DELETE
                <React.Fragment>
                    <Divider></Divider>
                    <Typography className={classes.settingsTitle} variant="subtitle2"><Trans>Notes</Trans></Typography>
                    <div className={classes.settingsRow}>
                        <FormControlLabel 
                            label={<Typography variant="body2" color="textSecondary"><Trans>View Notes</Trans></Typography>}
                            control={<Switch
                                size="small"
                                checked={values.noteView}
                                onChange={handleChange}
                                name="noteView"
                                inputProps={{ 'aria-label': 'edit notes' }}
                            />}
                        />
                    </div>
                    <div className={classes.settingsRow}>
                        <FormControlLabel 
                            label={<Typography variant="body2" color="textSecondary"><Trans>Edit Notes</Trans></Typography>}
                            control={<Switch
                                size="small"
                                disabled={props.disableNoteEdit}
                                checked={values.noteEdit}
                                onChange={handleChange}
                                name="noteEdit"
                                inputProps={{ 'aria-label': 'edit notes' }}
                            />}
                        />
                    </div>
                </React.Fragment>
            }
        </Grid>
    )
}

export default SettingsDetail;
