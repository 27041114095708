import { httpDelete, httpGet, httpPost, httpPut } from "../AppContext";
import { User } from "../models/auth";
import { BACKEND_URL, BACKEND_API_URL } from "../lib/config";
import { Page } from "../models/common";
import { Group } from "../models/group";
import { Member } from "../models/member";

const URL_USER = "/v1/users";
const URL_GROUPS = "/v1/groups";


export async function getGroupsPage(params: any): Promise<Page<Group>> {
    return httpGet<Page<Group>>(BACKEND_URL + URL_GROUPS, params);
}

export async function getGroupById(id: string): Promise<Group> {
    return httpGet<Group>(BACKEND_URL + URL_GROUPS + `/${id}`);
}

export async function updateGroup(id: string, data: Group): Promise<Group> {
    return httpPut<Group>(BACKEND_URL + URL_GROUPS + `/${id}`, data);
}

export async function deleteGroup(id: string): Promise<Group> {
    return httpDelete<Group>(BACKEND_URL + URL_GROUPS + `/${id}`);
}

export async function createGroup(group: any): Promise<any> {
    return httpPost<any>(BACKEND_URL + URL_GROUPS, group);
}

export async function getLoggedUser(): Promise<User> {
    return httpGet<User>(BACKEND_URL + URL_USER + "/logged");
}

export async function getUsersPage(params: any): Promise<Page<User>> {
    return httpGet<Page<User>>(BACKEND_API_URL + URL_USER, params);
}

export async function getUserById(id:string): Promise<User> {
    return httpGet<User>(BACKEND_URL + URL_USER + `/${id}`);
}

export async function updateUserById(id:string, body: User): Promise<User> {
    return httpPut<User>(BACKEND_URL + URL_USER + `/${id}`, body);
}

export async function getGroupMembersPage(groupId: string, params: any): Promise<Page<User>> {
    return httpGet<Page<Member>>(BACKEND_API_URL + URL_GROUPS  + `/${groupId}/members`, params);
}

export async function addGroupMember(groupId:string, params: any): Promise<any> {
    return httpPost<any>(BACKEND_API_URL + URL_GROUPS + `/${groupId}/members`, params);
}

export async function removeGroupMember(groupId: string, params: any): Promise<any> {
    return httpDelete<any>(BACKEND_API_URL + URL_GROUPS  + `/${groupId}/members`, params);
}