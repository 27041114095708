import React, { useEffect, useState } from 'react';
import {
  Theme,
  Tab,
  Tabs,
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import SettingsDetail from '../../settings/SettingsBasic';
import FeatureBrowser from './FeatureBrowser';
import DatasetFeatureConfig from '../../../../datasets/DatasetFeatureConfig';
import CloseIcon from '@mui/icons-material/Close';
import grey from '@mui/material/colors/grey';
import { ColorPicker } from '../../../../lib/components/ColorPicker/ColorPicker';

const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(3),
        },
        tabs: {
            backgroundColor: '#aed1cd',
        },
        inputWidth: {
            width: 240,
        },
    })
);

const SettingTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(2),
    marginLeft: 0,
    marginTop: theme.spacing(1),
}));

interface MyProps {
    map: any;
    layer: any;
    editable?: boolean;
    onClose: Function;
    onChangeLayer: (layer: any, changed?: string, value?: any) => void;
    changeSubtitle: Function;
    mapCurr: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


interface FeatureInputs {
    linepoints: boolean;
    radius: string;
    mode: string;
    color: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ marginTop: '-8px' }}>
                <br></br>
                {children}
            </Box>
        )}
        </div>
    );
}

function FeatureSettings(props: MyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(0);
    const [openEditDataset, setOpenEditDataset] = useState<boolean>(false);

    const [inputs, setInputs] = React.useState<FeatureInputs>({
        linepoints: false,
        radius: '1',
        mode: 'marker',
        color: '#ffeb3b',
      });
    const [editColor, setEditColor] = React.useState<boolean>(false);


    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };
    
    const handleOpenEditDataset = () => {
        setOpenEditDataset(true);
    };

    const handleCloseEditDataset = () => {
        setOpenEditDataset(false);
    };

    const handleChangeMode = (event: SelectChangeEvent<string>) => {
        setInputs({ ...inputs, mode: event.target.value });
        props.layer.settings.mode = event.target.value;
        props.onChangeLayer(props.layer);
    };

    const handleChangeRadiusValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputs({ ...inputs, radius: event.target.value });
        props.layer.settings.radiusValue = event.target.value;
        props.onChangeLayer(props.layer);
    };

    const handleOpenEditColor = () => {
        setEditColor(true);
    };
    
    const handleChangeColor = (color: string) => {
        setInputs({ ...inputs, color: color });
        props.layer.settings.color = color;
        setEditColor(false);
        props.onChangeLayer(props.layer);
    };

    const handleChangeLinepoints = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputs({ ...inputs, linepoints: event.target.checked });
        props.layer.settings.linepoints = event.target.checked;
        props.onChangeLayer(props.layer);
    };

    const initialConf = () => {
        setInputs({
            linepoints: props.layer.settings.linepoints || false,
            radius: props.layer.settings.radiusValue || 1,
            mode: props.layer.settings.mode || 'marker',
            color: props.layer.settings.color || '#ffeb3b',
        });
    };

    // inicializacion
    useEffect(() => {
        //console.log("setting begin",props.layer);
        initialConf();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const CloseDialogTitle = (props:any) => {
        const { children, onClose, ...other } = props;
      
        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <Box style={{ padding: '0px' }}>
            <Tabs
                className={classes.tabs}
                indicatorColor="secondary"
                value={tab}
                onChange={handleChangeTab}
                variant="fullWidth"
            >
                <Tab label={t('Browse')}></Tab>
                {props.editable && <Tab label={t('Settings')}></Tab>}
            </Tabs>

            {/*Browse Tab*/}
            <TabPanel value={tab} index={0}>
                <FeatureBrowser layer={props.layer} mapCurr={props.mapCurr}/>
            </TabPanel>
            {/*Settings Tab*/}
            <TabPanel value={tab} index={1}>
                <SettingsDetail {...props} allowNote={true}></SettingsDetail>
                <Divider />
                <Grid container spacing={1}>
                    <Grid item lg={12} xs={12} md={12} sx={{ marginLeft: '8px' }}>
                        <SettingTitle variant="subtitle2">
                            <Trans>Dataset</Trans>
                        </SettingTitle>
                        <Button variant="contained" color="secondary" size="small" onClick={handleOpenEditDataset}>{t("Configurate")}</Button>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sx={{ marginLeft: '8px' }}>
                        <SettingTitle variant="subtitle2">
                            <Trans>Styles</Trans>
                        </SettingTitle>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sx={{ marginLeft: '16px', marginTop: '-8px' }}>
                        <Typography variant="subtitle2">
                            <Trans>General</Trans>
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                        <InputLabel id="label">Color</InputLabel>
                    </Grid>
                    {!editColor && (
                        <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                            <ColorPicker mode="view" color={inputs.color} onOpenEdit={handleOpenEditColor} />
                        </Grid>
                    )}
                    {editColor && (
                        <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                            <ColorPicker mode="picker" onSelectColor={handleChangeColor} />
                        </Grid>
                    )}
                    <Grid item lg={12} xs={12} md={12} sx={{ marginLeft: '16px' }}>
                        <Typography variant="subtitle2">
                            <Trans>Points</Trans>
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }} >
                        <FormControl variant="outlined" size="small" className={classes.inputWidth} >
                            <InputLabel id="label">
                                <Trans>Mode</Trans>
                            </InputLabel>
                            <Select id="labelS" value={inputs.mode} onChange={handleChangeMode} label="Mode">
                                <MenuItem value="marker">
                                    <Trans>Marker</Trans>
                                </MenuItem>
                                <MenuItem value="rectangle">
                                    <Trans>Rectangle</Trans>
                                </MenuItem>
                                <MenuItem value="circle">
                                    <Trans>Circle</Trans>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {(inputs.mode === 'rectangle' || inputs.mode === 'circle') && (
                        <React.Fragment>
                            <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                                <TextField
                                    size="small"
                                    id="Radius"
                                    type="number"
                                    label={t('Radius')}
                                    value={inputs.radius}
                                    variant="outlined"
                                    className={classes.inputWidth}
                                    onChange={handleChangeRadiusValue}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                        <FormControlLabel
                            label={
                                <Typography variant="body2" color="textSecondary">
                                    <Trans>Line points</Trans>
                                </Typography>
                            }
                            control={
                                <Switch
                                    size="small"
                                    checked={inputs.linepoints}
                                    onChange={handleChangeLinepoints}
                                    name="active"
                                    inputProps={{ 'aria-label': 'active line points' }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sx={{ marginTop: '10px', marginLeft: '24px' }}>
                    </Grid>
                </Grid>
            </TabPanel>

            <Dialog open={openEditDataset} fullWidth maxWidth="md" onClose={handleCloseEditDataset}>
                <CloseDialogTitle onClose={handleCloseEditDataset} open={openEditDataset}>Dataset</CloseDialogTitle>
                <DialogContent>
                    <DatasetFeatureConfig originDataset={props.layer.dataset} canEdit={props.editable} layer={props.layer} onChangeLayer={props.onChangeLayer}/>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default FeatureSettings;
