import React, {useEffect} from 'react';
import {
    Theme,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    Toolbar,
    TextField,
    FormControl,
    FormHelperText,
    Select,
    InputLabel,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MapIcon from '@mui/icons-material/Map';
import { Group } from "../models/group";
import {Link as RouterLink} from "@reach/router";
import {useNavigate} from "@reach/router"
import { useTranslation, Trans } from "react-i18next";
import { createMap, getGroupsPage } from './services';
import { MapAkopica } from "../models/map"
import useLoading from '../lib/hooks/useLoading';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';


const useStyles = makeStyles((theme: Theme) =>
    ({
        title: {
            flexGrow: 1,
        },
        textField: {
            width: "100%"
        },
        toolbar: {
            paddingRight: 0,
            paddingLeft: 0
        },
        rowActions: {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: theme.spacing(2),
        },
    }),
);
interface InputError {
    groupId?: string,
    title?: string,
}

function MapsNewPage(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useLoading(false);
    const [groups, setGroups] = React.useState<any>();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [inputError, setInputError] = React.useState<InputError>({})
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});

    // state: input values
    const [input, setInput] = React.useState<MapAkopica>({
        title: "",
        detail: "",
        groupId: "", 
    });
    const navigate = useNavigate();

    useEffect(() => {
        searchGroupsPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[input]);

    const searchGroupsPage = async() => {
        setLoading(true);
        try{
            const fetchedGroups = await getGroupsPage()
            if(fetchedGroups) {
                setGroups(fetchedGroups);
                if (fetchedGroups?.page?.length === 1) setInput({...input, groupId: fetchedGroups?.page[0]?.id}) 
            }

        } catch(error) {
            console.error('Error:', error);
        } finally{
            setLoading(false);
        }
    };

    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
        const prop = event.target.name;
        setInput({...input, [prop]: event.target.value});
       if (prop === "groupId") {

            if (event.target.value === "") {
                setInputError({...inputError, groupId: t("Please choose a Group")});
            } else {
                setInputError({...inputError, groupId:undefined});
            }
        }         
    }

    const validateData = () => {
        if(input.groupId === "" || input?.title?.trim() === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const createNewMap = (map: MapAkopica) => {
        setLoading(true);
        createMap(map)
            .then(async newMap => {
                clearForms();
                navigate('/maps/' + newMap.id)
            })
            .catch(error => {
                if (error?.error?.message) {
                    setSnackBarMsg({msg:t(error.error.message), severity:"error"});
                } else if (error?.message) {
                    setSnackBarMsg({msg:t(error.message), severity:"error"});
                } else {
                    setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                }
                setOpenSnackbar(true);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    // handle input changes
    const handleChange = (prop: keyof MapAkopica) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput({...input, [prop]: event.target.value});
        const value = event.target.value
        
        if (prop === "title") {
            if (value.trim() === "") {
                setInputError({...inputError, title: t("Please provide a Title")});
            } else {
                setInputError({...inputError, title:undefined});
            } 
        }         
    };

    function handleCreate(event: any) {
        if (input.groupId === "") {
            alert(t("GROUP ID IS MISSING"))
            return;
        }
        if (input.title === ""){
            alert(t("Title is required."))
            return;
        }

        createNewMap(input);
    }

    function clearForms (){
        setInput({...input, title: "", detail: ""});
    }
        

    return (
        <Container maxWidth={false}>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>New Map</Trans>
                </Typography>
                <Hidden mdUp>
                    {/*<RecentMenu />*/}
                </Hidden>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/maps" color="secondary" startIcon={<MapIcon />}><Trans>Maps</Trans></Button>
                        </Box>
                        {/* <Box>
                            <Button color="secondary" startIcon={<Help />}><Trans>Help</Trans></Button>
                        </Box> */}
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <TextField
                                className={classes.textField}
                                label={t("Map title")}
                                id="title"
                                value={input.title}
                                onChange={handleChange("title")}
                                helperText={inputError.title}
                                error={inputError.title !== undefined}
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <FormControl variant="outlined" required fullWidth>
                                <InputLabel htmlFor="group-label">
                                    <Trans>Group</Trans>
                                </InputLabel>
                                <Select
                                    labelId="groupId-label"
                                    id="groupId"
                                    name="groupId"
                                    onChange={handleChangeSelect}
                                    value={input.groupId}
                                    error={inputError.groupId !== undefined}
                                    label={("Group")}>
                                        
                                    {
                                        groups?.page.map((group:Group) =>{
                                            return <MenuItem value={group.id} key={group.id}>{group.title}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText 
                                    error={inputError.groupId !== undefined}
                                >
                                    {(inputError.groupId !== undefined) ? inputError.groupId : null}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                className={classes.textField}
                                label={t("Detail")}
                                id="detail"
                                value={input.detail}
                                onChange={handleChange("detail")}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={10} className={classes.rowActions}>
                            <Button disabled={disabled||loading} onClick={handleCreate} variant="contained" color="primary" disableElevation><Trans>Create</Trans></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingProgress/>}
        </Container>
    );
}
export default MapsNewPage