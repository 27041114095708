import React from 'react';
import clsx from "clsx";
import {
    Theme,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Divider,
    MenuItem,
    Menu,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Hidden,
    Box,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MapIcon from "@mui/icons-material/Map";
import HomeIcon from "@mui/icons-material/Home";
import DataIcon from "@mui/icons-material/Storage";
import GroupIcon from "@mui/icons-material/Group";
import {Link as RouterLink, navigate} from "@reach/router"
import { authLogout, getAppUser } from "../AppContext";
import { useTranslation, Trans } from "react-i18next";
import { logout } from '../auth/services';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            display: 'flex',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.primary.main,
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: 240, // // 240 == drawerwidth
            width: "calc(100% - 240px)", // 240 == drawerwidth
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 0,
            marginLeft: "5px",
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
        },
        createMenu: {},
        createMenuButtom: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(6),
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: 240, // 240 == drawerwidth
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: 0, //theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: 0, // theme.spacing(9),
            },
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        linkButton: {
            color: "#ffffff",
        },
        logo: {
            maxWidth: "120px",
            marginRight: "16px",
            marginTop: "4px",
        },
        siteName: {
            fontSize: "14px",
            fontStyle: "italic",
            maxWidth: "160px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    }),
);


function FullLayout(props: any) {
    // console.debug("FullLayout start...");
    const { t, i18n } = useTranslation();

    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openProfile = Boolean(anchorEl);

    const user = getAppUser();    

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleProfile = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout()
            .then(async data => {
                console.log("response", data);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
                authLogout();
            }
        );
    }; 

    const handleChangeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const handleOpenPublicDashboard = () =>{
        window.open("/public/maps/" + user?.sitename, "_blank");  
    };

    const getUserName = React.useMemo(() => {
        let name = "";
        if (user == null) {
            return name;
        }
        if (user.lastname != null && user.lastname.trim() !== "") {
            name += user.lastname;

            if (user.firstname != null && user.firstname.trim() !== "") {
                name+= ", " + user.firstname;
            }
        } else if (user.firstname != null && user.firstname.trim() !== "") {
            name += user.firstname;
        } else {
            name = user.username ? user.username : "";
        }
        return name;
    }, [user]);

    return (
        <div className={classes.root}>
            <AppBar position="absolute" className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar} disableGutters={true}>
                    <IconButton
                        color="inherit"
                        aria-label={t("open drawer")}
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, openDrawer && classes.menuButtonHidden)}
                        size="large">
                        <MenuIcon/>
                    </IconButton>
                    <Hidden smDown>
                        <div>
                            <img alt="logo" className={classes.logo} src="/assets/images/logo-b.png" onClick={() => navigate("/home")} />
                        </div>
                        <Button sx={{color: '#ffff'}} component={RouterLink} to="/home"><Trans>Home</Trans></Button>
                        <Button sx={{color: '#ffff'}} component={RouterLink} to="/groups"><Trans>Groups</Trans></Button>
                        <Button sx={{color: '#ffff'}} component={RouterLink} to="/maps"><Trans>Maps</Trans></Button>
                        <Button sx={{color: '#ffff'}} component={RouterLink} to="/datasets"><Trans>Datasets</Trans></Button>
                        {/*<Button sx={{color: '#ffff'}} component={RouterLink} to="/assets"><Trans>Assets</Trans></Button>*/}
                    </Hidden>

                    <Box className={classes.title}></Box>

                    <Box sx={{display: "flex"}}>
                        {/*<Button sx={{color: '#ffff'}} onClick={handleProfile}>{getUserName}</Button>*/}
                        <Box sx={{cursor: "pointer"}} onClick={handleProfile}>
                            <Box>{getUserName}</Box>
                            <Box className={classes.siteName}>
                                {user?.sitetitle}
                            </Box>
                        </Box>
                        <IconButton
                            aria-label={t("account of current user")}
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleProfile}
                            color="inherit"
                            size="large">
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openProfile}
                            onClose={handleProfileClose}
                        >
                            <MenuItem>{getAppUser()?.username}</MenuItem>
                            <Divider/>
                            <MenuItem onClick={() => handleOpenPublicDashboard()}><Trans>Public map dashboard</Trans></MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleProfileClose} component={RouterLink} to="/user/profile"><Trans>Edit profile</Trans></MenuItem>
                            <Divider/>
                            <MenuItem onClick={() => handleChangeLanguage("es")}><Trans>Spanish</Trans></MenuItem>
                            <Divider/>
                            <MenuItem onClick={() => handleChangeLanguage("en")}><Trans>English</Trans></MenuItem>
                            {/*
                            <Divider/>
                            <MenuItem onClick={handleProfileClose} component={RouterLink} to="/manage"><Trans>Manage</Trans></MenuItem>
                            */
                            }
                            <Divider/>
                            <MenuItem onClick={handleLogout}><Trans>Exit</Trans></MenuItem>
                        </Menu>
                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                classes={{paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),}}
                open={openDrawer} onClose={handleDrawerClose}>

                <Box className={classes.toolbarIcon}>
                    <img alt="logo" className={classes.logo} src="/assets/images/logo-b.png" />
                    <IconButton onClick={handleDrawerClose} sx={{color: "#ffffff"}} size="large">
                        <ChevronLeft/>
                    </IconButton>
                </Box>
                <Divider/>
                <List>
                    <ListItem button component={RouterLink} to="/home" onClick={handleDrawerClose}>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary="Home"/>
                    </ListItem>
                    <ListItem button component={RouterLink} to="/groups" onClick={handleDrawerClose}>
                        <ListItemIcon><GroupIcon/></ListItemIcon>
                        <ListItemText primary="Groups"/>
                    </ListItem>
                    <ListItem button component={RouterLink} to="/maps" onClick={handleDrawerClose}>
                        <ListItemIcon><MapIcon/></ListItemIcon>
                        <ListItemText primary="Mapas"/>
                    </ListItem>
                    <ListItem button component={RouterLink} to="/datasets" onClick={handleDrawerClose}>
                        <ListItemIcon><DataIcon/></ListItemIcon>
                        <ListItemText primary="Datasets"/>
                    </ListItem>
                    {/* 
                    <ListItem button component={RouterLink} to="/assets" onClick={handleDrawerClose}>
                        <ListItemIcon><BusinessIcon/></ListItemIcon>
                        <ListItemText primary="Assets"/>
                    </ListItem>
                    */}
                </List>
            </Drawer>

            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                {props.children}
            </main>
        </div>
    );
}

export default FullLayout;
