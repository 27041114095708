import React, { useState } from "react";
import { Divider } from '@mui/material';
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Grid } from '@mui/material';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import VisibiltyIcon from "@mui/icons-material/Visibility";
import VisibiltyOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { handleLongText } from "../../lib/utils";

interface MyProps {
    map: any;
    layer: any;
    editable?: boolean;
    index: number;
    min: string;
    max: string;
    onOpen: Function;
    onChangeLayer: Function;
    moveLayers: Function;
}

function AkopicaMenuItem(props: MyProps) {
    const [openOrder, setOpenOrder] = useState<boolean>(false);
    const upDisabled = props.layer.id === props.min;
    const downDisabled = props.layer.id === props.max;

    props.layer.settings = props.layer.settings || {};
    props.layer.settings.basic = props.layer.settings.basic || {};
    const [basic, setBasic] = useState(props.layer.settings.basic);

    const handleOpen = () => {
        if (props.layer.lytype === 'feature' || props.layer.lytype === 'annotation' || props?.editable) props.onOpen(props.layer);
    };

    const handleVisibilityToggle = () => {
        props.layer.settings.basic = {
            ...basic,
            active: !basic.active
        };

        props.onChangeLayer(props.layer);
        setBasic(props.layer.settings.basic);
    };

    const handleOrder = () => {
        setOpenOrder(!openOrder);
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleOpen} sx={{padding: 1, display: 'flex', maxWidth: 'unset', minHeight: "42px",}}>
            <ListItemIcon sx={{ minWidth: "32px", zIndex: 900}}>
                <IconButton
                    aria-controls="order-open-button" 
                    aria-haspopup="true"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOrder();
                    }}
                    color={openOrder ? "secondary" : "primary"}
                >
                    <DragIndicatorIcon/>
                </IconButton>
            </ListItemIcon>
            <ListItemText 
                primary={handleLongText(36, props?.layer?.title)}
                primaryTypographyProps={{ variant: "body2" }} 
                secondary={handleLongText(40, props?.layer?.settings?.subtitle)} 
                secondaryTypographyProps={{ variant: "caption" }}
                />
            {
                openOrder 
                    ?  <ListItemSecondaryAction>
                            <Grid container direction="column" sx={{maxWidth: "32px"}}>
                                <Grid item xs={8} sx={{marginBottom: "-15px"}}>
                                    <IconButton disabled={upDisabled} onClick={() => props.moveLayers("up", props.layer, props.index)}>
                                        <ArrowDropUpIcon fontSize="medium" color={upDisabled ? "primary" : "secondary"}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <IconButton disabled={downDisabled} onClick={() => props.moveLayers("down", props.layer, props.index)}>
                                        <ArrowDropDownIcon fontSize="medium" color={downDisabled ? "primary" : "secondary"}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        
                        </ListItemSecondaryAction>
                    :   <ListItemSecondaryAction onClick={handleVisibilityToggle}>
                            { props.layer.settings?.basic?.active
                                ? <VisibiltyIcon fontSize="small" color="secondary"/>
                                : <VisibiltyOffIcon fontSize="small" color="secondary"/>
                            }
                        </ListItemSecondaryAction>
            }
            </ListItem>
            <Divider/>
        </React.Fragment>
    )
}

export default AkopicaMenuItem;