import { useCallback, useEffect, useState } from "react";

export interface AuthModel {
    storageKey: string,
    storage?: string,
    token?: string,
    // posiblemente otros campos para configurar mas cosas..
}

interface StorageData {
    storageKey: string,
    storage: string, // local, session
    // posiblemente otros campos para configurar mas cosas..
}


const createTokenProvider = (data: StorageData) => {
    const storageKey = data.storageKey;
    let storage: Storage = sessionStorage;
    if (data.storage === "local") {
        storage = localStorage;
    }

    let listeners: any[] = [];

    const subscribe = (listener: any) => {
        listeners.push(listener);
    };
    const unsubscribe = (listener: any) => {
        listeners = listeners.filter((l) => { return l !== listener; });
    };

    const getTokenInternal = () => {
        const data = storage.getItem(storageKey);
        const token = (data && JSON.parse(data)) || null;
        return token;
    };

    const getToken = () => {
        const token = getTokenInternal();

        return token;
    }

    const setToken = (token: string | null) => {
        if (token) {
            storage.setItem(storageKey, JSON.stringify(token));
        }
        else {
            storage.removeItem(storageKey);
        }
        notify();
    };

    const notify = () => {
        const isLogged = isLoggedIn();
        listeners.forEach((l) => { return l(isLogged); });
    };

    const isLoggedIn = () => {
        return (getTokenInternal() != null);
    };

    return {
        getToken: getToken,
        isLoggedIn: isLoggedIn,
        setToken: setToken,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
    };
}

const createAuthProvider = (data: AuthModel): [() => boolean, (s:string) => void, () => void, () => string] => {
    const storageKey = data.storageKey?data.storageKey:"auth_token";
    const token = data.token

    const storageData: StorageData = {
        storageKey,
        storage: data.storage?data.storage.trim().toLowerCase():'session'
    }
    const tp = createTokenProvider(storageData)

    if (token == null) {
        // que hago? importa?
    }

    const login = (newToken: string) => {
        tp.setToken(newToken);
    };

    const logout = () => {
        tp.setToken(null);
    };

    const useAuth = () => {
        const [isLogged, setIsLogged] = useState(tp.isLoggedIn())

        const listener = useCallback((newIsLogged: boolean | ((prevState: boolean) => boolean)) => {
            setIsLogged(newIsLogged);
        }, [setIsLogged]);

        useEffect(() => {
            tp.subscribe(listener);
            return function () {
                tp.unsubscribe(listener);
            };
        }, [listener]);
        return isLogged;
    };

    const getToken = () => {
        return tp.getToken();
    }

    return [useAuth, login, logout, getToken];
}


export default createAuthProvider;
