import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { Trans } from 'react-i18next'

export default function AnnotationConfirmDialog(props: any) {
  return (
    <>
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><Trans>Delete Annotation</Trans></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Trans>Are you sure you want to permanently delete this annotation?</Trans>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    <Trans>No</Trans>
                </Button>
                <Button onClick={props.onConfirm} color="primary" autoFocus>
                <Trans>Yes</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    </>
  )
}
