import { WMSParams } from 'leaflet';
import React from 'react';
import { WMSTileLayer } from "react-leaflet";

interface MyProps {
    layer: any,
}

interface SentinelLayerParams extends WMSParams {
    urlProcessingApi?: string;
    maxcc?: number | Array<number>;
    preset?: string;
    time?: string;
}
    
function SentinelLayer(props: MyProps) {
    const layer = props.layer;
    const key = "62d4ba65-614c-4128-b156-17150baaace9";
    //const baseUrl = `https://services.sentinel-hub.com/ogc/wms/${key}`;
    const baseUrl = `https://pr.dev.akopica.com/ogc/wms/id`;
    const urlProcessingApi = "https://services.sentinel-hub.com/ogc/wms/f9f27612-5c05-4d61-892e-78d50790da0e"
   
    return(
        <WMSTileLayer 
            url={baseUrl}
            attribution='&copy; <a href="https://www.sentinel-hub.com/">Sentinel Hub</a>'
            format={'image/jpg'}
            maxZoom={24}
            params={{
                urlProcessingApi: urlProcessingApi,
                layers:layer.settings.sentinel.preset,
                maxcc: layer.settings.sentinel.maxcc,
                preset: layer.settings.sentinel.preset,
                time: layer.settings.sentinel.time,
            } as SentinelLayerParams}
        />
    )
}

export default React.memo(SentinelLayer);
