import React from "react";
import ColorFilterTileLayer from "./ColorFilterTileLayer";
import NoteLayer from "../note/NoteLayer";

interface MyProps {
    url: string,
    layer: any,
    allowNote: boolean
}

function TileLayer(props: MyProps) {
    console.debug("Start TileLayer: props:", props);

    return (
        <React.Fragment>
            <ColorFilterTileLayer {...props}/>
            {props.allowNote &&
                <NoteLayer {...props}/>
            }
        </React.Fragment>
    );
}

/*
TileLayer.defaultProps = {
  popupContent: '',
}
*/

export default TileLayer;
