import { LayerSchema } from "./AssetSchema";

export const farmSchema: LayerSchema = {
    name: "FarmAssetSchema",
    colorField: "alert",
    fields:[
    {
        name: "title",
        label: "Título",
        type: "string",
    },
    {
        name: "place",
        label: "Place",
        type: "string",
        widget: "multiline",
        rows: 6,
    },
  ]
}
  
export const lotSchema: LayerSchema = {
    name: "LotAssetSchema",
    colorField: "alert",
    fields:[
    {
        name: "title",
        label: "Título",
        type: "string",
    },
    {
        name: "currentCrop",
        label: "Current Crop",
        type: "string",
    },
  ]
}

export const truckSchema: LayerSchema = {
    name: "TruckAssetSchema",
    colorField: "alert",
    fields:[
        {
            name: "patente",
            label: "Patente",
            type: "string",
            defVal: "AB 123 OX",
        },
        {
            name: "conductor",
            label: "Conductor",
            type: "string",
            defVal: "Juan Perez",
        },
        {
            name: "marcaYModeloCamion",
            label: "Marca Y Modelo Camion",
            type: "string",
            defVal: "M. Benz - ACTROS 2636",
        },
        {
            name: "tiempoPermanencia",
            label: "Tiempo de permanencia (Hs)",
            type: "string",
            defVal: "00:30",
        },
        {
            name: "horaArribo",
            label: "Hora de arribo a la planta",
            type: "string",
            defVal: "10:30",
        },
    ]
}

