import React, { ReactEventHandler, createRef, useState, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L, { LeafletMouseEvent, LayerEvent, rectangle, circle, marker, LatLng } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'
import PointDialog from "./PointDialog";
import NoteLayer from "../note/NoteLayer";
import { getDatasetFile, getUploadDatasetFileUrl } from "../../../services";

interface MyProps {
    layer: any,
    allowNote: boolean
}

/*
const schemaDefault = 
    {
        "schema": {
                "columns": {
                "latCol": 0,
                "lonCol": 1,
                "tsCol": 2,
                "valueCol": 3,
                "scaleCol": null
            },
        
            "showLines": true,
            "showPoints": true,
            "interleave": 5,
        
            "scaleX": 5,
            "scaleY": 5,
            "scaleZ": 5,
        
            "fields": [
                {"name":"Lat","label":"Lat","type":"string"},
                {"name":"Lng","label":"Lng","type":"string"},
                {"name":"TimeUS","label":"TimeUS","type":"string"},
                {"name":"Sensor","label":"Sensor","type":"string"},
                {"name":"Alt","label":"Alt","type":"string"},
                {"name":"Velocidad","label":"Velocidad","type":"string"}
            ],
            
            "pallete": "default",
        
            "palletes": {
                "default": {
                    "colors": [
                        [0.0, "#fffff", "const"],
                        [0.5, "#d50101", "const"],
                        [1.6, "#f9e500", "const"],
                        [2.5, "#59c101", "const"],
                        [3.0, "#007900", "const"]
                    ]
                },
                "pallete2": {
                    "colors": [
                        [0.0, "#fffff", "const"],
                        [1.0, "#d50101", "const"],
                        [2.3, "#f9e500", "const"],
                        [2.7, "#59c101", "const"],
                        [3.0, "#007900", "const"]
                    ]
                }
            }
        }
    }
*/

function PointLayer(props: MyProps) {
    const layer = props.layer;
    const settings = props.layer.settings || {};
    const featureGroupRef = createRef<L.FeatureGroup>();
    const [selectShape, setSelectShape] = useState({ open: false, layer: undefined });
    const filename = "index.csv";
    //const schema = props.layer.dataset?.settings?.schema || schemaDefault.schema;
    // const schema = props.layer.dataset?.settings?.schema;
    let schema:any = null;

    const onCreatePath = (event: ReactEventHandler) => {
        saveShapes();
        console.log("create path");
    }

    const saveShapes = () => {
        const data = featureGroupRef.current?.toGeoJSON();
        const fileData = JSON.stringify(data);
        const file = new Blob([fileData], {type: "text/plain"});
        getNewUrl(file, upload);
    }

    async function loadPoints() {
        const dataset = layer.dataset;
        if (dataset?.id && dataset.dstype === "csv") {
            try {
                const data = await getDatasetFile(dataset.id, dataset.path, filename);
                if (data === "") {
                    return;
                }
                
                const featureGroup = featureGroupRef?.current as L.FeatureGroup;
                featureGroup?.clearLayers();

                // parse csv TODO add real parser
                const rows = data.split("\n");

                if (schema) {
                    loadSchema(featureGroup, rows);
                } else {
                    const colLatName = props.layer.settings.point.colLat;
                    let colLatIndex: number|null = null;
                    const colLngName = props.layer.settings.point.colLng;
                    let colLngIndex: number|null = null;
                    const colRadiusName = props.layer.settings.point.colRadius;
                    let colRadiusIndex: number|null = null;

                    const colLabelName = props.layer.settings.point.colLabel;
                    let colLabelIndex: number|null = null;
                    const pointMode = props.layer.settings.point.mode;
                    const linePointsFlag = props.layer.settings.point.linepoints;
                    let radius = props.layer.settings.point.radiusValue || 1;
                    let colorValue = props.layer.settings.point.color || "#ffeb3b";

                    // console.log("begin", colLatName, colLngName);

                    const scaleXToMeterFunc = (value: number) => {
                        return value * 1;
                    };
            
                    // const fields = rows[0].split(",");
                    // console.log("fields", fields);
                    const colHeader = rows[0].split(",").map((title: string) => {
                        return title.trim();
                    })
                    for (let i = 0; i < colHeader.length; i++) {
                        // console.log("iter", i, fields[i], colLatName, colLngName, colRadiusName, colLabelName);
                        if (colHeader[i] === colLatName) {
                            colLatIndex = i;
                        } 
                        if (colHeader[i] === colLngName) {
                            colLngIndex = i;
                        } 
                        if (colHeader[i] === colRadiusName) {
                            colRadiusIndex = i;
                        }
                        if (colHeader[i] === colLabelName) {
                            colLabelIndex = i;
                        } 
                    }
                    // console.log("step1", colLatIndex, colLngIndex, colRadiusIndex);

                    // const interLeave = 0;

                    let count = 0;
                    let linepoints : LatLng[] = [];
                    // console.log("loading points", rows)
                    rows.forEach((row: string) => {
                        /*
                        if (count++ % interLeave !== 0) {
                            return;
                        }
                        */
                        count++;
                        if (count === 1) {
                            // titles
                            return;
                        }

                        // const cols = row.split(",");
                        const cols = row.split(",").map((values: string) => {
                            return values.trim();
                        })

                        if (colLatIndex == null || colLngIndex == null) {
                            // console.log("errror no lat o long");
                            return;
                        }
                        const lat = parseFloat(cols[colLatIndex]);
                        const lon = parseFloat(cols[colLngIndex]);

                        if (!lat || !lon) {
                            // console.log("errror no lat o long");
                            return;
                        }

                        if (colRadiusIndex != null) {
                            radius = cols[colRadiusIndex];
                        }

                        const radiusValue = parseFloat(radius);
                        // console.log("step2", lat, lon, radiusValue);
                        let labelValue = ("[" + lat + ", " + lon + "]");
                        if (colLabelIndex) {
                            labelValue = cols[colLabelIndex];
                        }
                         
                        if (linePointsFlag) {
                            linepoints.push(new LatLng(lat, lon));
                        }
                        

                        const center = new LatLng(lat, lon);
                        // console.log("center", center);
                        let newLayer;
                        if (pointMode === "rectangle") {
                            const layerRect = rectangle(
                                center.toBounds(scaleXToMeterFunc(radiusValue)), {
                                color: colorValue,
                                fillColor: colorValue,
                                fillOpacity: 0.5,
                                stroke: false,
                            });
                            newLayer = layerRect;
                        } else if (pointMode === "circle") {
                            const layerRect = circle(
                                center, {
                                radius: radiusValue,
                                color: colorValue,
                                fillColor: colorValue,
                                fillOpacity: 0.5,
                                stroke: false,
                            });
                            newLayer = layerRect;
                        } else {
                            const layerMark = marker(center, {icon: new Icon({ iconUrl: markerIconPng })});
                            newLayer = layerMark;
                        }
                        newLayer.bindTooltip(labelValue);
                        
                        // make a properties object from row
                        const mapCols: any = {};
                        let index = 0;
                        for (const hcol of colHeader) {
                            //console.log("test", hcol);
                            mapCols[hcol] = cols[index];
                            index++;
                        }
                        (newLayer as any).csvValues = mapCols;
                        featureGroup?.addLayer(newLayer);
                    });

                    if (linepoints.length > 0) {
                        featureGroup?.addLayer(L.polyline(linepoints));
                    }
                }

            } catch (error) {
                console.log("Error", error);
            }
        } else {
            console.error("Dataset null or type not supported. Must be csv");
        }
    }


    const loadSchema = (featureGroup: L.FeatureGroup<any> | undefined, rows: any) => {
        // search important columns
        schema = props.layer.dataset?.settings?.schema;
        let [latCol, lonCol, tsCol, valueCol, scaleCol] = (() => {
            // TODO implement
            return [
                schema.columns.latCol,
                schema.columns.lonCol,
                schema.columns.tsCol,
                schema.columns.valueCol,
                schema.columns.scaleCol,
            ]
        })();

        console.log("", tsCol != null?tsCol:""); // agregado para que no tire guarning, ver porque sobra

        const scaleXToMeterFunc = (value: number) => {
            return value * schema.scaleX;
        };

        /*
        const scaleYToMeterFunc = (value: number) => {
            return value * schema.scaleY;
        };

        const scaleZToMeterFunc = (value: number) => {
            return value * schema.scaleZ;
        };
        */

        const valueToColorFunc = (value: number): string => {
            const colors = (schema.palletes as any)[schema.pallete].colors;

            // find color for normalized value
            for (let i = 0; i < colors.length - 1; i++) {
                const controlMin = colors[i][0];
                const controlMax = colors[i+1][0];
                
                if (value >= controlMin && value <= controlMax) {
                    let color = colors[i][1];
                    return color as string;
                }
            }

            return colors[colors.length-1][1] as string;
        };

        /*
        const onLayerAdd = (event: LayerEvent) => {
            const layer: any = event.layer; // leaflet layer
            const feature = layer.feature = layer.feature || {};
            feature.type = "Feature";
            feature.properties = feature.properties || {};
        }
        */
        
        const interLeave = schema.interleave == null ? 5 : schema.interleave;

        let count = 0;
        let linepoints : LatLng[] = [];
        rows.forEach((row: string) => {
            if (count++ % interLeave !== 0) {
                return;
            }

            const cols = row.split(",");
            const lat = parseFloat(cols[latCol == null ? 0 : latCol]);
            const lon = parseFloat(cols[lonCol == null ? 1 : lonCol]);
            //const radius = parseFloat(cols[radiusCol])
            const colorValue = parseFloat(valueCol == null ? "1" : cols[valueCol]);
            const radiusValue = parseFloat(scaleCol == null ? "1" : cols[scaleCol]);
            
            if (!lat || !lon) {
                return;
            }

            if (schema.showLines) {
                linepoints.push(new LatLng(lat, lon));
            }

            if (schema.showPoints) {
                const center = new LatLng(lat, lon);
                const layerRect = rectangle(
                    center.toBounds(scaleXToMeterFunc(radiusValue)), {
                    color: valueToColorFunc(colorValue),
                    fillColor: valueToColorFunc(colorValue),
                    fillOpacity: 0.5,
                    stroke: false,
                });

                const newLayer = layerRect;
                newLayer.bindTooltip("" + colorValue);
                
                // make a properties object from row
                const mapCols: any = {};
                for (let col=0; col < cols.length; col++) {
                    const field = schema.fields[col];
                    if (!field)
                    continue;
                    const name: string = field.name;
                    mapCols[name.trim()] = cols[col];
                }
                (newLayer as any).csvValues = mapCols;
                featureGroup?.addLayer(newLayer);
            }
        });

        if (linepoints.length > 0) {
            featureGroup?.addLayer(L.polyline(linepoints));
        }
    };

    const upload = (file: any, url: string) => {
        fetch(url, {
            method: 'PUT',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            body: file
        }).then(() => {
            //console.log("Upload ok");
        }).catch((e) => {
            console.error("Upload Error: ", e);
        });
    }

    function getNewUrl(file: any, cb: Function) {
        getUploadDatasetFileUrl(layer.dataset.id, layer.dataset.path, filename)
        .then((url) => {
            //console.log("url: ", url);
            cb(file, url);
        }).catch((e) => {
            console.error("getNewUrl Error: ", e);
        });
    }

    const onEditPath = (event: ReactEventHandler) => {
        saveShapes();
        //console.log("edit path");
    }

    const onDeletePath = (event: ReactEventHandler) => {
        saveShapes();
        //console.log("delete path");
    }

    const onClickFeature = (event: LeafletMouseEvent) => {
        //console.log("click feature", event);
        setSelectShape({ open: true, layer: event.layer });
    }

    const onCloseShape = (event: MouseEvent) => {
        setSelectShape({ open: false, layer: undefined });
    }

    const onSaveShape = (event: MouseEvent) => {
        saveShapes();
    }

    function onLayerAdd(event: LayerEvent) {
        const layer: any = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
        feature.properties = { point: layer.csvValues };
    }

    useEffect(() => {
        loadPoints();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.layer.settings.point.colLat,
        props.layer.settings.point.colLng,
        props.layer.settings.point.colLabel,
        props.layer.settings.point.linepoints,
        props.layer.settings.point.radiusValue,
        props.layer.settings.point.colRadius,
        props.layer.settings.point.mode,
        props.layer.settings.point.color,
    ]);

    // console.debug("Start AkopicaPointLayer: props:", props);

    return (
        <React.Fragment>
            <FeatureGroup
                ref={featureGroupRef}
                eventHandlers={{
                    layeradd: onLayerAdd,
                    click: onClickFeature,
                }}
            >

                    {settings.point.edit &&
                        <EditControl position="topright"
                            onCreated={onCreatePath}
                            onEdited={onEditPath}
                            onDeleted={onDeletePath}
                            draw={{
                            rectangle: false
                            }}
                        />
                    }
            </FeatureGroup>
            {selectShape.open &&
                <PointDialog open={true} layer={props.layer} featureLayer={selectShape.layer} onClose={onCloseShape} onSave={onSaveShape}></PointDialog>
            }
            {props.allowNote &&
                <NoteLayer {...props}/>
            }
        </React.Fragment>
    );
}

PointLayer.defaultProps = {
    popupContent: '',
}

export default PointLayer;
