import React from 'react';
import clsx from "clsx";
import {
    Theme,
    TextField,
    Typography,
    Button,
    Divider,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useNavigate } from "@reach/router";
import { Trans, useTranslation } from "react-i18next";
import { signupWithEmail } from './services';
import useLoading from '../lib/hooks/useLoading';
import { LoadingRight } from '../lib/components/LoadingProgress/LoadingProgress';
import { Alert } from '@mui/lab';
import { AppError } from '../models/common';
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '100%',
        },
        row: {
            padding: "1ch",
        },
        actions: {
            padding: "1ch",
            textAlign: "right",
        },
        separator: {
            display: "inline-block",
            minWidth: "70px",
        }
    }),
);

interface FormState {
    email: string;
    emailError?: string;
    checkedTerms: boolean;
    showPassword: boolean;
};

function SignupPage(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [error, setError] = React.useState<AppError>();
    const [loading, setLoading] = useLoading(false);
    const [form, setForm] = React.useState<FormState>({
        email: "",
        checkedTerms: false,
        showPassword: false,
    });

    const recaptchaRef = React.useRef<ReCAPTCHA>(null);

    const signup = async () => {
        setLoading(true);
        
        const token = (recaptchaRef.current != null) ? await recaptchaRef.current.executeAsync() : null;

        signupWithEmail({
            email: form.email,
            recaptchaToken: token
        })
        .then(async user => {
            await navigate('/auth/signup/sent', { state: { email: form.email } });
        })
        .catch(error => {
            setError(error as AppError);
            console.error('Error:', error);
        })
        .finally(() => {
            setLoading(false);
        })
    };

    const handleSignup = () => {
        const emailRe = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

        if (!emailRe.test(form.email)) {
            setForm({...form, emailError: t("Please enter a valid email address.")});
            return;
        }

        setForm({...form, emailError: undefined});
        signup();
    }


    const handleChange = (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === "checkedTerms") {
            setForm({ ...form, [prop]: event.target.checked });
        } else {
            setForm({ ...form, [prop]: event.target.value });
        }
    };

    return (
        <div className={classes.container}>
            <div style={{ margin: "auto", maxWidth: 420 }}>
                { error?.code==="500" && <Alert severity="error"><Trans>Sorry, an unexpected error has ocurred</Trans></Alert> }
                <Typography variant="h4"><Trans>Signup with your email</Trans></Typography>
                <Typography variant="body1"><Trans></Trans></Typography>

                <div className={classes.row} />
                <div className={classes.row} />
                <div className={classes.row}>
                    <TextField
                        className={clsx(classes.textField)}
                        required
                        id="supemail"
                        label="Email"
                        value={form.email}
                        onChange={handleChange("email")}
                        variant="outlined"
                        error={form.emailError != null}
                        helperText={form.emailError}
                    />
                </div>

                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.checkedTerms}
                                onChange={handleChange("checkedTerms")}
                                name="supCheckedTerms"
                            />
                        }
                        label={
                            <Typography variant="caption">
                                <Trans>I agree to the Akopica</Trans><Link to="/tos"> <Trans>Terms of Service</Trans></Link> <Trans>and</Trans> <Link to="/privacy"><Trans>Privacy Policy</Trans></Link>
                            </Typography>
                            }
                    />
                </div>

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY || "6LdQjcsbAAAAABVtk3QZB8tkyMfcZ3W4tSnVwLy-"}
                    size="invisible"
                    ref={recaptchaRef}
                />
                <div className={classes.row}>
                    <Button disabled={!form.checkedTerms || loading } color="secondary" variant="contained" onClick={handleSignup}><Trans>Signup with your email</Trans></Button>
                    { loading && <LoadingRight></LoadingRight> }
                </div>

                <div className={classes.row} />
                <Divider></Divider>
                <div className={classes.row} />

                <div className={classes.row}>
                    <Trans>Already have a account?</Trans> <Button component={Link} to="/auth/login"><Trans>Sign in to Akopica</Trans></Button>
                </div>

            </div>
        </div>
    );
}

function SignupEmailSentPage(props: any) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div style={{ margin: "auto", maxWidth: 420 }}>
                <Typography variant="h5"><Trans>Check your inbox</Trans></Typography>
                <div className={classes.separator}></div>
                <Typography variant="body1"><Trans>Click the link we sent to</Trans><b> {props.location?.state.email} </b><Trans>to sign up.</Trans></Typography>
                <div className={classes.separator}></div>
                <div className={classes.row}>
                    <Button color="secondary" variant="contained" component={Link} to="/"><Trans>Ok</Trans></Button>
                </div>
            </div>
        </div>
    );
}

export { SignupPage, SignupEmailSentPage };
