import React from 'react';
import UserListItem from './UserListItem';
import { Theme, List, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { User } from "../models/auth"
import { Page } from '../models/common';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: Page<User>,
    handleSelect: Function,
    userSelected: any,
    next: any,
    prev: any
}

function UserList(props: MyProps) {
    const classes = useStyles();

    return (
        <>
            <List className={classes.root}>
                { 
                    props.data.page.map((user: User) => {
                        return <UserListItem key={user.uid} data={user} handleSelect={props.handleSelect} userSelected={props.userSelected}/>
                    })
                }
            </List>
            <Box>
                <Button onClick={props.prev} startIcon={ <ChevronLeft/> }><Trans>Previous</Trans></Button>
                <Button onClick={props.next} endIcon={ <ChevronRight/> }><Trans>Next</Trans></Button>
            </Box>
          
        </>
    );
  }

export default UserList;
