import React, {useEffect} from 'react';
import { Theme, Typography, Button, Container, Box, Grid, Hidden, Toolbar, TextField, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {Link as RouterLink} from "@reach/router";
import FolderIcon from '@mui/icons-material/Folder';
import {useNavigate} from "@reach/router"
import { useAppContext } from '../AppContext';
import { useTranslation, Trans } from "react-i18next";
import { Group } from '../models/group';
import { createAsset } from './services';


const useStyles = makeStyles((theme: Theme) =>
    ({
        container: {
            padding: 0,
        },
        title: {
            flexGrow: 1,
        },
        textField: {
        },
        row:{
            marginBottom: theme.spacing(2),
        },
        rowActions:{
            marginBottom: theme.spacing(2),
            width: "100%",
            textAlign: "right",
        },
        separator: {
            display: "inline-block",
            minWidth: theme.spacing(1),
        }
    }),
);

interface InputState {
    asttype: string,
    group: Group,
    detail: string,
    name: string,
    title: string,
};

let wbInput: InputState | null = null; // para guardar el historico de datos

function AssetNewPage(props: any) {
    const classes = useStyles();
    const app = useAppContext();
    const { t } = useTranslation();

    // state: input values
    const [input, setInput] = React.useState<InputState>({
        asttype: "",
        group: {id:""},
        detail: "",
        name: "",
        title: "",
    });
  
    const navigate = useNavigate();


    useEffect(() => {
        // recover from wayback
        if (wbInput) {
            setInput(wbInput);
        }   else {
            setInput({...input, group: {id:app.layout.currentGroup.id}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const defaultNameValue = value.toLocaleLowerCase().trim().replace(/\s+/g, "-");
        const defaultNameTitle = input.title.toLocaleLowerCase().trim().replace(/\s+/g, "-");
        let name = input.name;

        if (prop === "title" && name === defaultNameTitle) {
            name = defaultNameValue;
        }

        if (prop === "name") {
            name = value;
        }

        wbInput = { ...input, [prop]: value, name: name };
        setInput({...input, [prop]: value, name: name});
    };


    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
        const prop = event.target.name;
        wbInput = { ...input, [prop]: event.target.value };
        setInput({...input, [prop]: event.target.value});         
    };


    function handleCreate(event: any) {

        input.group.id = app.layout.currentGroup.id;

        if (input.group.id === "") {
            console.log("Group ID is missing")
            return;
        }
        if (input.title === ""){
            alert(t("Title is required."))
            return;
        }
        if (input.asttype === ""){
            alert(t("Asset Type is required."))
            return;
        }
        
        console.log("CREATE with app:", app);

        const data = {
            asttype: input.asttype,
            detail: input.detail,
            group: {id: input.group.id},
            location: {},
            name: input.name,
            settings: {},
            shape: {},
            title: input.title,
            ts: "2020-01-01T00:00:00.000Z",
        }
    
        createAsset(data)
        .then(response => navigate('/assets/'));

    }

    
    return (
        <Container className={classes.container} maxWidth={false}>
            <Toolbar disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>New Asset</Trans>
                </Typography>
            </Toolbar>
            <Grid container spacing={0} direction="row-reverse">
                <Hidden smDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/assets" color="secondary" startIcon={<FolderIcon />}><Trans>Search Asset</Trans></Button>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Container>
                        <div className={classes.row}>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="title"
                                label={t("Title")}
                                value={input.title}
                                onChange={handleChange("title")}
                                variant="filled"
                                required
                            />
                        </div>

                        <div className={classes.row}>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel htmlFor="asset-label"><Trans>Asset type</Trans></InputLabel>
                                <Select
                                    labelId="asset-label"
                                    id="asttype"
                                    name="asttype"
                                    onChange={handleChangeSelect}
                                    value={input.asttype}
                                    label={t("Asset type")}
                                    required
                                >

                                    <MenuItem aria-label={t("None")} value=""><em><Trans>None</Trans></em></MenuItem>
                                    <MenuItem value={"Building"}><Trans>Building</Trans></MenuItem>
                                    <MenuItem value={"Farm"}><Trans>Farm</Trans></MenuItem>
                                    <MenuItem value={"Lot"}><Trans>Lot</Trans></MenuItem>
                                    <MenuItem value={"Site"}><Trans>Site</Trans></MenuItem>
                                    <MenuItem value={"Vehicle"}><Trans>Vehicle</Trans></MenuItem>
                                    <MenuItem value={"Device"}><Trans>Device</Trans></MenuItem>
                                    
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.row}>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="name"
                                label={t("Name")}
                                value={input.name}
                                onChange={handleChange("name")}
                                variant="filled"
                                // required
                            />
                        </div>
                   
                        <div className={classes.row}>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="detail"
                                multiline
                                rows={4}
                                label={t("Detail")}
                                value={input.detail}
                                onChange={handleChange("detail")}
                                variant="outlined"
                            />
                        </div>


                        <div className={classes.rowActions}>
                            <Button onClick={handleCreate} variant="contained" color="secondary"><Trans>Create</Trans></Button>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AssetNewPage;
