import React, { useRef, useEffect, useState } from 'react';
import maplibregl, { StyleSpecification } from 'maplibre-gl';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import mapTilerJson from './MapTiler.json';
import { getFeaturesGeoJson } from '../../features/services';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'


const useStyles = makeStyles((theme: Theme) =>
    ({
        mapWrap: {
            position: 'relative',
            width: '100%',
            height: 'calc(100vh - 77px)'
        },
        map: {
            position: 'absolute',
            width: '100%',
            height: '100%'
        }
    }),
);


export default function MapLibre(){
    const mapContainer = useRef<any>(null);
    const map = useRef<any>(null);
    const [lng] = useState(-60.63932);
    const [lat] = useState(-32.94682);
    const [zoom] = useState(12);
    //const [API_KEY] = useState('DNaVOZ5spQL4YwWphnsL');
    const classes = useStyles();
    
    useEffect(() => {
        if (map.current) return;
        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: mapTilerJson as StyleSpecification,/*`https://api.maptiler.com/maps/streets/style.json?key=${API_KEY}`,*/
            center: [lng, lat],
            interactive: true,
            zoom: zoom
        });

        //const data = await getFeaturesGeoJson(layer.id, props.public);
        /*
        map.current.addControl(new maplibregl.NavigationControl({}), 'top-right');
        new maplibregl.Marker({color: "#FF0000"})
        .setLngLat([139.7525,35.6846])
        .addTo(map.current);
        */
        //const Draw = new MapboxDraw();
        

        //map.current.addControl(draw, 'top-right');

        map.current.on('load', function () {
            
            const draw = new MapboxDraw({
                //styles: drawStyles,
                controls: {
                    polygon: true,
                    trash: true
                },
                userProperties: true
            });
    
            map.current.addControl(draw);
            
            
            
            map.current.addSource('dataIntaOliveros', {
                type: 'vector',
                url:
                    'http://localhost:8000/v1/feature/tiles/tiles.json',
                interactive: true,
                
            });
            
            /*
            map.current.addSource('dataIntaOliveros', {
                type: 'vector',
                tiles:
                    'http://localhost:8000/v1/feature/{z}/{x}/{y}.pbf'
            });
            */
            
            /*
            map.current.addSource('dataIntaOliveros', {
                type: 'geojson',
                data:
                    'http://localhost:8000/v1/layers/94eff26e-1cde-4d25-a4c3-1842c3264b97/features/geoJson'
            });
            */
            /*
            map.current.addLayer({
                'id': 'test1',
                'type': 'line',
                'source': 'dataIntaOliveros',
                'source-layer': 'intaOliveros',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#ff69b4',
                    'line-width': 1
                }
            });
            */
            
            map.current.addLayer({
                'id': 'test3',
                'type': 'circle',
                'source': 'dataIntaOliveros',
                'source-layer': 'intaOliveros',
                "paint": {
                    "circle-radius": 5,
                    "circle-color": "#ff0000"
                }
            });

            map.current.on("mouseenter", 'test3', () => {
                map.current.getCanvas().style.cursor = "pointer";
              });
      
            map.current.on("mouseleave", 'test3', () => {
                map.current.getCanvas().style.cursor = "default";
              });

            map.current.on('mouseup', 'test3', function (e:any) {
                console.log('clicked on layer', e.features);
                //e.clickOnLayer = true;
            })

            map.current.on('draw.create', function (e:any) {
                console.log('create draw', e);
                //e.clickOnLayer = true;
            })

            /*
            map.current.addLayer({
                'id': 'postgis-tiles-layer',
                'type': 'line',
                'source': 'dataIntaOliveros',
                // ST_AsMVT() uses 'default' as layer name
                'source-layer': 'default', 
                'minzoom': 0,
                'maxzoom': 22,
                'paint': {
                    'line-opacity': 0.7,
                    'line-color': 'red',
                    'line-width': 1
                }
            });
            
           /*
            map.current.addLayer(
                {
                    "id": "test2",
                    "type": "symbol",
                    "source": "dataIntaOliveros",
                    "layout": {
                        "text-font": [
                            "Roboto Regular",
                            "Noto Sans Regular"
                        ],
                        "text-size": 12,
                        "text-field": "{localidad:localidad}",
                        "visibility": "visible",
                        "text-max-width": 80
                    },
                    "paint": {
                        "text-color": "rgba(76, 125, 173, 1)",
                        "text-halo-color": "rgba(255,255,255,0)",
                        "text-halo-width": 1
                    },
                },
            );
            */
            
            /*
            map.current.addSource('wms-test-source', {
                'type': 'raster',
                // use the tiles option to specify a WMS tile source URL
                // https://maplibre.org/maplibre-gl-js-docs/style-spec/sources/
                'tiles': [
                    'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015'
                ],
                'tileSize': 256
            });
            map.current.addLayer(
                {
                'id': 'wms-test-layer',
                'type': 'raster',
                'source': 'wms-test-source',
                'paint': {}
                },
                'aeroway_fill'
            );
            */
        });

        
    });
  
    return (
        <div className={classes.mapWrap}>
            <div ref={mapContainer} className={classes.map}/>
        </div>
    );
}