import React, { ReactEventHandler, createRef, useState, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { TooltipOptions, geoJSON, LeafletMouseEvent, LayerEvent, PathOptions } from "leaflet";
import AssetDialog from "./AssetDialog";
import NoteLayer from "../note/NoteLayer";
import { getAssetById } from "../../../services";

export const tooltipOptions: TooltipOptions = {
	permanent: false,
	direction: "center",
	opacity: 0.7,
};

const shapeStyle: PathOptions = {
	color: "#a0a",
	fillColor: "none",
	fillOpacity: 0,
	dashArray: [6],
	attribution: "Hola",
}

interface MyProps {
	layer: any,
	allowNote: boolean
}

function AssetLayer(props: MyProps) {
	const layer = props.layer;
	const settings = props.layer.settings || {};
	const featureGroupRef = createRef<L.FeatureGroup>();
	const [selectShape, setSelectShape] = useState({ open: false, layer: undefined });
	// const [devices, setDevices] = useState([]);

	const onCreatePath = (event: ReactEventHandler) => {
		//saveShapes();
		//console.log("create path");
	}

	async function loadAsset() {
		const dataset = layer.dataset;
		if (dataset?.id && dataset.dstype === "asset") {
		let assetId = dataset.settings["assetId"];
		try {
			const data = await getAssetById(assetId);

			const featureGroup = featureGroupRef.current;
			featureGroup?.clearLayers();
			geoJSON(data.shape, {style: shapeStyle}).eachLayer(layer => {
				// bind tooltop with note title if exists
				// const l: any = layer;
				if (data.title) {
					layer.bindTooltip(data.title, tooltipOptions);
				}

				// add note to group
				featureGroup?.addLayer(layer)
				//console.log("adding layer!!!!");
			});
		} catch (error) {
			console.log("Error", error);
		}
		} else {
			console.error("Dataset null or type not supported. Must be csv");
		}
	}
	/*
	async function loadPoints() {
		const dataset = layer.dataset;
		if (dataset?.id && dataset.dstype === "asset") {
		let assetId = dataset.settings["assetId"];
		let url = `${BACKEND_URL}/v1/assets/${assetId}/layer`;
		try {
			const response = await fetch(url);
			const data = await response.json();

			const featureGroup = featureGroupRef.current?.leafletElement;
			featureGroup?.clearLayers();

			//console.log("data",data);
			
			// setDevices(data.members);
		} catch (error) {
			console.log("Error", error);
		}
		} else {
		console.error("Dataset null or type not supported. Must be csv");
		}
	}
	*/

	const onEditPath = (event: ReactEventHandler) => {
		//saveShapes();
		//console.log("edit path");
	}

	const onDeletePath = (event: ReactEventHandler) => {
		//saveShapes();
		//console.log("delete path");
	}

	const onClickFeature = (event: LeafletMouseEvent) => {
		//console.log("click feature", event);
		setSelectShape({ open: true, layer: event.layer });
	}

	/*
	const onCloseShape = (event: MouseEvent) => {
		setSelectShape({ open: false, layer: undefined });
	}

	const onSaveShape = (event: MouseEvent) => {
		//saveShapes();
	}
	*/

	const onCloseNote = (event: MouseEvent) => {
		setSelectShape({ open: false, layer: undefined });
	}

	const onSaveNote = (event: MouseEvent) => {
		//saveNotes();
	}

	const onLayerAdd = (event: LayerEvent) => {
		const layer: any = event.layer; // leaflet layer
		const feature = layer.feature = layer.feature || {};
		feature.type = "Feature";
		feature.properties = feature.properties || {};
		feature.properties = { point: layer.csvValues };
	}

	useEffect(() => {
		// loadPoints();
		loadAsset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.debug("Start AkopicaPointLayer: props:", props);

	/*
	const myIcon = L.icon({
		iconUrl: truckIcon, //drone.png
		iconSize: [80, 80], //[70, 60],
		iconAnchor: [40, 40], //[35, 30]
	});
	*/

	return (
		<React.Fragment>
			<FeatureGroup
				ref={featureGroupRef}
				eventHandlers={{
					click: onClickFeature,
					layeradd: onLayerAdd
				}}
			>

				{settings.asset.edit &&
				<EditControl position="topright"
					onCreated={onCreatePath}
					onEdited={onEditPath}
					onDeleted={onDeletePath}
					draw={{
					rectangle: false
					}}
				/>
				}
			</FeatureGroup>)
		
			{selectShape.open &&
				<AssetDialog open={true} layer={props.layer} featureLayer={selectShape.layer} onClose={onCloseNote} onSave={onSaveNote}></AssetDialog>
			}
			{props.allowNote &&
				<NoteLayer {...props}/>
			} 
		</React.Fragment>
	);
}

AssetLayer.defaultProps = {
  	popupContent: '',
}

export default AssetLayer;
