import React, {useEffect, KeyboardEvent} from 'react';
import {
    Theme,
    Toolbar,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    TextField,
    IconButton,
    InputAdornment,
    Chip,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddCircle";
import TuneIcon from "@mui/icons-material/Tune";
import MapsList from './MapsList';
import LoadingMsg from "../layout/LoadingMsg";
import { Link as RouterLink, useNavigate, RouteComponentProps } from "@reach/router";
import { useTranslation, Trans } from "react-i18next";
import { MapAkopica } from "../models/map"
import useLoading from '../lib/hooks/useLoading';
import { getMapsPage, getGroupsPage } from './services';
import { AppError, Page } from '../models/common';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';
import { Alert } from '@mui/material';
import { Group } from '../models/group';
import { DialogFilter } from '../orgs/dialog/DialogFilter';
import { getGroupById } from '../groups/services';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';

const useStyles = makeStyles((theme: Theme) =>
    ({
        title: {
            flexGrow: 0,
            paddingRight: theme.spacing(2),
        },
        textSearch: {
            width: "85%",
        },
        toolbar: {
            paddingRight: 0,
            paddingLeft: 0
        },
        filterBox: {
            marginTop: theme.spacing(1),
        }
    }),
);

interface InputState {
    page: number,
    pageSize: number,
    order: string,
    q: string,
    groupId: string,
    group?: Group,
}

let wbInput: InputState | null = null; // para guardar el historico de datos

interface MapsPageProps extends RouteComponentProps {
    location?: any;
}

function MapsPage(props: MapsPageProps) {
    const [open, setOpen] = React.useState<boolean>(false);
    //const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useLoading(false);
    //const params = new URLSearchParams(location.search);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});

    // state: input values
    const [q, setQ] = React.useState<string>("");
    const [input, setInput] = React.useState<InputState>({
        page: 1,
        pageSize: 10,
        order: "recent",
        q: "",
        groupId: props.location?.state?.group?.id ?? "",
        group: props.location?.state?.group,
    });

    const [mapsPage, setMapsPage] = React.useState<Page<MapAkopica> | null>(null);
    const [error, setError] = React.useState<AppError>();

    const [disabledFilter, setDisabledFilter] = React.useState<boolean>(true);
    const [filterGroup, setFilterGroup] = React.useState<Group>();
    const [filterGroupId, setFilterGroupId] = React.useState<string>("");
    const [groups, setGroups] = React.useState<Page<Group>>();

    const getGroup = (id: string) => {
        setLoading(true);
        getGroupById(id)
            .then(async gr => {
                setInput({...input, group: gr });
            })
            .catch(error => {
                console.error('Error:', error);
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                setOpenSnackbar(true);    
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    const searchMapsPage = (params: any) => {
        setLoading(true);
        getMapsPage(params)
            .then(async page => {
                // console.log("response", page);
                setMapsPage(page);
            })
            .catch(error => {
                setError(error.error)
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        // load group if necesary
        if (input.groupId !== "" && !input.group) {
            getGroup(input.groupId);
        }

        // recover from wayback
        if (wbInput) {
            setInput(wbInput);
        }

        getGroupsPage().then((group) => {
            setGroups(group);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        searchMapsPage(input);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    useEffect(() => {
        if (filterGroup) {
            setDisabledFilter(false);
        } else {
            setDisabledFilter(true)
        }
    }, [filterGroup]);

    // pagination
    const handleNext = () => {
        if (mapsPage != null) {
            const nPages = Math.ceil(mapsPage.count/input.pageSize);
            if (input.page < nPages) {
                setInput({ ...input, page: input.page + 1 });
            }
        }
    };

    const handlePrev = () => {
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
        }
    };

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        wbInput = {...input, [prop]: event.target.value};
        setQ(event.target.value);
    };

    const handleSearch = (event: any) => {
        setInput({...input, q: q});
    };
    
    const handleEnter = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            setInput({...input, q: q});
        }
    };

    const handleDeleteGroup = () => {
        setInput({ ...input, groupId: "", group: undefined});
    };

    const handleClickGroup = () => {
        navigate("/groups/" + input.group?.id);
    };

    const handleOpenFilters = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeSelectFilters = (event: SelectChangeEvent<string>) => {
        const prop = event.target.name;

        if (prop === "group") {
            const selected = groups?.page.filter((g:Group) => (g.id===event.target.value))[0];
            setFilterGroupId(event.target.value);
            setFilterGroup(selected);
        }

    }

    const handleSubmitFilters = () => {
        if (filterGroup) {
            input.group =  {'id': filterGroup.id, 'title': filterGroup.title};
            input.groupId = filterGroupId;
            setInput({...input});
        }
        handleClose();
    };

    return (
        <Container maxWidth={false}>
            {loading && (<LoadingMsg/>)}
            { error?.code === "500" && <Alert severity="error"><Trans>Sorry, an unexpected error has ocurred</Trans></Alert> }
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>


            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>Maps</Trans>
                </Typography>
                <Box>
                    <Button component={RouterLink} to="/maps/new" variant="outlined" color="secondary" startIcon={<AddIcon/>}><Trans>New Map</Trans></Button>
                </Box>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button color="secondary" component={RouterLink} to="/maps/new" startIcon={<AddIcon/>}><Trans>New Map</Trans></Button>
                        </Box>

                        {/* <Box>
                            <Button color="secondary" startIcon={<Help/>}><Trans>Help</Trans></Button>
                        </Box> */}
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <TextField
                        className={classes.textSearch}
                        id="q"
                        type="search"
                        label={t("Search")}
                        value={q}
                        onChange={handleChange("q")}
                        onKeyPress={handleEnter}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleSearch}
                                        color="secondary"
                                        aria-label={t("Search")}
                                        size="large">
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                          }}
                    />
                    <IconButton
                        onClick={handleOpenFilters}
                        color="secondary"
                        aria-label={t("Filter")}
                        size="large">
                        <TuneIcon />
                    </IconButton>

                    <Box className={classes.filterBox}>
                        {input.group &&
                            <Chip label={t("group_filter") + input.group.title} onClick={handleClickGroup} onDelete={handleDeleteGroup} color="secondary" />
                        }
                    </Box>

                    {mapsPage?.page && <MapsList data={mapsPage} next={handleNext} prev={handlePrev}/> }
                </Grid>
                <Grid item xs={12} md={10}>
                    <DialogFilter open={open} onClose={handleClose} disabled={disabledFilter} onSubmit={handleSubmitFilters}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <FormControl variant="outlined" required fullWidth>
                                    <InputLabel htmlFor="group-label">
                                        <Trans>Group</Trans>
                                    </InputLabel>
                                    <Select
                                        labelId="groupId-label"
                                        id="groupId"
                                        name="group"
                                        onChange={handleChangeSelectFilters}
                                        value={filterGroup?.id ?? ""}
                                       // error={inputError.groupId !== undefined}
                                        label={("Group")}>

                                        {
                                            groups?.page.map((group: Group) => {
                                                return <MenuItem value={group.id} key={group.id}>{group.title}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogFilter>
                </Grid>
            </Grid>
            {loading && <LoadingProgress/>}
        </Container>
    );
  }

export default MapsPage;
