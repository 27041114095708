


export interface HttpConfiguration {
    getAuthorizationToken?: () => string,
    authorizationPrefix?: string,
    onNotAuthorized?: () => any,
    header?: any
}

/**
 * Http client
 *
 * @param getAuthorizationToken optional - function () => token ... used in header['Authorization']
 * @param authorizationPrefix optional - string... used in header['Authorization'] = prefix + token
 * @param onNotAuthorized - optional - function () => any ... use in status 401, 402, default:: return error
 * @param header - optional - http Header ... default:: {'Content-Type': 'application/json;charset=utf-8'}
 * 
 * @return [httpGet, httpPost, httpPut, httpDelete]
 */
const createHttpClient = (data: HttpConfiguration): [<T>(url:string, params?:{}, options?:any) =>  Promise<T>, <T>(url:string, body:any, options?:any) => Promise<T>, <T>(url:string, body:any, options?:any) => Promise<T>, <T>(url:string, params?:{}) => Promise<T>] => {
    const getToken = data.getAuthorizationToken;
    const prefix = data.authorizationPrefix || "";
    const onNotAuthorized = data.onNotAuthorized;

    let headerCfg:any = data.header ? data.header : {'Content-Type': 'application/json', 'Accept': 'application/json'};

    async function handleReturn(response: Response, contentTypeAccepted?:string): Promise<any> {
        if (contentTypeAccepted != null) {
            if (contentTypeAccepted === "application/json") {
                return await response.json();
            }
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return await response.json();
        }
        if (contentType && contentType.indexOf("application/geo+json") !== -1) {
            return await response.json();
        }
        if (contentType && contentType.indexOf("binary/octet-stream") !== -1) {
            return await response.text();
        }
        return await response.text();
    }

    async function httpFetch(method:string, url:string, params?:any, body?:any, options?:any): Promise<any> {
        let header:any = {};
        Object.assign(header, headerCfg);
        header = (body instanceof FormData) ? {} : header;

        const sendToken = options?.sendAuthToken != null ? options?.sendAuthToken : true;
        const paramToken = options?.useAuthTokenQuery != null ? options?.useAuthTokenQuery : false;
        const jsonStringfyOnBody = options?.useJsonStringfyOnBody != null ? options?.useJsonStringfyOnBody : true;
        const contentTypeAccepted = options?.contentTypeAccepted != null ? options?.contentTypeAccepted : null;

        if (getToken != null && getToken() != null && getToken() !== "null" && sendToken) {
            if (paramToken) {
                if (params != null) {
                    params.jwt = getToken();
                } else {
                    params = {"jwt":getToken()};
                }
            } else {
                header['Authorization'] = prefix + getToken();
            }
        }

        const urlX = new URL(url);
        
        if (params) {
            urlX.search = new URLSearchParams(params).toString();
        }

        const response = await fetch(urlX.toString(), {
            method: method,
            headers: header,
            mode: 'cors',
            body: body ? (jsonStringfyOnBody ? JSON.stringify(body) : body) : null
        });

        if (!response.ok) {
            if (response.status === 401 || response.status === 422) {
                if (onNotAuthorized != null) {
                    onNotAuthorized()
                } else {
                    return Promise.reject(await handleReturn(response) || 'HTTP error');
                }
            }
            return Promise.reject(await handleReturn(response) || 'HTTP error');
        }
        
        return await handleReturn(response, contentTypeAccepted);
    }

    async function httpGet<T>(url:string, params?:{}, options?:any): Promise<T> {
        return httpFetch('GET', url, params, undefined, options);
    }

    async function httpPost<T>(url:string, body:any, options?:any): Promise<T> {
        return httpFetch('POST', url, undefined, body, options);
    }

    async function httpPut<T>(url:string, body:any, options?:any): Promise<T> {
        return httpFetch('PUT', url, undefined, body, options);
    }

    async function httpDelete<T>(url:string, params?:{}, options?:any): Promise<T> {
        return httpFetch('DELETE', url, params, undefined, options);
    }

        return [httpGet, httpPost, httpPut, httpDelete];
    }


export default createHttpClient;
