import { NoteSchema } from "./NoteSchema";

export const genericWithAttach: NoteSchema = {
    colorField: "alert",
    fields:[
        {
            name: "title",
            label: "Título",
            type: "string",
        },
        {
            name: "attach",
            label: "Documento Adjunto",
            type: "link",
            widget: "link",
        },
        {
            name: "comments",
            label: "Comentarios",
            type: "string",
            widget: "multiline",
            rows: 6,
        },
    ]
}