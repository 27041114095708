import React from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { Trans } from "react-i18next";

interface DialogFilterProps {
    open: boolean;
    onClose: any;
    children?: React.ReactNode;
    disabled?: boolean;
    onSubmit: any;
}


export const DialogFilter = (props: DialogFilterProps) => {
    return (
        <>
            <Dialog
            disableEnforceFocus
            open={props.open} 
            onClose={props.onClose}
            fullWidth
            maxWidth={'sm'}
            aria-describedby="alert-dialog-description"
            aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Trans>Filters</Trans>
                </DialogTitle>
                <DialogContent>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} variant="contained" color="primary">
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button disabled={props.disabled} variant="contained" color="secondary" onClick={props.onSubmit}><Trans>filter</Trans></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
