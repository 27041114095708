import React, {useEffect} from 'react';
import {
    Theme,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    Toolbar,
    TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import GroupIcon from '@mui/icons-material/Group';
import {Link as RouterLink} from "@reach/router";
import {useNavigate} from "@reach/router"
import { useTranslation, Trans } from "react-i18next";
import { createGroup } from './services';
import useLoading from '../lib/hooks/useLoading';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';
import { slugfy } from '../lib/utils';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';


const useStyles = makeStyles((theme: Theme) =>
  ({
    toolbar: {
        paddingRight: 0,
        paddingLeft: 0
    },
    title: {
        flexGrow: 1,
    },
    rowActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
  }),
);

interface InputState {
    name: string,
    title: string,
    detail: string,    
}

interface InputError {
    title?: string,
}

let wbInput: InputState | null = null; // para guardar el historico de datos

function GroupsNewPage(props: any) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useLoading(false);
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});
    const [inputError, setInputError] = React.useState<InputError>({})

    // state: input values
    const [input, setInput] = React.useState<InputState>({
        name: "",
        title: "",
        detail: "",
    });
    const navigate = useNavigate();

    useEffect(() => {
        // recover from wayback
        if (wbInput) {
            setInput(wbInput);            
        } 
    }, []);

    useEffect(() => {
        if (input.title.trim() !== "") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [input]);

    // handle input changes
    const handleChange = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {        
        const value = event.target.value;
        let name = input.name;
        if (prop === 'title') {
            name = slugfy(value);
            if (value.trim() === "") {
                setInputError({...inputError, title: t("Please provide a Title")});
            } else {
                setInputError({...inputError, title:undefined});
            }
        }

        wbInput = { ...input, [prop]: value, name: name};
        setInput({ ...input, [prop]: value, name: name});
    };

    const handleCreate = async(event: any) => {
        try {
            if (inputError.title) {
                return
            }
            
            setLoading(true);
            const newGroup = await createGroup(input);
            if (newGroup) {
                setInputError({});
                clearForms();
                navigate("/groups/" + newGroup.id);
            }
        } catch(error:any) {
            if (error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    }

    const clearForms = () => {
        //setInput({...input, title: "", detail: ""});
    }

    return (
        <Container maxWidth={false}>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>New Group</Trans>
                </Typography>
                <Hidden mdUp>
                </Hidden>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/groups" color="secondary" startIcon={<GroupIcon />}><Trans>Groups</Trans></Button>
                        </Box>
                        {/* <Box>
                            <Button color="secondary" startIcon={<Help />}><Trans>Help</Trans></Button>
                        </Box> */}
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                label={t("Title")}
                                id="title"
                                value={input.title}
                                onChange={handleChange("title")}
                                variant="outlined"
                                helperText={inputError.title}
                                error={inputError.title !== undefined}
                                required
                            />
                        </Grid>

                        {/*
                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                label={t("Group name")}
                                id="name"
                                value={input.name}
                                onChange={handleChange("name")}
                                variant="outlined"
                                required
                            />
                        </Grid>
                        */}

                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                label={t("Detail")}
                                id="detail"
                                value={input.detail}
                                onChange={handleChange("detail")}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={10} className={classes.rowActions}>
                            <Button disabled={loading || disabled} onClick={handleCreate} variant="contained" color="primary" disableElevation><Trans>Create</Trans></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingProgress/>}
        </Container>
    );
}
export default GroupsNewPage