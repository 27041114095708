import React, { MouseEvent, useState, useEffect, MouseEventHandler } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Theme, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Layer } from "leaflet";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        dialog: {
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        inline: {
            margin: theme.spacing(1),
            display: "inline-block",
        },
        fieldBox: {
            height: '100%',
            width: 245,
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up(400)]: {
                width: "100%",
            },
        },
        fieldSize: {
            width: "100%",
        }
    }),
);

interface MyProps {
    layer: any,
    open: boolean,
    featureLayer?: Layer,
    isNew: boolean;
    isPublic: boolean;
    onClose: any, // TODO what type???
    onSave: Function,
    onDelete: MouseEventHandler
}

function AnnotationDialog(props: MyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const layer: any = props.featureLayer;
    const [open] = useState(props.open);
    const [ label, setLabel ] = useState<string>(() => layer?.featureProperties?.title || "");
    const [ notes, setNotes ] = useState<string>(() => layer?.featureProperties?.subtitle || "");
    const [ disabled, setDisabled ] = useState<boolean>(true);

    const handleSave = async (event: MouseEvent) => {
        
        const layer: any = props.featureLayer;
        layer.feature.properties.label = label;
        layer.feature.properties.note = notes || '';
        
        await props.onSave(event);
    }

    const validateLabel = () => {
        if(label.trim() !== "")  {
            setDisabled(false); 
        } else {
            setDisabled(true);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => validateLabel(), [label]);

    const handleChange = ( event: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.isPublic) {
            const id = event.target.id;
    
            if (id === 'label') setLabel(event.target.value);
    
            if (id === 'notes') setNotes(event.target.value);
        }
    };


    return (
        <Dialog onClose={props.onClose} className={classes.dialog} open={open} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
            <DialogTitle>{props.layer.title}</DialogTitle>
            <DialogContent>
                <Box className={classes.fieldBox}>
                    <TextField id="label" variant="outlined" className={classes.fieldSize} label={t("Label")} value={label} onChange={handleChange}/>
                    <TextField
                        id="notes"
                        className={classes.fieldSize}
                        sx={{marginTop: '1em'}}
                        label={t("Notes")}
                        placeholder={t("Add notes to your Annotation")}
                        multiline
                        rows={4}
                        value={notes} 
                        onChange={handleChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                {!props.isPublic && !props.isNew && 
                    <Button onClick={props.onDelete} color="error">
                        <Trans>Delete</Trans>
                    </Button>
                }
                <Button onClick={props.onClose} color="primary">
                    <Trans>Close</Trans>
                </Button>
                { !props.isPublic && 
                    <Button onClick={handleSave} disabled={disabled} color="primary">
                        <Trans>Save</Trans>
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default AnnotationDialog;
