import React, { useEffect } from "react";
import { Theme, Divider, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import Slider from '@mui/material/Slider';
import { Trans } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            margin: theme.spacing(1),
        },
        marginTop: {
            marginTop: theme.spacing(1),
        },
        settingsTitle: {
            marginLeft: theme.spacing(1),
            fontSize: 13,
        },
        slider: {
            width: '60%',
            marginLeft: theme.spacing(2),
            marginTop: "-10px",
        },
    }),
);

interface MyProps {
    map: any;
    layer: any;
    onClose: Function;
    onChangeLayer: Function;
    changeSubtitle: Function;
}

function TileSettings(props: MyProps) {
    console.debug("TileSettings: start...");
    const classes = useStyles();

    const [values, setValues] = React.useState({
        opacity: 100,
        brightness: 100,
        contrast: 100,
        hue: 0,
        saturate: 100,
    });

    const handleChangeOpacity = (event: any, newValue: number | number[]) => {
        setValues({ ...values, opacity: newValue as number});
        props.layer.settings.tile.opacity=newValue;
        const cloned = Object.assign({}, props.layer.settings.tile);
        props.layer.settings.tile=cloned
        props.onChangeLayer(props.layer, "tile::opacity", event.target.checked);
    };

    const opacityText = (value: number) => {
        return `${value} %`;
    }

    const handleChangeBrightness = (event: any, newValue: number | number[]) => {
        setValues({ ...values, brightness: newValue as number});
        props.layer.settings.tile.brightness=newValue;
        const cloned = Object.assign({}, props.layer.settings.tile);
        props.layer.settings.tile=cloned
        props.onChangeLayer(props.layer, "tile::brightness", event.target.checked);
    };

    const brightnessText = (value: number) => {
        return `${value} %`;
    }

    const handleChangeContrast = (event: any, newValue: number | number[]) => {
        setValues({ ...values, contrast: newValue as number});
        props.layer.settings.tile.contrast=newValue;
        const cloned = Object.assign({}, props.layer.settings.tile);
        props.layer.settings.tile=cloned
        props.onChangeLayer(props.layer, "tile::contrast", event.target.checked);
    };

    const contrastText = (value: number) => {
        return `${value} %`;
    }

    const handleChangeHue = (event: any, newValue: number | number[]) => {
        setValues({ ...values, hue: newValue as number});
        props.layer.settings.tile.hue=newValue;
        const cloned = Object.assign({}, props.layer.settings.tile);
        props.layer.settings.tile=cloned
        props.onChangeLayer(props.layer, "tile::hue", event.target.checked);
    };

    const hueText = (value: number) => {
        return `${value} %`;
    }

    const handleChangeSaturate = (event: any, newValue: number | number[]) => {
        setValues({ ...values, saturate: newValue as number});
        props.layer.settings.tile.saturate=newValue;
        const cloned = Object.assign({}, props.layer.settings.tile);
        props.layer.settings.tile=cloned
        props.onChangeLayer(props.layer, "tile::saturate", event.target.checked);
    };

    const saturateText = (value: number) => {
        return `${value} %`;
    }

    // inicializacion
    useEffect(() => {
        setValues(props.layer.settings.tile);
        return () => {
            // close edit
            //props.layer.settings.shape.edit = false;
            //setValues({...values, edit: false});
            //props.onChangeLayer(props.layer, "shape::edit", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    const marks2 = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 100,
            label: '100',
        },
        {
            value: 200,
            label: '200',
        },
        {
            value: 300,
            label: '300',
        },
    ];

    return (
        <React.Fragment>
            <SettingsDetail {...props} allowNote={props.layer.allowNote}></SettingsDetail>
            <Divider></Divider>
            <Grid container spacing={1}>
                <Grid item lg={12} xs={12} md={12} sx={{marginTop: '8px',  marginLeft: '8px',}}>
                    <Typography id="range-slider" className={clsx(classes.settingsTitle, classes.marginTop)}>
                        <Trans>Opacity</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={11} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.opacity}
                        onChange={handleChangeOpacity}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={opacityText}
                        name="thresholdArray"
                        min={0}
                        max={100}
                        marks={marks}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '8px',}}>
                    <Typography id="range-slider" className={classes.settingsTitle}>
                        <Trans>Brightness</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={11} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.brightness}
                        onChange={handleChangeBrightness}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={brightnessText}
                        name="brightness"
                        min={0}
                        max={100}
                        marks={marks}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '8px',}}>
                    <Typography id="range-slider" className={classes.settingsTitle}>
                        <Trans>Contrast</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={11} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.contrast}
                        onChange={handleChangeContrast}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={contrastText}
                        name="contrast"
                        min={0}
                        max={100}
                        marks={marks}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '8px',}}>
                    <Typography id="range-slider" className={classes.settingsTitle}>
                        <Trans>Hue</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={11} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.hue}
                        onChange={handleChangeHue}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={hueText}
                        name="hue"
                        min={0}
                        max={300}
                        marks={marks2}
                    />
                </Grid>
                <Grid item lg={12} xs={12} md={12} sx={{marginLeft: '8px',}}>
                    <Typography id="range-slider" className={classes.settingsTitle}>
                        <Trans>Saturate</Trans>
                    </Typography>
                </Grid>
                <Grid item lg={11} xs={12} md={12}>
                    <Slider
                        sx={{marginLeft: '16px', width: '80%', marginTop: '-10px'}}
                        value={values.saturate}
                        onChange={handleChangeSaturate}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={saturateText}
                        name="saturate"
                        min={0}
                        max={300}
                        marks={marks2}
                    />
                </Grid>
            </Grid>
            
        </React.Fragment>
    )
}

export default TileSettings;
