import React from 'react';
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { RouteComponentProps, useNavigate } from '@reach/router';
import AkopicaMap from './akopicamap/AkopicaMap';
import { getAppUser } from '../AppContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
        },
    }),
);

interface MapPageProps extends RouteComponentProps {
    mapId?: string;
    location?: any;
}

function MapPage(props: MapPageProps) {
    const classes = useStyles();
    const navigate = useNavigate();
    const pathName = props.location.pathname;

    let editable = pathName === `/maps/${props.mapId}/view` ? false : true;
    const user = getAppUser();
    if (user?.username === 'guest@akopica.com' && pathName !== `/maps/${props.mapId}/view`) {
        navigate(`/maps/${props.mapId}/view`);
        editable = false;
    }
    return (
        <div className={classes.container}>
            <AkopicaMap mapId={props.mapId} editable={editable}/>
        </div>
    )
}

export default MapPage;
