import React from 'react';
import { Theme, Toolbar, Typography, Button, Container, Box, Grid, Hidden, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "@reach/router"
import { Trans } from "react-i18next";


const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            display: 'flex',
        },
        title: {
            flexGrow: 1,
        },
        container: {
            padding: 0,
        },
        paperContainer: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
        },
    }),
);

function ManagePage(props: any) {
    const classes = useStyles();

    return (
        <Container className={classes.container} maxWidth={false}>
            <Toolbar disableGutters={true}>
                <Typography component="div" variant="h4" color="inherit" noWrap className={classes.title}>
                    <Trans>Manage</Trans>
                </Typography>
                <Hidden mdUp>
                    <span></span>
                </Hidden>
            </Toolbar>

            <Grid container spacing={2} direction="row-reverse">
                <Hidden smDown>
                    <Grid item md={4}>
                        <Typography variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button color="secondary" startIcon={<AddIcon/>}><Trans>Add User</Trans></Button>
                        </Box>                            
                        <Box> 
                            <Button color="secondary" startIcon={<AddIcon/>}><Trans>Create Group</Trans></Button>
                        </Box>
                    </Grid>
                </Hidden>

                <Grid container spacing={2} item xs={12} md={8}>
                    <Grid item xs={12}>
                        <Paper>
                            <Container className={classes.paperContainer}>
                                <Typography variant="h6" color="inherit" noWrap><Trans>Users</Trans></Typography>
                                
                                <p><Trans>Create, delete or pause users in this Akopica site.</Trans></p>
                               
                                <Button color="secondary" variant="contained"><Trans>Manage Users</Trans></Button>
                            </Container>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper>
                            <Container className={classes.paperContainer}>
                                <Typography variant="h6" color="inherit" noWrap><Trans>Groups</Trans></Typography>
                                
                                <p><Trans>Create, delete or modify groups and it members.</Trans></p>
                               
                                <Button color="secondary" variant="contained" component={RouterLink} to="/groups"><Trans>Manage Groups</Trans></Button>
                            </Container>
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>
        </Container>
    );
  }
  
export default ManagePage;

