import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from 'react-i18next';



function ConfirmationDialog(props: any) {
    const [ t ] = useTranslation();

    const handleClose = () => {
        props.onClose();
    };

    const handleConfirm = () => {
        props.onConfirm();
    }

    return (
        <span>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.subtitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary" autoFocus>
                        {t("Confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    )
}
  
export default ConfirmationDialog;
