import React, {useEffect, useState, KeyboardEvent} from 'react';
import {
    Theme,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    Toolbar,
    TextField,
    Tabs,
    Tab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    MenuItem,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {Link as RouterLink, navigate, RouteComponentProps} from "@reach/router";
import SearchIcon from "@mui/icons-material/Search";
import MapIcon from "@mui/icons-material/Map";
import DataIcon from "@mui/icons-material/Storage";
import { useTranslation, Trans } from "react-i18next";
import MemberList from './MemberList';
import UserList from './UserList';
import {useAsyncCallback} from "react-async-hook";
import { Group } from "../models/group"
import { addGroupMember, deleteGroup, getGroupById, getGroupMembersPage, getUsersPage, updateGroup, removeGroupMember } from './services';
import useLoading from '../lib/hooks/useLoading';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';
import { slugfy } from '../lib/utils';
import { getAppUser } from '../AppContext';
import { User } from '../models/auth';
import { Page } from '../models/common';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
        paddingRight: 0,
        paddingLeft: 0
    },
    title: {
        flexGrow: 1,
    },
    rowActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
    textSearch: {
        width: "100%"
    },
    tabs: {
        backgroundColor: "#aed1cd", // theme.palette.grey.A100,
    },
    iconButton: {
        marginTop: "3px",
        marginLeft: "-55px",
    }
  }),
);


interface GroupPageProps extends RouteComponentProps {
    groupId?: string;
}

interface InputState {
    page: number,
    pageSize: number,
    order: string,
    q: string,
    groupUid: string,
}

interface InputError {
    title?: string,
}

let wbInput: InputState | null = null;

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <br></br>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabProps(index: any) {
    return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
}

function GroupPage(props: GroupPageProps) {
    const classes = useStyles();
    const [group, setGroup] = useState<Group>({
        id: "",
        name:"",
        title:"",
        detail:"",
    });
    const [input, setInput] = React.useState<InputState>({
        page: 1,
        pageSize: 10,
        order: "recent",                                                        
        q: "",
        groupUid: props?.groupId as string
    });
    const [q, setQ]= React.useState<string>("")
    const [tab, setTab] = useState(0);
    const { t } = useTranslation();
    const [canEdit, setCanEdit] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [openRemoveMember, setOpenRemoveMember] = React.useState(false);
    const searchUser = useAsyncCallback(getUsersPage);
    const [showAdd, setShowAdd] = useState(false);
    const [userSelected, setUserSelected]= React.useState<any>({})
    const [memberSelected, setMemberSelected]= React.useState<any>({})
    const [loading, setLoading] = useLoading(true);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});
    const [inputError, setInputError] = React.useState<InputError>({})
    const [members, setMembers] = React.useState<Page<User>>();
    const [role, setRole] = React.useState('groupReader');
    const [openAdd, setOpenAdd] = React.useState<boolean>(false);

    const isAdmin = getAppUser()?.role !== "siteMember";
    
    const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRole(event.target.value);
    };
    
    const getGroup = (id: string) => {
        getGroupById(id)
            .then(async gr => {
                setGroup(gr);
                if(gr.my_role && (gr.my_role === "groupReader" || gr.my_role === "groupWriter")) {
                    setCanEdit(false);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                setOpenSnackbar(true);    
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    const saveGroup = (group: Group) => {
        setLoading(true);
        updateGroup(group.id || "", group)
            .then(async gr => {
                setSnackBarMsg({msg:t("Saved Successfully"), severity:"success"});
                setOpenSnackbar(true);
                // navigate('/groups/');
            })
            .catch(error => {
                if (error?.error?.message) {
                    setSnackBarMsg({msg:t(error.error.message), severity:"error"});
                } else if (error?.message) {
                    setSnackBarMsg({msg:t(error.message), severity:"error"});
                } else {
                    setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                }
                setOpenSnackbar(true);    
            })
            .finally( () => {
                setLoading(false);
                setInput({...input, q: "", page: 1}); 
            }
        );
    };

    const handleSearchMember = async (groupId?: string) => {
        const gId = groupId ? groupId : group.id ;
        if(gId) {
            const memberpage = await getGroupMembersPage(gId as string, input);
            setMembers(memberpage);
        }
    }

    //open user search
    const handleAddMember = () => {
        setShowAdd(true);
        handleSearchUser();
    }

    const handleSearchUser = () => {  
        searchUser.execute(input)
    };

    const handleSearchMemberInput = async() => {
        await handleSearchMember()
    }

    // selected user data
    const handleSelect = (user: any) => {
        setUserSelected(user);
    };

    const handleOpenAdd = () => {
        setOpenAdd(true);
    }
    // add new member with selected user data
    const handleAdd = () => {
        setLoading(true);
        addGroupMember(group.id || "", {...userSelected, role: role})
            .then(async () => {
                // console.log("respnse", newMember);
                handleSearchMember();
                setSnackBarMsg({msg:t("Member Added Successfully"), severity:"success"});
            })
            .catch(error => {
                console.error('Error:', error);
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            })
            .finally( () => {
                setOpenAdd(false);
                setLoading(false);
                setUserSelected({});
                setShowAdd(false);
                setOpenSnackbar(true);
            }
        );
    };

    const handleCancelAddMember = () => {
        setUserSelected({});
        setInput({...input, q: "", page: 1}); 
        setShowAdd(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenRemoveMember = () => {
        setOpenRemoveMember(true);
    };

    const handleConfirmRemoveMember = async() => {
        try {
            await removeGroupMember(group?.id || "", memberSelected);
            await handleSearchMember();
            setSnackBarMsg({msg:t("Member Removed Successfully"), severity:"success"});
        } catch (err) {
            setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
        } finally {
            setOpenSnackbar(true);    
            setOpenRemoveMember(false);
            setInput({...input, q: "", page: 1});
            setMemberSelected({});
        }
    };


    const handleDelete = async () => {
        handleCloseAlert();
        try {
            setLoading(true);
            await deleteGroup(group.id as string)
            navigate('/groups/');
        } catch (error: any) {
            setLoading(false);
            if (error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
        
    };

    const handleCloseAlert = () => {
        setOpen(false);
        setOpenRemoveMember(false);
        setOpenAdd(false);
        setMemberSelected({});
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    // handle input changes
    const handleChange = (prop: keyof Group) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        let name = group.name;
        if (prop === 'title') {
            name = slugfy(value);
            if (value.trim() === "") {
                setInputError({...inputError, title: t("Please provide a Title")});
            } else {
                setInputError({...inputError, title:undefined});
            }
        }
        
        setGroup({...group, [prop]: value, name: name});
    };

    const handleChangeSearch = (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        wbInput = { ...input, [prop]: event.target.value };
        setQ(event.target.value);
        setInput({...input, q: q});
    };


    function handleEnter(event: KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter") { 
            setInput({...input, q: q});
        }
    }

    const handleSave = () => {
        saveGroup(group);
    };

    // pagination
    const handleNextMembers = () => {
        const nPages = Math.ceil(members!.count / input.pageSize);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });
        }
    }

    const handlePrev = () => {
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
        }
    }

    const handleNextUsers = () => {
        const nPages = Math.ceil(searchUser.result!.count / input.pageSize);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });
        }
    }

    useEffect(() => {
        if (showAdd) searchUser.execute(input);
        
        if(!showAdd) handleSearchMember();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    useEffect(() => {
        // recover from wayback
        if (wbInput) {
            setInput(wbInput);
        };

        if (!props.groupId) {
            return;
        };
        
        getGroup(props.groupId);

        handleSearchMember(props.groupId);

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth={false}>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>Group</Trans>: {group.title}
                </Typography>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="primary" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/groups" color="secondary" startIcon={<SearchIcon />}><Trans>Search Group</Trans></Button>
                        </Box>
                        <Box>
                            <Button component={RouterLink} to={"/maps"} state={{ group: group }} color="secondary" startIcon={<MapIcon />}><Trans>Group Maps</Trans></Button>
                        </Box>
                        <Box>
                            <Button component={RouterLink} to={"/datasets"} state={{ group: group }} color="secondary" startIcon={<DataIcon />}><Trans>Group Datasets</Trans></Button>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Container>
                        <Tabs
                            className={classes.tabs}
                            indicatorColor="secondary"
                            value={tab}
                            onChange={handleChangeTab}
                            variant="fullWidth"
                            >

                            <Tab label={t("Main")} {...tabProps(0)}></Tab>  
                            <Tab label={t("Users/Members")} {...tabProps(1)}></Tab>
                        </Tabs>

                        {/* Main Panel */}
                        <TabPanel value={tab} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Typography component="h1" variant="h6" color="secondary" noWrap>
                                        <Trans>Properties</Trans>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        id="title"
                                        label={t("Title")}
                                        value={group.title}
                                        onChange={handleChange("title")}
                                        variant="outlined"
                                        helperText={inputError.title}
                                        error={inputError.title !== undefined}    
                                        required
                                        disabled={loading || !canEdit}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        id="detail"
                                        label={t("Detail")}
                                        value={group.detail ?? ""}
                                        onChange={handleChange("detail")}
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        disabled={loading || !canEdit}
                                    />
                                </Grid>


                                <Grid item xs={12} md={12} className={classes.rowActions}>
                                    <Button onClick={handleClickOpen} disabled={loading || !canEdit || !isAdmin} variant="outlined" color="secondary"><Trans>Delete</Trans></Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleCloseAlert}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{t("Delete Group")}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <Trans>Are you sure you want to permanently delete this group?</Trans>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseAlert} color="primary">
                                                <Trans>No</Trans>
                                            </Button>
                                            <Button onClick={handleDelete} color="primary" autoFocus>
                                            <Trans>Yes</Trans>
                                            </Button>
                                        </DialogActions>
                                    </Dialog>


                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button onClick={handleSave} disabled={loading || !canEdit} variant="contained" color="secondary"><Trans>Save</Trans></Button>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/* Members panel */}
                        <TabPanel value={tab} index={1}>
                            <Grid container spacing={2}>
                                {!showAdd &&
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Typography component="h1" variant="h6" color="secondary" noWrap>
                                            <Trans>Manage Members</Trans>
                                            &nbsp;&nbsp;

                                            {/* TODO Agregar tooltip: "Crear usuario y agregarlo al grupo" */}
                                            {/* TODO Mejorar html, espaciados, titulos, etc */}
                                        </Typography>
                                        <TextField
                                                className={classes.textSearch}
                                                id="q"
                                                type="search"
                                                label={t("Search Member")}
                                                value={q}
                                                onChange={handleChangeSearch("q")}
                                                onKeyPress={handleEnter}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                className={classes.iconButton}
                                                                onClick={handleSearchMemberInput}
                                                                color="secondary"
                                                                aria-label={t("Search")}
                                                                component="span"
                                                                size="large">
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {members && <MemberList data={members} next={handleNextMembers} prev={handlePrev} handleSelect={setMemberSelected} handleRemove={handleClickOpenRemoveMember} canEdit={canEdit}/>}
                                        {canEdit && <div className={classes.rowActions}>
                                            <Button onClick={handleAddMember} variant="contained" color="secondary"><Trans>Add Member</Trans></Button>
                                        </div>}
                                        <Dialog
                                            open={openRemoveMember}
                                            onClose={handleCloseAlert}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">{t("RemoveMember")}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    {t('Remove Member', { member: `${memberSelected.firstname} ${memberSelected.lastname}. Email: ${memberSelected.email}`})}
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseAlert} color="primary">
                                                    <Trans>No</Trans>
                                                </Button>
                                                <Button onClick={handleConfirmRemoveMember} color="primary" autoFocus>
                                                <Trans>Yes</Trans>
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                </>}
                            
                                {showAdd &&
                                    <Grid item xs={12} md={12}>
                                            <Typography component="h1" variant="h6" color="secondary" noWrap>
                                                <Trans>Add Member</Trans>
                                                &nbsp;&nbsp;
                                            </Typography>
                                            
                                            <br></br>

                                            <TextField
                                                className={classes.textSearch}
                                                id="q"
                                                type="search"
                                                label={t("Search User")}
                                                value={q}
                                                onChange={handleChangeSearch("q")}
                                                onKeyPress={handleEnter}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                className={classes.iconButton}
                                                                onClick={handleSearchUser}
                                                                color="secondary"
                                                                aria-label={t("Search")}
                                                                component="span"
                                                                size="large">
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                      
                                            {searchUser.result && <UserList data={searchUser.result} handleSelect={handleSelect} userSelected={userSelected} next={handleNextUsers} prev={handlePrev} />}
                                       
                                        
                                        <div className={classes.rowActions}>
                                            <Button onClick={handleCancelAddMember} variant="outlined" color="secondary"><Trans>Cancel</Trans></Button>
                                            
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button onClick={handleOpenAdd} disabled={!Boolean(userSelected.uid)} variant="contained" color="secondary"><Trans>Add selected member</Trans></Button>
                                            <Dialog
                                            open={openAdd}
                                            onClose={handleCloseAlert}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">{t("Select Role Member")}</DialogTitle>
                                            <DialogContent> 
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={10}>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <Trans>Please choose a role for the member</Trans>
                                                        </DialogContentText>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            label={t('Role')}
                                                            value={role}
                                                            fullWidth
                                                            onChange={handleChangeRole}
                                                            >
                                                            {roles.map((role) => (
                                                                <MenuItem key={role.value} value={role.value}>
                                                                {role.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>      
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseAlert} color="primary">
                                                    <Trans>Cancel</Trans>
                                                </Button>
                                                <Button onClick={handleAdd} color="primary" autoFocus>
                                                <Trans>Confirm</Trans>
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        </div>
                                        
                                    </Grid>
                                }
                            </Grid>
                        </TabPanel>
                    </Container>
                </Grid>
            </Grid>
            {loading && <LoadingProgress/>}
        </Container>
    );
}

export default GroupPage;


const roles = [
    {
        value: 'groupAdmin',
        label: 'Group Admin',
    },
    {
        value: 'groupReader',
        label: 'Group Reader',
    },
    {
        value: 'groupWriter',
        label: 'Group Writer',
    },
]
