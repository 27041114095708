import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { AlertColor } from '@mui/material';


interface SnackbarInput {
    msg: string,
    type?: string,
    severity?: string,
    openSnackbar: boolean,
    setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>,
    time?: number,
    position?: string
}


/**
 * Use this component to show success or error messages 
 *
 * @param msg - string
 * @param type - string - alert - default: common snackbar
 * @param severity - string - error, warning, info, success - default: success
 * @param openSnackbar - true/false state
 * @param setOpenSnackbar - function that control when to open/close the snackbar
 * @param time - time before hide - default: 4000
 * @param position - string - top-left, top-center, top-right, bottom-left, bottom-center, bottom-right - default: bottom-center
 */
function SnackbarObject(props: SnackbarInput) {

    
    const [pos, setPos] = React.useState<SnackbarOrigin>({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = pos;

    useEffect(() => {
        getPosition();
        // console.log(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPosition = () => {
        switch (props.position) {
            case 'top-left':
                setPos({vertical: 'top', horizontal: 'left'});
                break;
            case 'top-center':
                setPos({vertical: 'top', horizontal: 'center'});
                break;
            case 'top-right':
                setPos({vertical: 'top', horizontal: 'right'});
                break;
            case 'bottom-left':
                setPos({vertical: 'bottom', horizontal: 'left'});
                break;
            case 'bottom-center':
                setPos({vertical: 'bottom', horizontal: 'center'});
                break;
            case 'bottom-right':
                setPos({vertical: 'bottom', horizontal: 'right'});
                break;
            default:
                setPos({vertical: 'top', horizontal: 'center'});
                break;
        } 
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        props.setOpenSnackbar(false);
    };

    const Alert = React.forwardRef<typeof MuiAlert, React.PropsWithChildren<AlertProps>>(function Alert(props, ref:any) {
        return <MuiAlert elevation={6} variant="filled" {...props} ref={ref}/>;
    });

    const typeAlert = (
        <React.Fragment>
            <Snackbar anchorOrigin={{ vertical, horizontal }}
                open={props.openSnackbar} 
                autoHideDuration={props.time ? props.time : 4000} 
                onClose={handleClose}>
                    <Alert onClose={handleClose} severity={props.severity as AlertColor}>
                        {props.msg}
                    </Alert>
            </Snackbar>
        </React.Fragment>
    );

    const commonSnackbar = (
        <Snackbar anchorOrigin={{ vertical, horizontal }}
            open={props.openSnackbar}
            autoHideDuration={props.time ? props.time : 4000}
            onClose={handleClose}
            message={props.msg}
            color="secondary"
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )

    return (
        <React.Fragment>
            { props.type === "alert" ? typeAlert : commonSnackbar}
        </React.Fragment>
    )
}
  
export default SnackbarObject;
