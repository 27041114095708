import React, { useEffect } from "react";
import { Theme, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import ComboBoxLayer from "./ComboBoxLayer";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
        },
        settingsRow: {
            margin: theme.spacing(1),
        },
        settingsTitle: {
            margin: theme.spacing(1),
            marginLeft: 0,
        },
        root: {
            width: 300,
        }

    }),
);

interface MyProps {
    map: any;
    layer: any;
    onClose: Function;
    onChangeLayer: Function;
    changeSubtitle: Function;
}

function CompareSettings(props: MyProps) {
    console.debug("SettingsShape: start...");
    const classes = useStyles();
    const { t } = useTranslation();

    const [values, setValues] = React.useState({
        layer1: props.layer.settings?.compare?.layer1 || null,
        layer2: props.layer.settings?.compare?.layer2 || null,
    });

    const handleChange = (name:any, values:any) => {
        if(values && values.id){

            setValues({ ...values, [name]: values.id });
            
            props.layer.settings.compare = props.layer.settings.compare || {};
            
            if (name === "layer1") {
                props.layer.settings.compare.layer1 = values.id;
            }
            
            if (name === "layer2") {
                props.layer.settings.compare.layer2 = values.id;
            }
            
            props.onChangeLayer(props.layer, "compare::" + name, values.id);
        }
        
        //setPersonName(event.target.value as string[]);
      };

    // inicializacion
    useEffect(() => {
        //setValues(props.layer.settings.image);
        
        return () => {
            // close edit
            //props.layer.settings.shape.edit = false;
            //setValues({...values, edit: false});
            //props.onChangeLayer(props.layer, "shape::edit", false);
        }
    }, []);

    return (
        <div className={classes.container}>
            <SettingsDetail {...props} allowNote={false} disableNoteEdit={false}></SettingsDetail>
            <Divider></Divider>
            <div className={classes.settingsRow}>
                <ComboBoxLayer layers={props.map.layers} label={t("Layer Left")} handleChange={handleChange} name="layer1" layerid={values.layer1}/>
            </div>
            <div className={classes.settingsRow}>
                <ComboBoxLayer layers={props.map.layers} label={t("Layer Right")} handleChange={handleChange} name="layer2" layerid={values.layer2}/>
            </div>
        </div>
    )
}

export default CompareSettings;
