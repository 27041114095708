import React, { ReactEventHandler, createRef, useState, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { LeafletMouseEvent, LayerEvent, geoJSON, TooltipOptions, PathOptions } from "leaflet";
import NoteDialog from "./NoteDialog";
import { getDatasetFile, getUploadDatasetFileUrl, uploadDatasetFile } from "../../../services";


export const tooltipOptions: TooltipOptions = {
    permanent: false,
    direction: "center",
    opacity: 0.7,
};

const noteStyle: PathOptions = {
    color: "#0339fc",
    dashArray: [8],
}

function NoteLayer(props: any) {
    const layer = props.layer;
    const settings = props.layer.settings || {};
    const featureGroupRef = createRef<L.FeatureGroup>();
    const [selectShape, setSelectShape] = useState({ open: false, layer: undefined });
    const filename = "index.note.geojson";

    function onCreatePath(event: ReactEventHandler) {
        saveNotes();
    }

    function saveNotes() {
        const data = featureGroupRef.current?.toGeoJSON();
        const fileData = JSON.stringify(data);
        const file = new Blob([fileData], {type: "text/plain"});
        if (file.size) {
            getNewUrl(file, upload);
        }
    }

    async function loadNotes() {
        const dataset = layer.dataset;
        if (dataset?.id) {
            try {
                const response = await getDatasetFile(dataset.id, dataset.path, filename);

                const data = await response.json();
                if (data["code"] || data === "" || data.url.trim() === "" ) {
                    return;
                }

                const featureGroup = featureGroupRef.current;
                featureGroup?.clearLayers();
                geoJSON(data, {style: noteStyle}).eachLayer(layer => {            
                    // bind tooltop with note title if exists
                    const l: any = layer;
                    const ttLabel = l.feature?.properties?.note?.title;
                    if (ttLabel) {
                        layer.bindTooltip(ttLabel, tooltipOptions);
                    }

                    // add note to group
                    featureGroup?.addLayer(layer);
                });

            } catch (error) {
                // console.log("Error", error);
            }
        }
    }

    function upload(file: any, url: string) {
        uploadDatasetFile(url, file)
        .then(() => {
            // console.log("Upload ok");
        }).catch((e) => {
            console.error("Upload Error: ", e);
        });
    }

    function getNewUrl(file: any, cb: Function) {
        getUploadDatasetFileUrl(layer.dataset.id, layer.dataset.path, filename)
        .then((url) => {
            //console.log("url: ", url);
            cb(file, url);
        }).catch((e) => {
            console.error("getNewUrl Error: ", e);
        });
    }

    function onEditPath(event: ReactEventHandler) {
        saveNotes();
        //console.log("edit path");
    }

    function onDeletePath(event: ReactEventHandler) {
        saveNotes();
        //console.log("delete path");
    }

    const onClickFeature = (event: LeafletMouseEvent) => {
        //console.log("click feature", event);
        //console.log("toGeoJson: ", event.layer.toGeoJson());
        //console.log("featureGroup: 1: ", featureGroupRef);
        // featureGroupRef.current?.leafletElement.getLayers()
        setSelectShape({ open: true, layer: event.layer });
    }

    const onCloseNote = (event: MouseEvent) => {
        setSelectShape({ open: false, layer: undefined });
    }

    const onSaveNote = (event: MouseEvent) => {
        saveNotes();
    }

    function onLayerAdd(event: LayerEvent) {
        //console.log("add layer", event);
        const layer: any = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
    }

    useEffect(() => {
        loadNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.layer.settings.basic.noteView]);


    console.debug("Start NoteLayer: props:", props);

    return (
        <React.Fragment>
            {(settings?.basic?.noteView || layer.lytype === "note") &&
                <FeatureGroup
                    ref={featureGroupRef}
                    eventHandlers={{
                        click: onClickFeature,
                        layeradd: onLayerAdd,
                    }}
                    //color={noteStyle.color}
                    //dashArray={noteStyle.dashArray}
                >
                    {(settings?.basic?.noteEdit || settings?.note?.edit) &&
                        <EditControl position="topright"
                            onCreated={onCreatePath}
                            onEdited={onEditPath}
                            onDeleted={onDeletePath}
                            draw={{
                            rectangle: {
                                shapeOptions: noteStyle,
                            },
                            polygon: {
                                shapeOptions: noteStyle,
                            },
                            polyline: {
                                shapeOptions: noteStyle,
                            },
                            circle: {
                                shapeOptions: noteStyle,
                            },
                            circlemarker: {
                            },
                            marker: {
                            },
                            }}
                        />
                    }
                </FeatureGroup>
            }
            {selectShape.open &&
                <NoteDialog open={true} layer={props.layer} featureLayer={selectShape.layer} onClose={onCloseNote} onSave={onSaveNote}></NoteDialog>
            }
        </React.Fragment>
    );
}

NoteLayer.defaultProps = {
    popupContent: '',
}

export default NoteLayer;
