import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
            "account of current user": "account of current user",
            "Active": "Active",
            "Activities": "Activities",
            "Add Layer": "Add Layer",
            "Add Member": "Add Member",
            "Add User": "Add User",
            "Adjust title for every column": "Adjust title for every column",
            "Add a layer of Annotation to the map": "Add a layer of Annotation to the map",
            "Add a layer with an existent dataset": "Add a layer with an existent dataset",
            "Add a layer of background (osm, google, sentinel...)": "Add a layer of background (osm, google, sentinel...)",
            "Add notes to your Annotation": "Add notes to your Annotation",
            "Add selected member": "Add selected member",
            "Annotation": "Annotation",
            "Application": "Application",
            "Application Plan": "Application Plan",
            "Are you sure you want to discard changes?":"Are you sure you want to discard changes?",
            "Are you sure you want to edit the settings of this dataset?":"Are you sure you want to edit the settings of this dataset?",
            "Are you sure you want to permanently delete this annotation?": "Are you sure you want to permanently delete this annotation?",
            "Are you sure you want to permanently clean this dataset?": "Are you sure you want to permanently clean this dataset?",
            "Are you sure you want to permanently delete this dataset?": "Are you sure you want to permanently delete this dataset?",
            "Are you sure you want to permanently delete this group?": "Are you sure you want to permanently delete this group?",
            "Are you sure that you wish to delete the layer?": "Are you sure that you wish to delete the layer?",
            "Are you sure you wish to delete the map?":"Are you sure you wish to delete the map?",
            "Asset": "Asset",
            "Assets": "Assets",
            "Asset created successfully": "Asset created successfully",
            "Basic": "Basic",
            "Browse": "Browse",
            "Brightness": "Bright",
            "Background": "Background",
            "Cancel": "Cancel",
            "Can not add more datasets to the site": "Can not add more datasets to the site",
            "Can not add sentinel layer to public map": "Can not add sentinel layer to public map",
            "Can not upload more files to the dataset": "Can not upload more files to the dataset",
            "Can not add more groups": "Can not add more groups",
            "Can not add more layers to the map": "Can not add more layers to the map",
            "Can not add more maps to the site": "Can not add more maps to the site",
            "Cant create a group on this site": "Cant create a group on this site",
            "Cant add a layer on this map": "Cant add a layer on this map",
            "Cant delete a layer on this map": "Cant delete a layer on this map",
            "Cant update a layer on this map": "Cant update a layer on this map",
            "Cant create a dataset on this group or site": "Cant create a dataset on this group or site",
            "Cant delete a dataset on this group or site": "Cant delete a dataset on this group or site",
            "Cant update a dataset on this group or site": "Cant update a dataset on this group or site",
            "Cant create a map on this group or site": "Cant create a map on this group or site",
            "Cant delete a map on this group or site": "Cant delete a map on this group or site",
            "Can not publish a map with a sentinel layer": "Can not publish a map with a sentinel layer",
            "Cant update a map on this group or site": "Cant update a map on this group or site",
            "Cant update this group on this site": "Cant update this group on this site",
            "Choose File": "Choose File",
            "Circle": "Circle",
            "Clean": "Clean",
            "Confirm": "Confirm",
            "Confirmation": "Confirmation",
            "Copied successfully": "Copied successfully",
            "Copy Link": "Copy Link",
            "Change current site": "Change current site",
            "Change Map Name": "Change Map Name",
            "Clean Dataset": "Clean Dataset",
            "Clean Successfully": "Clean Successfully",
            "Clone Dataset": "Clone Dataset",
            "Close": "Close",
            "close drawer": "close drawer",
            "close settings": "close settings",
            "Cloud Coverage": "Cloud Coverage",
            "Color Palette": "Color Palette",
            "Columns": "Columns",
            "Comma": "Comma",
            "Confidence": "Confidence",
            "Contrast": "Contrast",
            "Create": "Create",
            "Configurate": "Configurate",
            "Dataset Tile Description": "A dataset that is a directory with TMS Tiles. Following the OSGeo Tile Map Service Specification. You must upload the directory in a .zip file. If you have a big GeoTiff you can process to generate the TMS Tiles for view in an Akopica map.",
            "Dataset Geojson Description": "A dataset that contains geometric shapes with properties. You can upload a Geojson file or a .zip file with Shapefile archives.",
            "Dataset Csv Description": "A dataset that contains rows with with one or two location information and columns with data asociated to that locations.",
            "Dataset Feature Description": "The type determines how the dataset is processed, e.g. Feature indicates that each row of the dataset corresponds to a geolocated point.",
            "Dataset Image Description": "A dataset that contains a set of images and each image can has anotations. You must upload .zip file with JPG images.",
            "Create a Map": "Create a Map",
            "Create Asset": "Create Asset",
            "Create Dataset": "Create Dataset",
            "Create Group": "Create Group",
            "Create Site": "Create Site",
            "Dataset": "Dataset",
            "Dataset not found":"Dataset not found",
            "Dataset search": "Dataset search",
            "Dataset Type": "Dataset Type",
            "Datasets": "Datasets",
            "Datasets are the data displayed on the maps. You can upload figure data, images, mosaics, etc.": "Datasets are the data displayed on the maps. You can upload figure data, images, mosaics, etc.",
            "Date": "Date",
            "Delete": "Delete",
            "Delete Annotation": "Delete Annotation",
            "Delete Dataset": "Delete Dataset",
            "Delete Group": "Delete Group",
            "Delete Map":"Delete Map",
            "Delete Schema": "Delete Schema",
            "Detail": "Detail",
            "Detection Comment": "Detection Comment",
            "Detection Data": "Detection Data",
            "Don't have a account?": "Don't have a account?",
            "Down": "Down",
            "Edit": "Edit",
            "Edit Map": "Edit Map",
            "Edit Notes": "Edit Notes",
            "Edit profile": "Edit profile",
            "English": "English",
            "Enter": "Enter",
            "Exit": "Exit",
            "Existent Dataset": "Existent Dataset",
            "Figures": "Figures",
            "File": "File",
            "File Uploads": "File Uploads",
            "Files size": "Files size",
            "filter": "Filter",
            "Filter": "Filter",
            "Filters": "Filters",
            "First name": "First name",
            "Folders": "Folders",
            "Folder Name": "Folder Name",
            "Forgot your password?": "Forgot your password?",
            "Full URL": "Full URL",
            "General": "General",
            "Group": "Group",
            "Groups": "Groups",
            "Group Datasets": "Group Datasets",
            "Group Maps": "Group Maps",
            "GROUP ID IS MISSING, PLEASE CONTACT SUPPORT": "GROUP ID IS MISSING, PLEASE CONTACT SUPPORT",
            "date_from_filter": "Date From",
            "date_to_filter": "Date To",
            "group_filter": "Group: ",
            "Headers are required in the file": "Headers are required in the file",
            "Help": "Help",
            "Home": "Home",
            "In one or more rows, latitude and/or longitude columns have no value": "In one or more rows, latitude and/or longitude columns have no value",
            "Incorrect file format": "Incorrect file format",
            "Incorrect format of Latitude and Longitude": "Incorrect format of Latitude and Longitude",
            "Installed Pipelines": "Installed Pipelines",
            "Install Pipeline": "Install New Pipeline",
            "Image Comment": "Image Comment",
            "Interval Days": "Interval Days",
            "Label": "Label",
            "Last name": "Last name",
            "Latitude": "Latitude",
            "Layer": "Layer",
            "Layer Data": "Layer Data",
            "The Layer has been created": "The Layer has been created",
            "The Layer has been deleted": "The Layer has been deleted",
            "The Layer has been updated": "The Layer has been updated",
            "Latitude and Longitude are required in the file": "Latitude and Longitude are required in the file",
            "Layer Left": "Layer Left",
            "Layer Right": "Layer Right",
            "Layer Type": "Layer Type",
            "Line points": "Line points",
            "Link Dataset": "Link Dataset",
            "Loading":"Loading",
            "Login": "Login",
            "Longitude": "Longitude",
            "Main": "Main",
            "Manage": "Manage",
            "Manage Members": "Manage Members",
            "Manage Sites": "Manage Sites",
            "Manage Users": "Manage Users",
            "Maps": "Maps",
            "Map Data": "Map Data",
            "Map title": "Map title",
            "Marker": "Marker",
            "Member Added Successfully": "Member Added Successfully",
            "Member Removed Successfully": "Member Removed Successfully",
            "Mode": "Mode",
            "Move Up": "Move Up",
            "Move Down": "Move Down",
            "My Account": "My Account",
            "Name": "Name",
            "Name Helper New Dataset": "It is used to identify the table with the data of the dataset, e.g.: world_ports_2022",
            "New Dataset": "New Dataset",
            "New Group": "New Group",
            "New Layer": "New Layer",
            "New Map": "New Map",
            "Next": "Next",
            "No": "No",
            "No file selected": "No file selected",
            "None": "None",
            "Note": "Note",
            "Notes": "Notes",
            "Number of detections": "Number of detections",
            "Opacity": "Opacity",
            "open drawer": "open drawer",
            "Options": "Options",
            "Order": "Order",
            "Password": "Password",
            "Please choose a Group" : "Please choose a Group",
            "Please choose a Dataset Type" : "Please choose a Dataset Type",
            "Please choose a role for the member": "Please choose a role for the member",
            "Please provide a Subject" : "Please provide a Subject",
            "Please provide a Title": "Please provide a Title",
            "Points": "Points",
            "Preset": "Preset",
            "Previous": "Prev",
            "Projects": "Projects",
            "Private": "Private",
            "Public": "Public",
            "Public Map": "Public Map",
            "Unpublic Map": "Unpublic Map",
            "The map is no longer public": "The map is no longer public",
            "Map published successfully": "Map published successfully",
            "Pipe": "Pipe",
            "Properties": "Properties",
            "Public map dashboard": "Public map dashboard",
            "Radius": "Radius",
            "Recents": "Recents",
            "Recover here": "Recover here",
            "Rectangle": "Rectangle",
            "Reference Date": "Reference Date",
            "Related Asset": "Related Asset",
            "RemoveMember": "Remove Member",
            "Remove Member": "Are you sure you want to remmove {{member}} from this group?",
            "Resolution X [px/m]": "Resolution X [px/m]",
            "Resolution Y [px/m]": "Resolution Y [px/m]",
            "Resolution Z [px/m]": "Resolution Z [px/m]",
            "Role": "Role",
            "Role validation error": "You do not have privileges to perform this action",
            "Rotate Image": "Rotate Image",
            "Rotate Left": "Rotate Left",
            "Rotate Right": "Rotate Right",
            "Save": "Save",
            "Saved Successfully": "Saved Successfully",
            "Schema": "Schema",
            "Schema Name": "Schema Name",
            "Schema not found in Site": "Schema not found in Site",
            "Schema Type": "Schema Type",
            "Search": "Search",
            "Search dataset": "Search dataset",
            "Search Dataset": "Search Dataset",
            "Search Group": "Search Group",
            "Search Member": "Search Member",
            "search site": "search site",
            "Search User": "Search User",
            "See Notes": "See Notes",
            "Select Dataset": "Select Dataset",
            "Select Layer Type": "Select Layer Type",
            "Select Role Member": "Select Role Member",
            "Select Site": "Select Site",
            "Select Column to Edit": "Select Column to Edit",
            "Select Title and Subtitle columns" : "Identify the columns to use as Title and Subtitle when viewing the data.",
            "Select a file with xls, xlsx, csv or geojson extension.": "Select a file with xls, xlsx, csv or geojson extension. They must contain geo-referenced data. The geojson files must contain geometry and coordinate properties (Point, Linestring, Polygon, others). The xls, xlsx and csv files must contain column names or titles starting with Lat (Latitude) and Long (Longitude).",
            "Select the separator of the fields in the csv file, by default it will use the comma.": "Select the separator of the fields in the csv file, by default it will use the comma.",
            "Semicolon": "Semicolon",
            "Sentinel Settings": "Sentinel Settings",
            "Separator": "Separator",
            "Settings": "Settings",
            "Share Map": "Share Map",
            "Signin with email": "Signin with email",
            "Sign up": "Sign up",
            "Site": "Site",
            "Sites": "Sites",
            "Site not found":"Site not found",
            "Spanish": "Spanish",
            "Start creating a map": "Start creating a map",
            "Storage": " Storage",
            "Styles": "Styles",
            "Sorry, an unexpected error has ocurred": "Sorry, an unexpected error has ocurred",
            "Subject": "Subject",
            "Subtitle": "Subtitle",
            "Success": "Success", 
            "Temperature": "Temperature",
            "The Annotation has been created": "The Annotation has been created",
            "The Annotation has been deleted": "The Annotation has been deleted",
            "The Annotation has been updated": "The Annotation has been updated",
            "The file you selected is too large": "The file you selected is too large",
            "The Dataset has a Map associated": "The Dataset has a Map associated",
            "The Group has a Dataset associated": "The Group has a Dataset associated",
            "The Group has a Map associated": "The Group has a Map associated",
            "The number of rows in the Dataset exceeds the maximum allowed.": "The number of rows in the Dataset exceeds the maximum allowed.",
            "Title": "Title",
            "Title Helper New Dataset": "It is the title that identifies the dataset in searches and uses, e.g.: Major Ports of the World by Country 2022",
            "Title is required.": "Title is required.",
            "Tools": "Tools",
            "Type": "Type",
            "Type of Dataset": "Type of Dataset",
            "Unknown": "Unknown",
            "Update": "Update",
            "Update Dataset Settings": "Update Dataset Settings",
            "Update user data": "Update user data",
            "Update successful":"Update successful",
            "Upload Data": "Upload Data",
            "Upload Dataset": "Upload Dataset",
            "Upload File": "Upload File",
            "Upload Files": "Upload Files",
            "Upload Successfully": "Upload Successfully",
            "Uploaded File": "Uploaded File",
            "Upload a dataset": "Upload a dataset",
            "Users": "Users",
            "Users/Members": "Users/Members",
            "User name": "User name",
            "User Profile": "User Profile",
            "With Akopica you can create maps with different types of information layers stored in datasets.": "With Akopica you can create maps with different types of information layers stored in datasets.",
            "View Notes": "View Notes",  
            "Yes": "Yes",
         }
      },
      es: {
        translations: {
            "Please wait to enter Akopica...": "Por favor espere para ingresar a Akopica...",
            "Your signin link has expired": "Su enlace de ingreso a expirado",
            "Go to signin page and we’ll send a new link to your inbox.": "Ve a la página de ingreso y le enviaremos un nuevo enlace a su casilla.",
            "Go to signin page": "Ir a la página de ingreso",
            "Signin with email": "Ingresar con email", 
            "Don't have a account?": "No tienen una cuenta?",
            "Sign up":  "Registrarse",
            "Recover here": "Recuerparla aquí",
            "Sorry, email was not found": "Lo siento, el email no se encontró",
            "The email": "El email",
            "was not recognized in any account": " no fue reconocido en ninguan cuenta",
            "You can": "Puedes",
            "try with another email": "probar con otro email",
            "or": "o",
            "Please enter a valid email address.": "Por favor ingrese una dirección de email valida.",
            "create a new account": "crear una cuenta nueva",
            "Check your inbox": "Verifique su casilla de correos",
            "Click the link we sent to": "Haga click en el enlace que le enviamos a",
            "to sign in": "para ingresar",
            "Signup with your email": "Registrarse con un email",
            "I agree to the Akopica": "Estoy de acuerdo con",
            "Terms of Service": "los terminos de servicio",
            "and": "y",
            "Privacy Policy": "las políticas de privacidad de Akopica",
            "Already have a account?": "Ya tienen una cuenta?",
            "to sign up.": "para registrarse.",
            "account of current user": "cuenta del usuario actual",
            "Active": "Activo",
            "Activities": "Actividades",
            "Add Layer": "Agregar Layer",
            "Add Member": "Agregar Miembro",
            "Add User": "Agregar Usuario",
            "Adjust title for every column": "Ajuste el título de cada columna",
            "Add a layer of Annotation to the map": "Agregar un layer de Anotación al mapa",
            "Add a layer with an existent dataset": "Agregar un layer con un dataset existente",
            "Add a layer of background (osm, google, sentinel...)": "Agregar un layer de fondo (osm, google, sentinel...)",
            "Add notes to your Annotation": "Agregar notas a su Anotación",
            "Add selected member": "Agregar miembro seleccionado",
            "Annotation": "Anotación",
            "Application": "Application",
            "Application Plan": "Plan de Aplicación",
            "Are you sure you want to discard changes?": "¿Está seguro de que desea descartar los cambios?",
            "Are you sure you want to edit the settings of this dataset?" : "¿Está seguro de que desea editar la configuración de este dataset?",
            "Are you sure you want to permanently clean this dataset?": "¿Está seguro de que desea limpiar la tabla de éste dataset de forma permanente?",
            "Are you sure you want to permanently delete this annotation?": "¿Está seguro de que desea eliminar esta anotación de forma permanente?",
            "Are you sure you want to permanently delete this dataset?": "¿Está seguro de que desea eliminar éste dataset de forma permanente?",
            "Are you sure you want to permanently delete this group?": "¿Está seguro de que desea eliminar éste grupo de forma permanente?",
            "Are you sure that you wish to delete the layer?": "¿Está seguro de que desea eliminar el layer?",
            "Are you sure you wish to delete the map?":"¿Está seguro de que desea eliminar el mapa?",
            "Asset": "Activo",
            "Assets": "Activos",
            "Asset created successfully": "Activo creado con éxito",
            "Basic": "Básico",
            "Brightness": "Brillo",
            "Browse": "Explorar",
            "Background": "Fondo",
            "Cancel": "Cancelar",
            "Can not add more datasets to the site": "No puede añadir más datasets al sitio",
            "Can not add sentinel layer to public map": "No puede añadir un layer de sentinel en un mapa publico",
            "Can not publish a map with a sentinel layer": "No puede publicar un mapa con un layer de sentinel",
            "Can not upload more files to the dataset": "No puede cargar mas archivos al dataset",
            "Can not add more groups": "No puede añadir mas grupos",
            "Can not add more layers to the map": "No puede añadir más layers al sitio",
            "Cant create a group on this site": "No puede crear un grupo en este sitio",
            "Can not add more maps to the site": "No puede añadir más mapas al sitio",
            "Cant add a layer on this map": "No puede agregar un layer en este mapa",
            "Cant delete a layer on this map": "No puede eliminar un layer en este mapa",
            "Cant update a layer on this map": "No puede actualizar un layer en este mapa",
            "Cant create a dataset on this group or site": "No puede crear un dataset en este grupo o sitio",
            "Cant delete a dataset on this group or site": "No puede eliminar un dataset en este grupo o sitio",
            "Cant update a dataset on this group or site": "No puede actualizar un dataset en este grupo o sitio",
            "Cant create a map on this group or site": "No puede crear un mapa en este grupo o sitio",
            "Cant delete a map on this group or site": "No puede eliminar un mapa en este grupo o sitio",
            "Cant update a map on this group or site": "No puede actualizar un mapa en este grupo o sitio",
            "Cant update this group on this site": "No puede actualizar este grupo en este sitio",
            "Choose File": "Seleccionar",
            "Circle": "Círculo",
            "Clean": "Limpiar",
            "Comma": "Coma",
            "Confirm": "Confirmar",
            "Confirmation": "Confirmación",
            "Copied successfully": "Copiado",
            "Copy Link": "Copiar Link",
            "Change current site": "Cambiar sede actual",
            "Change Map Name": "Renombrar Mapa",
            "Clean Dataset": "Limpiar Dataset",
            "Clean Successfully": "Limpiado Correctamente",
            "Clone Dataset": "Clonar Dataset",
            "Close": "Cerrar",
            "close drawer": "cerrar menu",
            "close settings": "cerrar ajustes",
            "Cloud Coverage": "Cloud Coverage",
            "Color Palette": "Paleta de Colores",
            "Columns": "Columnas",
            "Confidence": "Confianza",
            "Configurate": "Configurar",
            "Contrast": "Contraste",
            "Create": "Crear",
            "Create a Map": "Crear un Mapa",
            "Create Asset": "Crear Activo",
            "Create Dataset": "Crear Dataset",
            "Create Group": "Crear Grupo",
            "Create Site": "Crear Sede",
            "Dataset": "Dataset",
            "Dataset not found": "Dataset no encontrado",
            "Dataset search": "Buscar dataset",
            "Dataset Type": "Tipo Dataset",
            "Dataset Tile Description": "Un dataset que es un directorio con 'TMS Tiles' siguientdo las especificaciones OSGeo Tile Map Service Specification. Se debe subir el directorio en un archivo .zip.",
            "Dataset Geojson Description": "Un dataset que contiene figuras geométricas con propiedades. Se puede subir archivos Geojson o un archivo .zip con archivos del formato Shapefile.",
            "Dataset Csv Description": "Un dataset en formato CSV que contiene renglones y columnas con información de puntos geográficos y ademas columas extra con datos asociados a esos sitios geográficos.",
            "Dataset Feature Description": "El tipo determina como se procesa el dataset. Ejemplo: Feature indica que que cada fila del dataset se corresponde con un punto geolocalizado.",
            "Dataset Image Description": "Un dataset que tiene un conjunto de imágenes y cada imágen puede tener anotaciones en forma de figuras. Se debe subir un archivo .zip con imágenes JPG.",
            "Datasets": "Datasets",
            "Datasets are the data displayed on the maps. You can upload figure data, images, mosaics, etc.": "Los dataset son los datos que se visualizan en los mapas. Puedes subir datos de figuras, imágenes, mosaicos, etc.",
            "Date":"Fecha",
            "Discard Changes": "Descartar Cambios",
            "Delete": "Eliminar",
            "Delete Annotation": "Eliminar Anotación",
            "Delete Dataset": "Eliminar Dataset",
            "Delete Group": "Eliminar Grupo",
            "Delete Map": "Eliminar Mapa",
            "Delete Schema": "Eliminar Esquema",
            "Detail": "Detalle",
            "Detection Comment": "Comentario de Detección",
            "Detection Data": "Datos Detección",
            "Edit": "Editar",
            "Edit Map": "Editar Mapa",
            "Edit Notes": "Editar Notas",
            "Edit profile": "Editar perfil",
            "English": "Inglés",
            "Enter": "Ingresar",
            "Exit": "Salir",
            "Existent Dataset": "Dataset Existente",
            "Figures": "Figuras",
            "File": "Archivo",
            "File Uploads": "Carga de Archivos",
            "Files size": "Tamaño archivos",
            "filter": "Filtrar",
            "Filter": "Filtro",
            "Filters": "Filtros",
            "First name": "Nombre",
            "Folders": "Carpetas",
            "Folder Name": "Nombre Carpeta",
            "Forgot your password?": "¿Olvido su contraseña?",
            "Full URL": "URL Completa",
            "General": "General",
            "Group": "Grupo",
            "Groups": "Grupos",
            "Group Datasets": "Datasets del Grupo",
            "Group Maps": "Mapas del Grupo",
            "GROUP ID IS MISSING" : "NO SE ENCUENTRA EL ID DE GRUPO",
            "date_from_filter": "Fecha Desde",
            "date_to_filter": "Fecha Hasta",
            "group_filter": "Grupo: ",
            "Headers are required in the file": "El archivo debe contener titulos",
            "Help": "Ayuda",
            "Home": "Inicio",
            "In one or more rows, latitude and/or longitude columns have no value": "En una o más filas, las columnas latitud y/o longitud no tienen valor",
            "Incorrect file format": "Formato incorrecto de archivo",
            "Incorrect format of Latitude and Longitude": "Formato incorrecto de Latitud y Longitud",
            "Installed Pipelines": "Pipelines Instalados",
            "Install Pipeline": "Instalar Nuevo Pipeline",
            "Image Comment": "Comentario imagen",
            "Interval Days": "Intervalo de Días",
            "Label": "Etiqueta",
            "Last name": "Apellido",
            "Latitude": "Latitud",
            "Layer": "Layer",
            "The Layer has been created": "El Layer ha sido creado",
            "The Layer has been deleted": "El Layer ha sido eliminado",
            "The Layer has been updated": "El Layer ha sido actualizado",
            "Latitude and Longitude are required in the file": "Las columnas Latitud y Longitud son requeridas en el archivo",
            "Layer Data": "Datos del Layer",
            "Layer Left": "Layer Izquierda",
            "Layer Right": "Layer Derecha",
            "Layer Type": "Tipo de layer",
            "Line points": "Trazar linea",
            "Link Dataset": "Enlazar Dataset",
            "Loading": "Cargando",
            "Login": "Iniciar Sesión",
            "Longitude": "Longitud",
            "Main": "Principal",
            "Manage": "Administrar",
            "Manage Members": "Administrar Miembros",
            "Manage Sites": "Administrar Sedes", 
            "Manage Users": "Administrar Usuarios",
            "Maps": "Mapas",
            "Map Data": "Datos del Mapa",
            "Map title": "Título del mapa",
            "Marker": "Marcador",
            "Member Added Successfully": "Miembro agregado exitosamente",
            "Member Removed Successfully": "Miembro eliminado exitosamente",
            "Mode": "Modo",
            "more": "mas",
            "Move Up": "Subir",
            "Move Down": "Bajar",
            "My Account": "Mi Cuenta",
            "Name": "Nombre",
            "Name Helper New Dataset": "Es usado para identificar la tabla con los datos del dataset. Ejemplo: puertos_mundo_2022",
            "New Dataset": "Nuevo Dataset",
            "New Group": "Nuevo Grupo",
            "New Layer": "Nuevo Layer",
            "New Map": "Nuevo Mapa",
            "Next": "Siguiente",
            "No": "No",
            "No file selected": "No hay un archivo",
            "None": "Ninguno",
            "Note": "Nota",
            "Notes": "Notas",
            "Number of detections": "Cantidad de detecciones",
            "Opacity": "Opacidad",
            "open drawer": "abrir menú",
            "Options": "Opciones",
            "Order": "Orden",
            "Password": "Clave",
            "Pipe": "Pleca",
            "Please choose a Group" : "Por favor seleccione un Grupo",
            "Please choose a Dataset Type": "Por favor seleccion un tipo de Dataset",
            "Please choose a role for the member": "Por favor, seleccione un rol para el miembro",
            "Please provide a Subject": "Por favor ingrese un Asunto",
            "Please provide a Title": "Por favor ingrese un Título",
            "Points": "Puntos",
            "Preset": "Preset",
            "Previous": "Anterior",
            "Projects": "Proyectos",
            "Private": "Privado",
            "Public": "Público",
            "Public Map": "Publicar Mapa",
            "Unpublic Map": "Dejar de publicar Mapa",
            "The map is no longer public": "El mapa ya no es público",
            "Map published successfully": "Mapa publicado exitosamente",
            "Properties": "Propiedades",
            "Public map dashboard": "Portal de mapas públicos",
            "Radius": "Radio",
            "Recents": "Recientes",
            "Rectangle": "Rectángulo",
            "Reference Date": "Fecha de Referencia",
            "Related Asset": "Activo Relacionado",
            "RemoveMember": "Eliminar Miembro",
            "Remove Member": "Esta seguro que desea eliminar a {{member}} de este grupo?",
            "Resolution X [px/m]": "Resolución X [px/m]",
            "Resolution Y [px/m]": "Resolución Y [px/m]",
            "Resolution Z [px/m]": "Resolución Z [px/m]",
            "Role": "Rol",
            "Role validation error": "No tiene permisos para realizar esta acción",
            "Rotate Image": "Rotar Imagen",
            "Rotate Left": "Rotar a la izquierda",
            "Rotate Right": "Rotar a la derecha",
            "Save": "Guardar",
            "Saved Successfully": "Guardado",
            "Schema": "Esquema",
            "Schema Name": "Nombre de Esquema",
            "Schema not found in Site": "El Sitio al cual pertenece no tiene un Schema",
            "Schema Type": "Tipo de Esquema",
            "Search": "Buscar",
            "Search dataset": "Buscar dataset",
            "Search Dataset": "Buscar Dataset",
            "Search Group": "Buscar Grupo",
            "Search Member": "Buscar Miembro",
            "search site": "buscar sitio",
            "Search User": "Buscar Usuario",
            "Select Dataset": "Seleccione un Dataset",
            "Select Layer Type": "Seleccione un tipo de layer",
            "Select Column to Edit": "Seleccione Columna a Editar",
            "Select Role Member": "Seleccione el Rol del Miembro",
            "Select Title and Subtitle columns" : "Identifique las columnas para usar como Título y Subtítulo cuando se visualizan los datos.",
            "Select Site": "Seleccionar Sede",
            "Select a file with xls, xlsx, csv or geojson extension.": "Seleccione un archivo con extensión xls, xlsx, csv o geojson. Es necesario que contengan datos georeferenciados. Los archivos geojson deben contener propiedades geometry y coordenadas (Point, Linestring, Polygon, otros). Los archivos xls, xlsx y csv deben contener nombres o títulos de columnas que empiecen con Lat (Latitud) y Long (Longitud).",
            "Select the separator of the fields in the csv file, by default it will use the comma.": "Seleccione el separador de los campos del archivo csv, por defecto utilizara la coma.",
            "Semicolon": "Punto y Coma",
            "Sentinel Settings": "Configuración de Sentinel",
            "Separator": "Separador",
            "Settings": "Configuración",
            "Share Map": "Compartir Mapa",
            "Site": "Sede",
            "Sites": "Sedes",
            "Site not found": "Sitio no encontrado",
            "Spanish": "Español",
            "Styles": "Estilos",
            "Start creating a map": "Empieza creando un mapa",
            "Storage": "Almacenamiento",
            "Sorry, an unexpected error has ocurred": "Ha ocurrido un error inesperado",
            "Subject": "Asunto",
            "Subtitle": "Subtítulo",
            "Success": "Exito",
            "Temperature": "Temperatura",
            "The Annotation has been created": "La Anotación ha sido creada",
            "The Annotation has been deleted": "La Anotación ha sido eliminada",
            "The Annotation has been updated": "La Anotación ha sido actualizada",
            "The file you selected is too large": "El archivo seleccionado es demasiado grande",
            "The Dataset has a Map associated": "El Dataset tiene un Mapa asociado",
            "The Group has a Dataset associated": "El Grupo tiene un Dataset asociado",
            "The Group has a Map associated": "El Grupo tiene un Mapa asociado",
            "The number of rows in the Dataset exceeds the maximum allowed.": "La cantidad de filas del dataset supera el máximo permitido.",
            "Title": "Título",
            "Title Helper New Dataset": "Es el título que identifica al dataset en las búsquedas y usos. Ejemplo: Principales Puertos del Mundo por Países 2022",
            "Title is required.": "Se requiere un título",
            "Tools": "Herramientas",
            "Type": "Tipo",
            "Type of Dataset": "Tipo del Dataset",
            "Unknown": "Desconocido",
            "Update": "Actualizar",
            "Update Dataset Settings": "Actualizar Configuración del Dataset",
            "Upload Data": "Subir Datos",
            "Upload Dataset": "Cargar Dataset",
            "Upload File": "Cargar Archivo",
            "Upload Files": "Cargar Archivos",
            "Upload Successfully": "Cargado Correctamente",
            "Uploaded File": "Archivo Cargado",
            "Update user data": "Actualizar datos de usuario",
            "Update successful":"Actualización exitosa",
            "Upload a dataset": "Sube un dataset",
            "Users": "Usuarios",
            "Users/Members": "Usuarios/Miembros",
            "User Profile": "Perfil de Usuario",
            "User name": "Nombre de usuario",
            "With Akopica you can create maps with different types of information layers stored in datasets.": "Con Akopica puedes crear mapas con distintos tipos de capas de información almacenadas en datasets.",
            "View Notes": "Ver Notas", 
            "Yes": "Si",
          }
      }
    },
    fallbackLng: "es",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
