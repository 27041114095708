import React, { useEffect, useState } from 'react';
import {
    Theme,
    Typography,
    Button,
    Grid,
    Box,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { authLogin, setAppUser, useAppContext} from '../AppContext';
import { Trans } from "react-i18next";
import { accessWithEmailToken } from './services';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { AppError } from '../models/common';
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '100%',
        },
        row: {
            padding: "1ch",
        },
        actions: {
            padding: "1ch",
            textAlign: "right",
        },
        separator: {
            display: "inline-block",
            minWidth: "70px",
        },
        leftPanel: {
            height: "100vh",
            backgroundColor: "#292e42",
            backgroundImage: "url(/assets/images/login-b.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    }),
);

interface EmailAccessProps extends RouteComponentProps {
    emailToken?: string;
}

function AccessWithEmailTokenPage(props: EmailAccessProps) {
    const classes = useStyles();
    const navigate = useNavigate();
    const app = useAppContext();
    const [error, setError] = useState<AppError>();

    const recaptchaRef = React.useRef<ReCAPTCHA>(null);

    const handleLoginWithEmail = async () => {

        const token = (recaptchaRef.current != null) ? await recaptchaRef.current.executeAsync() : null;

        return accessWithEmailToken(props.emailToken!, token)
            .then(async userInfo => {
                if (userInfo.access_token) {
                    setAppUser(userInfo.user);
                    authLogin(userInfo.access_token);
                    app.setLayout({
                        ...app.layout,
                        user: userInfo.user,
                        groups: userInfo.groups,
                        currentGroup: userInfo.groups ? userInfo.groups[0] : null,
                    })
                    navigate("/home");
                } else {
                    alert("Invalid User/Password");
                }
            })
            .catch(err => {
                setError(err.error as AppError);
            });
    }

    useEffect(() => {
        //logout();
        handleLoginWithEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={0} className={classes.container}>
            <Grid item lg={4} className={classes.leftPanel}>
                <p></p>
            </Grid>
            <Grid item lg={8} xs={12} sx={{marginTop: "12vh"}}>
                <Box style={{margin: "auto", maxWidth: 420}}>
                    { !error && 
                        <Box className={classes.container}>
                            <Typography variant="h5"><Trans>Please wait to enter Akopica...</Trans></Typography>
                        </Box>
                    }
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY || "6LdQjcsbAAAAABVtk3QZB8tkyMfcZ3W4tSnVwLy-"}
                        size="invisible"
                        ref={recaptchaRef}
                    />
                    { error?.name === "LoginTokenExpired" && 
                        <Box className={classes.container}>
                            <Typography variant="h5"><Trans>Your signin link has expired</Trans></Typography>
                            <Box className={classes.separator}></Box>

                            <Typography variant="body1"><Trans>Go to signin page and we’ll send a new link to your inbox.</Trans></Typography>
                            <Box className={classes.separator}></Box>

                            <Box className={classes.row}>
                                <Button variant="contained" component={Link} to="/auth/signin"><Trans>Go to signin page</Trans></Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export { AccessWithEmailTokenPage };
