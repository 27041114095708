import React, { useCallback, useEffect, useState } from 'react';
import {
    Theme,
    Typography,
    Button,
    Grid,
    Box,
    Toolbar,
    AppBar,
    Paper,
    styled,
    TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from "lodash";
import useLoading from '../lib/hooks/useLoading';
import { getPublicMapsBySite, getPublicSite } from './services';
import { Page } from '../models/common';
import { MapAkopica } from '../models/map';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';
import { Site } from '../models/group';
import lightBlue from '@mui/material/colors/lightBlue';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate } from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
    ({
        toolbar: {
            paddingRight: 24,
        },
        logo: {
            maxWidth: "120px",
            marginRight: "16px",
            marginTop: "4px",
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            maxWidth: "1280px",
            marginLeft: "auto",
            marginRight: "auto"
        },
        textSearch: {
            width: "90%",
        },
        imageBox: {
            maxWidth: "225px",
            maxHeight: "175px",
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "10px"
        },
        image: {
            width: "100%"
        }
    }),
);

export const MapTitle = styled(Typography)(({ theme }) => ({
    textTransform: "uppercase",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: "10px",
    minHeight: "80px",
    maxHeight: "80px",
    fontWeight: "bold",
    overflow: "hidden",
}));

export const MapDesc = styled(Typography)(({ theme }) => ({
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "35px",
    maxHeight: "130px",
    overflow: "hidden",
}));

export const MapPaper = styled(Paper)(({ theme }) => ({
    cursor: "pointer",
    maxWidth: "260px",
    backgroundColor:  lightBlue['50'],
    '&:hover': {
        backgroundColor:  lightBlue['100'],
    }
}));

interface InputState {
    q: string,
    page: number,
    pageSize: number,
    order: string,
}


function MapDashboardPage(props: any) {
    const classes = useStyles();
    const PAGE_SIZE = 6;
    const [loading, setLoading] = useLoading(false);
    const navigate = useNavigate();
    const [site, setSite] = useState<Site | null>(null);
    const [mapPage, setMapPage] = useState<Page<MapAkopica>| null>(null);

    const [q, setQ] = React.useState<string>("");
    const [input, setInput] = React.useState<InputState>({
        page: 1,
        pageSize: 8,
        order: "recent",
        q: "",
    });

    const searchPublicSite = (siteName: string) => {
        setLoading(true);
        getPublicSite(siteName)
            .then(async site => {
                setSite(site);
                searchQ(siteName, input);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    const searchPublicMapsBySite = (siteName: string, values: InputState) => {
        setLoading(true);
        const params = {
            page: values.page,
            pageSize: values.pageSize,
            order: values.order,
            q: values.q
        }
        getPublicMapsBySite(siteName, params)
            .then(async page => {
                setMapPage(page);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    const searchQ = (siteName: string, params: InputState) => {
        searchPublicMapsBySite(siteName, params);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlerChangeQ = useCallback(debounce((filter)=>searchQ(props.siteName, input), 300), []);
    
    const changeQ = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQ(event.target.value);
        const val = event.target.value.trim();

        if (val.length > 2) {
            const newInput = input;
            newInput.page = 1;
            newInput.q = event.target.value.trim();
            setInput(newInput);
            handlerChangeQ(newInput);
        }

        if (val === "") {
            const newInput = input;
            newInput.page = 1;
            newInput.q = "";
            setInput(newInput);
            handlerChangeQ(newInput);
        }
    }


    // pagination
    const handleNext = () => {
        const nPages = Math.ceil((mapPage?.count || 0) / PAGE_SIZE);
        if (input.page < nPages) {
            setInput({ ...input, page: input.page + 1 });
            searchPublicMapsBySite(props.siteName, input);
        }
    };
    const handlePrev = () => {
        if (input.page > 1) {
            setInput({ ...input, page: input.page - 1 });
            searchPublicMapsBySite(props.siteName, input);
        }
    };

    const gotoMap = (map: MapAkopica) => {
        navigate(`/public/map/${map.id}`);
    };


    useEffect(() => {
        if (!props.siteName) {
            return;
        }

        const name = props.siteName;
        searchPublicSite(name);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <AppBar position="absolute" >
                <Toolbar className={classes.toolbar} disableGutters={true}>
                    <Box sx={{ marginLeft: "40px" }}>
                        <img alt="logo" className={classes.logo} src="/assets/images/logo-b.png" />
                    </Box>
                    <Box sx={{ flexGrow: 2 }}></Box>
                    <Box>
                        {site?.title}
                    </Box>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <Box sx={{ flexGrow: 1, marginTop: "100px", marginLeft: "20px", marginRight: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} xs={12}>
                            <Typography component="div" variant="h5" color="inherit" noWrap>
                                {site?.title}
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <TextField
                                className={classes.textSearch}
                                id="q"
                                type="text"
                                label="Buscar"
                                value={q}
                                onChange={changeQ}
                                variant="outlined"
                            />
                        </Grid>
                        {
                            (mapPage == null || mapPage.page == null || mapPage.page.length < 1) && !loading &&
                            <Grid item lg={4} xs={12}>
                                No se encontraron mapas públicos.
                            </Grid>
                        }
                        { 
                            mapPage?.page.map((map, i) => {
                                return (
                                    <Grid item lg={4} xs={12} key={i}>
                                        <MapPaper elevation={3} sx={{minHeight: "50px"}} onClick={e => gotoMap(map)}>
                                            <MapTitle>{map.title}</MapTitle>
                                            <Box className={classes.imageBox}>
                                                <img src="/assets/images/not-found-icon.png" 
                                                     alt="/assets/images/not-found-icon.png"
                                                     className={classes.image} />
                                            </Box>
                                            <MapDesc>{map.detail}</MapDesc>
                                        </MapPaper>
                                    </Grid>
                                );
                            })
                        }
                        <Grid item lg={12} xs={12}>
                            <Button onClick={handlePrev} startIcon={<ChevronLeft />}>
                                Anterior
                            </Button>
                            <Button onClick={handleNext} endIcon={<ChevronRight />}>
                                Siguiente
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {loading && <LoadingProgress></LoadingProgress>}
            </main>
        </Box>
        
    )
}

export { MapDashboardPage };
