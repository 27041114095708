import React, { useState, useEffect } from "react";
import { Theme, FormControlLabel, Switch, Typography, Divider, Grid, Box, Tabs, Tab, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import { Trans, useTranslation } from "react-i18next";
import clsx from "clsx";
import AnnotationBrowser from "./AnnotationBrowser";
import { ColorPicker } from "../../../../lib/components/ColorPicker/ColorPicker";

const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(2)
        },
        marginTop: {
            marginTop: theme.spacing(1),
        },
        settingsTitle: {
            marginLeft: theme.spacing(1),
        },
        tabs: {
            backgroundColor: '#aed1cd',
        },

    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ marginTop: '-8px' }}>
                <br></br>
                {children}
            </Box>
        )}
        </div>
    );
}

interface MyProps {
    map: any;
    layer: any;
    mapCurr?: any;
    editable?: boolean;
    onClose: Function;
    onChangeLayer: Function;
    onDrawerClose: Function;
    changeSubtitle: Function;
}

function AnnotationSettings(props: MyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = React.useState({
        edit: props.layer.settings?.annotation?.edit || false,
        color: props.layer.settings.color || '#3388ff',
    });
    const [tab, setTab] = useState<number>(0);
    const [editColor, setEditColor] = React.useState<boolean>(false);

    const handleChangeColor = (color: string) => {
        setValues({ ...values, color: color });
        props.layer.settings.color = color;
        setEditColor(false);
        props.onChangeLayer(props.layer);
    };

    const handleOpenEditColor = () => {
        setEditColor(true);
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    function handleDisabledBasic() {
        if (props.layer.settings.annotation.edit){
            setValues({...values, edit: false})
            props.layer.settings.annotation.edit = false;
            props.onChangeLayer(props.layer);
        }
    }
    
    
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
       
        const name = event.target.name;
        setValues({ ...values, [name]: event.target.checked });

        props.layer.settings.annotation = props.layer.settings.annotation || {};
        if (name === "edit") {
            props.layer.settings.annotation.edit = event.target.checked;
        }

        props.onChangeLayer(props.layer);
        
    };

    // inicializacion
    useEffect(() => {
        // setValues(props.layer.settings.annotation);
        if(props.editable) {
            props.layer.settings.annotation.edit = true;
            setValues({...values, edit: true});
            props.onChangeLayer(props.layer, "annotation::edit", true);
        }
        return () => {
            // close edit 
            if(props.editable) {
                props.layer.settings.annotation.edit = false;
                setValues({...values, edit: false});
                props.onChangeLayer(props.layer, "annotation::edit", false);
            }        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box style={{ padding: '0px' }}>
            <Tabs
                className={classes.tabs}
                indicatorColor="secondary"
                value={tab}
                onChange={handleChangeTab}
                variant="fullWidth"
            >
                <Tab label={t('Browse')}></Tab>
                {props.editable && <Tab label={t('Settings')}></Tab>}
            </Tabs>
            <TabPanel value={tab} index={0}>
                <AnnotationBrowser layer={props.layer} mapCurr={props.mapCurr}/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <React.Fragment>
                    <SettingsDetail {...props} allowNote={false} disableNoteEdit={true} handleDisabledBasic={handleDisabledBasic}></SettingsDetail>
                    <Divider/>
                    <Grid container spacing={1}>
                        <Grid item lg={12} xs={12} md={12} sx={{marginTop: '8px',  marginLeft: '8px',}}>
                            <Typography className={clsx(classes.settingsTitle, classes.marginTop)} variant="subtitle2"><Trans>Figures</Trans></Typography>
                        </Grid>
                        <Grid item lg={11} xs={12} md={12} sx={{marginBottom: '8px', marginLeft: '24px',}}>
                            <FormControlLabel 
                                label={<Typography variant="body2" color="textSecondary"><Trans>Edit</Trans></Typography>}
                                control={
                                    <Switch
                                        size="small"
                                        checked={values.edit}
                                        onChange={handleChange}
                                        disabled={!props.layer.settings.basic.active}
                                        name="edit"
                                        inputProps={{ 'aria-label': 'edit notes' }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                        <InputLabel id="label">Color</InputLabel>
                            </Grid>
                            {!editColor && (
                                <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                                    <ColorPicker mode="view" color={values.color} onOpenEdit={handleOpenEditColor} />
                                </Grid>
                            )}
                            {editColor && (
                                <Grid item lg={12} xs={12} md={12} sx={{ marginBottom: '8px', marginLeft: '24px' }}>
                                    <ColorPicker mode="picker" onSelectColor={handleChangeColor} />
                                </Grid>
                            )}
                    </Grid>
                </React.Fragment>
            </TabPanel>
        </Box>
    )
}

export default AnnotationSettings;
