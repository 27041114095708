import React, { FC, useEffect, useState } from 'react';
import {
  Theme,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
  Tab,
  Tabs,
  Box,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from '../../settings/SettingsBasic';
import AddNewAssetChild from './AddNewAssetChild';
import AssetBrowse from './AssetBrowse';
import { Trans, useTranslation } from 'react-i18next';
import { Asset } from '../../../../models/asset';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsRow: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    settingsTitle: {
      margin: theme.spacing(2),
      marginLeft: 0,
    },
    tabs: {
      backgroundColor: '#aed1cd',
    },
  })
);

interface MyProps {
  map: any;
  layer: any;
  onClose: Function;
  onChangeLayer: (layer: any, changed?: string, value?: any) => void;
  changeSubtitle: Function;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface AssetPage {
  count: number;
  page: Asset[];
}
export interface AssetProps {
  id: string;
  parentId?: string;
  title: string;
  page: number;
  pageSize: number;
  q?: string;
  children: AssetPage;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop: '-8px' }}>
          <br></br>
          {children}
        </Box>
      )}
    </div>
  );
}

const AssetSettings: FC<MyProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  const [mode, setMode] = useState<'Empty' | 'NewAssetChild'>('Empty');

  const [asset, setAsset] = useState<Asset>(props.layer.asset);
  const [values, setValues] = useState({
    edit: props.layer.settings?.asset?.edit || false,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    setValues({ ...values, [name]: event.target.checked });

    props.layer.settings.asset = props.layer.settings.asset || {};
    if (name === 'edit') {
      props.layer.settings.asset.edit = event.target.checked;
    }

    props.onChangeLayer(props.layer, 'asset::' + name, event.target.checked);
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  // inicializacion
  useEffect(() => {
    setValues(props.layer.settings.asset);
    return () => {
      // close edit
      props.layer.settings.asset.edit = false;
      setValues({ ...values, edit: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: '0px' }}>
      {mode === 'Empty' && (
        <>
          <Tabs
            className={classes.tabs}
            indicatorColor="secondary"
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            <Tab label={t('Browse')}></Tab>
            <Tab label={t('Settings')}></Tab>
          </Tabs>

          {/*Browse Tab*/}
          <TabPanel value={tab} index={0}>
            <AssetBrowse setMode={setMode} asset={asset} setAsset={setAsset} />
          </TabPanel>
          {/*Settings Tab*/}
          <TabPanel value={tab} index={1}>
            <SettingsDetail
              {...props}
              allowNote={true}
              disableNoteEdit={values.edit}
            />
            <Divider />
            <Typography sx={{ margin: '8px' }} variant="subtitle2">
              <Trans>Figures</Trans>
            </Typography>
            <div className={classes.settingsRow}>
              <FormControlLabel
                label={
                  <Typography variant="body2" color="textSecondary">
                    <Trans>Edit</Trans>
                  </Typography>
                }
                control={
                  <Switch
                    size="small"
                    checked={values.edit}
                    onChange={handleChange}
                    disabled={props.layer.settings.basic.noteEdit}
                    name="edit"
                    inputProps={{ 'aria-label': 'edit notes' }}
                  />
                }
              />
            </div>
          </TabPanel>
        </>
      )}
      {mode === 'NewAssetChild' && (
        <AddNewAssetChild setMode={setMode} asset={asset} setAsset={setAsset} />
      )}
    </div>
  );
};

export default AssetSettings;
