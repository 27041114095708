import React from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
    }),
);

function ErrorMsg(props: any) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            Error: {props.error.message} 
        </div>
    );
  }

export default ErrorMsg;
