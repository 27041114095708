import { useMap } from "react-leaflet";
import L from "leaflet";
//import "leaflet-side-by-side";
import { useEffect, useState, useRef } from "react";
import { BACKEND_URL } from '../../../../lib/config';

const LayerSideBySide = (props) => {
    const { map } = useMap();
    const [sideBySideLayer, setSideBySideLayer] = useState();
    const [layer1Map, setLayer1Map] = useState();
    const [layer2Map, setLayer2Map] = useState();

    const layer1Ref = useRef();
    const layer2Ref = useRef();

    useEffect(() => () => {
        layer1Ref.current = layer1Map;
    },[layer1Map]);

    useEffect(() => () => {
        layer2Ref.current = layer2Map;
    },[layer2Map]);

    useEffect(() => {

        let layer1 = createLayer(props.layer.settings.compare.layer1);
        layer1.addTo(map);
        let layer2 = createLayer(props.layer.settings.compare.layer2);
        layer2.addTo(map);

        let sideBySideLayer = L.control.sideBySide(layer1, layer2);
        sideBySideLayer.addTo(map);

        setLayer1Map(layer1);
        setLayer2Map(layer2);
        setSideBySideLayer(sideBySideLayer);

        // returned function will be called on component unmount 
        return () => {
            map.removeControl(sideBySideLayer);

            layer1=layer1Ref.current;
            map.removeLayer(layer1);
            layer2=layer2Ref.current;
            map.removeLayer(layer2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function createLayer(layerToCompare) {
        let l = props.layers.find((ly) => ly.id === layerToCompare);
        let datasetid = l.dataset.id;
        let path = l.dataset.path;

        let url = `${BACKEND_URL}/v1/datasets/${datasetid}/files/${path}/{z}/{x}/{y}.png`;

        let layer = L.tileLayer.wms(url, {
            maxZoom: 23,
            transparent: true,
            opacity: 1,
            tms: true
        });
        return layer;
    }

    useEffect(() => {

        if (sideBySideLayer) {
            map.removeLayer(layer1Map);

            let layer1 = createLayer(props.layer.settings.compare.layer1);
            layer1.addTo(map);

            setLayer1Map(layer1);
            sideBySideLayer.setLeftLayers(layer1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.layer.settings.compare.layer1])

    useEffect(() => {
        if (sideBySideLayer) {
            map.removeLayer(layer2Map);

            let layer2 = createLayer(props.layer.settings.compare.layer2);
            layer2.addTo(map);

            setLayer2Map(layer2);
            sideBySideLayer.setRightLayers(layer2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.layer.settings.compare.layer2])

    return null;
};

export default LayerSideBySide;
