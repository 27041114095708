import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Theme, Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Trans } from "react-i18next";
import { getFeatureData } from "../../../../features/services";
import { LoadingProgress } from "../../../../lib/components/LoadingProgress/LoadingProgress";
import useLoading from "../../../../lib/hooks/useLoading";

const useStyles = makeStyles((theme: Theme) =>
    ({
        row: {
            marginBottom: theme.spacing(2),
        },
        inline: {
            margin: theme.spacing(1),
            display: "inline-block",
        },
        fieldBox: {
            width: 245,
            marginBottom: theme.spacing(1.5),
            [theme.breakpoints.up(400)]: {
                width: "100%",
            },
        },
        fieldSize: {
            width: "50%"
        }
    }),
);

interface MyProps {
    layer: any,
    open: boolean,
    featureLayer?: any,
    onClose: any, // TODO what type???
    onSave: Function, 
}

function FeatureDialog(props: MyProps) {
    const classes = useStyles();
    const [open] = useState(props.open);
    const [feature, setFeature] = React.useState<any>({});
    const [loading, setLoading] = useLoading(false);

    const isValidUrl = (urlString: string) => {
        let urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

  const urlWithHttp = (urlString: string) => {
    const hasHttp = urlString.startsWith('http://') || urlString.startsWith('https://');
    if (!hasHttp) return `http://${urlString}`;

    return urlString;
  }
    
    const widgets: JSX.Element[] = [];

    /*
    function handleSave(event: MouseEvent) {
        const layer: any = props.featureLayer;
        console.log("layer: ", layer);
        layer.feature.properties.point = point;
        props.onSave(event);
        props.onClose(event);
    }
    */

    const loadFeatureData = (uid: string, id: number) => {
        setLoading(true);
        getFeatureData(uid, id)
            .then(async data => {
                setFeature(data);
            })
            .catch(error => {
                setFeature(null);
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    const findTitle = (name: string): string => {
        for (const col of props.layer.dataset.settings.columns) {
            if  (col.name === name) {
                return col.title;
            }
        }
        return name;
    }


    useEffect(() => {
        const featureLayer: any = props.featureLayer;
        const layer: any = props.layer;

        //setFeature(featureLayer.featureProperties || {});
        
        console.log("Feature Dialog: ", [props, open]);
        loadFeatureData(layer.id, featureLayer.featureProperties["_id"]);
        //console.log("Feature: ", layer.featureProperties);
        //setCsvValues(layer.csvValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadFields = () => {
        //console.log("LOADING CUSTOM FIELDS");
        let comp;
        const maxValueLength = 60;

        for (const key in feature) {
            // console.log("keylist", customFieldList[i]);
            const title = findTitle(key);
            comp =  <Box key={key} className={classes.fieldBox}>
                        { title.length > 30 && 
                            <TextField variant="outlined" color="secondary" size="small" fullWidth className={classes.fieldSize} value={title}
                            sx={{backgroundColor: "rgba(0, 0, 0, 0.06)"}}/>
                        }
                        { title.length <= 30 && 
                            <TextField variant="outlined" color="secondary" size="small" className={classes.fieldSize} value={title}
                            sx={{backgroundColor: "rgba(0, 0, 0, 0.06)"}}/>
                        }
                        {feature[key].split(' ').length === 1 && isValidUrl(feature[key]) && 
                            <Button
                                variant="outlined"
                                href={urlWithHttp(feature[key])}
                                target="_blank"
                                rel="noopener"
                                className={classes.fieldSize}
                                sx={{justifyContent: "flex-start"}}
                            >
                                <Typography
                                    className={classes.fieldSize}
                                    variant="subtitle1"
                                    color="primary"
                                >
                                    {feature[key]}
                                </Typography>
                            </Button>
                        }
                        {(""+ feature[key]).length > maxValueLength && !isValidUrl(feature[key]) &&
                            <TextField variant="outlined" size="small" fullWidth multiline className={classes.fieldSize} value={feature[key]}/>
                        }
                        {(""+ feature[key]).length <= maxValueLength && !isValidUrl(feature[key]) &&
                            <TextField variant="outlined" size="small" multiline className={classes.fieldSize} value={feature[key]}/>
                        }
                        
                    </Box>
            widgets.push(comp);
        }

        // widgets.push(comp);
        
    };

    loadFields();


    return (
        <Dialog open={open} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
            
            <DialogTitle>
                {
                    (props.featureLayer?.featureProperties["title"] != null) 
                    ? props.featureLayer?.featureProperties["title"]
                    : props.layer.title
                }
            </DialogTitle>
            <DialogContent>
                { feature != null && widgets.map((w: any) => { return w })}
                {loading && <LoadingProgress></LoadingProgress>}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    <Trans>Close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FeatureDialog;
