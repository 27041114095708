import React, { MouseEvent, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Theme, MenuItem, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { Layer } from "leaflet";
import { ShapeSchema } from "./ShapeSchema";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        dialog: {
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        inline: {
            margin: theme.spacing(1),
            display: "inline-block",
        },
        fieldBox: {
            width: 245,
            marginBottom: theme.spacing(1.5),
            [theme.breakpoints.up(400)]: {
                width: "100%",
            },
        },
        fieldSize: {
            width: "50%"
        }
    }),
);

interface MyProps {
    layer: any,
    open: boolean,
    featureLayer?: Layer,
    onClose: any, // TODO what type???
    onSave: Function, 
}

interface CustomField {
    label: string,
    value: any,
}

function ShapeDialog(props: MyProps) {
    const classes = useStyles();
    const [open] = useState(props.open);
    const defval: {[key: string]: any} = {};
    const [shape, setShape] = React.useState(defval);
    const [customFieldList, setCustomFieldList] = React.useState<CustomField[]>([]);

  
    const handleSave = (event: MouseEvent) => {
        if (props.layer.shape?.schema == null) {
            handleSaveCustomFields();
        } else {
            const layer: any = props.featureLayer;
            layer.feature.properties = shape;
        }
        
        props.onSave(event);
        props.onClose(event);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setShape({ ...shape, [name]: event.target.value });
    
        // props.onChangeLayer(props.layer, "basic::" + name, event.target.checked);
    };

    useEffect(() => {
        const layer: any = props.featureLayer;
        setShape(layer.feature?.properties || {});

        if (props.layer.shape?.schema == null) {
            buildCustomFields(layer.feature?.properties);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const widgets: JSX.Element[] = [];
    
    const loadSchema = () => {
        const fields = (props.layer.shape?.schema as ShapeSchema)?.fields || [];
        // console.log("schema", props.layer);

        for (let field of fields) {
            const widget = field.widget || "default";
            const fieldOptions =  field.options || [];
            const width = field.width || "48ch";
            const variant = widgets.length === 0 ? "filled" : "outlined";
            let comp;
    
            if (field.type === "string" && widget === "default") {
                const cls = classes.row;
                comp = 
                    <div key={field.name} className={cls}>
                        <TextField
                            label={field.label}
                            id={field.name}
                            name={field.name}
                            variant={variant}
                            size="small"
                            value={shape[field.name] || ""}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if (field.type === "string" && widget === "multiline") {
            const cls = classes.row;
            comp =
                <div key={field.name} className={cls}>
                    <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        size="small"
                        multiline
                        rows={field.rows || 6}
                        value={shape[field.name] || ""}
                        variant={variant}
                        onChange={handleChange}
                        style={{
                            width: width,
                        }}
                    />
                </div>
            } else if (field.type === "float" && widget === "default") {
                const cls = classes.row;
                comp =
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            size="small"
                            type="number"
                            value={shape[field.name] || ""}
                            variant={variant}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if (field.type === "int" && widget === "default") {
                const cls = classes.row;
                comp = 
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            label={field.label}
                            size="small"
                            type="number"
                            value={shape[field.name] || ""}
                            variant={variant}
                            onChange={handleChange}
                            style={{
                                width: width,
                            }}
                        />
                    </div>
            } else if ((field.type === "string" || field.type === "int" ) && widget === "select") {
                const cls = classes.row;
                comp = 
                    <div key={field.name} className={cls}>
                        <TextField
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            size="small"
                            select
                            value={shape[field.name] || ""}
                            onChange={handleChange}
                            helperText=""
                            variant={variant}
                            style={{
                                width: width,
                            }}
                        >
                        {fieldOptions.map((option) => (
                            <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                            </MenuItem>
                        ))}
                        </TextField>
                    </div>
            } else {
                comp = <div><Trans>Unknown</Trans></div>
            }
    
            widgets.push(comp);
        }
    };

    const buildCustomFields = (properties: any) => {
        const initCustomFieldList = [];
        for (const field in properties) {
            const cf: CustomField = {
                label: field,
                value: properties[field]
            }
            initCustomFieldList.push(cf);
        }
        if (initCustomFieldList.length === 0 ) {
            const newCF: CustomField = {
                label: "",
                value: ""
            }
            initCustomFieldList.push(newCF);
        }
        setCustomFieldList(initCustomFieldList);
    };

    const handleChangeField = (input: string, pos: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // console.log("change", input, pos, event.target.value);
        if (input === "label") {
            let tempList: CustomField[] = [];
            Object.assign(tempList, customFieldList);
            tempList[pos].label = event.target.value;
            setCustomFieldList(tempList);
        } else {
            let tempList: CustomField[] = [];
            Object.assign(tempList, customFieldList);
            tempList[pos].value = event.target.value;
            setCustomFieldList(tempList);
        }
    };

    const checkEmptiesField = (): number[] => {
        const emptyIndexList = [];
        for (let i = 0; i < customFieldList.length; i++ ) {
            if (customFieldList[i].label.trim() === "") {
                emptyIndexList.push(i);
            }
        }

        return emptyIndexList;
    };

    const handleAddField = () => {
        if (checkEmptiesField().length > 0) {
            return;
        }
        let tempList: CustomField[] = [];
        Object.assign(tempList, customFieldList);
        const newCF: CustomField = {
            label: "",
            value: ""
        }
        tempList.push(newCF);
        setCustomFieldList(tempList);
    };

    const handleSaveCustomFields = () => {
        const newJsonFields: any = {};
        for (let i = 0; i < customFieldList.length; i++ ) {
            if (customFieldList[i].label.trim() !== "") {
                newJsonFields[customFieldList[i].label.trim()] = customFieldList[i].value.trim();
            }
        }
        const layer: any = props.featureLayer;
        layer.feature.properties = newJsonFields;
    };

    const loadCustomFields = () => {
        // console.log("LOADING CUSTOM FIELDS", shape);
        let comp;
        for (let i = 0; i < customFieldList.length; i++ ) {
            // console.log("keylist", customFieldList[i]);
            comp =  <Box key={i} className={classes.fieldBox}>
                        <TextField variant="outlined" size="small" className={classes.fieldSize} value={customFieldList[i].label} onChange={e => handleChangeField("label", i, e)}/>
                        <TextField variant="outlined" size="small" className={classes.fieldSize} value={customFieldList[i].value} onChange={e => handleChangeField("value", i, e)}/>
                    </Box>
            widgets.push(comp);
        }
        comp = <Box key="buttonAdd">
                    <Button  color="primary" startIcon={<AddIcon />} onClick={handleAddField}><Trans>Add</Trans></Button>
                </Box>
        widgets.push(comp);
        
    };
    
    
    if (props.layer.shape?.schema != null) {
        loadSchema();
    } else {
        loadCustomFields();
    }
    
    


    return (
        <Dialog className={classes.dialog} open={open} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
            <DialogTitle>{props.layer.title}</DialogTitle>
            <DialogContent>
                { widgets.map((w: any) => { return w })}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    <Trans>Close</Trans>
                </Button>
                <Button onClick={handleSave} color="primary">
                    <Trans>Save</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShapeDialog;
