import React from 'react';
import DatasetListItem from './DatasetListItem';
import { Theme, List, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { SearchResponse } from "./DatasetsPage"
import { Dataset } from '../models/dataset'
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: SearchResponse,
    next: any,
    prev: any,
}

function DatasetList(props: MyProps) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <List className={classes.root}>
                { 
                    props.data.page.map((dataset: Dataset) => {
                        return <DatasetListItem key={dataset.id} data={dataset}/>
                    })
                }
            </List>
            <Box>
                <Button onClick={props.prev} startIcon={ <ChevronLeft/> }><Trans>Previous</Trans></Button>
                <Button onClick={props.next} endIcon={ <ChevronRight/> }><Trans>Next</Trans></Button>
            </Box>

        </React.Fragment>
    );
  }

export default DatasetList;
