/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

export default function ComboBoxLayer(props:any) {

    const handleChange = (event:any, values:any) => {
        props.handleChange(props.name, values);
    }

    return (
            <Autocomplete
                id={props.name}
                options={props.layers.filter((ly:any) => ly.lytype==="tile")}
                onChange={handleChange}
                defaultValue={props.layers.find((ly:any) => ly.id===props.layerid)}
                getOptionLabel={(option:any) => option.title}
                renderInput={(params) => <TextField {...params} label={props.label} variant="outlined"  />}
            />
    );
}
