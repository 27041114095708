import React, { useState } from 'react';
import clsx from "clsx";
import {
    Theme,
    TextField,
    Typography,
    Button,
    Divider,
    Link as MatLink,
    Grid,
    Box,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useNavigate } from "@reach/router";
import { Trans, useTranslation } from "react-i18next";
import { loginWithEmail } from './services';
import useLoading from '../lib/hooks/useLoading';
import { LoadingRight } from '../lib/components/LoadingProgress/LoadingProgress';
import { Alert } from '@mui/lab';
import { AppError } from '../models/common';
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: "100vh",
            maxWidth: "unset",
            padding: 0,
        },
        leftPanel: {
            height: "100vh",
            backgroundColor: "#292e42",
            backgroundImage: "url(/assets/images/login-b.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        body: {
            margin: "auto",
            maxWidth: 420,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 20,
                marginRight: 20
            },
        },
        title: {
            marginLeft: 9
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '100%',
        },
        row: {
            padding: "1ch",
        },
        actions: {
            padding: "1ch",
            textAlign: "right",
        },
        separator: {
            display: "inline-block",
            minWidth: "70px",
        },
        logoCellBox:{
            position: "absolute",
            width: '100%',
            height: 75,
            top: 0,
            left: 0,
            backgroundColor: "#292e42",
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        logoCell: {
            position: "relative",
            width: 200,
            marginTop: 15,
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
            backgroundImage: "url(/assets/images/logo-b.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
        }
    }),
);

interface FormState {
    email: string;
    emailError?: string;
};

function LoginPage(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const {t } = useTranslation(); 
    const [loading, setLoading] = useLoading(false);
    const [error, setError] = useState<AppError>();
    const [values, setValues] = useState<FormState>({
        email: '',
    });

    const recaptchaRef = React.useRef<ReCAPTCHA>(null);

    const handleChange = (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    /*
    const handleReLogin = () => {
        setValues({ email: '' });
    };
    */

    const login = async (email: string) => {
        setLoading(true);

        const token = (recaptchaRef.current != null) ? await recaptchaRef.current.executeAsync() : null;

        loginWithEmail(email, token)
        .then(async user => {
            navigate("/auth/signin/sent", { state: { email: values.email } });
        })
        .catch(err => {
            setError(err.error as AppError);
            if (err.error && err.error.name === "UserNotFound") {
                navigate("/auth/signin/notfound", { state: { email: values.email } });
            }
            console.error('Error:', err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleLoginWithEmail = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const emailRe = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

        if (!emailRe.test(values.email)) {
            setValues({...values, emailError: t("Please enter a valid email address.")});
            return;
        }

        setValues({...values, emailError: undefined });
        login(values.email);
    }

    return (
        <Grid container spacing={0} className={classes.container}>
            <Grid item lg={4} className={classes.leftPanel}>
                <p></p>
            </Grid>
            <Grid item lg={8} xs={12} sx={{marginTop: "12vh"}}>
                <Box>
                    <Box style={{ margin: "auto", maxWidth: 420 }}>
                        { error?.code==="500" && <Alert severity="error"><Trans>Sorry, an unexpected error has ocurred</Trans></Alert> }
                        <Box className={classes.logoCellBox}>
                            <p className={classes.logoCell}></p>
                        </Box>
                        <Typography variant="h4" className={classes.title}><Trans>Signin with email</Trans></Typography>
                        <Box className={classes.row} />
                        <Box className={classes.row} />
                        <Box className={classes.row}>
                            <TextField
                                className={clsx(classes.textField)}
                                required
                                id="email"
                                label="Email"
                                value={values.email}
                                onChange={handleChange("email")}
                                variant="outlined"
                                helperText={values.emailError}
                                error={values.emailError != null}
                            />
                        </Box>
                        <Box className={classes.row}>
                            <Button color="secondary" variant="contained" disabled={loading} onClick={handleLoginWithEmail}><Trans>Signin with email</Trans></Button>
                            { loading && <LoadingRight></LoadingRight> }
                        </Box>

                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY || "6LdQjcsbAAAAABVtk3QZB8tkyMfcZ3W4tSnVwLy-"}
                            size="invisible"
                            ref={recaptchaRef}
                        />

                        <br></br>
                        <Divider></Divider>
                        <br></br>

                        <Box className={classes.row}>
                            {/*
                            <Trans>Don't have a account?</Trans> <Button component={Link} to="/auth/signup"><Trans>Sign up</Trans></Button>
                            <br></br>
                            <Trans>Forgot your password?</Trans><Button><Trans>Recover here</Trans></Button>
                            */}
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        
    );
}

function LoginEmailNotFoundPage(props: any) {
    const classes = useStyles();

    return (
        <Grid container spacing={0} className={classes.container}>
            <Grid item lg={4} className={classes.leftPanel}>
                <p></p>
            </Grid>
            <Grid item lg={8} xs={12} sx={{marginTop: "12vh"}}>
                <Box>
                    <Box style={{ margin: "auto", maxWidth: 420 }}>
                        <Typography variant="h5"><Trans>Sorry, email was not found</Trans></Typography>
                        <div className={classes.separator}></div>
                        <Typography variant="body1"><Trans>The email</Trans> <b>{props.location?.state.email}</b> <Trans>was not recognized in any account</Trans>.</Typography>
                        <div className={classes.separator}></div>
                        <Typography variant="body1">
                            You can
                            <MatLink href="#" color="secondary" component={Link} to="/auth/signin"> <Trans>try with another email</Trans> </MatLink>
                            {/*
                            or
                            <MatLink color="secondary" component={Link} to="/auth/signup"> <Trans>create a new account</Trans> </MatLink>
                            */}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

function LoginEmailSentPage(props: any) {
    const classes = useStyles();

    return (
        <Grid container spacing={0} className={classes.container}>
            <Grid item lg={4} className={classes.leftPanel}>
                <p></p>
            </Grid>
            <Grid item lg={8} xs={12} sx={{marginTop: "12vh"}}>
                <Box>
                    <Box className={classes.body}>
                        <Typography variant="h5"><Trans>Check your inbox</Trans></Typography>
                        <Box className={classes.separator}></Box>
                        <Typography variant="body1"><Trans>Click the link we sent to</Trans> <b>{props.location?.state.email}</b> <Trans>to sign in</Trans>.</Typography>
                        <Box className={classes.separator}></Box>
                        <Box className={classes.row}>
                            <Button color="secondary" variant="contained" component={Link} to="/"><Trans>Ok</Trans></Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export { LoginPage, LoginEmailNotFoundPage, LoginEmailSentPage };
