import 'react-leaflet-fullscreen/dist/styles.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import React, {useState, useEffect} from "react";
import { MapContainer, ZoomControl, useMapEvents } from 'react-leaflet';
import AkopicaLayer from './AkopicaLayer';
import AkopicaMapMenu from './AkopicaMapMenu';
import { Box, Chip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { alert3 } from './layer/shape/ShapeSchemaCatalog';
import moment from "moment";
import { sentinelPresets } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { genericWithAttach } from './layer/note/NoteSchemaCatalog';
import { farmSchema, lotSchema, truckSchema } from './layer/asset/AssetSchemaCatalog';
import SnackbarObject from '../../lib/components/Snackbar/SnackbarObject';
import { addLayerToMap, getMapById, removeLayerFromMap, updateLayerInMap, updateMap } from '../services';
import MapLibre from './MapLibre';
//import AkopicaMapTools from './AkopicaMapTools';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 0,
            display: 'flex',
            maxWidth: 'unset',
        },
        content: {
            display: "block",
            height: "400px",
            width: "100%",
            margin: "0 auto",
        },
    }),
);

interface AkopicaMapProps {
    mapId?: string;
    editable?: boolean;
}

function AkopicaMap(props: AkopicaMapProps) {
    const { t } = useTranslation();
    const [ drawControl, setDrawControl] = useState<any>();
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});
    const [intoLayer, setIntoLayer] = useState<string>("");
    const classes = useStyles();

    // estado del componente
    const [map, setMap] = useState<any>({
        editFeature: false,
        editFeatureLayer: null,
        public: false,
        layers: [],
        settings: { 
            viewport: {
                center: null,
                zoom: null
            }}
    });
    const [mapInstance, setMapInstance] = useState<any>();
    const [canEdit, setCanEdit] = useState<boolean>(true); 

    const loadMap = (id: string) => {
        getMapById(id)
            .then(async map => {
                configMap(map);
                if (map.group?.my_role === "groupReader") {
                    setCanEdit(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
            }
        );
    };

    const saveMap = (map: any) => {
        updateMap(`${map.id}`, map)
            .then(async map => {
            })
            .catch(error => {
                if (error?.error?.message) {
                    setSnackBarMsg({msg:t(error.error.message), severity:"error"});
                } else if (error?.message) {
                    setSnackBarMsg({msg:t(error.message), severity:"error"});
                } else {
                    setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                }
                setOpenSnackbar(true);
            })
            .finally( () => {
            }
        );
    }; 

    const updateLayer = (mapId: string, layerId: string, layer: any) => {
        updateLayerInMap(mapId, layerId, layer)
            .then(async data => {
            })
            .catch(error => {
                if (error?.error?.message) {
                    setSnackBarMsg({msg:t(error.error.message), severity:"error"});
                } else if (error?.message) {
                    setSnackBarMsg({msg:t(error.message), severity:"error"});
                } else {
                    setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                }
                setOpenSnackbar(true);
            })
            .finally( () => {
            }
        );
    };

    const createLayer = (mapId: string, layer: any) => {
        if(map.public && layer.lytype === "sentinel") {
            setSnackBarMsg({msg: t("Can not add sentinel layer to public map"), severity:"warning"});
            setOpenSnackbar(true);
            return;
        }
        addLayerToMap(mapId, layer)
            .then(async (data) => {
                handleChangeMap();
                
                // Assign the layer id to focus on the map only when it's created
                setIntoLayer(data.layer?.id)
                setSnackBarMsg({msg: t("The Layer has been created"), severity:"success"});
            })
            .catch(error => {
                if (error?.error?.message) {
                    setSnackBarMsg({msg:t(error.error.message), severity:"error"});
                } else if (error?.message) {
                    setSnackBarMsg({msg:t(error.message), severity:"error"});
                } else {
                    setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                }
            })
            .finally( () => {
                setOpenSnackbar(true);
            }
        );
    };

    const removeLayer = (mapId: string, layer: any) => {
        removeLayerFromMap(mapId, layer.id)
            .then(async () => {
                const updatedMap = map;
                // get the index of layer in array
                const index = updatedMap.layers.findIndex((l:any) => l.id === layer.id);
                // remove the layer in the index found
                updatedMap.layers.splice(index, 1);
                // update map
                setMap(updatedMap);
                setSnackBarMsg({msg: t("The Layer has been deleted"), severity:"success"});
                setOpenSnackbar(true);
                //handleChangeMap();
            })
            .catch(error => {
                if (error?.error?.message) {
                    setSnackBarMsg({msg:t(error.error.message), severity:"error"});
                } else if (error?.message) {
                    setSnackBarMsg({msg:t(error.message), severity:"error"});
                } else {
                    setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                }
                setOpenSnackbar(true);
            })
            .finally( () => {
            }
        );
    };

    const saveLayer = (map: any, layer: any) => {
        if (layer) {
            if (layer.id) {
                // before save, check if layer exist
                const layerInMap = map.layers.find((itemLayer: any) => itemLayer.id === layer.id);
                if (!layerInMap) {
                    return;
                }

                let data: any = {
                    title: layer.title,
                    detail: layer.detail,
                    settings: layer.settings,
                };
                
                if (layer.dataset?.id !== '0') {
                    data ={ ...data, dataset_id: layer.dataset?.id }; 

                }
                updateLayer(map.id, layer.id, data);

            } else {
                let params = {
                    title: layer.title,
                    detail: layer.detail,
                    lytype: layer.lytype,
                    datasetId: layer?.datasetId,
                    assetId: layer?.assetId,
                    asset:layer?.asset,
                    mapId: map.id,
                };

                createLayer(map.id, params);
            }
        }
    }

    const handleChangeLayer = (layer: any, changed: string, value?: any) => {      
        if ((changed !== "shape::edit" && changed !== "annotation::edit") && canEdit) saveLayer(map, layer);
        
        if (layer && layer.id){
            const cloned = Object.assign({}, map);
            cloned.layers = cloned.layers.sort((a: any, b: any) => a.settings.basic.order - b.settings.basic.order);
            setMap(cloned);
        }
        
    }

    const handleRemoveLayer = async (layer: any) => {
        removeLayer(props.mapId || "", layer);
    }


    const handleChangeMap = () => {
        loadMap(props.mapId || "");
        const cloned = Object.assign({}, map);
        setMap(cloned);
    }

    const configMap = async (data: any) => {
        // Initialiing settings, basic settings values
        data.layers.map((ly: any) => {

            ly.settings = ly.settings || {};            
            // init basic
            ly.settings.basic = ly.settings.basic || {};
            ly.settings.subtitle = ly.settings.subtitle || ly.lytype;
            const basic = ly.settings.basic;
            basic.active = basic.active === undefined ? true : basic.active;
            basic.noteView = false; // basic.noteView === undefined ? true  : basic.noteView;
            basic.noteEdit = false; // basic.noteEdit === undefined ? false : basic.noteEdit;
            basic.order = basic.order || 100;

            // init shape
            if (ly.lytype === "shape") {
                // shape config
                ly.shape = ly.shape || {};

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.schema || genericWithAttach;
                // settings config
                ly.settings.shape = ly.settings.shape || {};
                ly.settings.shape.edit = ly.settings.shape.edit || false;
            }
            // init annotation
            if (ly.lytype === "annotation") {
                // annotation config
                ly.annotation = ly.annotation || {};
                // settings config
                ly.settings.annotation = ly.settings.annotation || {};
                ly.settings.annotation.edit = false;
                ly.settings.annotation.updated = false;
            }
            // init note
            if (ly.lytype === "note") {
                // note config
                ly.note = ly.note || {};
                ly.note.schema = ly.note.schema || genericWithAttach;

                // settings config
                ly.settings.note = ly.settings.note || {};
                ly.settings.note.edit = ly.settings.note.edit || false;
            }
            
            // init point
            if (ly.lytype === "point") {
                // point config
                ly.point = ly.point || {};
                ly.point.schema = ly.point.schema || alert3;

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note || alert3;

                // settings config
                ly.settings.point = ly.settings.point || {};
                ly.settings.point.edit = ly.settings.point.edit === undefined ? false : ly.settings.point.edit;
            }

            if (ly.lytype === "tile") {
                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note || alert3;

                // settings config
                ly.settings.tile = ly.settings.tile || {};
                ly.settings.tile.opacity = ly.settings.tile.opacity || 100;
                ly.settings.tile.brightness = ly.settings.tile.brightness || 100;
                ly.settings.tile.contrast = ly.settings.tile.contrast || 100;
                ly.settings.tile.hue = ly.settings.tile.hue || 0;
                ly.settings.tile.saturate = ly.settings.tile.saturate || 100;

            }

            if (ly.lytype === "image") {
                // image config
                ly.image = ly.image || {};
                ly.image.schema = ly.image.schema || alert3;

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note || alert3;

                // settings config
                ly.settings.image = ly.settings.image || {};
                ly.settings.image.thresholdArray = ly.settings.image.thresholdArray || [0, 20];
                ly.settings.image.maxscoreArray = ly.settings.image.maxscoreArray || [0, 100]; 
            }

            if (ly.lytype === "sentinel") {
                // settings config
                let dateAfter = moment(new Date()).format("YYYY-MM-DD");
                let dateBefore: Date|string = new Date();
                dateBefore.setDate(dateBefore.getDate() - 10);
                dateBefore = moment(dateBefore).format("YYYY-MM-DD");

                const time = `${dateBefore}/${dateAfter}`;
                ly.settings.sentinel = ly.settings.sentinel || {};
                ly.settings.sentinel.preset = ly.settings.sentinel.preset || "TRUE-COLOR-S2L2A";
                ly.settings.sentinel.maxcc = ly.settings.sentinel.maxcc || 20;
                ly.settings.sentinel.time = ly.settings.sentinel.time || time;
                ly.settings.sentinel.interval = ly.settings.sentinel.interval || 10;
                ly.settings.sentinel.date = ly.settings.sentinel.date || new Date();
                
                const subtitle = `${ly.lytype} - ${dateAfter} ${sentinelPresets[ly.settings.sentinel.preset]}`;
                ly.settings.subtitle = ly.settings.subtitle || subtitle;
            }

            if (ly.lytype === "geoInta") {
                // settings config
                let dateAfter = moment(new Date()).format("YYYY-MM-DD");
                let dateBefore: Date|string = new Date();
                dateBefore.setDate(dateBefore.getDate() - 10);
                dateBefore = moment(dateBefore).format("YYYY-MM-DD");

                const time = `${dateBefore}/${dateAfter}`;
                ly.settings.geoInta = ly.settings.geoInta || {};
                ly.settings.geoInta.preset = ly.settings.geoInta.preset || "TRUE-COLOR-S2L2A";
                ly.settings.geoInta.maxcc = ly.settings.geoInta.maxcc || 20;
                ly.settings.geoInta.time = ly.settings.geoInta.time || time;
                ly.settings.geoInta.interval = ly.settings.geoInta.interval || 10;
                ly.settings.geoInta.date = ly.settings.geoInta.date || new Date();
                
                const subtitle = `${ly.lytype} - ${dateAfter} ${sentinelPresets[ly.settings.geoInta.preset]}`;
                ly.settings.subtitle = ly.settings.subtitle || subtitle;
            }

            if (ly.lytype === "compare") {
                // image config
                ly.compare = ly.compare || {};
                ly.compare.schema = ly.compare.schema || alert3;

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note || alert3;

                // settings config
                ly.settings.compare = ly.settings.compare || {};
                ly.settings.compare.layer1 = ly.settings.compare.layer1 || '';
                ly.settings.compare.layer2 = ly.settings.compare.layer2 || ''; 
            }

            // init asset
            if (ly.lytype === "asset") {
                let schema;
                if (ly.settings?.schema?.name === "default__farm_asset") {
                    schema = farmSchema;
                } else if (ly.settings?.schema?.name === "default__lot_asset") {
                    schema = lotSchema;
                } else if (ly.settings?.schema?.name === "default__truck_asset") {
                    schema = truckSchema;
                } else {
                    schema = alert3;
                }

                // asset config
                ly.asset = ly.asset || {};
                ly.asset.schema = schema;

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note || alert3;

                // settings config
                ly.settings.asset = ly.settings.asset || {};
                ly.settings.asset.edit = ly.settings.asset.edit || false;
            }
            return ly;
        });

        data.layers.sort((a: any, b: any) => a.settings.basic.order - b.settings.basic.order);

        data.settings = data.settings || {}
        data.settings.viewport = data.settings.viewport || {zoom: 4, center: [-32.726148064681844, -60.763927102088935]};
        
        setMap({...map, ...data});
        
    }

    // inicializacion
    useEffect(() => {
        loadMap(props.mapId || "");

        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const MapEvents = () => {
        const mapEvents = useMapEvents({
            zoomend: () => {
                onViewportChanged({center: mapEvents.getCenter(), zoom: mapEvents.getZoom()});
            },
            move: () => {
                onViewportChanged({center: mapEvents.getCenter(), zoom: mapEvents.getZoom()});
                
            },
        });

        return null
    }

    const onViewportChanged = (viewport: any) => {  
        // The viewport got changed by the user, keep track in state
        if (canEdit) {
            map.settings.viewport = viewport;
            saveMap(map);
        }
    }

    const handleEditLayer = (title: string, layerId: string) : void => {  
        const index = map.layers.findIndex((layer: any) => layer.id === layerId);
        const updatedMap: any = map;
        updatedMap.layers[index].title = title;
        updateLayerInMap(updatedMap.id, updatedMap.layers[index].id, updatedMap.layers[index])
            .then(async () => {
                setMap(updatedMap);
                setSnackBarMsg({msg: t("The Layer has been updated"), severity:"success"});
                setOpenSnackbar(true);
            }); 
        
    }

    const layers: any = map.layers;

    return (
        <div className={classes.container}>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Box>
                <AkopicaMapMenu
                    editable={canEdit}
                    map={map}
                    setMap={setMap}
                    mapRef={mapInstance}
                    onChangeLayer={handleChangeLayer}
                    onRemoveLayer={handleRemoveLayer}
                    onEditLayer={handleEditLayer}
                />
            </Box>
            
            { map.public && <Chip label={t("Public")} color="secondary" 
                        sx={{zIndex: 1190, position: "absolute", bottom: 20, left: 20, textTransform: "uppercase"}}/> }
            { !map.public && <Chip label={t("Private")} color="primary" 
                        sx={{zIndex: 1190, position: "absolute", bottom: 20, left: 20, textTransform: "uppercase"}}/> }
            <main id="akopicamap1" className={classes.content}>
                {map.settings.viewport.center && true &&
                <MapContainer
                    whenCreated={ map => setMapInstance(map)}
                    //ref={setMapInstance} necessary for v4
                    center={map.settings.viewport.center}
                    zoomControl={false}
                    style={{
                        height: "calc(100vh - 64px)",
                        width: '100%',
                        margin: '0 auto',
                    }}
                    zoom={map.settings.viewport.zoom}
                    minZoom={2}
                    maxZoom={21}
                >
                    <MapEvents/>
                    <ZoomControl position='bottomright' />
                    {/* <FullscreenControl position="bottomleft" /> */}

                    {mapInstance && layers &&
                    layers.map((layer: any) => {
                        return (
                            <AkopicaLayer
                                key={ layer.id }
                                allowNote={ layer.allowNote }
                                layer={ layer }
                                lytype={ layer.lytype }
                                data={ layer.locations }
                                dataset={ layer.dataset }
                                maxZoom="22"
                                layers={layers}
                                drawControl={drawControl}
                                setDrawControl={setDrawControl}
                                mapRef={mapInstance}
                                intoLayer={intoLayer}
                                setIntoLayer={setIntoLayer}
                                onChangeMap={handleChangeMap}
                                onChangeLayer={handleChangeLayer}
                            />
                        )
                    })}
                    
                    {/* <LayerGroupSideBySide /> */}
                    

                </MapContainer>
                }
                { false &&
                <MapLibre></MapLibre>
                }
            </main>
        </div>
    )
}

export default AkopicaMap;
