import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {CircularProgress, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    ({
        loading: {
            left: '50%',
            top:'50%',
            position: 'fixed',
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            zIndex: 9999,
            [theme.breakpoints.down('xs')]: {
                marginLeft: -15,
            },
        },
        loadingRight: {
            position: "relative",
            top: 10,
            left: 8,
        }

    }),
);

const LoadingProgress = (props: any) => {
    const classes = useStyles();

    return (
        <span>
            <div className={classes.loading}>
                <CircularProgress color="primary" />
            </div>
        </span>
    )
}

const LoadingRight = (props: any) => {
    const classes = useStyles();

    return (
        <CircularProgress className={classes.loadingRight} size={28}/>
    )
}


export {LoadingProgress, LoadingRight };
