import React, { useState, useEffect } from 'react';
import {
  Divider,
  Container,
  Grid,
  Button,
  TextField,
  Autocomplete,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Asset } from '../../../../models/asset';
import {
  createAssetChild,
  getAssetChildrenPage,
} from '../../../../assets/services';
import { useTranslation, Trans } from 'react-i18next';
import SnackbarObject from '../../../../lib/components/Snackbar/SnackbarObject';

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    paddingRight: theme.spacing(3),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    backgroundColor: theme.palette.secondary.light,
    ...theme.mixins.toolbar,
  },
  settingsContent: {
    margin: theme.spacing(1),
  },
  wrapNormal: {
    whiteSpace: 'normal',
  },
  container: {
    paddingLeft: 0,
    margin: theme.spacing(1),
  },
  comboLytype: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '80%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: '50ch',
    width: '80%',
  },
  rowActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

interface AddNewAssetChildProps {
  asset: Asset;
  setAsset: (asset: Asset) => void;
  setMode: (mode: 'Empty' | 'NewAssetChild') => void;
}

interface InputError {
  title?: string;
  asttype?: string;
}

const AddNewAssetChild = ({
  setMode,
  asset,
  setAsset,
}: AddNewAssetChildProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [inputError, setInputError] = useState<InputError>({});
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState<{
    msg: string;
    severity: string;
  }>({ msg: '', severity: 'success' });

  const [input, setInput] = React.useState<any>({
    title: '',
    asttype: '',
  });

  const asttypes = [
    { title: 'Building', asttype: 'Building' },
    { title: 'Depo', asttype: 'Depo' },
    { title: 'Vehicle', asttype: 'Vehicle' },
    { title: 'Farm', asttype: 'Farm' },
    { title: 'Lot', asttype: 'Lot' },
    { title: 'Site', asttype: 'Site' },
    { title: 'Device', asttype: 'Device' },
  ];

  useEffect(() => {
    validateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const validateData = () => {
    if (input.asttype === '' || input.title.trim() === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const handleClose = () => {
    setMode('Empty');
  };

  const handleChangeText =
    (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value.trim() === '') {
        setInputError({ ...inputError, title: t('Please provide a Title') });
      } else {
        setInputError({ ...inputError, title: undefined });
      }
      setInput({ ...input, [prop]: value });
    };

  const handleChangeCombo = (event: any, values: any) => {
    if (values) {
      if (values.lytype === '') {
        setInputError({
          ...inputError,
          asttype: t('Please choose a Layer Type'),
        });
      } else {
        setInputError({ ...inputError, asttype: undefined });
      }
      setInput({ ...input, asttype: values.asttype });
    }
  };

  const handleSave = async () => {
    try {
      if (asset.id != null) {
        const newAssetChild = await createAssetChild(asset.id, input);
        if (newAssetChild) {
          const children = await getAssetChildrenPage(asset.id, {
            pageSize: 4,
            page: 1,
          });
          setSnackBarMsg({
            msg: t('Asset created successfully'),
            severity: 'success',
          });
          setOpenSnackbar(true);
          setAsset({ ...asset, children: children });
          setMode('Empty');
        }
      }
    } catch (error) {
      setSnackBarMsg({
        msg: t('Sorry, an unexpected error has ocurred'),
        severity: 'error',
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <React.Fragment>
      <Divider />
      <Container maxWidth="md" className={classes.container}>
        <SnackbarObject
          type="alert"
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          msg={snackBarMsg.msg}
          severity={snackBarMsg.severity}
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Trans>Asset Info</Trans>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              size="small"
              label={t('Title')}
              id="title"
              onChange={handleChangeText('title')}
              variant="outlined"
              helperText={inputError.title}
              error={inputError.title !== undefined}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={classes.comboLytype}
              size="small"
              id="combo-box-asttype"
              options={asttypes}
              onChange={handleChangeCombo}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) =>
                option.asttype === value.asttype
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Asset Type')}
                  variant="outlined"
                  helperText={inputError.asttype}
                  error={inputError.asttype !== undefined}
                />
              )}
            />
          </Grid>
          <Grid item xs={8} className={classes.rowActions}>
            <Button color="primary" variant="contained" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={4} className={classes.rowActions}>
            <Button
              disabled={disabled}
              color="secondary"
              variant="contained"
              onClick={handleSave}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default AddNewAssetChild;
