import React, { useEffect } from "react";
import clsx from 'clsx';
import { navigate } from '@reach/router';
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, Drawer, Theme, IconButton, Divider, List, Fade, Grow, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Menu, MenuItem, styled, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AkopicaMenuItem from "./AkopicaMenuItem";
import SettingsGoogle from "./settings/SettingsGoogle";
import ImageSettings from "./layer/image/ImageSettings";
import TileSettings from "./layer/tile/TileSettings";
import ShapeSettings from "./layer/shape/ShapeSettings";
import AssetSettings from "./layer/asset/AssetSettings";
import PointSettings from "./layer/point/PointSettings";
import FeatureSettings from "./layer/feature/FeatureSettings";
import SentinelSettings from "./layer/sentinel/SentinelSettings";
import GeoIntaSettings from "./layer/geoInta/GeoIntaSettings";
import CompareSettings from "./layer/compare/CompareSettings";
import { useTranslation, Trans } from "react-i18next";
import NewLayer from "./newLayer/NewLayer";
import NoteSettings from "./layer/note/NoteSettings";
import ConfirmationDialog from "../../lib/components/ConfirmationDialog/ConfirmationDialog";
import SnackbarObject from '../../lib/components/Snackbar/SnackbarObject';
import { updateMap, removeMap } from '../services';
import { generateSubtitle, handleLongText } from "../../lib/utils";
import grey from "@mui/material/colors/grey";
import AnnotationSettings from "./layer/annotation/AnnotationSettings";



const useStyles = makeStyles((theme: Theme) =>
({
    containerOld: {
        padding: 0,
        display: 'flex',
        maxWidth: 'unset',
    },
    container: {
        position: "absolute",
        height: "480px",
        display: "block",
        width: "366px"
    },
    settingsRow: {
        margin: theme.spacing(1),
    },
    settingsTitle: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    titleMap: {
        marginLeft: "20px"
    },
    separator: {
        paddingRight: theme.spacing(3),
    },
    buttonOpen: {
        top: 10,
        left: 10,
        zIndex: 1000,
        [theme.breakpoints.up('sm')]: {
            top: 18,
        },
    },
    buttonClose: {
        top: 10,
        left: 335,
        zIndex: 1000,
        [theme.breakpoints.up('sm')]: {
            top: 18,
            left: 360,
        },
    },
    saveButton: {
        float: "right",
        margin: theme.spacing(1),
    },
    layersButtonHidden: {
        display: 'none',
    },
    drawerPaper: {
        //position: "relative",
        marginTop: 80,
        marginLeft: 20,
        whiteSpace: 'nowrap',
        width: 330, // 240 == drawerwidth
        //maxHeight: 40 * 12,
        height: "auto !important",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        borderWidth: 0,
        [theme.breakpoints.down(600)]: {
            marginTop: 56,
            marginLeft: 0
        },
    },
    drawerSettings: {
        width: 330,
        overflowX: "hidden"
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -40,
        width: 0, //theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: 0, // theme.spacing(9),
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: theme.palette.secondary.light,
        ...theme.mixins.toolbar,
    },
    content: {
        display: "block",
        height: "400px",
        width: "80%",
        margin: "0 auto",
    },
    tab: {
        width: 330 / 2,
        minWidth: 330 / 2,
    },
}),);

export const LayerList = styled(List)(({ theme }) => ({
    maxHeight: 500 ,
    [theme.breakpoints.up('sm')]: {
        maxHeight: 615,
    },
    overflowY: "auto"
}));

/*

paper:
                        clsx(classes.drawerPaper,
                            !openDrawer && classes.drawerPaperClose,
                            settingsLayer && classes.drawerSettings,
                            settingsLayer),
*/


const SettingsRegistry: any = {
    "annotation": AnnotationSettings,
    "google": SettingsGoogle,
    "osm": SettingsGoogle,
    "image": ImageSettings,
    "tile": TileSettings,
    "shape": ShapeSettings,
    "note": NoteSettings,
    "point": PointSettings,
    "compare": CompareSettings,
    "asset": AssetSettings,
    "feature": FeatureSettings,
    "sentinel": SentinelSettings,
    "geoInta": GeoIntaSettings,
}
interface ItemsProps {
    map: any;
    setMap: Function;
    layer: any;
    onDrawerClose: any;
    onOpenSettings: any;
    onOpenAddLayer: any;
    editable?: boolean;
    onChangeLayer: Function;
}

function Items(props: ItemsProps) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openShare, setOpenShare] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>(props?.map?.title);
    const [detail, setDetail] = React.useState<string>(props?.map?.detail);
    const [shareLink] = React.useState<string>("")
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});
    const [mapMenuAnchorEl, setMapMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const [hideLayers, setHideLayers] = React.useState<boolean>(false);
    
    const handleChangeMapNameClick = (event: React.MouseEvent) => {
        handleMapMenuClose();
        setOpenEdit(true);
    };

    const handleAddLayerClick = (event: React.MouseEvent) => {
        handleMapMenuClose();
        props.onOpenAddLayer();
    };

    // Edit Layer events
    const handleCloseEdit = () => {
        setOpenEdit(false);
      };

    const handleCloseShare = () => {
        setOpenShare(false);
    };
  
    const handleSaveEdit = async() => {
        // props.onEditLayer(title);
        try {
            await updateMap(props.map.id, {id: props.map.id, name: title, title: title, detail: detail})
            props.setMap({...props.map, name: title, title: title, detail: detail})
            setSnackBarMsg({msg:t("Update successful"), severity:"success"});
            setOpenSnackbar(true);

        } catch (error: any) {
            if (error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        } finally {
            setOpenEdit(false);
        }
    };

    const handleChangePublic = async () => {
        try {
            const hasSentinel = props.map.layers?.find((layer: any) => layer.lytype === 'sentinel');
            if (!hasSentinel) {
                await updateMap(props.map.id, {id: props.map.id, name: title, title: title, public: !props.map.public})
                props.setMap({...props.map, public: !props.map.public })
    
                if (props.map.public) {
                    setSnackBarMsg({msg: t("The map is no longer public"), severity:"success"});
                } else {
                    setSnackBarMsg({msg: t("Map published successfully"), severity:"success"});
                }
                handleMapMenuClose();
            }
            else {
                setSnackBarMsg({msg: t("Can not publish a map with a sentinel layer"), severity:"warning"});
            }
            setOpenSnackbar(true);
        } catch (error: any) {
            if (error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        }
    }
  
    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value);
    };

    const handleChangeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDetail(event.target.value);
    };

    const handleShare = async () => {
        try {
            await navigator.clipboard.writeText(shareLink);
            setSnackBarMsg({msg: t("Copied successfully"), severity:"success"});
            setOpenSnackbar(true);
        } catch (err) {
            setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            setOpenSnackbar(true);
            console.debug(err);
        }
    };

    const handleOpenDeleteMap = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleDelete = async () => {
        try {
            await removeMap(props?.map?.id);
            navigate("/maps") 
        } catch (error: any) {
            if (error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        } finally {
            setOpenDelete(false);
        }
    }

    /*
    const generateUrl = async () => {
        try {
            const res = await getShareMapUrl(props?.map?.id);
            if (res && res.url) setShareLink(res.url);
        } catch (err) {
            console.debug(err);
        }
    }
    */


    const onChangeHideLayers = () => {
        if (!hideLayers) {
            setHideLayers(true);
        } else {
            setHideLayers(false);
        }
    }

    useEffect(() => {
        setTitle(props.map.title);
        setDetail(props.map.detail);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.map.title, props.map.detail])


    const handleMapMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMapMenuAnchorEl(event.currentTarget);
    };  

    const handleMapMenuClose = () => {
        setMapMenuAnchorEl(null);
    };

    // This function is for move one layer up or down
    // Make a swap between order of 2 layers
    const moveLayers = (where: string, layer: any, index: number) => {
        let otherLayer;
        if( where === 'up') {
            otherLayer = props.map.layers[index-1];
            [
                layer.settings.basic.order,
                otherLayer.settings.basic.order
            ] = [ 
                otherLayer.settings.basic.order, 
                layer.settings.basic.order
            ]
        }

        if( where === 'down') {
            otherLayer = props.map.layers[index+1];
            [
                layer.settings.basic.order,
                otherLayer.settings.basic.order
            ] = [ 
                otherLayer.settings.basic.order, 
                layer.settings.basic.order
            ]
        }
        updateLayers([layer, otherLayer])
    }
    
    // update multiple layers
    const updateLayers = (layers: any) => {
        layers.forEach((layer: any) => {
            props.onChangeLayer(layer);
        });
    }

    return (
        <Box>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Box className={classes.toolbarIcon}>
                <Box sx={{paddingLeft: "10px"}}>
                    <SettingsIcon sx={{ color: grey[100] }}/>
                </Box>
                <Box style={{ minWidth: 250, maxWidth: 250, overflowX: "hidden", cursor: "pointer" }} onClick={onChangeHideLayers}>
                    <Box className={classes.separator}>
                        <Typography sx={{marginLeft: "20px"}}>
                            {props.map.title}
                        </Typography>
                        <Typography sx={{color: "white", marginLeft: "20px"}} variant="subtitle2">
                            {props?.map?.group?.title}
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ width: "100%" }}></Box>
                {props.editable ? 
                <Box style={{ width: "100%" }}>
                    <IconButton aria-controls="title-menu" aria-haspopup="true" onClick={handleMapMenuClick}><MoreVertIcon /></IconButton>
                    <Menu
                        id="title-menu"
                        anchorEl={mapMenuAnchorEl}
                        keepMounted
                        open={Boolean(mapMenuAnchorEl)}
                        onClose={handleMapMenuClose}
                    >
                        <MenuItem onClick={handleChangeMapNameClick}><Trans>Edit Map</Trans></MenuItem>
                        <MenuItem onClick={handleAddLayerClick}><Trans>Add Layer</Trans></MenuItem>
                        {/*<MenuItem onClick={handleOpenShareMap}><Trans>Share Map</Trans></MenuItem>*/}
                        { !props.map.public && <MenuItem onClick={handleChangePublic}><Trans>Public Map</Trans></MenuItem>}
                        { props.map.public && <MenuItem onClick={handleChangePublic}><Trans>Unpublic Map</Trans></MenuItem>}
                        <MenuItem onClick={handleOpenDeleteMap}><Trans>Delete Map</Trans></MenuItem>
                    </Menu>
                </Box>: null}
            </Box>
            <Divider />
            {
                !hideLayers &&
                <Grow in={true}>
                    <LayerList>
                        {
                            props.map.layers.map((layer: any, index: number) => {
                                return <AkopicaMenuItem
                                    editable={props.editable}
                                    key={layer.id}
                                    map={props.map}
                                    layer={layer}
                                    onOpen={props.onOpenSettings}
                                    moveLayers={moveLayers}
                                    index={index}
                                    min={props.map.layers[0]?.id}
                                    max={props.map.layers[props.map.layers.length - 1]?.id}
                                    onChangeLayer={props.onChangeLayer} />
                            })
                        }
                    </LayerList>
                </Grow>
            }
            
            {/* Edit Map Dialog */}
            <Dialog open={openEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><Trans>Map Data</Trans></DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="mapTitle"
                    label={t("Title")}
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={title ?? ""}
                    onChange={handleChangeTitle}
                />
                <TextField
                    margin="dense"
                    id="mapDetail"
                    label={t("Detail")}
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={detail ?? ""}
                    onChange={handleChangeDetail}
                />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit} color="primary">
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={handleSaveEdit} color="primary">
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Share Map Dialog */}
            <Dialog open={openShare} onClose={handleCloseShare} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'sm'}>
                
                <DialogTitle id="form-dialog-title">Share Map</DialogTitle>
                <DialogContent>
                <TextField
                    margin="dense"
                    id="mapDetail"
                    label="Share Link"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={shareLink}
                    disabled
                />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseShare} color="primary">
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={handleShare} color="secondary" variant="contained" >
                        <Trans>Copy Link</Trans>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Map Dialog */}
            <Dialog open={openDelete} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><Trans>Delete Map</Trans></DialogTitle>
                <DialogContent>
                    <Typography>
                        <Trans>Are you sure you wish to delete the map?</Trans>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        <Trans>No</Trans>
                    </Button>
                    <Button onClick={handleDelete} color="secondary" variant="contained">
                        <Trans>Yes</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

interface SettingsProps {
    map: any;
    setMap: Function;
    layer: any;
    setSettings: Function;
    onCloseSettings: any;
    onChangeLayer: Function;
    onRemoveLayer: Function;
    onDrawerClose?: Function;
    editable?: boolean;
    mapCurr: any;
    onEditLayer: (title: string, layerId: string) => void;
}

function Settings(props: SettingsProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [tabValue] = React.useState(0);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [title, setTitle] = React.useState(props?.layer?.title || "");
    const [subtitle, setSubtitle] = React.useState(props?.layer?.settings?.subtitle || "");
    const [openConfirmRemoveLayer, setOpenConfirmRemoveLayer] = React.useState<boolean>(false);
        
    const DynSettings = SettingsRegistry[props.layer.lytype];
    if (!DynSettings) {
        console.error("Unknown layer type:", props.layer);
    }

    // Title events
    function handleClose() {
        props.onCloseSettings();
    }

    const handleRemove = async () => {
        setOpenConfirmRemoveLayer(true);
    }
    
    const confirmRemoveLayer = async () => {
        await props.onRemoveLayer(props.layer);
        setOpenConfirmRemoveLayer(false);
        props.onCloseSettings();
    }

    const cancelRemoveLayer = () => {
        setOpenConfirmRemoveLayer(false);
    }
    
    const handleClickTitle = (event: React.MouseEvent) => {
        if (props.editable) setOpenEdit(true);
    };

    // Edit Layer events
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const changeSubtitle = () => {
        const subT = generateSubtitle(props.layer.lytype, props.layer.settings);
        if (subT !== subtitle) {
            setSubtitle(subT);
            props.layer.settings.subtitle = subT;
            props.onChangeLayer(props.layer);
        }
    };
  
    const handleSaveEdit = () => {
        props.layer.settings.subtitle = subtitle;
        props.onEditLayer(title, props.layer.id);
        props.onChangeLayer(props.layer);
        setOpenEdit(false);
    };
  
    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleChangeSubTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubtitle(event.target.value);
    };

    React.useEffect(() => {
        setTitle(props.layer?.title);
    }, [props.layer?.title])

    return (
        <div>
            <div className={classes.toolbarIcon}>
                <IconButton
                    edge="start"
                    color="primary"
                    aria-label={t("close settings")}
                    onClick={handleClose}
                    >
                    <ChevronLeft />
                </IconButton>
                <Link href="#" onClick={handleClickTitle} style={{ width: "100%", textDecoration: "none" , cursor: props.editable ? 'pointer': 'default'}}>
                    <div className={classes.separator}>
                        <Typography>
                            {handleLongText(30, props?.layer?.title)}
                        </Typography>
                        <Typography variant="caption">{handleLongText(36, props?.layer?.settings?.subtitle)}</Typography>
                        &nbsp;
                        {props.editable && <EditIcon style={{ fontSize: "12pt", verticalAlign: "middle" }} fontSize="small"/>}
                    </div>
                </Link>
                
                {props.editable && 
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label={t("close settings")}
                        onClick={handleRemove}
                    >
                        <DeleteIcon />
                    </IconButton>
                }

                {/* Edit Layer Dialog */}
                <Dialog open={openEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Trans>Layer Data</Trans></DialogTitle>
                    <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="layerName"
                        label={t("Name")}
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={handleChangeTitle}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="layerSubTitle"
                        label={t("Subtitle")}
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={subtitle}
                        onChange={handleChangeSubTitle}
                    />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit} color="primary">
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button onClick={handleSaveEdit} color="primary">
                            <Trans>Save</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>

            <Divider />
            { DynSettings && tabValue === 0 &&
                <Fade in={true}>
                    <div style={{ margin: "0px" }}>
                        <DynSettings {...props} changeSubtitle={changeSubtitle}/>
                    </div>
                </Fade>
            }
            <ConfirmationDialog open={openConfirmRemoveLayer} onClose={cancelRemoveLayer} onConfirm={confirmRemoveLayer}
                title={t("Confirmation")} subtitle={t("Are you sure that you wish to delete the layer?")}/>
        </div>
        
    )
}

interface SettingsLayer {
    lytype?: string,
    settings: any,
}


interface AkopicaMapMenuProps {
    map: any;
    setMap: Function;
    onChangeLayer: Function;
    onRemoveLayer: Function;
    editable?: boolean;
    mapRef?: any;
    onEditLayer: (title: string, layerId: string) => void;
}

function AkopicaMapMenu(props: AkopicaMapMenuProps) {
    const mapCurr = props.mapRef;

    const classes = useStyles();
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = React.useState(true);
    const [settingsLayer, setSettingsLayer] = React.useState<SettingsLayer|null>(null);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleCloseSettings = () => {
        setSettingsLayer(null!);
    };

    const handleOpenAddLayer = () => {
        let layer: any = {
            lytype: "new",
            settings: { basic: {} }
        };

        setSettingsLayer(layer);
    };

    return (
        <div className={classes.container}>
            <Drawer
                variant="permanent"
                ModalProps={{ hideBackdrop: true }}
                classes={{
                    paper:
                        clsx(classes.drawerPaper,
                            !openDrawer && classes.drawerPaperClose,
                            settingsLayer && classes.drawerSettings,
                            settingsLayer),
                }}
                open={openDrawer}>

                <NewLayer {...props}
                    layer={settingsLayer}
                    onCloseSettings={handleCloseSettings}
                    onChangeLayer={props.onChangeLayer}
                />
                {settingsLayer && settingsLayer?.lytype !== "new" &&
                    <Settings {...props}
                        layer={settingsLayer}
                        setSettings={setSettingsLayer}
                        onCloseSettings={handleCloseSettings}
                        onChangeLayer={props.onChangeLayer}
                        onRemoveLayer={props.onRemoveLayer}
                        onDrawerClose={handleDrawerClose}
                        mapCurr={mapCurr}
                    />
                }

                { settingsLayer == null &&
                    <Items {...props}
                        layer={settingsLayer}
                        onDrawerClose={handleDrawerClose}
                        onOpenSettings={setSettingsLayer}
                        onOpenAddLayer={handleOpenAddLayer} />
                }

            </Drawer>
            <Fade in={openDrawer} timeout={600}>
                <IconButton
                    className={clsx(classes.buttonClose, !openDrawer && classes.layersButtonHidden)}
                    sx={{
                        backgroundColor: "rgb(51, 160, 149)", 
                        marginLeft: 0,
                        position: "absolute",
                        '&:hover': {
                            backgroundColor: "#ddd",
                        },
                        borderRadius: 1,
                    }}
                    edge="start" color="inherit"
                    aria-label={t("close drawer")}
                    size="small"
                    onClick={handleDrawerClose}
                >
                    <ChevronLeft />
                </IconButton>
            </Fade>
            <Fade in={!openDrawer} timeout={600}>
                <IconButton
                    className={clsx(classes.buttonOpen, openDrawer && classes.layersButtonHidden)}
                    sx={{
                        backgroundColor: "rgb(51, 160, 149)", 
                        marginLeft: 0,
                        position: "absolute",
                        '&:hover': {
                            backgroundColor: "#ddd",
                        },
                        borderRadius: 1,
                    }}
                    edge="start" color="inherit"
                    aria-label={t("open drawer")}
                    size="small"
                    onClick={handleDrawerOpen}
                >
                    <SettingsIcon sx={{ color: grey[100] }}/>
                </IconButton>
            </Fade>
        </div>
    )
}

export default AkopicaMapMenu
