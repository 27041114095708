import React from 'react';
import AssetItem from './AssetItem';
import makeStyles from '@mui/styles/makeStyles';
import {Theme, Container, List, Box, Button } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { SearchResponse } from "./AssetsPage"
import { Trans } from "react-i18next";
import { Asset } from '../models/asset';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
    }),
);

interface MyProps {
    data: SearchResponse,
    next: any,
    prev: any,
};

function AssetList(props: MyProps) {
    const classes = useStyles();

    return (
        <Container maxWidth={false}>
            <List className={classes.root}>
                {
                    props.data.page.map((asset: Asset) => {
                        return <AssetItem key={asset.id} data={asset}/>
                    })
                }
            </List>
            <Box>
                <Button onClick={props.prev} startIcon={ <ChevronLeft/> }><Trans>Previous</Trans></Button>
                <Button onClick={props.next} endIcon={ <ChevronRight/> }><Trans>Next</Trans></Button>
            </Box>

        </Container>
    );
  }
  
export default AssetList;
