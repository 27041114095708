import React, { ReactNode } from 'react';
import createAuthProvider from './lib/AuthManager';
import { User } from './models/auth';
import createHttpClient from './lib/HttpClient';
import { navigate } from '@reach/router';

/**
 * Token authetication managment
 */
export const [useAuth, authLogin, authLogout, authToken] = createAuthProvider(
    {
        storageKey: 'tokenAkopica',
        storage: 'local',
    }
);

export const [httpGet, httpPost, httpPut, httpDelete] = createHttpClient(
    {
        getAuthorizationToken: () => `${authToken()}`,
        authorizationPrefix: "Bearer ",
        onNotAuthorized: () => {
            authLogout();
            setAppUser(null);
            navigate("/auth/signin");
        }
    }
);

/**
 * App User managment
 */
let appUser: User | null = null;
export const setAppUser = (user: User | null) => {
    appUser = user;
}
export const getAppUser = () => {
    return appUser;
}

export interface LayoutContext {
    showCopyright: boolean,
    user: any,
    groups?: any,
    currentGroup?: any,
}

export interface MapsContext {
    maps: {
        result: {}
    },
    map: {},
}

interface AppContext {
    layout: LayoutContext,
    setLayout: (value: LayoutContext) => void,

    maps: MapsContext,
    setMaps: (value: MapsContext) => void,
}

const defaultContext = {
    layout: {
        showCopyright: true,
        user: {},
        groups: [],
        currentGroup: {},
    },
    maps: {
        maps: {
            result: {}
        },
        map: {}
    },
    image: {
        isModalOpen: false,
        imageName: "",
        dataset: {
            id: ""
        }
    }
}

export const appContext = React.createContext<AppContext>(undefined!);

export function useAppContext(): AppContext {
    return React.useContext<AppContext>(appContext);
}

interface AppProviderProps {
    children: ReactNode
}

const AppContextProvider = ({children}: AppProviderProps) => {
    const [layout, setLayout] = React.useState<LayoutContext>(defaultContext.layout);
    const [maps, setMaps] = React.useState<MapsContext>(defaultContext.maps);

    return (
        <appContext.Provider value={{
            layout, setLayout,
            maps, setMaps }}>
            {children}
        </appContext.Provider>
    );
};

export default AppContextProvider;
