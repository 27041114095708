import React from 'react';
import { Theme, Toolbar, Typography, Button, Container, Box, Grid, Hidden, Card, CardContent, CardActions, CardActionArea, CardMedia } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Map from "@mui/icons-material/Map";
import { Link as RouterLink } from "@reach/router"
import { useTranslation, Trans } from "react-i18next";


const useStyles = makeStyles((theme: Theme) =>
({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    padding: {
        paddingRight: 0,
        paddingLeft: 0
    },
    textJustify: {
        textAlign: 'justify'
    }
}),
);

function HomePage(props: any) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container maxWidth={false}>
            <Toolbar className={classes.padding} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>Home</Trans>
                </Typography>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden smDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/user/profile" color="secondary" startIcon={<Map />}><Trans>Update user data</Trans></Button>
                        </Box>
                    </Grid>
                </Hidden>

                <Grid item xs={12} md={8}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6}>

                            <Card>
                                <CardActionArea component={RouterLink} to="/maps/new">
                                    <CardMedia
                                        sx={{
                                            width: 60,
                                            marginLeft: "16px",
                                            marginTop: "16px",
                                        }}
                                        component="img"
                                        alt={t("Create Map")}
                                        image="/assets/images/mapa.png"
                                        title={t("Create Map")}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            <Trans>Start creating a map</Trans>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.textJustify}>
                                            <Trans>With Akopica you can create maps with different types of information layers stored in datasets.</Trans>
                                            <br></br>&nbsp;
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary" component={RouterLink} to="/maps/new">
                                        <Trans>Create a Map</Trans>
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>

                            <Card>
                                <CardActionArea component={RouterLink} to="/datasets/new" sx={{minHeight: 192}}>
                                    <CardMedia
                                        sx={{
                                            width: 60,
                                            marginLeft: "16px",
                                            marginTop: "16px",
                                        }}
                                        component="img"
                                        alt={t("Create dataset")}
                                        image="/assets/images/dataset.png"
                                        title={t("Create dataset")}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            <Trans>Upload a dataset</Trans>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.textJustify}>
                                            <Trans>Datasets are the data displayed on the maps. You can upload figure data, images, mosaics, etc.</Trans>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary" component={RouterLink} to="/datasets/new">
                                        <Trans>Upload Data</Trans>
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        
                        {/* 
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardActionArea component={RouterLink} to="/assets/new">
                                    <CardMedia
                                        sx={{
                                            width: 60,
                                            marginLeft: "16px",
                                            marginTop: "16px",
                                        }}
                                        component="img"
                                        alt="Create dataset"
                                        image="/assets/images/asset.png"
                                        title="Create asset"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Identifica tus activos
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.textJustify}>
                                            Los activos son cosas relevantes de distintos tipos, campos, sitios, cosechadoras y otros, que pueden
                                            ser administrados y/o visualizados en los datasets.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary" component={RouterLink} to="/assets/new">
                                        Identificar activos
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        */}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default HomePage;
