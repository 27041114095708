import { ShapeSchema } from "./ShapeSchema";

export const alert3: ShapeSchema = {
    colorField: "alert",
    fields:[
        {
            name: "title",
            label: "Título",
            type: "string",
        },
        {
            name: "comments",
            label: "Comentarios",
            type: "string",
            widget: "multiline",
            rows: 6,
        },
        {
            name: "alert",
            label: "Nivel Critico(opcional)",
            type: "string",
            widget: "select",
            options: [
                ["1", "Bajo"],
                ["2", "Medio"],
                ["3", "Alto"],
            ]
        },
    ]
}
  

export const recipe: ShapeSchema = {
    colorField: "product",
    fields:[
        {
            name: "title",
            label: "Título",
            type: "string",
        },
        {
            name: "product",
            label: "Producto",
            type: "string",
            widget: "select",
            options: [
                ["A", "Compuesto A"],
                ["B", "Compuesto B"],
                ["C", "Compuesto C"],
            ]
        },  
        {
            name: "conc",
            label: "Concentración",
            type: "float",
        },  
        {
            name: "concUnit",
            label: "Unidad",
            type: "string",
            widget: "select",
            options: [
                ["mm/l", "mm/l"],
            ]
        },
        {
            name: "comments",
            type: "string",
            widget: "multiline",
            rows: 6,
        },
    ]
}
  