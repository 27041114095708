import React from 'react';
import MemberListItem from './MemberListItem';
import { Theme, List, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Page } from '../models/common';
import { User } from '../models/auth';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
    }),
);

interface MyProps {
    data: Page<User>,
    next: any,
    prev: any,
    canEdit: boolean,
    handleSelect: Function,
    handleRemove: Function,
}

function MemberList(props: MyProps) {
    const classes = useStyles();
    return (
        <>
            <List className={classes.root}>
                { 
                    props.data.page.map((member: User, index: number) => {   // VER JSON DE RESPUESTA DE GET MEMBERS...
                        return <MemberListItem key={index} data={member} handleSelect={props.handleSelect} handleRemove={props.handleRemove} canEdit={props.canEdit}/>
                    })
                }
            </List>
            <Box>
                <Button onClick={props.prev} startIcon={ <ChevronLeft/> }><Trans>Previous</Trans></Button>
                <Button onClick={props.next} endIcon={ <ChevronRight/> }><Trans>Next</Trans></Button>
            </Box>
        </>
    );
  }

export default MemberList;
