import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Theme, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Layer } from "leaflet";
import { LayerSchema } from "./AssetSchema";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        dialog: {
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        inline: {
            margin: theme.spacing(1),
            display: "inline-block",
        }
    }),
);

interface MyProps {
    layer: any,
    open: boolean,
    featureLayer?: Layer,
    onClose: any, // TODO what type???
    onSave: Function, 
}

function AkopicaAssetDialog(props: MyProps) {
    const classes = useStyles();
    const [open] = useState(props.open);
    const defval: {[key: string]: any} = {};
    const [note, setNote] = React.useState(defval);
    
    /*
    function handleSave(event: MouseEvent) {
        const layer: any = props.featureLayer;
        //console.log("layer: ", layer);

        // update tooltip
        if (props.featureLayer && note.title) {
        props.featureLayer.bindTooltip(note.title, tooltipOptions);
        }

        layer.feature.properties.note = note;
        props.onSave(event);
        props.onClose(event);
    }
    */

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setNote({ ...note, [name]: event.target.value });

        // props.onChangeLayer(props.layer, "basic::" + name, event.target.checked);
        // console.log("Values: ", note);
    };

    useEffect(() => {
        // const layer: any = props.featureLayer;
        //setNote(layer.feature?.properties?.note || {});
    }, []);

    const widgets = [];
    const fields = (props.layer.asset?.schema as LayerSchema)?.fields || [];
    for (const field of fields) {
        const widget = field.widget || "default";
        const fieldOptions =  field.options || [];
        const width = field.width || "48ch";
        const variant = widgets.length === 0 ? "filled" : "outlined";
        let comp;

        if (field.type === "string" && widget === "default") {
            const cls = classes.row;
            comp = 
            <div key={field.name} className={cls}>
                <TextField
                    label={field.label}
                    id={field.name}
                    name={field.name}
                    variant={variant}
                    size="small"
                    value={note[field.name] || ( field.defVal || "")}
                    onChange={handleChange}
                    style={{
                        width: width,
                    }}
                />
            </div>
        } else if (field.type === "string" && widget === "multiline") {
            const cls = classes.row;
            comp =
                <div key={field.name} className={cls}>
                    <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        size="small"
                        multiline
                        rows={field.rows || 6}
                        value={note[field.name] || ""}
                        variant={variant}
                        onChange={handleChange}
                        style={{
                            width: width,
                        }}
                    />
                </div>
        } else if (field.type === "float" && widget === "default") {
            const cls = classes.row;
            comp =
                <div key={field.name} className={cls}>
                    <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        size="small"
                        type="number"
                        value={note[field.name] || ""}
                        variant={variant}
                        onChange={handleChange}
                        style={{
                            width: width,
                        }}
                    />
                </div>
        } else if (field.type === "int" && widget === "default") {
            const cls = classes.row;
            comp = 
                <div key={field.name} className={cls}>
                    <TextField
                        id={field.name}
                        label={field.label}
                        size="small"
                        type="number"
                        value={note[field.name] || ""}
                        variant={variant}
                        onChange={handleChange}
                        style={{
                            width: width,
                        }}
                    />
                </div>
        } else if ((field.type === "string" || field.type === "int" ) && widget === "select") {
            const cls = classes.row;
            comp = 
                <div key={field.name} className={cls}>
                    <TextField
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        size="small"
                        select
                        value={note[field.name] || ""}
                        onChange={handleChange}
                        helperText=""
                        variant={variant}
                        style={{
                            width: width,
                        }}
                    >
                        {fieldOptions.map((option) => (
                            <MenuItem key={option[0]} value={option[0]}>
                                {option[1]}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
        } else {
            comp = <div>Unknown</div>
        }

        widgets.push(comp);
    }


    return (
        <Dialog className={classes.dialog} open={open} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
            <DialogTitle><Trans>Asset</Trans> - {props.layer.title}</DialogTitle>
            <DialogContent>
                { widgets.map((w: any) => { return w })}
                {//<img src={camionDialog} alt="Camion" style={{ width: "40%", margin: "30px 0" }} />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                <Trans>Close</Trans>
                </Button>
                {/*<Button onClick={handleSave} color="primary">
                <Trans>Save</Trans>
                </Button> */}
            </DialogActions>
        </Dialog>
    );
}

export default AkopicaAssetDialog;
