import L, { circle, geoJSON, Icon, LatLng, LayerEvent, LeafletMouseEvent, marker, PathOptions, rectangle, TooltipOptions } from "leaflet";
import React, { useState, useEffect, Ref } from "react";
import { FeatureGroup } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { getFeaturesGeoJson } from "../../../../features/services";
import FeatureDialog from "./FeatureDialog";

import './../../../../Leaflet.VectorGrid'; 
import { authToken } from "../../../../AppContext";
import { BACKEND_API_URL } from "../../../../lib/config";

interface MyProps {
    layer: any,
    public: boolean,
    allowNote: boolean
};

let myPointToLayer = (geoJsonPoint:any, latlng:any) => {
    return L.marker(latlng, {icon: new Icon({ iconUrl: markerIconPng })});
};

const shapeStyle: PathOptions = {
    color: "#ff7800",
    dashArray: [8],
};

const tooltipOptions: TooltipOptions = {
    permanent: false,
    direction: "center",
    opacity: 0.7,
};

function FeatureLayer(props: MyProps) {
    const layer = props.layer;
    //const settings = props.layer.settings || {};
    const featureGroupRef = React.useRef<L.FeatureGroup>();
    const [selectFeature, setSelectFeature] = useState({ open: false, layer: undefined });
    // const schema = props.layer.dataset?.settings?.schema;
    //let schema:any = null;


    const onClickFeature = (event: LeafletMouseEvent) => {
        console.log("click feature", event);
        setSelectFeature({ open: true, layer: event.propagatedFrom });
    }

    const onCloseFeature = (event: MouseEvent) => {
        setSelectFeature({ open: false, layer: undefined });
    }

    const onSaveFeature = (event: MouseEvent) => {
        //saveFeatures();
    }

    const onLayerAdd = (event: LayerEvent) => {
        const layer: any = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
    }

    async function loadFeatures() {
        const dataset = layer.dataset;
        if (dataset?.id == null || dataset.dstype !== "feature") {
            console.error("Dataset null or type not supported.");
            return;
        }

        try {
            /* ##### TESTING VECTOR TILES WITH LEAFLET VECTOR GRID #####
            featureGroupRef.current?.clearLayers();

            let colorValue = props.layer.settings.color || "#ffeb3b";
            let radius = props.layer.settings.radiusValue || 1;

            let vectorTileStyling = {
                intaOliveros: {
                    
                    fill: true,
                    weight: 1,
                    fillColor: colorValue,
                    color: colorValue,
                    fillOpacity: 0.8,
                    opacity: 1,
                    radius: radius,
                    
                    //icon: new L.Icon({ iconUrl: markerIconPng })
                }
            }

            let vectorTileOptions = {
                interactive: true,
                rendererFactory: L.canvas.tile,
                attribution: 'test',
                vectorTileLayerStyles: vectorTileStyling,
                maxZoom: 16
            };

            
            const url = `${BACKEND_API_URL}/v1/feature/intaOliveros/{z}/{x}/{y}.pbf?token=${authToken()}`;
            const test = L.vectorGrid.protobuf(url, vectorTileOptions)
            .on('click', (e: any) => {
                console.log(e.layer);
                //console.log("aver");
                //L2.DomEvent.stop(e);
            })
            
            featureGroupRef.current?.addLayer(test);
            */

            
            // console.log("feature", props.public)
            const data = await getFeaturesGeoJson(layer.id, props.public);


            // console.log("carga data", data);
            
            if (data === "") {
                return;
            }

            const featureGroup = featureGroupRef.current;
            featureGroup?.clearLayers();

            const features = data.features;

            const pointMode = props.layer.settings.mode;
            const linePointsFlag = props.layer.settings.linepoints;
            let radius = props.layer.settings.radiusValue || 1;
            let colorValue = props.layer.settings.color || "#ffeb3b";

            const scaleXToMeterFunc = (value: number) => {
                return value * 1;
            };

            let linepoints : LatLng[] = [];
            // console.log("loading points", rows)
            features.forEach((feature: any) => {
                if (feature.geometry.type === "Point") {
                    const lat = feature.geometry.coordinates[1];
                    const lon = feature.geometry.coordinates[0];
                    // const lat = feature.properties.lat;
                    // const lon = feature.properties.long;
    
                    if (!lat || !lon) {
                        // console.log("errror no lat o long");
                        return;
                    }
    
                    const radiusValue = parseFloat(radius);
                    // console.log("step2", lat, lon, radiusValue);
                    let labelValue = ("[" + lon + ", " + lat + "]");
                    if (feature.properties.title != null) {
                        labelValue = "<b>" + feature.properties.title + "</b>" ;
                    }
                    if (feature.properties.subtitle != null) {
                        labelValue += "<br>" + feature.properties.subtitle;
                    }
                    
                    if (linePointsFlag) {
                        linepoints.push(new LatLng(lat, lon));
                    }
    
                    const center = new LatLng(lat, lon);
                    // console.log("center", center);
                    let newLayer;
                    if (pointMode === "rectangle") {
                        const layerRect = rectangle(
                            center.toBounds(scaleXToMeterFunc(radiusValue)), {
                            color: colorValue,
                            fillColor: colorValue,
                            fillOpacity: 0.5,
                            stroke: false,
                        });
                        newLayer = layerRect;
                    } else if (pointMode === "circle") {
                        const layerRect = circle(
                            center, {
                            radius: radiusValue,
                            color: colorValue,
                            fillColor: colorValue,
                            fillOpacity: 0.5,
                            stroke: false,
                        });
                        newLayer = layerRect;
                    } else {
                        const layerMark = marker(center, {icon: new Icon({ iconUrl: markerIconPng })});
                        newLayer = layerMark;
                    }
                    newLayer.bindTooltip(labelValue);
                    
                    (newLayer as any).featureProperties = feature.properties;
                    
                    featureGroup?.addLayer(newLayer);
                }
                if (feature.geometry.type === 'Polygon') {
                    try {
                        geoJSON(feature, {
                            style: {...shapeStyle, color: colorValue},
                            pointToLayer: myPointToLayer,
                        }).eachLayer(layer => {                             
                            // bind tooltop with note title if exists
                            const l: any = layer;
                            // const ttLabel = l.feature?.properties?.title;
                            let ttLabel = `<b>${l.feature?.properties['_id']}</b>`;
                            // console.log ("ACTUALIZANDO 2 - PROPERTIES", l.feature?.properties);
                            if (feature.properties.title != null) {
                                ttLabel = "<b>" + feature.properties.title + "</b>" ;
                            }
                            if (feature.properties.subtitle != null) {
                                ttLabel += "<br>" + feature.properties.subtitle;
                            }

                            if (ttLabel) {
                                layer.bindTooltip(ttLabel, tooltipOptions);
                            }
                            (l as any).featureProperties = feature.properties;
    
                            // add note to group
                            featureGroup?.addLayer(layer);
                        });
                    } catch(error) {
                        console.error("Error: loading geojson dataset", [dataset, data, error]);
                    }
                }
            });

            if (linepoints.length > 0) {
                featureGroup?.addLayer(L.polyline(linepoints));
            }
            
            
        } catch (error) {
            console.log("Error", error);
        }
    }

    useEffect(() => {
        //console.log("Layer Feature - cargando");
        loadFeatures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.layer.settings.linepoints,
        props.layer.settings.radiusValue,
        props.layer.settings.mode,
        props.layer.settings.color,
        props.layer.dataset.settings.columnTitle,
        props.layer.dataset.settings.columnSubtitle,
    ]);


    return (
        <React.Fragment>
            <FeatureGroup
                ref={featureGroupRef as Ref<L.FeatureGroup<any>>}
                eventHandlers={{
                    click: onClickFeature,
                    layeradd: onLayerAdd,
                }}
            />

            {selectFeature.open &&
                <FeatureDialog open={true} layer={props.layer} featureLayer={selectFeature.layer} onClose={onCloseFeature} onSave={onSaveFeature}></FeatureDialog>
            }
        </React.Fragment>
    );

}


export default FeatureLayer;
