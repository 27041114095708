import { Button, Container, Grid, Hidden, TextField, Theme, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getAppUser, setAppUser } from '../AppContext';
import { LoadingProgress } from '../lib/components/LoadingProgress/LoadingProgress';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';
import useLoading from '../lib/hooks/useLoading';
import { User } from '../models/auth';
import { getUserById, updateUserById } from './services';


const useStyles = makeStyles((theme: Theme) =>
  ({
    toolbar: {
        paddingRight: 0,
        paddingLeft: 0
    },
    title: {
        flexGrow: 1,
    },
    rowActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
    },
  }),
);

interface UserProfileForm {
    firstname: string,
    lastname: string
}


function UserProfilePage(props: any) {

    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useLoading(false);
    const [user, setUser] = React.useState<User|null>();
    const [userProfileForm, setUserProfileForm] = React.useState<UserProfileForm>({firstname:"", lastname:""});
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});


    useEffect(() => {
        const user = getAppUser();
        if (user != null && user.id != null) {
            getUserProfileInfo(user.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserProfileInfo = (id: string) => {
        setLoading(true);
        getUserById(id)
            .then(async user => {
                setUser(user);
                initUserProfileForm(user);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    }; 

    const updateUserProfile = (id: string, body: User) => {
        setLoading(true);
        updateUserById(id, body)
            .then(async user => {
                setUser(user);
                initUserProfileForm(user);
                setAppUser(user);
                // window.location.reload();
                setSnackBarMsg({msg:t("Update successful"), severity:"success"});
                setOpenSnackbar(true);
                
            })
            .catch(error => {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
                setOpenSnackbar(true);
                console.error('Error:', error);
            })
            .finally( () => {
                setLoading(false);
            }
        );
    };

    const initUserProfileForm = (user: User) => {
        const form: UserProfileForm = {
            firstname: user.firstname ? user.firstname : "",
            lastname: user.lastname ? user.lastname : ""
        };
        setUserProfileForm(form);
    }

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserProfileForm({...userProfileForm, firstname: event.target.value});
    };
    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserProfileForm({...userProfileForm, lastname: event.target.value});
    };

    const handleUpdate = () => {
        if (user != null && user.id != null) {
            const body: User = {
                firstname: userProfileForm.firstname,
                lastname: userProfileForm.lastname
            }
            updateUserProfile(user?.id, body);
        }
    };

    return (
        <Container maxWidth={false}>
            <Toolbar className={classes.toolbar} disableGutters={true}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    <Trans>User Profile</Trans>
                </Typography>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        {/*
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        */}
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                id="username"
                                label={t("User name")}
                                value={user?user.username:""}
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                id="firstname"
                                label={t("First name")}
                                value={userProfileForm.firstname}
                                onChange={handleChangeFirstName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                fullWidth
                                id="lastname"
                                label={t("Last name")}
                                value={userProfileForm.lastname}
                                onChange={handleChangeLastName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={10} className={classes.rowActions}>
                            <Button disabled={loading} onClick={handleUpdate} variant="contained" color="secondary"><Trans>Update</Trans></Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            {loading && <LoadingProgress/>}
        </Container>
    );
}

export default UserProfilePage;