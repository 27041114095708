import React from 'react';
import SelectOrgItem from './SelectOrgItem';
import makeStyles from '@mui/styles/makeStyles';
import { Theme, Container, List, Box, Button } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { SearchResponse } from "./SelectOrgPage";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: SearchResponse,
    next: any,
    prev: any,
};

function SelectOrgList(props: MyProps) {
    const classes = useStyles();

    return (
        <Container maxWidth={false}>
            <List className={classes.root}>
                { 
                    props.data.page.map((org: any) => {
                        return <SelectOrgItem key={org.id} data={org}/>
                    })
                }
            </List>
            <Box>
                <Button onClick={props.prev} startIcon={ <ChevronLeft/> }><Trans>Previous</Trans></Button>
                <Button onClick={props.next} endIcon={ <ChevronRight/> }><Trans>Next</Trans></Button>
            </Box>

        </Container>
    );
}
  
export default SelectOrgList;
