import { httpDelete, httpGet, httpPost, httpPut } from '../AppContext';
import { BACKEND_URL, BACKEND_API_URL } from '../lib/config';
import { Asset } from '../models/asset';
import { Page } from '../models/common';
import { Group } from '../models/group';


const URL_ASSETS = "/v1/assets";
const URL_GROUPS = "/v1/groups";


export async function getAssetsPage(params: any): Promise<Page<Asset>> {
    return httpGet<Page<Asset>>(BACKEND_API_URL + URL_ASSETS, params)
}

export async function getAssetById(id: string): Promise<any> {
    return httpGet<any>(BACKEND_API_URL + URL_ASSETS + `/${id}`)
}

export async function updateAsset(id: string, data:any): Promise<Asset> {
    return httpPut<Asset>(BACKEND_URL + URL_ASSETS + `/${id}`, data)
}

export async function deleteAsset(id: string): Promise<any> {
    return httpDelete<any>(BACKEND_URL + URL_ASSETS + `/${id}`)
}

export async function createAsset(asset: Asset): Promise<Page<Asset>> {
    return httpPost<Page<Asset>>(BACKEND_URL + URL_ASSETS, asset)
}

export async function getGroupsPage(): Promise<Page<Group>> {
    return httpGet<Page<Group>>(BACKEND_URL + URL_GROUPS);
}

export async function createAssetChild(id:string, asset:Asset): Promise<Asset> {
    return httpPost<Asset>(`${BACKEND_API_URL}${URL_ASSETS}/${id}/child`, asset);
}

export async function getAssetChildrenPage(id: string, params: any): Promise<Page<Asset>> {
    return httpGet<Page<Asset>>(`${BACKEND_API_URL}${URL_ASSETS}/${id}/children`, params);
}