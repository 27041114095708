//export const alphanumeric = /^[^\p{Ll}\p{N}]+$/uig;
import moment from "moment";

export const alphanumeric = /[^a-z0-9áéíóúñäëïöü]/ig;
export function slugfy(value: string) : string {
    const slug2 = value.toLocaleLowerCase().trim().replace(/-/ig, "");
    const slug1 = slug2.toLocaleLowerCase().trim().replace(alphanumeric, "-");
    const slug =  slug1.replace(/-+/g, "-");
    return slug;
};

export const sentinelPresets: any = {
    "AGRICULTURE": "Agriculture",
    "BATHYMETRIC": "Bathymetric",
    "FALSE-COLOR": "False Color (vegetation)",
    "FALSE-COLOR-URBAN": "False Color (urban)",
    "GEOLOGY": "Geology",
    "MOISTURE-INDEX": "Moisture Index",
    "NATURAL-COLOR": "Natural Color (true color)",
    "NDVI": "NDVI",           
    "SWIR": "SWIR",          
    "TRUE-COLOR-S2L2A": "True Color S2L2A", 
};

// Generates the subtitle based on the type of layer
export const generateSubtitle = (lytype: string, settings: any) => {
    let subtitle = lytype;
    
    if (lytype === "sentinel") {
        const time = moment(settings.sentinel.date).format("YYYY-MM-DD");
        const preset = sentinelPresets[settings.sentinel.preset];
        subtitle = `${subtitle} - ${time} ${preset}`;
    }
    
    return subtitle;
};

// If the text is longer than a specifict number of characters, it will be shortened
export const handleLongText = (characters: number, text: string) => {
    var textFixed = "";
    if (text.length > characters) {
        textFixed = `${text.substring(0,characters)}...`;
    }
    return textFixed || text;
};