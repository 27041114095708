import React, { ReactEventHandler, createRef, useState, useEffect } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { LeafletMouseEvent, LayerEvent, geoJSON, TooltipOptions, PathOptions, Icon } from "leaflet";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import ShapeDialog from "./ShapeDialog";
import NoteLayer from "../note/NoteLayer";
import { getDatasetFile, getUploadDatasetFileUrl, uploadDatasetFile } from "../../../services";

interface MyProps {
    layer: any,
    allowNote: boolean,
    onChangeMap: Function,
    onChangeLayer: Function
}

export const tooltipOptions: TooltipOptions = {
    permanent: false,
    direction: "center",
    opacity: 0.7,
};

const shapeStyle: PathOptions = {
    color: "#555",
    dashArray: [8],
}

function ShapeLayer(props: MyProps) {
    const layer = props.layer;
    const settings = props.layer.settings || {};
    const featureGroupRef = createRef<L.FeatureGroup>();
    const [selectShape, setSelectShape] = useState({ open: false, layer: undefined , saved: false});

    const filename = "index.shape.geojson";

    function onCreatePath(event: ReactEventHandler) {
        saveShapes();
        console.log("create path");
    }

    useEffect(() => {
        if(!selectShape.open && selectShape.saved) {
            loadShapes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectShape.open, selectShape.saved])

    function saveShapes() {
        const data = featureGroupRef.current?.toGeoJSON();
        if (!data) {
            console.error("Data error: no feature geojson data found.");
            return;
        }
        const fileData = JSON.stringify(data);
        const file = new Blob([fileData], {type: "text/plain"});
        getNewUrl(file, upload);
    }

    async function loadShapes() {
        const dataset = layer.dataset;
        if (dataset?.id) {
            try {
                const data = await getDatasetFile(dataset.id, dataset.path, filename);
                const featureGroup = featureGroupRef?.current;
                featureGroup?.clearLayers();
                try {
                    geoJSON(data, {
                        style: shapeStyle,
                        pointToLayer: myPointToLayer,
                    }).eachLayer(layer => { 
                        // bind tooltop with note title if exists
                        const l: any = layer;
                        // const ttLabel = l.feature?.properties?.title;
                        let ttLabel = "";
                        // console.log ("ACTUALIZANDO 2 - PROPERTIES", l.feature?.properties);
                        for (const key in l.feature?.properties) {
                            ttLabel += "<b>" + key + "</b>: " + l.feature?.properties[key] + "</br>";
                        }

                        if (ttLabel) {
                            layer.bindTooltip(ttLabel, tooltipOptions);
                        }

                        // add note to group
                        featureGroup?.addLayer(layer);
                    });
                } catch(error) {
                        console.error("Error: loading geojson dataset", [dataset, data, error]);
                }
            } catch (error) {
                console.error("Error: loading dataset", [dataset, error]);
            }
        }
    }

    async function upload(file: any, url: string) {
        try {
            await uploadDatasetFile(url, file);
            setSelectShape({open: false, layer: undefined, saved: true});
        } catch (e) {
            console.error("Upload Error: ", e);
        }
    }

    function getNewUrl(file: any, cb: Function) {
        getUploadDatasetFileUrl(layer.dataset.id, layer.dataset.path, filename)
        .then((url) => {
            //console.log("url: ", url);
            cb(file, url);
        }).catch((e) => {
            console.error("getNewUrl Error: ", e);
        });
    }

    function onEditPath(event: ReactEventHandler) {
        saveShapes();
    }

    function onDeletePath(event: ReactEventHandler) {
        saveShapes();
    }

    const onClickFeature = (event: LeafletMouseEvent) => {
        setSelectShape({ open: true, layer: event.layer, saved: false});
    }

    const onCloseShape = (event: MouseEvent) => {
        setSelectShape({ open: false, layer: undefined, saved: false });
    }

    const onSaveShape = (event: MouseEvent) => {
       saveShapes();
    }


    let myPointToLayer = (geoJsonPoint:any, latlng:any) => {
        return L.marker(latlng, {icon: new Icon({ iconUrl: markerIconPng })});
    }

    function onLayerAdd(event: LayerEvent) {
        const layer: any = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
    }

    useEffect(() => {
        loadShapes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.debug("Start AkopicaShapeLayer: props:", props);

    return (
        <React.Fragment>
            <FeatureGroup
                ref={featureGroupRef}
                eventHandlers={{
                    layeradd: onLayerAdd,
                    click: onClickFeature,
                }}
            >

                {settings.shape.edit &&
                <EditControl position="topright"
                    onCreated={onCreatePath}
                    onEdited={onEditPath}
                    onDeleted={onDeletePath}
                    draw={{
                        rectangle: false,
                        circle: false,
                        circlemarker: false,
                    }}
                />
                }
            </FeatureGroup>
            {selectShape.open &&
                <ShapeDialog open={true} layer={props.layer} featureLayer={selectShape.layer} onClose={onCloseShape} onSave={onSaveShape}></ShapeDialog>
            }
            {props.allowNote &&
                <NoteLayer {...props}/>
            }
        </React.Fragment>
    );
}

ShapeLayer.defaultProps = {
    popupContent: '',
}

export default ShapeLayer;
