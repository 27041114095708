import React from 'react';
import { Link as RouterLink } from "@reach/router"
import { Theme, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MapAkopica } from "../models/map"

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: MapAkopica,
}

function MapsListItem(props: MyProps) {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" button component={RouterLink} to={"/maps/" + props.data.id}>
            <ListItemAvatar>
                <Avatar alt="Map" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary = {
                    <React.Fragment>
                        {props.data.title}
                        <Typography component="span" variant="body2" className={classes.inline} color="secondary">
                            { props.data.asset ? " (" + props.data.asset?.title + ")" : "" }
                        </Typography>
                    </React.Fragment>
                }
                secondary = {
                    <React.Fragment>
                        {props.data.group?.title} - { props.data.owner?.firstname + " " + props.data.owner?.lastname }
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}

export default MapsListItem;
