import React from 'react';
import { Theme, ListItemAvatar, Avatar, ListItemText, Typography, ListItemButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { User } from '../models/auth';
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: User,
    handleSelect: Function,
    userSelected: any,
}

function UserListItem(props: MyProps) {
    const classes = useStyles();
    return (
        //button onClick={() => props.handleAdd(props.data)}
        <ListItemButton selected={props.userSelected?.uid === props.data?.uid}  alignItems="flex-start" onClick={() => props.handleSelect(props.data)} >
            <ListItemAvatar>
                <Avatar alt="Group" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary={`${props.data.firstname} ${props.data.lastname}`}
                secondary={
                    <React.Fragment>
                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                        <Trans>Email</Trans>: {props.data.email}
                    </Typography>
                    {""}
                    </React.Fragment>
                }
            />
        </ListItemButton>
    );
}

export default UserListItem;
