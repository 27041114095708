import { BACKEND_PR_URL } from "../../../../lib/config";

export interface GeoIntaProject {
    key: string;
    title: string;
    sublayers: IntaLayer[];
}

export interface IntaLayer {
    type: string;
    title: string;
    list?: IntaLayer[];
    visible?: Boolean;
    wms?: GeoIntaWms; 
}

export interface GeoIntaWms {
    url: string;
    format: string;
    layers: string;
    styles: string;
    transparent: boolean;
    version: string;
    tiled?: boolean;
    srs?: string;
}

// https://inta-akop.net.tecso.coop/geoserversf/wms
// http://geointa.inta.gov.ar/geoserver/gwc/service/wms

const urlAkopicaInta = BACKEND_PR_URL + "/"; // pr.dev.akopica.com

export const geoIntaProjects: GeoIntaProject[] = [
    { 
        title: 'Suelos Santa Fe', key: 'suelos_santa_fe',
        sublayers: [
            {
                type: "group", title: "Mapa básico de suelos",
                list: [
                    {
                        type: "layer", title: "Suelos de la pcia. de Santa Fe (1:250.000)", visible: true,
                        wms: {
                            url: urlAkopicaInta + "geoserversf/wms",
                            format: "image/png",
                            layers: "georafaela:suelos_santa_fe_250",
                            styles: "mapa_suelos_250",
                            transparent: true,
                            version: "1.1.1"
                        }
                    },
                    {
                        type: "layer", title: "Suelos de la pcia. de Santa Fe (1:50 000)", visible: true,
                        wms: {
                            url: urlAkopicaInta + "geoserversf/wms",
                            format: "image/png",
                            layers: "georafaela:suelos_santa_fe",
                            styles: "mapa_suelos",
                            transparent: true,
                            version: "1.1.1"
                        }
                    }
                ]
            },
            {
                type: "layer", title: "Capacidad Productiva de las Tierras", visible: true,
                wms: {
                    url: urlAkopicaInta + "geoserversf/wms", 
                    format: "image/png",
                    layers: "georafaela:cpa_def_latlong",
                    styles: "CPA",
                    transparent: true,
                    version: "1.1.1"
                }
            },
            {
                type: "layer", title: "Distritos Santa Fe", visible: true,
                wms: {
                    url: urlAkopicaInta + "geoserversf/wms",
                    format: "image/png",
                    layers: "georafaela:distritos",
                    styles: "distritos",
                    transparent: true,
                    version: "1.1.1"
                }
            },
        ]
    },
    { 
        title: 'Suelos de la República Argentina', key: 'suelos_argentina',
        sublayers: [
            {
                type: "group", title: "1:500.000 y 1:1.000.000",
                list: [
                    {
                        type: "layer", title: "Suelos de la República Argentina 1:500.000 y 1:1.000.000", visible: true,
                        wms: {
                            url: urlAkopicaInta + "geoserversf/wms",
                            format: "image/png",
                            layers: "vectores:suelos_argentina_1_500",
                            styles: "suelos_500k",
                            transparent: true,
                            version: "1.1.1"
                        }
                    },
                ]
            },
            /*
            {
                type: "group", title: "1:50.000",
                list: [
                    {
                        type: "layer", title: "Suelos Buenos Aires 1:50.000", visible: false,
                        wms: {
                            url: "Suelos Buenos Aires 1:50.000",
                            format: "image/png",
                            layers: "vectores:vst_suelos_buenos_aires_50000",
                            styles: "cartas_suelos_bsas_1.0",
                            transparent: true,
                            version: "1.1.1"
                        }
                    },
                ]
            },
            */
            
        ]
    },
    { 
        title: 'Suelos de la provincia de Corrientes', key: 'suelos_prov_corrientes',
        sublayers: [
            {
                type: "group", title: "Corrientes",
                list: [
                    {
                        type: "layer", title: "Departamentos de Corrientes", visible: true,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_dptos_2012",
                            styles: "Departamentos",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Río Paraná y Uruguay en Corrientes", visible: true,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_rios_principales",
                            styles: "Rios_Principales",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Cuerpos de agua en Corrientes", visible: false,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_cuerpos_agua",
                            styles: "Cuerpos de agua",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Suelos de Corrientes 1:500.000 ", visible: false,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_suelos_500",
                            styles: "suelos_500",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Red vial nacional de Corrientes", visible: false,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_red_vial_nacional",
                            styles: "Rutas_nacionales",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Red vial provincial", visible: false,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_red_vial_provincial",
                            styles: "rutas_provinciales",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Ríos y arroyos de Corrientes", visible: false,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_rios_arroyos",
                            styles: "Rios y Arroyos",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                    {
                        type: "layer", title: "Localidades de Corrientes", visible: true,
                        wms: {
                            url: urlAkopicaInta + "geocorrientes/gwc/service/wms",
                            format: "image/png",
                            layers: "geocorrientes:Ctes_localidades",
                            styles: "capitals",
                            transparent: true,
                            version: "1.1.1",
                            tiled: true,
                            srs: "EPSG:3857"
                        }
                    },
                ]
            },
            
        ]
    },
    { 
        title: 'Mapa Nacional de Cultivos 2020-2021', key: 'mapa_cultivos_nacional_2020_2021',
        sublayers: [
            {
                type: "layer", title: "Mapa Nacional de Cultivos Extensivos - Invierno 2020", visible: true,
                wms: {
                    url: urlAkopicaInta + "geoserversf/wms",
                    format: "image/png",
                    layers: "rasters:invierno2020",
                    styles: "MNC_INV_2020",
                    transparent: true,
                    version: "1.1.1"
                }
            },
            {
                type: "layer", title: "Mapa Nacional de Cultivos Extensivos - Verano 2021", visible: true,
                wms: {
                    url: urlAkopicaInta + "geoserversf/wms",
                    format: "image/png",
                    layers: "rasters:verano2021",
                    styles: "MNC_VER_2021",
                    transparent: true,
                    version: "1.1.1"
                }
            },
            
        ]
    },
];