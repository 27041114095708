import { useState } from 'react';


const useLoading = (initL: boolean):[boolean, (flag: boolean) => void] => {
    const [loading, setLoadingInt] = useState(initL);
    let loadingSmp = 0;

    const setLoading = (flag: boolean) => {
        if (flag) {
            loadingSmp++;
            setLoadingInt(true);
        } else {
            if (loadingSmp > 0) {
                loadingSmp--;
                if (loadingSmp <= 0) {
                    loadingSmp = 0;
                    setLoadingInt(false);
                } else {
                    setLoadingInt(true);
                }
            } else {
                setLoadingInt(false);
            }
        }
        
    }

    return [loading, setLoading]
}


export default useLoading;
