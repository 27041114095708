import { WMSParams } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { WMSTileLayer } from "react-leaflet";
import { IntaLayer } from './GeoIntaProjectsData';

interface MyProps {
    layer: any,
}

interface GeointaLayerParams extends WMSParams {
    tiled?: boolean;
    srs?: string;
}

function GeoIntaLayer(props: MyProps) {
    const [wstLayerList, setWstLayerList] = useState<IntaLayer[]>([]);

    useEffect(() => {
        if (props.layer.settings.proyect != null) {
            setWstLayerList(props.layer.settings.proyect.sublayers);
        } else {
            setWstLayerList([]);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.layer.settings.proyect]);

    
    const loadWstLayerList = (layerList: IntaLayer[]) => {
        return (
            <React.Fragment>
                {
                    layerList.map((item: IntaLayer, i: number) => {
                        return (
                            <React.Fragment key={i}>
                                {
                                    item.type === "group" &&
                                    <React.Fragment>
                                        {loadWstLayerList(item.list || [])}
                                    </React.Fragment>
                                }
                                {
                                    item.type === "layer" && item.visible && item.wms != null && item.wms.tiled == null &&
                                    <WMSTileLayer 
                                        url={item.wms.url}
                                        format={item.wms.format}
                                        layers={item.wms.layers}
                                        styles={item.wms.styles}
                                        transparent={item.wms.transparent}
                                        version={item.wms.version}
                                    />
                                }
                                {
                                    item.type === "layer" && item.visible && item.wms != null && item.wms.tiled != null &&
                                    <WMSTileLayer 
                                        url={item.wms.url}
                                        format={item.wms.format}
                                        layers={item.wms.layers}
                                        styles={item.wms.styles}
                                        transparent={item.wms.transparent}
                                        version={item.wms.version}
                                        params={{
                                            layers: item.wms.layers,
                                            tiled: item.wms.tiled,
                                            srs: item.wms.srs,
                                        } as GeointaLayerParams}
                                    />
                                }
                            </React.Fragment>
                        )
                    })
                }
            </React.Fragment>
        )
    };


    /**http://geointa.inta.gov.ar/geoserversf/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=georafaela:distritos&TILED=true&SRS=EPSG:3857&STYLES=distritos&WIDTH=256&HEIGHT=256&BBOX=-5009377.085697312,-3130860.6785608195,-4383204.949985148,-2504688.5428486555 */
    /**http://geointa.inta.gov.ar/geoserversf/wms?service=WMS&request=GetMap&layers=georafaela%3Adistritos&styles=&format=image%2Fpng&transparent=false&version=1.1.1&width=256&height=256&srs=EPSG%3A3857&bbox=-6887893.492833803,-4070118.882129064,-6731350.458905762,-3913575.848201024 */
    /**http://geointa.inta.gov.ar/geoserversf/wms?service=WMS&request=GetMap&layers=georafaela%3Adistritos&styles=distritos&format=image%2Fpng&transparent=false&version=1.1.1&width=256&height=256&srs=EPSG%3A3857&attribution=%C2%A9%20%3Ca%20href%3D%22http%3A%2F%2Fgeointa.inta.gov.ar%2F%22%3EGeo%20Inta%3C%2Fa%3E&bbox=-7200979.560689886,-4070118.882129064,-7044436.526761844,-3913575.848201024 */
    return(
        <React.Fragment>
            {
                loadWstLayerList(wstLayerList)
            }
        </React.Fragment>
    )
}

export default GeoIntaLayer;
