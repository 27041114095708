import React from 'react';
import { Link as RouterLink } from "@reach/router"
import { Theme, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Group } from '../models/group';
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
        inline: {
            display: "inline",
        },
    }),
);

interface MyProps {
    data: Group,
}

function GroupListItem(props: MyProps) {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" button component={RouterLink} to={"/groups/" + props.data.id}>
            <ListItemAvatar>
                <Avatar alt="Group" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
                primary={props.data.title}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            <Trans>Detail</Trans>: {props.data.detail}
                        </Typography>
                        {""}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
  }

export default GroupListItem;
