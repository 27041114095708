import React, { useEffect, useState } from 'react';
import {
    Theme,
    Typography,
    Button,
    Container,
    Box,
    Grid,
    Hidden,
    Toolbar,
    TextField,
    Tabs,
    Tab,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {Link as RouterLink, navigate, RouteComponentProps} from "@reach/router";
import { cleanDataset, deleteDataset, getDataset, getSize, updateDataset, uploadFile } from './services'
import { Dataset, Files } from '../models/dataset'
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BuildIcon from '@mui/icons-material/Build';
import { useTranslation, Trans } from "react-i18next";
import LoadingMsg from '../layout/LoadingMsg';
import SnackbarObject from '../lib/components/Snackbar/SnackbarObject';
import { LoadingRight } from '../lib/components/LoadingProgress/LoadingProgress';
import DatasetFeatureConfig from './DatasetFeatureConfig';

const useStyles = makeStyles((theme: Theme) =>
    ({
        toolbar: {
            paddingRight: 0,
            paddingLeft: 0
        },
        title: {
            flexGrow: 1,
        },
        marginBottom2: {
            marginBottom: theme.spacing(2),
        },
        rowActions:{
            width: "100%",
            textAlign: "right",
        },
        tabs: {
            backgroundColor: "#aed1cd", // theme.palette.grey.A100,
        },
    }),
);


interface DatasetPageProps extends RouteComponentProps {
    datasetId?: string;
    location?: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

interface InputError {
    title?: string,
    subject?: string,
}

export interface UpdateInput {
    subject?: string,
    title?: string,
    detail?: string,
    settings?: any,
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
        {value === index && (        
            <Box>
                <br></br>
                {children}
            </Box>
        )}
        </div>
    );
}

function tabProps(index: any) {
    return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
}

const separators = [
    {value: ',', text: 'Comma'},
    {value: ';', text: 'Semicolon'},
    {value: '|', text: 'Pipe'},
]

function DatasetPage(props: DatasetPageProps) {
    const classes = useStyles();
    const [dataset, setDataset] = useState({} as Dataset);
    const [canEdit, setCanEdit] = useState<boolean>(true);
    const [inputError, setInputError] = React.useState<InputError>({});
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [tab, setTab] = useState<number>(props?.location?.state?.new ? 1 : 0);
    const [loadingFile, setLoadingFile] = useState<boolean>(false);
    const [files, setFiles] = useState<Files>();
    const [isCsv, setIsCsv] = useState<boolean>(false);
    const [separator, setSeparator] = useState<string>(',');
    const [selectedFile, setSelectedFile] = React.useState<File|null>();
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [openCleanDataset, setOpenCleanDataset] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenCleanDataset = () => {
        setOpenCleanDataset(true);
    };

    const handleOnChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => setSeparator(event.target.value);

    const handleDelete = async() => {
        handleCloseAlert();
        try {
            setLoading(true);
            const isFeature = dataset.dstype === "feature" || dataset.dstype === "annotation";
            await deleteDataset(dataset.id, isFeature);
            navigate('/datasets/');
        } catch(error: any){
            setLoading(false);
            if (error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        }   
    };

    const handleCloseAlert = () => {
        setOpen(false);
    };

    const handleCloseCleanDataset = () => {
        setOpenCleanDataset(false);
    };

    const handleUploadFile = async() => {
        try {
            setLoadingFile(true);
            const isFeature = dataset.dstype === "feature";

            if(selectedFile) {
                await uploadFile(selectedFile, dataset.id, isFeature, separator);
                if (!isFeature) await getFilesSize();
                if (isFeature) {
                    const data = await getDataset(dataset.id);
                    if (data) {
                        dataset.settings = data.settings;
                        setDataset({...dataset})
                        setSelectedFile(null);
                        setIsCsv(false);
                        setSeparator(',');
                    }
                }
                setSnackBarMsg({msg:t("Upload Successfully"), severity:"success"});
                setOpenSnackbar(true);
            }

        } catch(error: any) {
            if(error?.error?.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else if (error?.message) {
                setSnackBarMsg({msg:t(error.message), severity:"error"});
            }   else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }
            setOpenSnackbar(true);
        } finally {
            setLoadingFile(false);
        }
    }

    const handleCleanDataset = async() => {
        try {
            setLoading(true);
            if (dataset.dstype === "feature") {
                await cleanDataset(dataset.id);
                const newSettings = dataset.settings;
                delete newSettings?.columns;
                delete newSettings?.columnLatitude;
                delete newSettings?.columnLongitude;
                delete newSettings?.fileName;
                setDataset({settings: newSettings.settings, ...dataset});
                setSnackBarMsg({msg:t("Clean Successfully"), severity:"success"});
                setOpenSnackbar(true);
               
            }
        } catch (error: any) {
            setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            setOpenSnackbar(true);
        } finally {
            setOpenCleanDataset(false);
            setLoading(false);
        }
    } 

    const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const selectFile = (e: React.ChangeEvent<HTMLInputElement> ) => {
        const fileList = e.target.files;

        if (!fileList) return;

        const file = fileList[0];
        file?.name?.endsWith('.csv') ? setIsCsv(true): setIsCsv(false);

        if (file?.size > 300*1024*1024) {
            setSnackBarMsg({msg:t("The file you selected is too large"), severity:"error"});
            setOpenSnackbar(true);
        } else {
            setSelectedFile(file);
        }
    };

    const handleSize = (size: number) => {
        const s = size > 1000000000 ? 
            `${(size/(1024*1024*1024)).toFixed(2)} Gb` : size > 1000000 ? 
                `${(size/(1024*1024)).toFixed(2)} Mb` : `${(size/1024).toFixed(2)} Kb`
        return s
    }

    const getFilesSize = async() => {
        try{
            const res = await getSize(dataset.id)
            if (res) {
                setFiles(res);
            }
        } catch (error) {
            setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            setOpenSnackbar(true);
        }
    }

    // handle input changes
    const handleChange =  (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const prop = event.target.id;
        if (prop === "title") {
            if (value.trim() === "") {
                setInputError({...inputError, title: t("Please provide a Title")});
            } else {
                setInputError({...inputError, title:undefined});
            } 
        }

        setDataset({...dataset, [prop]: value });
    };

    const validateData = () => {
        if(dataset.title?.trim() === "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const handleSave = async () => {
        try {
            const id = props.datasetId ? props.datasetId : ""
            const params = {
                title: dataset.title, 
                detail: dataset.detail
            }
            const res = await updateDataset(id, params);
            if (res) {
                setSnackBarMsg({msg:t("Saved Successfully"), severity:"success"});
                setOpenSnackbar(true);
            }
        } catch (error: any) {
            if (error.error.message) {
                setSnackBarMsg({msg:t(error.error.message), severity:"error"});
            } else {
                setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            }

            setOpenSnackbar(true);
        }
    };

    useEffect(() => {
        if (!props.datasetId) {
            return;
        }

        getDataset(props.datasetId)
        .then(data => {
            setDataset(data);
            if(data.group.my_role === "groupReader") {
                setCanEdit(false);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateData();

        if(dataset.id) {
            getFilesSize();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataset]);

    if (!dataset.id) {
        return ( <LoadingMsg></LoadingMsg> )
    }

    return (
        <Container maxWidth={false} >
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <Toolbar disableGutters={true} className={classes.toolbar}>
                <Typography component="div" variant="h5" color="inherit" noWrap className={classes.title}>
                    {dataset.title}
                </Typography>
            </Toolbar>
            <Grid container spacing={2} direction="row-reverse">
                <Hidden mdDown>
                    <Grid item md={4}>
                        <Typography component="h1" variant="h6" color="primary" noWrap>
                            <Trans>Tools</Trans>
                        </Typography>
                        <Box>
                            <Button component={RouterLink} to="/datasets" color="secondary" startIcon={<SearchIcon />}><Trans>Search Dataset</Trans></Button>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Container>
                        <Tabs
                            className={classes.tabs}
                            indicatorColor="secondary"
                            value={tab}
                            onChange={handleChangeTab}
                            variant="fullWidth"
                            >

                            <Tab label={t("Main")} {...tabProps(0)}></Tab>
                            <Tab label={t("Storage")} {...tabProps(1)}></Tab>
                            { dataset.dstype === "feature" && (
                                <Tab label={t("Settings")} {...tabProps(3)} disabled={!Boolean(dataset.settings?.fileName)}></Tab>
                                )
                            }
                            {/*
                            <Tab label={t("Schema")} {...tabProps(2)}></Tab>
                            <Tab label={t("Pipelines")} {...tabProps(3)}></Tab>
                            */}

                        </Tabs>

                    {/* Main Panel */}
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography component="h1" variant="h6" color="secondary" noWrap>
                                    <Trans>Title</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                        fullWidth
                                        id="title"
                                        label={t("Title")}
                                        value={dataset.title || ""}
                                        onChange={handleChange}
                                        variant="outlined"       
                                        helperText={inputError.title}
                                        error={inputError.title !== undefined}
                                        required
                                        disabled={!canEdit}
                                    />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                        fullWidth
                                        id="name"
                                        label={t("Name")}
                                        value={dataset.name || ""}
                                        variant="outlined"
                                        disabled
                                    />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                        fullWidth
                                        id="detail"
                                        label={t("Detail")}
                                        value={dataset.detail || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        disabled={!canEdit}
                                    />
                            </Grid>

                            <Grid item xs={12} md={12} className={classes.rowActions}>
                                <Button onClick={handleClickOpen} disabled={loading || !canEdit} variant="outlined" color="secondary"><Trans>Delete</Trans></Button>
                                <Dialog
                                    open={open}
                                    onClose={handleCloseAlert}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{t("Delete Dataset")}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Trans>Are you sure you want to permanently delete this dataset?</Trans>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseAlert} color="primary">
                                            <Trans>No</Trans>
                                        </Button>
                                        <Button onClick={handleDelete} color="primary" autoFocus>
                                        <Trans>Yes</Trans>
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button onClick={handleSave} disabled={disabled || loading || !canEdit} variant="contained" color="secondary"><Trans>Save</Trans></Button>
                                { loading ? <LoadingRight/> : null}
                            </Grid>
                        </Grid>
                    </TabPanel>

                    {/* Storage panel */}
                    <TabPanel value={tab} index={1}>
                        <Grid container spacing={2}>
                            
                            { dataset.dstype === "feature" && dataset.settings?.fileName ?
                                <Grid item xs={12} md={12}>
                                    <Typography component="h1" variant="h6" color="secondary" noWrap>
                                        <Trans>Uploaded File</Trans>
                                    </Typography>
                                </Grid>
                                : 
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Typography component="h1" variant="h6" color="secondary" noWrap>
                                        <Trans>Upload Files</Trans>
                                        </Typography>
                                    </Grid>
                                    {dataset.dstype === "feature" && 
                                        <Grid item xs={12} md={12}>
                                            <Typography component="h1" variant="subtitle2" color="primary">
                                                <Trans>Select a file with xls, xlsx, csv or geojson extension.</Trans>   
                                            </Typography>
                                        </Grid>
                                    }
                                </>
                            }

                            <Grid item xs={12} md={10} style={{ textAlign: "left",}}>   
                                { dataset.dstype === "feature" && dataset.settings?.fileName 
                                    ? <Chip color="primary" label={dataset.settings?.fileName} variant="outlined" />     
                                    : <label htmlFor="btn-upload">
                                        <input
                                            id="btn-upload"
                                            name="btn-upload"
                                            style={{display:'none'}}
                                            type="file"
                                            disabled={Boolean(dataset.settings?.fileName) || loadingFile || !canEdit}
                                            onChange={selectFile}
                                        />
                                        <Grid container style={{alignItems: 'space-between'}}>
                                            <Grid item>
                                                <Button
                                                    className="btn-choose"
                                                    variant="outlined"
                                                    component="span"
                                                    color="secondary"
                                                    style={{marginRight: 25}}
                                                    disabled={Boolean(dataset.settings?.fileName) || loadingFile || !canEdit}
                                                >
                                                    <Trans>Choose File</Trans>
                                                </Button>
                                            </Grid> 
                                            <Grid item>
                                                <TextField
                                                    variant="standard"
                                                    value={selectedFile ? selectedFile.name : t("No file selected")}
                                                    disabled
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </label>
                                }
                            </Grid>
                            
                            {isCsv && dataset.dstype === "feature" && (
                                <Grid item lg={12} xs={12} md={12} sx={{marginTop: "10px"}}>
                                    <Grid container>
                                        <Grid item lg={12} xs={12} md={12}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ marginBottom: '8px'}}
                                            >
                                                <Trans>Select the separator of the fields in the csv file, by default it will use the comma.</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} md={3} >
                                            <TextField
                                                select
                                                id="separator"
                                                name="separator"
                                                fullWidth
                                                size="small"
                                                value={separator ?? ','}
                                                onChange={handleOnChangeSelect}
                                                label={t("Separator")}
                                                disabled={loadingFile || !canEdit}
                                            >
                                                {separators.map((sep, i) => (
                                                    <MenuItem key={i} value={sep.value}>
                                                        <Trans>{sep.text}</Trans> ({sep.value})
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item xs={12} md={12} className={classes.rowActions}>
                                {   dataset.dstype === "feature" && (
                                        <Button onClick={handleClickOpenCleanDataset} disabled={loading || Boolean(!dataset.settings?.fileName) || !canEdit} variant="outlined" color="secondary"><Trans>Clean</Trans></Button>
                                    )
                                }
                                
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button disabled={!selectedFile||loadingFile} onClick={handleUploadFile} variant="contained" color="secondary"><Trans>Upload File</Trans></Button>{loadingFile && (<LoadingRight/>)}
                                <Dialog
                                    open={openCleanDataset}
                                    onClose={handleCloseCleanDataset}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{t("Clean Dataset")}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <Trans>Are you sure you want to permanently clean this dataset?</Trans>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseCleanDataset} color="primary">
                                            <Trans>No</Trans>
                                        </Button>
                                        <Button onClick={handleCleanDataset} color="primary" autoFocus>
                                        <Trans>Yes</Trans>
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                            
                            {dataset.dstype !== "feature" && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Typography component="h1" variant="h6" color="secondary" noWrap>
                                            <Trans>Folders</Trans>
                                        </Typography>
                                    </Grid>
                                    
                                    {(files !== undefined && !loadingFile) ? (
                                        <React.Fragment>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    fullWidth
                                                    id="size"
                                                    label={t("Files size")}
                                                    value={handleSize(files?.size || 0)}
                                                    variant="standard"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                {files != null && files?.files.length > 0 && 
                                                    <Typography component="h1" variant="h6" color="secondary" noWrap>
                                                        Archivos    
                                                    </Typography>
                                                }
                                            
                                                { files?.files.map((file,i) => (                                      
                                                    <List dense={true} disablePadding={true} key={i}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={file.name}
                                                                secondary={handleSize(file.size)}
                                                            />
                                                        </ListItem>
                                                    </List>       
                                                ))}
                                            </Grid> 
                                        </React.Fragment>) : <LoadingRight/>
                                    }
                                </>
                            )}
                            
                        </Grid>
                    </TabPanel>
                    
                    {/* Settings Panel */}
                    <TabPanel value={tab} index={2}>
                        <DatasetFeatureConfig originDataset={dataset} canEdit={canEdit}/>
                    </TabPanel>
                    {/* Schemas Panel */}
                    <TabPanel value={tab} index={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography component="h1" variant="h6" color="secondary" noWrap>
                                    <Trans>Schema Type</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="schemaName"
                                    label={t("Schema Name")}
                                    value="Application Plan"
                                    variant="standard"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="layerType"
                                    label={t("Layer Type")}
                                    value="Point"
                                    variant="standard"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Typography component="h1" variant="h6" color="secondary" noWrap>
                                    <Trans>Options</Trans>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    onChange={() => console.log('resolutionX')}
                                    id="resolutionX"
                                    label={t("Resolution X [px/m]")}
                                    value="5"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    onChange={() => console.log('resolutionY')}
                                    id="resolutionY"
                                    label={t("Resolution Y [px/m]")}
                                    value="5"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    onChange={() => console.log('resolutionZ')}
                                    id="resolutionZ"
                                    label={t("Resolution Z [px/m]")}
                                    value="5"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={12}
                                    onChange={() => console.log('colorPalette')}
                                    id="colorPalette"
                                    label={t("Color Palette")}
                                    variant="standard"
                                    size="small"
                                    value={'\
[\n\
[0, "#fffff", "const"],\n\
[0.25, "#d50101", "const"],\n\
[0.3,  "#d50101", "const"],\n\
[0.35, "#d50101", "const"],\n\
[0.4,  "#f9e500", "const"],\n\
[0.45, "#f9e500", "const"],\n\
[0.5,  "#f9e500", "const"],\n\
[0.55, "#59c101", "const"],\n\
[0.6,  "#59c101", "const"],\n\
[0.65, "#59c101", "const"],\n\
[0.7,  "#59c101", "const"],\n\
[0.75, "#007900", "const"],\n\
[0.8,  "#007900", "const"],\n\
[0.85, "#007900", "const"],\n\
[0.9,  "#007900", "const"],\n\
[0.95, "#007900", "const"],\n\
[1,    "#007900", "const"]\n\
]'}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} className={classes.rowActions}>
                                <Button onClick={handleSave} variant="outlined" color="secondary"><Trans>Delete Schema</Trans></Button>
                                &nbsp;&nbsp;
                                <Button onClick={handleSave} variant="contained" color="secondary"><Trans>Save</Trans></Button>
                            </Grid>
                        </Grid>
                    </TabPanel>


                    {/* Pipelines Panel */}
                    <TabPanel value={tab} index={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography component="h1" variant="h6" color="secondary" noWrap>
                                    <Trans>Installed Pipelines</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography>Recomendación Fitosanitaria</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{display: "block"}}>
                                        <p>1. Ortomosaico</p>
                                        <p>2.1 Prescripción Fertilizante</p>
                                        <p>2.2 Prescripción Fumigación</p>
                                        <p>&nbsp;</p>
                                        <p>Execute on create: True &nbsp;&nbsp;&nbsp;&nbsp; Execute on change: True</p>
                                        <div className={classes.marginBottom2}>
                                            <Button startIcon={<BuildIcon/>} variant="contained" color="primary"><Trans>Exceute now</Trans></Button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                        <Typography>Otro Pipeline....</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails style={{display: "block"}}>
                                        <p>1. ...</p>
                                        <p>2. ...</p>
                                        <p>3. ...</p>

                                        <p>&nbsp;</p>
                                        <p>Execute on create: True &nbsp;&nbsp;&nbsp;&nbsp; Execute on change: True</p>

                                        <div className={classes.marginBottom2}>
                                            <Button startIcon={<BuildIcon/>} variant="contained" color="primary"><Trans>Exceute now</Trans></Button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} md={12} className={classes.rowActions}>
                                <Button onClick={handleSave} variant="contained" color="secondary"><Trans>Install Pipeline</Trans></Button>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DatasetPage;
