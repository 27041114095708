import React, { useState, useEffect } from "react";
import { Box, styled, Tab, Tabs, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SettingsDetail from "../../settings/SettingsBasic";
import { useTranslation } from "react-i18next";
import GeoIntaProjects from "./GeoIntaProjects";


const useStyles = makeStyles((theme: Theme) =>
    ({
        settingsRow: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(3),
        },
        tabs: {
            backgroundColor: '#aed1cd',
        },
    })
);

export const SettingTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(2),
    marginLeft: 0,
    marginTop: theme.spacing(1),
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ marginTop: '-8px' }}>
                <br></br>
                {children}
            </Box>
        )}
        </div>
    );
}



interface MyProps {
    map: any;
    layer: any;
    onChangeLayer: Function;
    changeSubtitle: Function;
}

function SentinelSettings(props: MyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(0);


    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };
   


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box style={{ padding: '0px' }}>
            <Tabs
                className={classes.tabs}
                indicatorColor="secondary"
                value={tab}
                onChange={handleChangeTab}
                variant="fullWidth"
            >
                <Tab label={t('Projects')}></Tab>
                <Tab label={t('Settings')}></Tab>
            </Tabs>

            {/*Browse Tab*/}
            <TabPanel value={tab} index={0}>
                <GeoIntaProjects layer={props.layer} onChangeLayer={props.onChangeLayer}/>
            </TabPanel>
            {/*Settings Tab*/}
            <TabPanel value={tab} index={1}>
                <SettingsDetail {...props} allowNote={true}></SettingsDetail>
            </TabPanel>
        </Box>
    )
}

export default React.memo(SentinelSettings);
