import React, { useEffect, useState } from 'react';
import { AppBar, Box, Theme, Toolbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {MapContainer, ZoomControl} from 'react-leaflet';
import moment from 'moment';
import { sentinelPresets } from '../lib/utils';
import AkopicaLayer from '../maps/akopicamap/AkopicaLayer';
import { getPublicMapById } from './services';
import AkopicaMapMenu from '../maps/akopicamap/AkopicaMapMenu';

const useStyles = makeStyles((theme: Theme) =>
    ({
        toolbar: {
            paddingRight: 24,
        },
        logo: {
            maxWidth: "120px",
            marginRight: "16px",
            marginTop: "4px",
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: 240, // // 240 == drawerwidth
            width: "calc(100% - 240px)", // 240 == drawerwidth
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    }),
);

function PublicMapPage(props: any) {
    const classes = useStyles();

    const [map, setMap] = useState<any>({
        editFeature: false,
        editFeatureLayer: null,
        public: false,
        layers: [],
        settings: { 
            viewport: {
                center: null,
                zoom: null
            }}
    });

    const [intoLayer, setIntoLayer] = useState<string>("");
    const [mapInstance, setMapInstance] = useState<any>();

    const loadMap = (id: string) => {
        
        getPublicMapById(id)
            .then(async map => {
                configMap(map);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally( () => {
            }
        );
        
    };

    const handleChangeMap = () => {
        loadMap(props.mapId || "");
        const cloned = Object.assign({}, map);
        setMap(cloned);
    }

    const handleChangeLayer = (layer: any, changed: string, value: any) => {   
        const cloned = Object.assign({}, map);
        const layerInMap = cloned.layers.find((itemLayer: any) => itemLayer.id === layer.id);
        layerInMap.settings.basic.active = layer.settings.basic.active;
        cloned.layers = cloned.layers.sort((a: any, b: any) => a.settings.basic.order - b.settings.basic.order);
        setMap(cloned);
    }

    const handleRemoveLayer = (layer: any, changed: string, value: any) => {        
    }

    const handleEditLayer = (title: string, layerId: string) : void => {  
    }

    const configMap = async (data: any) => {
        // Initialiing settings, basic settings values
        data.layers.map((ly: any) => {

            ly.settings = ly.settings || {};            
            // init basic
            ly.settings.basic = ly.settings.basic || {};
            ly.settings.subtitle = ly.settings.subtitle || ly.lytype;
            const basic = ly.settings.basic;
            basic.active = basic.active === undefined ? true : basic.active;
            basic.noteView = false; // basic.noteView === undefined ? true  : basic.noteView;
            basic.noteEdit = false; // basic.noteEdit === undefined ? false : basic.noteEdit;
            basic.order = basic.order || 100;

            // init shape
            if (ly.lytype === "shape") {
                // shape config
                ly.shape = ly.shape || {};

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                // settings config
                ly.settings.shape = ly.settings.shape || {};
                ly.settings.shape.edit = ly.settings.shape.edit || false;
            }

            // init note
            if (ly.lytype === "note") {
                // note config
                ly.note = ly.note || {};

                // settings config
                ly.settings.note = ly.settings.note || {};
                ly.settings.note.edit = ly.settings.note.edit || false;
            }
            
            // init point
            if (ly.lytype === "point") {
                // point config
                ly.point = ly.point || {};

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note;

                // settings config
                ly.settings.point = ly.settings.point || {};
                ly.settings.point.edit = ly.settings.point.edit === undefined ? false : ly.settings.point.edit;
            }

            if (ly.lytype === "tile") {
                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note;

                // settings config
                ly.settings.tile = ly.settings.tile || {};
                ly.settings.tile.opacity = ly.settings.tile.opacity || 100;
                ly.settings.tile.brightness = ly.settings.tile.brightness || 100;
                ly.settings.tile.contrast = ly.settings.tile.contrast || 100;
                ly.settings.tile.hue = ly.settings.tile.hue || 0;
                ly.settings.tile.saturate = ly.settings.tile.saturate || 100;

            }

            if (ly.lytype === "image") {
                // image config
                ly.image = ly.image || {};

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note;

                // settings config
                ly.settings.image = ly.settings.image || {};
                ly.settings.image.thresholdArray = ly.settings.image.thresholdArray || [0, 20];
                ly.settings.image.maxscoreArray = ly.settings.image.maxscoreArray || [0, 100]; 
            }

            if (ly.lytype === "sentinel") {
                // settings config
                let dateAfter = moment(new Date()).format("YYYY-MM-DD");
                let dateBefore: Date|string = new Date();
                dateBefore.setDate(dateBefore.getDate() - 10);
                dateBefore = moment(dateBefore).format("YYYY-MM-DD");

                const time = `${dateBefore}/${dateAfter}`;
                ly.settings.sentinel = ly.settings.sentinel || {};
                ly.settings.sentinel.preset = ly.settings.sentinel.preset || "TRUE-COLOR-S2L2A";
                ly.settings.sentinel.maxcc = ly.settings.sentinel.maxcc || 20;
                ly.settings.sentinel.time = ly.settings.sentinel.time || time;
                ly.settings.sentinel.interval = ly.settings.sentinel.interval || 10;
                ly.settings.sentinel.date = ly.settings.sentinel.date || new Date();
                
                const subtitle = `${ly.lytype} - ${dateAfter} ${sentinelPresets[ly.settings.sentinel.preset]}`;
                ly.settings.subtitle = ly.settings.subtitle || subtitle;
            }

            if (ly.lytype === "geoInta") {
                // settings config
                let dateAfter = moment(new Date()).format("YYYY-MM-DD");
                let dateBefore: Date|string = new Date();
                dateBefore.setDate(dateBefore.getDate() - 10);
                dateBefore = moment(dateBefore).format("YYYY-MM-DD");

                const time = `${dateBefore}/${dateAfter}`;
                ly.settings.geoInta = ly.settings.geoInta || {};
                ly.settings.geoInta.preset = ly.settings.geoInta.preset || "TRUE-COLOR-S2L2A";
                ly.settings.geoInta.maxcc = ly.settings.geoInta.maxcc || 20;
                ly.settings.geoInta.time = ly.settings.geoInta.time || time;
                ly.settings.geoInta.interval = ly.settings.geoInta.interval || 10;
                ly.settings.geoInta.date = ly.settings.geoInta.date || new Date();
                
                const subtitle = `${ly.lytype} - ${dateAfter} ${sentinelPresets[ly.settings.geoInta.preset]}`;
                ly.settings.subtitle = ly.settings.subtitle || subtitle;
            }

            if (ly.lytype === "compare") {
                // image config
                ly.compare = ly.compare || {};

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note;

                // settings config
                ly.settings.compare = ly.settings.compare || {};
                ly.settings.compare.layer1 = ly.settings.compare.layer1 || '';
                ly.settings.compare.layer2 = ly.settings.compare.layer2 || ''; 
            }

            // init asset
            if (ly.lytype === "asset") {
                // asset config
                ly.asset = ly.asset || {};

                // notes config
                ly.allowNote = false;
                ly.note = ly.note || {};
                ly.note.schema = ly.note.note;

                // settings config
                ly.settings.asset = ly.settings.asset || {};
                ly.settings.asset.edit = ly.settings.asset.edit || false;
            }
            return ly;
        });

        data.layers.sort((a: any, b: any) => a.settings.basic.order - b.settings.basic.order);

        data.settings = data.settings || {}
        data.settings.viewport = data.settings.viewport || {zoom: 4, center: [-32.726148064681844, -60.763927102088935]};
        
        setMap({...map, ...data});
        
    }

    // inicializacion
    useEffect(() => {
        loadMap(props.mapId || "");

        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <AppBar position="absolute" >
                <Toolbar className={classes.toolbar} disableGutters={true}>
                    <Box sx={{ marginLeft: "40px" }}>
                        <img alt="logo" className={classes.logo} src="/assets/images/logo-b.png" />
                    </Box>
                    <Box sx={{ flexGrow: 2 }}></Box>
                    <Box>
                        {map?.group?.site?.title}
                    </Box>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <Box sx={{marginTop: "63px"}}>
                    <AkopicaMapMenu
                        editable={false}
                        map={map}
                        setMap={setMap}
                        mapRef={mapInstance}
                        onChangeLayer={handleChangeLayer}
                        onRemoveLayer={handleRemoveLayer}
                        onEditLayer={handleEditLayer}
                    />
                </Box>
                {map.settings.viewport.center &&
                <MapContainer
                    whenCreated={map => setMapInstance(map)}
                    //ref={setMapInstance}
                    center={map.settings.viewport.center}
                    zoom={map.settings.viewport.zoom}
                    zoomControl={false}
                    minZoom={2}
                    maxZoom={21}
                    style={{
                        height: "calc(92vh + 16px)",
                        width: '100%',
                        margin: '0 auto',
                    }}
                >

                    <ZoomControl position='bottomright' />
                    {/* <FullscreenControl position="bottomleft" /> */}

                    {mapInstance && map.layers &&
                    map.layers.map((layer: any) => {
                        return (
                            <AkopicaLayer
                                key={ layer.id }
                                public={true}
                                allowNote={ layer.allowNote }
                                layer={ layer }
                                lytype={ layer.lytype }
                                data={ layer.locations }
                                dataset={ layer.dataset }
                                maxZoom="22"
                                layers={map.layers}
                                mapRef={mapInstance}
                                intoLayer={intoLayer}
                                setIntoLayer={setIntoLayer}
                                onChangeMap={handleChangeMap}
                                onChangeLayer={handleChangeLayer}
                            />
                        )
                    })}
                    
                    {/* <LayerGroupSideBySide /> */}
                    
                </MapContainer>
                }
            </main>
        </Box>
    )
}

export default PublicMapPage;
