import { useMap } from "react-leaflet";
import L from "leaflet";
import "./../../../../../node_modules/leaflet.tilelayer.colorfilter/src/leaflet-tilelayer-colorfilter.js"; 
import { useEffect, useState, useRef } from "react";

const ColorFilterTileLayer = (props) => {
    const { map } = useMap();
    const [layerMap, setLayerMap] = useState();

    const layerRef = useRef();

    useEffect(() => () => {
        layerRef.current = layerMap;
    },[layerMap]);

    useEffect(() => {

        /* 
        let myFilter = [
            'blur:0px',
            'brightness:95%',
            'contrast:130%',
            'grayscale:20%',
            'hue:290deg',
            'opacity:20%',
            'invert:100%',
            'saturate:300%',
            'sepia:10%',
        ]; */

        props.layer.settings=props.layer.settings || {}
        props.layer.settings.tile=props.layer.settings.tile || {}
        props.layer.settings.tile.opacity=props.layer.settings.tile.opacity || 100;
        props.layer.settings.tile.brightness=props.layer.settings.tile.brightness || 100;
        props.layer.settings.tile.contrast=props.layer.settings.tile.contrast || 100;
        props.layer.settings.tile.hue=props.layer.settings.tile.hue || 0;
        props.layer.settings.tile.saturate=props.layer.settings.tile.saturate || 100;

        let opacity=props.layer.settings.tile.opacity + '%';
        let brightness=props.layer.settings.tile.brightness  + '%';
        let contrast=props.layer.settings.tile.contrast + '%';
        let hue=props.layer.settings.tile.hue + 'deg';
        let saturate=props.layer.settings.tile.saturate + '%';
        let myFilter = [
            `brightness:${brightness}`,
            `contrast:${contrast}`,
            `opacity:${opacity}`,
            `hue:${hue}`,
            `saturate:${saturate}`,
        ];

        let layer = L.tileLayer.colorFilter(props.url, {
            maxZoom: 23,
            transparent: true,
            opacity: 1,
            tms: true,
            filter: myFilter,
        });

        layer.addTo(map);

        setLayerMap(layer);

        // returned function will be called on component unmount 
        return () => {
            layer=layerRef.current;
            map.removeLayer(layer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (layerMap) {
        
            let opacity=props.layer.settings.tile.opacity + '%';
            let brightness=props.layer.settings.tile.brightness  + '%';
            let contrast=props.layer.settings.tile.contrast + '%';
            let hue=props.layer.settings.tile.hue + 'deg';
            let saturate=props.layer.settings.tile.saturate + '%';
            let newFilter = [
                `brightness:${brightness}`,
                `contrast:${contrast}`,
                `opacity:${opacity}`,
                `hue:${hue}`,
                `saturate:${saturate}`,
            ];
            layerMap.updateFilter(newFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.layer.settings.tile])

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default ColorFilterTileLayer;
