import React, { ReactEventHandler, useState, useEffect, createRef, useRef, Ref } from "react";
import { FeatureGroup } from "react-leaflet";
import { LeafletMouseEvent, LayerEvent, geoJSON, TooltipOptions, PathOptions, Icon, LatLng, marker } from "leaflet";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import AnnotationDialog from "./AnnotationDialog";
import { EditControl } from "react-leaflet-draw";
import { createAnnotation, deleteAnnotation, getAnnotationGeojson, updateAnnotation } from "../../../services";
import AnnotationConfirmDialog from "./AnnotationConfirmDialog";
import SnackbarObject from '../../../../lib/components/Snackbar/SnackbarObject';
import { useTranslation } from "react-i18next";

interface MyProps {
    public: boolean;
    layer: any,
    drawControl: any,
    allowNote: boolean,
    setDrawControl: Function,
    onChangeMap: Function,
    onChangeLayer: Function
}

export const tooltipOptions: TooltipOptions = {
    permanent: false,
    direction: "center",
    opacity: 0.7,
};

const shapeStyle: PathOptions = {
    color: "#3388ff",
    //dashArray: [8],
    fill: true,
    fillOpacity: 0.2,
    opacity: 0.5,
    stroke: true,
    weight: 4
}

function AnnotationLayer(props: MyProps) {
    const layer = props.layer;
    const { t } = useTranslation();
    const settings = props.layer.settings || {};
    const annotationGroupRef = useRef<L.FeatureGroup>();

    const [selectShape, setSelectShape] = useState({ open: false, layer: undefined , saved: false, isNew: false});
    const [ openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState<{msg: string, severity: string}>({msg:"", severity:"success"});

    function onCreatePath(event: ReactEventHandler) {
        const { layer } = event as any;
        setSelectShape({ open: true, layer: layer, saved: false, isNew: true});
    }

    useEffect(() => {
        if(!selectShape.open && selectShape.saved) {
            loadShapes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectShape.open, selectShape.saved, annotationGroupRef.current])

    useEffect(() => {
        loadShapes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.layer?.settings?.color])

    useEffect(() => {
        if(!settings?.annotation?.edit) {
            props.drawControl?.remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings?.annotation?.edit])

    async function saveShapes() {
        try {
            const data = selectShape?.layer as any;
            if (!data) {
                console.error("Data error: no feature geojson data found.");
                return;
            }
    
            const geojsonData = data?.toGeoJSON();
            const annotation = {
                _geom: geojsonData?.geometry,
                label: geojsonData.properties.label,
                note: geojsonData.properties.note
            }   
            if (selectShape.isNew) {
                await createAnnotation(layer.id, annotation);
                setSnackBarMsg({msg: t("The Annotation has been created"), severity:"success"});
            } else {    
                await updateAnnotation(layer.id, geojsonData.properties._id, annotation);
                setSnackBarMsg({msg: t("The Annotation has been updated"), severity:"success"});
            }
            setOpenSnackbar(true);

        } catch (e) {
            console.log(e);
            setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            setOpenSnackbar(true);
        }
    }

    async function onOpenConfirmDeleteDialog() {
        setSelectShape({ ...selectShape, open: false });
        setOpenConfirmDelete(true);
    };

    async function onCloseConfirmDeleteDialog() {
        setSelectShape({ open: false, layer: undefined, saved: false, isNew: false });
        setOpenConfirmDelete(false);
    };
    
    async function onDeleteShape() {
        try{
            const data = selectShape?.layer as any;
            const geojsonData = data?.toGeoJSON();
    
            await deleteAnnotation(layer.id, geojsonData.properties._id)
            
            annotationGroupRef.current?.removeLayer(data);
            setSelectShape({ open: false, layer: undefined, saved: true, isNew: false });
            
            props.layer.settings.annotation.updated = true;
            props.onChangeLayer(props.layer);

            setOpenConfirmDelete(false);
            setSnackBarMsg({msg: t("The Annotation has been deleted"), severity:"success"});
            setOpenSnackbar(true);
        } catch (e) {
            console.log(e);
            setSnackBarMsg({msg:t("Sorry, an unexpected error has ocurred"), severity:"error"});
            setOpenSnackbar(true);
        }
    };

    async function loadShapes() {
        const dataset = layer.dataset;
        if (dataset?.id == null || dataset.dstype !== "annotation") {
            console.error("Dataset null or type not supported.");
            return;
        }

        try {
            const data = await getAnnotationGeojson(layer.id);
         
            if (data === "") {
                return;
            }

            const annotationGroup = annotationGroupRef.current;
            annotationGroup?.clearLayers();

            const features = data.features;

            let colorValue = props.layer.settings.color || "#3388ff";

            let linepoints : LatLng[] = [];
            
            features?.forEach((feature: any) => {
                if (feature.geometry.type === "Point") {
                    const lat = feature.geometry.coordinates[1];
                    const lon = feature.geometry.coordinates[0];
    
                    if (!lat || !lon) {
                 
                        return;
                    }

                    let labelValue = ("[" + lon + ", " + lat + "]");
                    if (feature.properties.title != null) {
                        labelValue = "<b>" + feature.properties.title + "</b>" ;
                    }
    
                    const center = new LatLng(lat, lon);
               
                    let newLayer;
                  
                    const layerMark = marker(center, {icon: new Icon({ iconUrl: markerIconPng })});
                    newLayer = layerMark;
                    
                    newLayer.bindTooltip(labelValue);
                    
                    (newLayer as any).featureProperties = feature.properties;
                    
                    annotationGroup?.addLayer(newLayer);
                }
                if (feature.geometry.type === 'Polygon' || 'LineString') {
                    try {
                        const fill = feature.geometry.type === 'LineString' ? false : shapeStyle.fill;
                        geoJSON(feature, {
                            style: {...shapeStyle, color: colorValue, fill: fill},
                            pointToLayer: myPointToLayer,
                        }).eachLayer(layer => {                             
                            // bind tooltop with note title if exists
                            const l: any = layer;
                           
                            let ttLabel = `<b>${l.feature?.properties['_id']}</b>`;
                            
                            if (feature.properties.title != null) {
                                ttLabel = "<b>" + feature.properties.title + "</b>" ;
                            }
                         
                            if (ttLabel) {
                                layer.bindTooltip(ttLabel, tooltipOptions);
                            }
                            (l as any).featureProperties = feature.properties;
    
                            // add note to group
                            annotationGroup?.addLayer(layer);
                        });
                    } catch(error) {
                        console.error("Error: loading geojson dataset", [dataset, data, error]);
                    }
                }
            });

            if (linepoints.length > 0) {
                annotationGroup?.addLayer(L.polyline(linepoints));
            }

            if (!props.public) {
                props.layer.settings.annotation.updated = false;
                props.onChangeLayer(props.layer);
            }


        } catch (error) {
            console.log("Error", error);
        }
    }

    const onClickFeature = (event: LeafletMouseEvent) => {
        setSelectShape({ open: true, layer: event.propagatedFrom, saved: false, isNew: false});
    }

    const onCloseShape = (event: MouseEvent) => {
        if (selectShape.isNew) annotationGroupRef.current?.removeLayer(selectShape.layer as any);
        setSelectShape({ open: false, layer: undefined, saved: false, isNew: false });
    }

    const onSaveShape = async (event: MouseEvent) => {
        await saveShapes();
        setSelectShape({ open: false, layer: undefined, saved: true, isNew: false });
        props.layer.settings.annotation.updated = true;
        props.onChangeLayer(props.layer);
    }

    let myPointToLayer = (geoJsonPoint:any, latlng:any) => {
        return L.marker(latlng, {icon: new Icon({ iconUrl: markerIconPng })});
    }

    function onLayerAdd(event: LayerEvent) {
        const layer: any = event.layer; // leaflet layer
        const feature = layer.feature = layer.feature || {};
        feature.type = "Feature";
        feature.properties = feature.properties || {};
    }

    return (
        <React.Fragment>
            <SnackbarObject type="alert" openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} msg={snackBarMsg.msg} severity={snackBarMsg.severity}/>
            <FeatureGroup
                ref={annotationGroupRef as Ref<L.FeatureGroup<any>>}
                eventHandlers={{
                    layeradd: onLayerAdd,
                    click: onClickFeature,
                }}
            >

                {!props.public && settings?.annotation?.edit &&
                    <EditControl position="topright"
                        edit={{
                            remove: false,
                            edit: false,
                        }}
                        onCreated={onCreatePath}
                        onMounted={(drawControl: any) => {
                            props.setDrawControl(drawControl);
                        }}
                        draw={{
                            rectangle: false,
                            circle: false,
                            circlemarker: false,
                        }}
                    />
                }
            </FeatureGroup>
            {selectShape.open &&
                <AnnotationDialog 
                    open={true} 
                    layer={props.layer} 
                    featureLayer={selectShape.layer} 
                    isNew={selectShape.isNew} 
                    onClose={onCloseShape} 
                    onSave={onSaveShape} 
                    onDelete={onOpenConfirmDeleteDialog}
                    isPublic={props.public}
                />
            }
           
            <AnnotationConfirmDialog open={openConfirmDelete} onClose={onCloseConfirmDeleteDialog} onConfirm={onDeleteShape}/>
             
        </React.Fragment>
    );
}

export default AnnotationLayer;
