import { httpDelete, httpGet, httpPost, httpPut } from '../AppContext';
import { Group } from '../models/group';
import{ Dataset, Files } from '../models/dataset';
import { Page } from '../models/common';
import { UpdateInput } from "./DatasetPage";
import { InputState, UploadFileOutput } from "./DatasetNewPage";
import { BACKEND_URL, BACKEND_API_URL } from '../lib/config';

const URL_DATASETS = "/v1/datasets";
const URL_GROUPS = "/v1/groups";

export async function getGroups(): Promise<Page<Group>> {
    return httpGet<Page<Group>>(BACKEND_URL + URL_GROUPS)
}

export async function getDatasetPage(params: any): Promise<Page<Dataset>> {
    return httpGet<Page<Dataset>>(BACKEND_URL + URL_DATASETS, params);
}

export async function getDataset(datasetId: string): Promise<Dataset> {
    return httpGet<Dataset>(BACKEND_URL + URL_DATASETS + `/${datasetId}`)
}

export async function getSize(datasetId: string): Promise<Files> {
    return httpGet<Files>(BACKEND_URL + URL_DATASETS + `/${datasetId}/status`)
}

export async function createDataset(values: InputState): Promise<Dataset> {
    const params = {
        subject: values.subject,
        title: values.title,
        detail: values.detail,
        groupId: values.groupId,
        dstype: values.dstype,
        name: values.name,
        refdate: values.refdate,
    }
    return httpPost<Dataset>(BACKEND_URL + URL_DATASETS, params);
}

export async function updateDataset(datasetId: string, values: UpdateInput, feature?: boolean): Promise<Dataset> {
    const params = {
        subject: values.subject,
        title: values.title,
        detail: values.detail,
        settings: values.settings,
    }
    const url = feature ? BACKEND_API_URL : BACKEND_URL;
    return httpPut<Dataset>(url + URL_DATASETS + `/${datasetId}`, params);
}

export async function deleteDataset(datasetId: string, feature: boolean): Promise<Dataset> {
    const url = feature ? BACKEND_API_URL : BACKEND_URL;
    return httpDelete<Dataset>(url + URL_DATASETS + `/${datasetId}`);
}

export async function uploadFile(file: File, datasetId: string, feature: boolean, separator?: string) {
    const formData = new FormData();
    formData.append('file', file);
    if (separator) formData.append('separator', separator);
    const url = feature ? BACKEND_API_URL : BACKEND_URL;
    return httpPost<UploadFileOutput>(url + URL_DATASETS + `/${datasetId}/files/upload`, formData, {useJsonStringfyOnBody: false});
}

export async function cleanDataset(datasetId: string): Promise<Dataset> {
    return httpDelete<Dataset>(BACKEND_API_URL + URL_DATASETS + `/${datasetId}/files/clean`);
};
