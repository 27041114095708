import React from 'react';
import GroupListItem from './GroupListItem';
import { Theme, List, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Group } from "../models/group"
import { Trans } from "react-i18next";
import { Page } from '../models/common';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
        },
    }),
);

interface MyProps {
    data: Page<Group>,
    next: any,
    prev: any,
}

function GroupList(props: MyProps) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <List className={classes.root}>
                { 
                props.data.page.map((group: Group) => {
                    return <GroupListItem key={group.id} data={group}/>
                })
                }
            </List>
            <Box>
                <Button onClick={props.prev} startIcon={ <ChevronLeft/> }><Trans>Previous</Trans></Button>
                <Button onClick={props.next} endIcon={ <ChevronRight/> }><Trans>Next</Trans></Button>
            </Box>
        </React.Fragment>
    );
  }

export default GroupList;
